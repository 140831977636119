import React from 'react'
import {
  List,
  ListProps,
  TextField,
  ReferenceManyField,
  SingleFieldList,
  ChipField,
  DateField,
  NumberField,
} from 'react-admin'
import { ClientContractFilters } from '../../molecules/ClientContractFilters'
import { ReferenceChip } from '../../molecules/ReferenceChip'
import { TypeStateField } from '../../atoms/TypeStateField'
import { LabelledDatagrid } from '../../atoms/LabelledDatagrid'
import { FieldTooltip } from '../../atoms/FieldTooltip'

/**
 * Page that displays a list of client contracts.
 * @param {ListProps} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 * @example
 * return (
 * <ClientContractList />
 * )
 * @see {@link https://marmelab.com/react-admin/List.html|List}
 */
export const ClientContractList: React.FC<ListProps> = (props) => {
  return (
    <List
      empty={false}
      perPage={props.perPage ?? 25}
      filters={props.filters ?? <ClientContractFilters />}
      sort={props.sort ?? { field: 'createdAt', order: 'DESC' }}
      {...props}
    >
      <LabelledDatagrid rowClick="show">
        <TextField source="contractNo" />
        <TypeStateField source="state" />
        <ReferenceChip
          referenceSource="clientId"
          tooltipSource="clientNo"
          source="Client"
          chipSource="name"
          reference="clients"
          emptyText={'N/A'}
          link="show"
        />
        <ReferenceChip
          referenceSource="serviceId"
          tooltipSource="name"
          source="serviceNo"
          chipSource="name"
          reference="services"
          emptyText={'N/A'}
          link="show"
        />
        <ReferenceManyField
          label="Orders"
          reference="serviceOrders"
          target="contractIds"
        >
          <SingleFieldList linkType="show">
            <FieldTooltip titleSource="description">
              <ChipField size="small" source="orderNo" />
            </FieldTooltip>
          </SingleFieldList>
        </ReferenceManyField>
        <TextField source="reference" emptyText="N/A" />
        <TextField source="description" />
        <NumberField source="contractPeriod" />
        <DateField source="periodStart" />
        <DateField source="periodEnd" emptyText="N/A" />
        <DateField source="createdAt" />
      </LabelledDatagrid>
    </List>
  )
}
