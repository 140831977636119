import React from 'react'
import {
  Edit,
  EditProps,
  TextInput,
  required,
  TabbedForm,
  FormTab,
  SelectInput,
  FormDataConsumer,
  ArrayInput,
  SimpleFormIterator,
} from 'react-admin'
import { Box, Button } from '@mui/material'
import AddIcon from '@mui/icons-material/AddOutlined'
import { get } from 'lodash'
import { ReferenceAutocomplete } from '../../molecules/ReferenceAutocomplete'
import {
  connectorChoices,
  modeChoices,
  speedChoices,
} from '../../particles/utils'

/**
 * Page to edit a ServicePort.
 * @property {EditProps} props
 * @returns {React.FC<EditProps>}
 * @example
 * import { ServicePortEdit } from './ServicePortEdit'
 * <ServicePortEdit {...props} />
 * @todo refactor the tabs into their own template components
 */
export const ServicePortEdit: React.FC<EditProps> = (props) => {
  return (
    <Edit {...props} mutationMode="undoable">
      <TabbedForm>
        <FormTab label="general">
          <TextInput source={'name'} validate={required()} fullWidth />
          <FormDataConsumer>
            {({ formData }) => (
              <>
                <ReferenceAutocomplete
                  required
                  label="Service"
                  source="serviceId"
                  reference="services/list"
                  filter={{ clientId: formData?.clientId }}
                />
                <ReferenceAutocomplete
                  required
                  label="Site"
                  source="serviceSiteId"
                  reference="serviceSites/list"
                  filter={{
                    serviceIds: formData?.serviceId,
                    clientId: formData?.clientId,
                  }}
                />
              </>
            )}
          </FormDataConsumer>
          <SelectInput
            source="state"
            defaultValue="active"
            validate={required()}
            choices={[
              { id: 'pending', name: 'Pending' },
              { id: 'provisioning', name: 'Provisioning' },
              { id: 'cancelled', name: 'Cancelled' },
              { id: 'active', name: 'Active' },
              { id: 'terminated', name: 'Terminated' },
            ]}
            fullWidth
          />
          <FormDataConsumer>
            {({ formData, getSource }) => (
              <>
                <SelectInput
                  source={'type'}
                  label="Type"
                  choices={[
                    { id: 'fiber', name: 'Fiber' },
                    { id: 'copper', name: 'Copper' },
                  ]}
                  validate={required()}
                  fullWidth
                />
                <SelectInput
                  source={'connector'}
                  label="Connector"
                  choices={connectorChoices(formData, 'type')}
                  validate={required()}
                  fullWidth
                />
                <SelectInput
                  source={'mode'}
                  label="Mode"
                  choices={modeChoices(formData, 'type')}
                  fullWidth
                />
                <SelectInput
                  source={'speed'}
                  label="Speed"
                  choices={speedChoices(formData, 'type')}
                  fullWidth
                />
              </>
            )}
          </FormDataConsumer>
        </FormTab>
        <FormTab label="patch">
          <TextInput source={'room'} label="Room" fullWidth />
          <TextInput source={'rack'} label="Rack" fullWidth />
          <TextInput source={'panel'} label="Panel" fullWidth />
          <TextInput source={'device'} label="Device" fullWidth />
          <TextInput source={'port'} label="Port" fullWidth />
          <TextInput source={'label'} label="Label" fullWidth />
        </FormTab>
        <FormTab label="equipment">
          <ArrayInput
            source={'equipment'}
            label={false}
            sx={{ mb: 3 }}
            fullWidth
          >
            <SimpleFormIterator
              getItemLabel={(index) => `#${index + 1}`}
              addButton={
                <Button>
                  <AddIcon />
                  &nbsp;Add Equipment
                </Button>
              }
              fullWidth
            >
              <FormDataConsumer>
                {({ getSource }) => (
                  <>
                    <Box
                      display={{ xs: 'block', sm: 'flex' }}
                      sx={{ width: '100%' }}
                    >
                      <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <SelectInput
                          source={getSource?.('type') || ''}
                          label="Type"
                          choices={[
                            { id: 'router', name: 'Router' },
                            { id: 'modem', name: 'Modem' },
                            { id: 'ap', name: 'Access Point' },
                          ]}
                          validate={required()}
                          fullWidth
                        />
                      </Box>
                      <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <TextInput
                          source={getSource?.('brand') || ''}
                          label="Brand"
                          validate={required()}
                          fullWidth
                        />
                      </Box>
                      <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <TextInput
                          source={getSource?.('model') || ''}
                          label="Model"
                          validate={required()}
                          fullWidth
                        />
                      </Box>
                      <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <TextInput
                          source={getSource?.('serial') || ''}
                          label="Serial"
                          validate={required()}
                          fullWidth
                        />
                      </Box>
                    </Box>
                    <Box
                      display={{ xs: 'block', sm: 'flex' }}
                      sx={{ width: '100%' }}
                    >
                      <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <TextInput
                          source={getSource?.('hostname') || ''}
                          label="Hostname"
                          fullWidth
                        />
                      </Box>
                      <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <TextInput
                          source={getSource?.('address') || ''}
                          label="IP address"
                          fullWidth
                        />
                      </Box>
                    </Box>
                    <Box
                      display={{ xs: 'block', sm: 'flex' }}
                      sx={{ width: '100%' }}
                    >
                      <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <TextInput
                          multiline
                          source={getSource?.('notes') || ''}
                          label="Notes"
                          fullWidth
                        />
                      </Box>
                    </Box>
                  </>
                )}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="networks">
          <ArrayInput
            source={'networks'}
            label={false}
            sx={{ mb: 3 }}
            fullWidth
          >
            <SimpleFormIterator
              getItemLabel={(index) => `#${index + 1}`}
              fullWidth
              addButton={
                <Button>
                  <AddIcon />
                  &nbsp;Add Network
                </Button>
              }
            >
              <FormDataConsumer>
                {({ formData, getSource }) => (
                  <>
                    <Box
                      display={{ xs: 'block', sm: 'flex' }}
                      sx={{ width: '100%' }}
                    >
                      <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <SelectInput
                          source={getSource?.('scope') || ''}
                          label="Scope"
                          choices={[
                            { id: 'local', name: 'Local' },
                            { id: 'global', name: 'Global' },
                          ]}
                          validate={required()}
                          fullWidth
                        />
                      </Box>
                      <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <SelectInput
                          source={getSource?.('version') || ''}
                          label="Version"
                          choices={[
                            { id: 'ipv4', name: 'IPv4' },
                            { id: 'ipv6', name: 'IPv6' },
                          ]}
                          validate={required()}
                          fullWidth
                        />
                      </Box>
                      <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <SelectInput
                          source={getSource?.('allocation') || ''}
                          label="Allocation"
                          choices={[
                            { id: 'manual', name: 'Manual (static)' },
                            { id: 'dhcp', name: 'DHCP (dynamic)' },
                            { id: 'bgp', name: 'BGP session' },
                          ]}
                          validate={required()}
                          fullWidth
                        />
                      </Box>
                    </Box>
                    {get(formData, getSource?.('allocation') || '') ===
                    'bgp' ? (
                      <>
                        <Box
                          display={{ xs: 'block', sm: 'flex' }}
                          sx={{ width: '100%' }}
                        >
                          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                              source={getSource?.('localPeer') || ''}
                              label="Local BGP peer"
                              validate={required()}
                              fullWidth
                            />
                          </Box>
                          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                              source={getSource?.('remotePeer') || ''}
                              label="Remote BGP peer"
                              validate={required()}
                              fullWidth
                            />
                          </Box>
                          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                              source={getSource?.('localAS') || ''}
                              label="Local AS number"
                              validate={required()}
                              fullWidth
                            />
                          </Box>
                          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                              source={getSource?.('remoteAS') || ''}
                              label="Remote AS number"
                              validate={required()}
                              fullWidth
                            />
                          </Box>
                        </Box>
                        <Box
                          display={{ xs: 'block', sm: 'flex' }}
                          sx={{ width: '100%' }}
                        >
                          <TextInput
                            source={getSource?.('network') || ''}
                            label="Network"
                            validate={required()}
                            fullWidth
                          />
                        </Box>
                      </>
                    ) : (
                      <Box
                        display={{ xs: 'block', sm: 'flex' }}
                        sx={{ width: '100%' }}
                      >
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                          <TextInput
                            source={getSource?.('network') || ''}
                            label="Network"
                            validate={required()}
                            fullWidth
                          />
                        </Box>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                          <TextInput
                            source={getSource?.('address') || ''}
                            label="Primary address"
                            fullWidth
                          />
                        </Box>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                          <TextInput
                            source={getSource?.('usableRange') || ''}
                            label="Usable range"
                            fullWidth
                          />
                        </Box>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                          <TextInput
                            source={getSource?.('dns1') || ''}
                            label="DNS server 1"
                            fullWidth
                          />
                        </Box>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                          <TextInput
                            source={getSource?.('dns2') || ''}
                            label="DNS server 2"
                            fullWidth
                          />
                        </Box>
                      </Box>
                    )}
                    <Box
                      display={{ xs: 'block', sm: 'flex' }}
                      sx={{ width: '100%' }}
                    >
                      <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <TextInput
                          multiline
                          source={getSource?.('notes') || ''}
                          label="Notes"
                          fullWidth
                        />
                      </Box>
                    </Box>
                  </>
                )}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="notes">
          <TextInput multiline fullWidth source="notes" />
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}
