import React, { useRef, useEffect } from 'react'

/**
 * Component that displays an iframe.
 * @property {string} source - HTML Data.
 * @returns {JSX.Element} The rendered component.
 * @example
 * return (
 * <IFrame html="{html}" />
 * )
 */
export const IFrame: React.FC<{
  html: string
  style?: Record<string, unknown>
}> = (props) => {
  const iframeRef = useRef<HTMLIFrameElement>(null)

  useEffect(() => {
    const document = iframeRef.current
    if (document) {
      document.srcdoc = props.html
    }
  }, [props.html])

  return (
    <iframe
      title={props.html}
      ref={iframeRef}
      width={'100%'}
      height={'100%'}
      style={props.style}
    />
  )
}
