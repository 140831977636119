import React from 'react'
import { TextInput } from 'react-admin'
import { Box } from '@mui/material'
import { MarkdownInput } from '@react-admin/ra-markdown'

/**
 * Form input fields for creating or editing notes on a service.
 * @returns {React.FC} Form input fields for creating or editing notes on a service.
 * @example
 * <ServiceInput />
 */
export const NotesInput: React.FC = () => {
  return (
    <>
      <Box display={{ sm: 'block', md: 'flex' }} sx={{ p: 0.5 }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <TextInput
            multiline
            source="notes"
            label="Internal notes"
            fullWidth
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <TextInput
            multiline
            source="notesForClient"
            label="Notes for client"
            fullWidth
          />
        </Box>
      </Box>
      <Box display={{ sm: 'block', md: 'flex' }} sx={{ p: 0.5 }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <MarkdownInput source="deliveryNotes" fullWidth />
        </Box>
      </Box>
    </>
  )
}
