import React from 'react'
import {
  DateField,
  Show,
  ShowProps,
  TabbedShowLayout,
  Tab,
  BooleanField,
  TextField,
} from 'react-admin'
import { PageTitle } from '../../atoms/PageTitle'
import { ReferenceChip } from '../../molecules/ReferenceChip'
import { HistoryDisplayTab } from '../../templates/HistoryDisplayTab'
import { MetaListDisplay } from '../../organisms/MetaList'
import { ServiceHealthIndicatorField } from '../../atoms/ServiceHealthIndicatorField'
import { Toolbar } from '../../molecules/Toolbar'
import { ViewInGrafanaButton } from '../../atoms/ViewInGrafanaButton'

/**
 * Component to show a monitor probe.
 * @param props The Show component props
 * @see https://marmelab.com/react-admin/Show.html
 */
export const MonitorProbeShow: React.FC<ShowProps> = (props) => {
  return (
    <Show
      {...props}
      title={<PageTitle prefix="Monitor Probe" />}
      actions={
        <Toolbar showEdit>
          <ViewInGrafanaButton />
        </Toolbar>
      }
    >
      <TabbedShowLayout {...props}>
        <Tab label="summary">
          <TextField source="name" emptyText="N/A" />
          <ReferenceChip
            referenceSource="monitorTargetId"
            tooltipSource="hostname"
            source="hostname"
            chipSource="hostname"
            label="Target"
            reference="monitorTargets"
            emptyText="N/A"
            link="show"
            removeDoubleLabel
          />
          <ReferenceChip
            referenceSource="monitorProbeTypeId"
            tooltipSource="name"
            source="name"
            chipSource="name"
            label="Probe Type"
            reference="monitorProbeTypes"
            emptyText="N/A"
            link="show"
            removeDoubleLabel
          />
          <ServiceHealthIndicatorField label="Health status" source="health" />
          <TextField source="health.state" />
          <TextField source="health.severity" />
          <BooleanField source="isActive" fullWidth />
          <BooleanField source="isMuted" fullWidth />
          <DateField source="createdAt" showTime />
          <DateField source="updatedAt" showTime />
        </Tab>
        <Tab label="config" key="config">
          <MetaListDisplay source="config" />
          <small>
            This is the custom config for this probe (overrides default
            config-schema of probe type)
          </small>
        </Tab>
        <Tab label="history">
          <HistoryDisplayTab />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
