import { useNotify } from 'react-admin'
import { apiHttpClient } from '../../../providers/dataProvider'

/** This hook exports a function that handles the process of downloading a request's data as an Excel file. */
export const useExportRequestToExcelFunction = () => {
  const notify = useNotify()

  /** Downloads the request's data as an Excel file. */
  const handleRequestDownload = async (
    requestId: string,
    options?: {
      /** If defined, the request's data will be downloaded for the specified site. */
      siteId?: string
      /** If defined, the request's data will be downloaded for the specified service. It's ignored in case `siteId` is defined. */
      serviceId?: string
    }
  ) => {
    try {
      notify('Download is starting...')

      const requestUrl = `${process.env.REACT_APP_API_URL}/api/requests/${requestId}`

      let downloadUrl = `${requestUrl}/download`

      if (options?.siteId) {
        downloadUrl = `${requestUrl}/sites/${options.siteId}/download`
      } else if (options?.serviceId) {
        downloadUrl = `${requestUrl}/services/${options.serviceId}/download`
      }

      const { json } = await (apiHttpClient(downloadUrl) as Promise<{
        status: number
        json: {
          data: {
            name: string
            contentType: string
            content: string
          }
        }
      }>)

      if (json) {
        const link = document.createElement('a')
        link.href = `data:application/octet-stream;base64,${json.data?.content}`
        link.download = json.data?.name ?? 'request.xlsx'
        link.target = '_blank'
        link.click()
      }
    } catch (err) {
      notify('Something went wrong, please try again later', {
        type: 'error',
      })
    }
  }

  return handleRequestDownload
}
