import React, { useEffect, useMemo } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from '@mui/material'
import {
  ArrayField,
  Datagrid,
  DateField,
  FunctionField,
  SimpleShowLayout,
  useGetOne,
  useRecordContext,
  EditButton,
  ListContextProvider,
  useList,
  useGetList,
  SimpleForm,
  TextInput,
  useRefresh,
  useNotify,
  useDataProvider,
} from 'react-admin'
import ExpandAccordionIcon from '@mui/icons-material/ExpandMore'
import DoneIcon from '@mui/icons-material/CheckBoxOutlined'
import ToDoIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { ContactDetail } from './ContactDetail.component'
import { QuoteOrderDetailsToolbar } from './QuoteOrderDetailsToolbar.component'

interface LegalEntityData {
  address: string
  city: string
  postalCode: string
  countryCode: string
  companyName: string
  companyRegNo: string
  companyVatNo: string
  id: string
}

export interface ContactDetails {
  name: string
  emailAddress: string
  phoneNumber: string
  id: string
}

export const QuoteOrderDetailsAccordion: React.FC<{
  clientId: string
  endCustomerId: string
}> = ({ clientId, endCustomerId }) => {
  const record = useRecordContext()
  const [isExpanded, setIsExpanded] = React.useState(false)
  const refresh = useRefresh()
  const notify = useNotify()
  const dataProvider = useDataProvider()
  const [isLoading, setIsLoading] = React.useState(false)

  const quoteOrderDetails = useMemo(() => {
    return (
      record?.quotes?.[0]?.state === 'ordered' &&
      record?.quotes?.[0]?.orderDetails
    )
  }, [record])

  const { data: clientData } = useGetOne(
    `clients`,
    {
      id: clientId,
    },
    { enabled: !!clientId }
  )
  const { data: endCustomerData } = useGetOne(
    `endCustomers`,
    {
      id: endCustomerId,
    },
    { enabled: !!endCustomerId }
  )
  useEffect(() => {
    if (record?.quotes[0]?.isOrderConfirmed || record?.quotes[0]?.isFulfilled) {
      setIsExpanded(false)
      return
    }
    if (quoteOrderDetails || record.isQuoteSent) {
      setIsExpanded(true)
    }
  }, [quoteOrderDetails, record.isQuoteSent, record.quotes])

  const toggleAccordion = () => {
    setIsExpanded(!isExpanded)
  }

  const legalEntity = quoteOrderDetails?.contract?.legalEntity

  const legalEntityData: LegalEntityData =
    legalEntity === 'client' ? clientData : endCustomerData

  const listContext = useList({ data: [legalEntityData] })

  const { data: allContacts } = useGetList<ContactDetails>('clientContacts', {
    filter: { clientId: clientId },
  })

  const extractMatchingContacts = (
    allContacts: ContactDetails[] | undefined,
    roleContacts: { existingId: string }[]
  ) => {
    if (!roleContacts || !allContacts) return []
    const contractContactIds = new Set(
      roleContacts?.map((roleContact) => roleContact.existingId)
    )
    return allContacts?.filter((contact) => contractContactIds.has(contact.id))
  }

  const useContacts = (
    allContacts: ContactDetails[] | undefined,
    quoteOrderDetails: any
  ) => {
    return useMemo(
      () => ({
        localContacts: extractMatchingContacts(
          allContacts,
          quoteOrderDetails?.sites?.[0].contacts ?? []
        ),
        orderContacts: extractMatchingContacts(
          allContacts,
          quoteOrderDetails?.order?.contacts ?? []
        ),
        billingContacts: extractMatchingContacts(
          allContacts,
          quoteOrderDetails?.billing?.contacts ?? []
        ),
        deliveryContacts: extractMatchingContacts(
          allContacts,
          quoteOrderDetails?.delivery?.contacts ?? []
        ),
        contractContacts: extractMatchingContacts(
          allContacts,
          quoteOrderDetails?.contract?.contacts ?? []
        ),
      }),
      [allContacts, quoteOrderDetails]
    )
  }

  const {
    localContacts,
    orderContacts,
    billingContacts,
    deliveryContacts,
    contractContacts,
  } = useContacts(allContacts, quoteOrderDetails)

  const handleSave = async (formData: any) => {
    setIsLoading(true)
    try {
      const data = await dataProvider.customRequest(
        'quotes',
        `${record.quotes[0].id}/orderDetails`,
        {
          data: {
            action: 'save',
            sites: formData.orderDetails.sites,
            order: quoteOrderDetails.order,
            billing: quoteOrderDetails.billing,
            delivery: quoteOrderDetails.delivery,
            contract: quoteOrderDetails.contract,
          },
          method: 'PUT',
        }
      )
      if (data?.json?.data?.id) {
        notify('Order has been saved')
        refresh()
      }
    } catch (error: any) {
      notify(error.message, { type: 'error' })
    }

    setIsLoading(false)
  }

  const handleConfirm = async () => {
    setIsLoading(true)

    try {
      const data = await dataProvider.customRequest(
        'quotes',
        `${record.quotes[0].id}/orderDetails`,
        {
          data: {
            action: 'confirm',
            sites: quoteOrderDetails.sites,
            order: quoteOrderDetails.order,
            billing: quoteOrderDetails.billing,
            delivery: quoteOrderDetails.delivery,
            contract: quoteOrderDetails.contract,
          },
          method: 'PUT',
        }
      )
      if (data?.json?.data?.id) {
        notify('Order has been confirmed')
        refresh()
      }
    } catch (error: any) {
      notify(error.message, { type: 'error' })
    }

    setIsLoading(false)
    setIsExpanded(false)
  }

  if (!record) return null

  return (
    <Accordion expanded={isExpanded} disableGutters>
      <AccordionSummary
        expandIcon={<ExpandAccordionIcon color="info" />}
        onClick={toggleAccordion}
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h6" alignItems="center" display="flex" gap={1}>
              {record?.quotes[0]?.isOrderConfirmed ? (
                <DoneIcon sx={{ mr: 1 }} />
              ) : (
                <ToDoIcon sx={{ mr: 1 }} />
              )}{' '}
              Customer: Order
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        {quoteOrderDetails ? (
          <SimpleForm
            record={record.quotes[0]}
            onSubmit={handleSave}
            toolbar={
              <QuoteOrderDetailsToolbar
                onConfirm={handleConfirm}
                isLoading={isLoading}
                clientId={clientId}
                requestProductId={`${record.id}`}
              />
            }
            sx={{ padding: 0, '& legend': { display: 'none' } }}
          >
            <Grid
              container
              rowSpacing={1}
              columnSpacing={3}
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Grid item xs={12} pb={2}>
                <Typography variant="body1">
                  🎉 Customer has ordered this quoted product at{' '}
                  <DateField variant="body1" source="orderedAt" showTime />
                </Typography>
              </Grid>
              <Grid item xs={12} xl={6}>
                <Card variant="outlined" sx={{ minHeight: 250 }}>
                  <CardHeader
                    title="Site"
                    titleTypographyProps={{ variant: 'body1' }}
                    sx={{ pb: 0 }}
                  />
                  <CardContent>
                    <SimpleShowLayout sx={{ p: 0, m: 0 }}>
                      <Box display={'flex'} alignItems={'center'}>
                        <Typography variant="body1" p={2}>
                          Demarcation Point:
                        </Typography>
                        <TextInput
                          source="orderDetails.sites[0].demarcationPoint"
                          label={false}
                        />
                      </Box>
                      <ArrayField
                        source={'orderDetails.sites[0].contacts'}
                        label={'Sites contacts'}
                      >
                        {localContacts.map((contact) => (
                          <Card key={contact.id}>
                            <CardContent>
                              <ContactDetail contact={contact} />
                            </CardContent>
                          </Card>
                        ))}
                      </ArrayField>
                    </SimpleShowLayout>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} xl={6}>
                <Card variant="outlined" sx={{ minHeight: 280 }}>
                  <CardHeader
                    title="Order"
                    titleTypographyProps={{ variant: 'body1' }}
                    sx={{ pb: 0 }}
                  />
                  <CardContent>
                    <SimpleShowLayout sx={{ p: 0, mt: 6.5 }}>
                      <ArrayField
                        source={'orderDetails.order.contacts'}
                        label={'Order contacts'}
                      >
                        {orderContacts.map((contact) => (
                          <Card key={contact.id}>
                            <CardContent>
                              <ContactDetail contact={contact} />
                            </CardContent>
                          </Card>
                        ))}
                      </ArrayField>
                    </SimpleShowLayout>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} xl={6}>
                <Card variant="outlined" sx={{ minHeight: 250 }}>
                  <CardHeader
                    title="Billing"
                    titleTypographyProps={{ variant: 'body1' }}
                    sx={{ pb: 0 }}
                  />
                  <CardContent>
                    <SimpleShowLayout sx={{ p: 0, m: 0 }}>
                      <ArrayField
                        source={'orderDetails.billing.contacts'}
                        label={'Billing contacts'}
                      >
                        {billingContacts.map((contact) => (
                          <Card key={contact.id}>
                            <CardContent>
                              <ContactDetail contact={contact} />
                            </CardContent>
                          </Card>
                        ))}
                      </ArrayField>
                    </SimpleShowLayout>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} xl={6}>
                <Card variant="outlined" sx={{ minHeight: 250 }}>
                  <CardHeader
                    title="Delivery"
                    titleTypographyProps={{ variant: 'body1' }}
                    sx={{ pb: 0 }}
                  />
                  <CardContent>
                    <SimpleShowLayout sx={{ p: 0, m: 0 }}>
                      <ArrayField
                        source={'orderDetails.delivery.contacts'}
                        label={'Delivery contacts'}
                      >
                        {deliveryContacts.map((contact) => (
                          <Card key={contact.id}>
                            <CardContent>
                              <ContactDetail contact={contact} />
                            </CardContent>
                          </Card>
                        ))}
                      </ArrayField>
                    </SimpleShowLayout>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={24} xl={12}>
                <Card variant="outlined" sx={{ minHeight: 250 }}>
                  <CardHeader
                    title="CSO"
                    titleTypographyProps={{ variant: 'body1' }}
                    sx={{ pb: 0 }}
                  />
                  <CardContent>
                    <SimpleShowLayout sx={{ p: 0, m: 0 }}>
                      <ArrayField
                        source={'orderDetails.contract.contacts'}
                        label={'CSO contacts'}
                      >
                        {contractContacts.map((contact) => (
                          <Card key={contact.id}>
                            <CardContent>
                              <ContactDetail contact={contact} />
                            </CardContent>
                          </Card>
                        ))}
                      </ArrayField>
                      {(clientData || endCustomerData) && (
                        <ListContextProvider value={listContext}>
                          <Typography p={2}>Legal Entity</Typography>
                          <Datagrid bulkActionButtons={false}>
                            <FunctionField
                              label="Address"
                              render={(record: LegalEntityData) =>
                                record?.address
                              }
                            />
                            <FunctionField
                              label="City"
                              render={(record: LegalEntityData) => record?.city}
                            />
                            <FunctionField
                              label="Postal Code"
                              render={(record: LegalEntityData) =>
                                record?.postalCode
                              }
                            />
                            <FunctionField
                              label="Country Code"
                              render={(record: LegalEntityData) =>
                                record?.countryCode
                              }
                            />
                            <FunctionField
                              label="Company Name"
                              render={(record: LegalEntityData) =>
                                record?.companyName
                              }
                            />
                            <FunctionField
                              label="Company Reg No"
                              render={(record: LegalEntityData) =>
                                record?.companyRegNo
                              }
                            />
                            <FunctionField
                              label="Company Vat No"
                              render={(record: LegalEntityData) =>
                                record?.companyVatNo
                              }
                            />
                            <EditButton resource={`clients`} />
                          </Datagrid>
                        </ListContextProvider>
                      )}
                    </SimpleShowLayout>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </SimpleForm>
        ) : (
          <FunctionField
            variant="body1"
            color="text.secondary"
            render={() => (
              <Typography variant="h6" p={2}>
                ℹ️ Customer has not ordered this option (yet)
              </Typography>
            )}
          />
        )}
      </AccordionDetails>
    </Accordion>
  )
}
