import React from 'react'
import { useValidation } from './DocumentCategory.hooks'
import { Edit, EditProps, SimpleForm, TextInput, required } from 'react-admin'

/**
 * Edit an existing document category.
 * @property {EditProps} props
 * @returns {React.FC<EditProps>}
 * @example
 * <DocumentCategoryEdit />
 * @see {@link https://marmelab.com/react-admin/CreateEdit.html|Edit}
 * @see {@link https://marmelab.com/react-admin/Inputs.html|Inputs}
 * @see {@link https://marmelab.com/react-admin/Validation.html|Validation}
 */
export const DocumentCategoryEdit: React.FC<EditProps> = (props) => {
  const validation = useValidation()

  return (
    <Edit {...props} mutationMode="undoable">
      <SimpleForm>
        <TextInput disabled label="Id" source="id" fullWidth />
        <TextInput source="key" validate={validation} fullWidth />
        <TextInput source="name" validate={required()} fullWidth />
      </SimpleForm>
    </Edit>
  )
}
