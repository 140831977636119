import { MonitorProbeTypeCreate } from './Create.component'
import { MonitorProbeTypeEdit } from './Edit.component'
import { MonitorProbeTypeList } from './List.component'
import { MonitorProbeTypeShow } from './Show.component'

export default {
  list: MonitorProbeTypeList,
  show: MonitorProbeTypeShow,
  create: MonitorProbeTypeCreate,
  edit: MonitorProbeTypeEdit,
}
