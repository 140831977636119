import React from 'react'
import { Divider } from '@mui/material'
import { AddTicketComment } from '../../organisms/AddTicketComment'
import { TicketCommentList } from '../../organisms/TicketCommentList'

/**
 * Section of the ticket show page that displays the comments.
 * @returns {React.FC}
 * @example
 * import { TicketCommentTab } from './TicketComments.component.tsx'
 * <TicketCommentTab />
 * @see {@link https://mui.com/components/dividers/|MUI Dividers}
 */
export const TicketCommentTab: React.FC = () => {
  return (
    <>
      <TicketCommentList />
      <Divider>Add a new comment</Divider>
      <AddTicketComment />
    </>
  )
}
