/**
 * Version label positioning styles.
 * @returns {React.CSSProperties} The login styles.
 * @exports useVersionLabelStyles
 * @see https://mui.com/styles/api/#css
 */
export const useVersionLabelStyles = () => {
  return {
    position: 'fixed',
    right: '32px',
    bottom: '24px',
    zIndex: 1,
    opacity: 0.45,
    color: '#fff',
  }
}

/**
 * Login page avatar styles.
 * @returns {React.CSSProperties} The avatar styles.
 * @exports useAvatarStyles
 * @see https://mui.com/styles/api/#css
 */
export const useAvatarStyles = () => {
  return {
    margin: '2em',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
  }
}

/**
 * Login card styles.
 * @returns {React.CSSProperties} The login card styles.
 * @exports useLoginCardStyles
 * @see https://mui.com/styles/api/#css
 */
export const useLoginCardStyles = () => {
  return {
    marginTop: '6em',
    textAlign: 'center',
    borderRadius: 4,
    padding: 2,
  }
}
