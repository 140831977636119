import { useRecordContext, useDataProvider, useNotify } from 'react-admin'
import React, { useState, useEffect, useRef } from 'react'
import get from 'lodash/get'

/**
 * Represents a statistic graph field component.
 * @returns The rendered StatisticGraphField component.
 * @see useRecordContext
 * @see useDataProvider
 * @see useNotify
 * @see useState
 * @see useEffect
 * @see useRef
 * @see get
 * @see https://marmelab.com/react-admin/Fields.html#functionfield
 * @see https://reactjs.org/docs/hooks-reference.html#useref
 * @see https://lodash.com/docs/4.17.15#get
 * @see https://reactjs.org/docs/hooks-reference.html#usestate
 * @see https://reactjs.org/docs/hooks-reference.html#useeffect
 *
 */
export const StatisticGraphField: React.FC = () => {
  const record = useRecordContext()
  const [imageData, setImageData] = useState()
  const [contentType, setContentType] = useState()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const loaded = useRef(false)
  const value = get(record, 'id')

  useEffect(() => {
    const fetchData = async () => {
      if (!loaded.current) {
        loaded.current = true
        try {
          const { data } = await dataProvider.getOne('serviceStatistics', {
            id: `${value || record.id}/graph`,
          })
          if (data && data.content) {
            setImageData(data.content)
            setContentType(data.contentType)
          }
        } catch (error: any) {
          notify(error.message)
        }
      }
    }

    fetchData()
  }, [dataProvider, notify, record.id, value])

  if (!loaded.current) {
    return <p>Loading...</p>
  }

  return (
    <img
      src={`data:image/${contentType};base64,${imageData}`}
      alt="Statistics Graph"
    />
  )
}
