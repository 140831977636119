import React from 'react'
import {
  List,
  ListProps,
  RaRecord,
  TextField,
  DateField,
  FunctionField,
  ChipField,
} from 'react-admin'
import { RequestFilters } from '../../molecules/RequestFilters'
import { TypeStateField } from '../../atoms/TypeStateField'
import { LastActivityTextField } from '../../atoms/LastActivityTextField'
import { LabelledDatagrid } from '../../atoms/LabelledDatagrid'
import { ReferenceChip } from '../../molecules/ReferenceChip'
import { EllipsedTextField } from '../../atoms/EllipsedField'

/**
 * Page to display a list of requests.
 * @property {ListProps} props
 * @returns {JSX.Element}
 * @example <RequestList />
 */
export const RequestList: React.FC<ListProps> = (props) => {
  return (
    <List
      empty={false}
      filters={<RequestFilters />}
      sort={{ field: 'createdAt', order: 'DESC' }}
      {...props}
    >
      <LabelledDatagrid rowClick="show" optimized bulkActionButtons={false}>
        <TextField source="requestNo" emptyText={'N/A'} />
        <TypeStateField source="state" fullWidth />
        <EllipsedTextField source="reference" emptyText={'N/A'} />
        <ReferenceChip
          referenceSource="clientId"
          tooltipSource="clientNo"
          source="Client"
          reference="clients"
          emptyText={'N/A'}
          link="show"
          chipSource="name"
        />
        <ReferenceChip
          referenceSource="projectId"
          tooltipSource="projectNo"
          source="title"
          reference="projects"
          label="Project"
          emptyText="N/A"
          link="show"
          chipSource="title"
          removeDoubleLabel
        />
        <FunctionField
          label={'Services'}
          render={(record: RaRecord) => (
            <ChipField
              size="small"
              record={{ value: record.services ? record.services.length : 0 }}
              source="value"
            />
          )}
        />
        <LastActivityTextField />
        <DateField source="createdAt" showTime />
      </LabelledDatagrid>
    </List>
  )
}
