import React, { useEffect, useState } from 'react'
import {
  SimpleShowLayout,
  FunctionField,
  TabbedShowLayoutTabs,
  Show,
  DatagridConfigurable,
  List,
  NumberField,
  TextField,
  useCreate,
  useRefresh,
  useNotify,
  useGetOne,
  FilterButton,
  TopToolbar,
  useRecordContext,
} from 'react-admin'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Stack,
  Tooltip,
  Typography,
  Chip,
  Card,
  CardContent,
} from '@mui/material'
import { useParams } from 'react-router-dom'
import CheckIcon from '@mui/icons-material/CheckCircleOutline'
import AddIcon from '@mui/icons-material/Add'
import OrderedIcon from '@mui/icons-material/ShoppingBag'
import { ProductProcurementAccordion } from '../../molecules/ProductProcurementAccordion'
import { ProductSalesMarginAccordion } from '../../molecules/ProductSalesMarginAccordion'
import { RequestProductHistory } from '../../molecules/RequestProductHistory'
import { ProductsTabbedShowLayoutProps } from './ProductsTabbedShowLayout.types'
import { TabbedShowLayout } from '../../molecules/CustomTabbedShowLayout/CustomTabbedShowLayout.component'
import { CurrencyField } from '../../atoms/CurrencyField'
import { ReferenceChip } from '../../molecules/ReferenceChip'
import { ReferenceText } from '../../molecules/ReferenceText'
import { ProductDetailsExpand } from '../ProductDetailsExpand'
import { useProductFilters } from '../../particles/filters'
import { QuoteOrderDetailsAccordion } from '../../molecules/QuoteOrderDetailsAccordion'
import { ServiceDetailsAccordion } from '../../molecules/ServiceDetailsAccordion'

/** Component that renders tabs based on requested products - uses a RequestProducts List as its context */
export const ProductsTabbedShowLayout: React.FC<
  ProductsTabbedShowLayoutProps
> = (props) => {
  const record = useRecordContext()

  const [isDirty, setIsDirty] = useState(false)
  const [
    lastRecalculationRequestTimestamp,
    setLastRecalculationRequestTimestamp,
  ] = useState(Date.now())
  const { requestId, serviceId } = useParams()
  const { data: requestServiceData } = useGetOne(
    `requests/${requestId}/services`,
    {
      id: serviceId,
    }
  )

  const [isSelectSourceDialogOpen, setIsSelectSourceDialogOpen] =
    useState(false)

  const [selectedSource, setSelectedSource] = useState<
    'searchResults' | 'catalog'
  >()

  const [isCreateCustomOptionDialogOpen, setIsCreateCustomOptionDialogOpen] =
    useState(false)

  const [selectedProduct, setSelectedProduct] = useState<any>()

  const [activeTab, setActiveTab] = useState<number>(0)

  const productFilters = useProductFilters()

  const [create] = useCreate()

  const refresh = useRefresh()

  const notify = useNotify()

  const handleProductProcurementAccordionSave = () => {
    setLastRecalculationRequestTimestamp(Date.now())
  }

  // helper to determine whether the product in question is custom
  const checkIsCustom = (productId: string) => {
    return (
      requestServiceData?.products?.some(
        (p: { id: string; isCustom: boolean }) =>
          p.id === productId && p.isCustom
      ) && true
    )
  }

  // helper to determine whether the product in question is ordered
  const checkIsOrdered = (productId: string) => {
    return (
      requestServiceData?.products?.some(
        (p: { id: string; isOrdered: boolean }) =>
          p.id === productId && p.isOrdered
      ) && true
    )
  }

  // set the first ordered option as active tab on load
  useEffect(() => {
    const orderedProduct = requestServiceData?.products?.find(
      (p: { id: string; isOrdered?: boolean }) => p.isOrdered
    )
    if (orderedProduct?.[0]) {
      setActiveTab(
        record.products?.findIndex(
          (p: { id: string }) => orderedProduct?.[0]?.id === p.id
        )
      )
    }
  }, [requestServiceData, record])

  return (
    <Card>
      <CardContent>
        {record?.products?.length > 0 ? (
          <TabbedShowLayout
            value={activeTab}
            syncWithLocation={false}
            tabs={
              <TabbedShowLayoutTabs scrollButtons={true} variant="fullWidth" />
            }
            sx={{
              '.RaTabbedShowLayout-content': {
                padding: 0,
              },
            }}
            isDirty={isDirty}
          >
            {record?.products?.map((product: any, index: number) => {
              const tabTitle = `Option ${index + 1}`
              return (
                <TabbedShowLayout.Tab
                  value={index}
                  key={index}
                  sx={{ p: 0 }}
                  label={
                    <Typography
                      variant="body2"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        fontWeight: 'bold',
                      }}
                    >
                      {product?.isQuoteSent && <CheckIcon sx={{ mr: 1 }} />}
                      {tabTitle}
                      {checkIsCustom(product?.id) && (
                        <Chip
                          label="Custom"
                          size="small"
                          sx={{ marginLeft: 1, fontSize: 8 }}
                        />
                      )}
                      {checkIsOrdered(product?.id) && (
                        <OrderedIcon color="success" sx={{ marginLeft: 1 }} />
                      )}
                    </Typography>
                  }
                >
                  <Show
                    resource={`requestProducts`}
                    id={product.id}
                    title={' '}
                  >
                    <SimpleShowLayout>
                      <Grid container spacing={1}>
                        <Grid item flex={1}>
                          <Stack gap={1}>
                            <ProductProcurementAccordion
                              setIsDirty={setIsDirty}
                              onSave={handleProductProcurementAccordionSave}
                            />
                            <ProductSalesMarginAccordion
                              lastRecalculationRequestTimestamp={
                                lastRecalculationRequestTimestamp
                              }
                            />
                            <QuoteOrderDetailsAccordion
                              clientId={props.clientId}
                              endCustomerId={props.endCustomerId}
                            />
                            <ServiceDetailsAccordion />
                          </Stack>
                        </Grid>
                        <Grid item width={320}>
                          <RequestProductHistory
                            clientId={props.clientId}
                            reference={tabTitle}
                          />
                        </Grid>
                      </Grid>
                    </SimpleShowLayout>
                  </Show>
                </TabbedShowLayout.Tab>
              )
            })}
            <TabbedShowLayout.Tab
              disabled={!requestServiceData?.isReviewConfirmed}
              value={9999}
              key={9999}
              label={
                <Typography
                  variant="body2"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    fontWeight: 'bold',
                  }}
                >
                  <AddIcon /> CUSTOM
                </Typography>
              }
              onClick={() => setIsSelectSourceDialogOpen(true)}
            >
              {isSelectSourceDialogOpen && (
                <Dialog
                  open={isSelectSourceDialogOpen}
                  onClose={() => setIsSelectSourceDialogOpen(false)}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {'Select the source for the custom option?'}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      The custom option can be added from the original search
                      results or from the catalog
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => {
                        setSelectedSource('searchResults')
                        setIsSelectSourceDialogOpen(false)
                      }}
                    >
                      Search Results
                    </Button>
                    <Button
                      onClick={() => {
                        setSelectedSource('catalog')
                        setIsSelectSourceDialogOpen(false)
                      }}
                      autoFocus
                    >
                      Catalog
                    </Button>
                  </DialogActions>
                </Dialog>
              )}
              {isCreateCustomOptionDialogOpen && (
                <Dialog
                  open={isCreateCustomOptionDialogOpen}
                  onClose={() => setIsCreateCustomOptionDialogOpen(false)}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {'Create custom option?'}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Do you want to create a custom option based on the
                      selected product?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => {
                        setIsCreateCustomOptionDialogOpen(false)
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        create(
                          `requestServices/${serviceId}/customProducts`,
                          {
                            data:
                              selectedSource === 'catalog'
                                ? { productId: selectedProduct.id }
                                : { requestProductId: selectedProduct.id },
                          },
                          {
                            onSuccess: () => {
                              refresh()
                              setIsCreateCustomOptionDialogOpen(false)
                            },
                            onError: () => {
                              notify('Error creating custom option', {
                                type: 'error',
                              })
                              setIsCreateCustomOptionDialogOpen(false)
                            },
                          }
                        )
                      }}
                      autoFocus
                    >
                      Create
                    </Button>
                  </DialogActions>
                </Dialog>
              )}
              <List
                empty={false}
                perPage={25}
                filters={productFilters}
                filterDefaultValues={{
                  serviceCategoryId: requestServiceData?.serviceCategoryId,
                  countryCode: requestServiceData?.sites?.[0]?.countryCode,
                }}
                actions={
                  <TopToolbar>
                    <FilterButton />
                  </TopToolbar>
                }
                sort={{ field: 'code', order: 'ASC' }}
                resource={
                  selectedSource === 'catalog'
                    ? `products`
                    : `requests/${requestId}/services/${serviceId}/products`
                }
              >
                <DatagridConfigurable
                  preferenceKey="requestFlow.custom.products"
                  rowClick={'expand'}
                  expand={<ProductDetailsExpand buttons={true} />}
                  bulkActionButtons={false}
                  omit={[
                    'countryCode',
                    'serviceCategoryId',
                    'serviceTypeId',
                    'accessTechnologyId',
                    'specifications.bandwidthUp',
                    'specifications.bandwidthDown',
                    'contractTerms.leadTime',
                    'contractTerms.meanTimeToRepair',
                    'contractTerms.serviceUptime',
                    'updatedAt',
                  ]}
                >
                  <TextField source="code" />
                  <ReferenceChip
                    referenceSource={'vendorId'}
                    tooltipSource="name"
                    source="vendorId"
                    chipSource="name"
                    label={'Vendor'}
                    reference="vendors"
                    emptyText={'N/A'}
                    removeDoubleLabel
                    link={false}
                  />
                  <TextField source="name" />
                  <ReferenceText
                    source="countryCode"
                    reference="countries/list"
                    emptyText={'N/A'}
                    link={false}
                  />
                  <ReferenceChip
                    referenceSource={'coverageZoneId'}
                    tooltipSource="description"
                    source="coverageZoneId"
                    chipSource="name"
                    label={'Coverage'}
                    reference="coverageZones"
                    emptyText={
                      selectedSource === 'searchResults'
                        ? 'Search Result'
                        : 'N/A'
                    }
                    removeDoubleLabel
                    link={false}
                  />
                  <ReferenceChip
                    referenceSource={'serviceTypeId'}
                    tooltipSource="name"
                    source="serviceTypeId"
                    chipSource="key"
                    label={'Type'}
                    reference="serviceTypes/list"
                    emptyText={'N/A'}
                    removeDoubleLabel
                    link={false}
                  />
                  <ReferenceChip
                    referenceSource={'serviceCategoryId'}
                    tooltipSource="name"
                    source="serviceCategoryId"
                    chipSource="key"
                    label={'Category'}
                    reference="serviceCategories/list"
                    emptyText={'N/A'}
                    removeDoubleLabel
                    link={false}
                  />
                  <ReferenceChip
                    referenceSource={'accessTechnologyId'}
                    tooltipSource="name"
                    source="accessTechnologyId"
                    chipSource="key"
                    label={'Category'}
                    reference="accessTechnologies/list"
                    emptyText={'N/A'}
                    removeDoubleLabel
                    link={false}
                  />
                  <NumberField
                    source="specifications.bandwidthUp"
                    label="Bandwidth down"
                  />
                  <NumberField
                    source="specifications.bandwidthDown"
                    label="Bandwidth up"
                  />
                  <NumberField
                    source="contractTerms.contractPeriod"
                    label="Contract period"
                  />
                  <NumberField
                    source="contractTerms.meanTimeToRepair"
                    label="MTTR"
                  />
                  <NumberField
                    source="contractTerms.leadTime"
                    label="Lead time"
                  />
                  <NumberField
                    source="contractTerms.serviceUptime"
                    label="Service uptime"
                  />
                  <CurrencyField
                    currency={'EUR'}
                    source="valueNrc"
                    label={'Value NRC'}
                  />

                  <CurrencyField
                    currency={'EUR'}
                    source="valueMrc"
                    label={'Value MRC'}
                  />
                  <CurrencyField
                    currency={'purchaseCurrency'}
                    source="purchaseNrc"
                    label={'Purchase NRC'}
                  />
                  <CurrencyField
                    currency={'purchaseCurrency'}
                    source="purchaseMrc"
                    label={'Purchase MRC'}
                  />
                  <FunctionField
                    render={(record: any) =>
                      record?.products?.some(
                        (product: { id: string }) => product.id === record.id
                      ) ? (
                        <Tooltip title="This product is already on the options list">
                          <span>
                            <Button disabled={true} variant="outlined">
                              Select
                            </Button>
                          </span>
                        </Tooltip>
                      ) : (
                        <Button
                          onClick={() => {
                            setSelectedProduct(record)
                            setIsCreateCustomOptionDialogOpen(true)
                          }}
                          variant="outlined"
                          disabled={false}
                        >
                          Select
                        </Button>
                      )
                    }
                  />
                </DatagridConfigurable>
              </List>
            </TabbedShowLayout.Tab>
          </TabbedShowLayout>
        ) : (
          <FunctionField
            variant="body1"
            color="text.secondary"
            render={() => (
              <Typography variant="h6" p={2}>
                ℹ️ Customer has not made a firm offer request for any product
                (yet)
              </Typography>
            )}
          />
        )}
      </CardContent>
    </Card>
  )
}
