import { ClientShow } from './Show.component'
import { ClientList } from './List.component'
import { ClientCreate } from './Create.component'
import { ClientEdit } from './Edit.component'

export default {
  list: ClientList,
  show: ClientShow,
  create: ClientCreate,
  edit: ClientEdit,
}
