/**
 * Select options for the coverage zone
 * @returns {Array} Array of options
 * @example
 * const options = useChoices()
 */
export const useChoices = () => {
  return [
    { id: 'area', name: 'Area' },
    { id: 'address', name: 'Address' },
    { id: 'datacenter', name: 'Datacenter' },
  ]
}
