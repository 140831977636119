import React from 'react'
import { NameFilter } from '../../atoms/NameFilter'
import { List, ListProps, Datagrid, TextField, DateField } from 'react-admin'

/**
 * Page to list user groups.
 * @property {ListProps} props
 * @returns {React.FC<ListProps>}
 * @example
 * import { UserGroupList } from './UserGroupList.component'
 * <UserGroupList {...props} />
 * @see https://marmelab.com/react-admin/List.html
 * @see https://marmelab.com/react-admin/Fields.html
 * @see https://marmelab.com/react-admin/Datagrid.html
 */
export const UserGroupList: React.FC<ListProps> = (props) => {
  return (
    <List
      empty={false}
      perPage={25}
      filters={<NameFilter />}
      sort={{ field: 'name', order: 'ASC' }}
      {...props}
    >
      <Datagrid rowClick="show">
        <TextField source="name" fullWidth />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
      </Datagrid>
    </List>
  )
}
