import React, { useState } from 'react'
import * as Yup from 'yup'
import {
  useCreate,
  useNotify,
  useRecordContext,
  useRefresh,
  Confirm,
  required,
  SelectInput,
  BooleanInput,
} from 'react-admin'
import { FieldValues } from 'react-hook-form/dist/types/fields'
import { WizardForm, WizardFormStep } from '@react-admin/ra-form-layout'
import { Dialog, DialogTitle, DialogContent, Box } from '@mui/material'
import { Add } from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'
import { validationSchema } from './validationSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import { ProvisioningStatus } from './ProvisioningUpdate.types'
import { Button } from '../../atoms/Button'
import { CustomToolbar } from './Toolbar.component'
import { MarkdownInput } from '@react-admin/ra-markdown'
import UpdateEmailPreview from './UpdateEmailPreview.component'
import { DateInput } from '../../atoms/DateInput'

export const ProvisioningUpdateModal: React.FC = () => {
  const record = useRecordContext()
  const [showDialog, setShowDialog] = useState(false)
  const [, setIsSubmitting] = useState(false)
  const [confirmClose, setConfirmClose] = useState(false)
  const notify = useNotify()
  const refresh = useRefresh()
  const [create] = useCreate()

  const [isSentImmediate, setIsSentImmediate] = useState(true)
  const [isOnPreviewStep, setIsOnPreviewStep] = useState(false)

  const handleClick = () => {
    setShowDialog(true)
  }

  const handleCloseClick = (event?: any, reason?: string) => {
    if (reason && reason === 'backdropClick') {
      // prevent backdrop click close
      return false
    }
    if (isOnPreviewStep) {
      setIsOnPreviewStep(false)
    }
    if (!isSentImmediate) {
      setIsSentImmediate(true)
    }
    setConfirmClose(true)
  }

  const handleClose = () => {
    setConfirmClose(false)
    setShowDialog(false)
  }

  const handleSubmit = async (values: FieldValues) => {
    setIsSubmitting(true)
    const {
      sendImmediate,
      estimatedDeliveryDate,
      provisioningUpdateNotes,
      provisioningStatus,
      ragStatus,
      isOnHold,
    } = values
    const formData = {
      sendImmediate,
      estimatedDeliveryDate,
      notes: provisioningUpdateNotes,
      provisioningStatus,
      ragStatus,
      isOnHold,
    }
    try {
      await create(
        `services/${record.id}/updates`,
        { data: formData },
        { returnPromise: true }
      )
      notify('Update has been saved', { type: 'success' })
      setIsSubmitting(false)
      setShowDialog(false)
      setIsSentImmediate(true)
      setTimeout(refresh, 500)
    } catch (error: any) {
      notify(error.message || error, { type: 'warning' })
    }
  }

  return (
    <>
      <Button color="primary" aria-label="complete" onClick={handleClick}>
        <Add />
        &nbsp;{'Provisioning Update'}
      </Button>
      <Confirm
        isOpen={confirmClose}
        title="Cancel Update"
        content="Are you sure you want to cancel this update? Any changed data will be lost!"
        onConfirm={handleClose}
        onClose={() => {
          setConfirmClose(false)
        }}
      />
      <Dialog
        fullWidth
        maxWidth="lg"
        open={showDialog}
        onClose={handleCloseClick}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Add Update
          <Button
            sx={{ margin: 0, float: 'right' }}
            size={'small'}
            onClick={handleCloseClick}
          >
            <CloseIcon />
            Close
          </Button>
        </DialogTitle>
        <DialogContent>
          <WizardForm
            onSubmit={handleSubmit}
            defaultValues={{
              sendImmediate: true,
              estimatedDeliveryDate:
                record?.serviceUpdates?.[0]?.estimatedDeliveryDate,
              provisioningStatus:
                record?.serviceUpdates?.[0]?.provisioningStatus,
              ragStatus: record?.serviceUpdates?.[0]?.ragStatus,
              isOnHold: record?.serviceUpdates?.[0]?.isOnHold,
            }}
            resolver={yupResolver<Yup.AnyObject>(validationSchema)}
            toolbar={
              <CustomToolbar
                isSentImmediate={isSentImmediate}
                setIsOnPreviewStep={setIsOnPreviewStep}
              />
            }
          >
            <WizardFormStep label="Update details">
              <Box sx={{ p: 0.5 }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <SelectInput
                    source="provisioningStatus"
                    label="Provisioning status"
                    validate={required()}
                    choices={[
                      {
                        id: ProvisioningStatus['SiteSurvey'],
                        name: 'Site survey',
                      },
                      {
                        id: ProvisioningStatus['Installation'],
                        name: 'Installation',
                      },
                      {
                        id: ProvisioningStatus['Configuration'],
                        name: 'Configuration',
                      },
                      {
                        id: ProvisioningStatus['Testing'],
                        name: 'Testing',
                      },
                      {
                        id: ProvisioningStatus['HandedOver'],
                        name: 'Handed over',
                      },
                    ]}
                    sx={{ width: '80%', flex: 1 }}
                  />
                  <BooleanInput
                    label="Is on-hold"
                    source="isOnHold"
                    sx={{ width: '15%', float: 'right', paddingTop: 2 }}
                  />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <DateInput
                      source="estimatedDeliveryDate"
                      fullWidth
                      label={'Estimated delivery date (EDD)'}
                    />
                  </Box>
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <SelectInput
                    validate={required()}
                    source="ragStatus"
                    label="RAG status"
                    fullWidth
                    optionText={(choice) => {
                      return (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <span
                            style={{
                              display: 'inline-block',
                              width: '20px',
                              height: '20px',
                              backgroundColor:
                                choice.id === 'amber' ? '#ffbf00' : choice.id,
                            }}
                          ></span>
                          <span
                            style={{
                              display: 'inline-block',
                              marginLeft: '0.5em',
                            }}
                          >
                            {choice.name}
                          </span>
                        </div>
                      )
                    }}
                    choices={[
                      { id: 'red', name: 'Red' },
                      { id: 'amber', name: 'Amber' },
                      { id: 'green', name: 'Green' },
                    ]}
                  />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <MarkdownInput
                    source="provisioningUpdateNotes"
                    label="Notes"
                    isRequired={false}
                    placeholder="Here you can add further notes on this update. You do not need to include opening or closing words - they are added to the e-mail automatically."
                    fullWidth
                    height={'300px'}
                  />
                </Box>
                <Box>
                  <BooleanInput
                    label="E-mail this update out to contacts that should receive updates"
                    source="sendImmediate"
                    onChange={(e) => {
                      setIsSentImmediate(e.target.checked)
                    }}
                  />
                </Box>
              </Box>
            </WizardFormStep>
            {isSentImmediate && (
              <WizardFormStep label="E-mail preview">
                <UpdateEmailPreview />
              </WizardFormStep>
            )}
          </WizardForm>
        </DialogContent>
      </Dialog>
    </>
  )
}
