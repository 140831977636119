import React from 'react'
import {
  ReferenceManyField,
  TextField,
  ShowButton,
  Pagination,
  BooleanField,
  DateField,
  DatagridConfigurable,
} from 'react-admin'
import { ReferenceChip } from '../../molecules/ReferenceChip'

import { IProductsList } from './ProductsList.types'
import { CurrencyField } from '../../atoms/CurrencyField'

/**
 * List of products, used in related context
 * @returns {React.FC}
 * @example
 * import { ProductsList } from './ProductsList'
 * <ProductsList />
 * @see https://marmelab.com/react-admin/Fields.html
 */
export const ProductsList: React.FC<IProductsList> = ({
  omit,
  target,
  sort,
  label,
  ...rest
}) => {
  return (
    <ReferenceManyField
      {...rest}
      target={target}
      reference="products"
      label={label || 'Products'}
      pagination={<Pagination />}
      sort={sort || { field: 'createdAt', order: 'DESC' }}
    >
      <DatagridConfigurable bulkActionButtons={false} omit={omit || [target]}>
        <TextField source="code" />
        <TextField source="name" />
        <ReferenceChip
          referenceSource="vendorId"
          tooltipSource="name"
          label="Vendor"
          source="vendorId"
          reference="vendors"
          chipSource="name"
          link={'show'}
        />
        <ReferenceChip
          referenceSource="coverageZoneId"
          tooltipSource="description"
          label="Coverage Zone"
          source="coverageZoneId"
          reference="coverageZones"
          chipSource="name"
          link={'show'}
        />
        <CurrencyField currency={'EUR'} source="valueNrc" label={'Value NRC'} />
        <CurrencyField currency={'EUR'} source="valueMrc" label={'Value MRC'} />
        <BooleanField source="isActive" />
        <DateField source="deactivatedAt" showTime />
        <ShowButton />
      </DatagridConfigurable>
    </ReferenceManyField>
  )
}
