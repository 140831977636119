import React from 'react'
import { IServicePortFilters } from './ServicePortFilters.types'
import { Filter, TextInput } from 'react-admin'
import { ReferenceAutocomplete } from '../../molecules/ReferenceAutocomplete'

/**
 * Filters for ServicePort
 * @returns {React.FC}
 * @example <ServicePortFilters />
 * @see https://marmelab.com/react-admin/List.html#the-filter-button
 */
export const ServicePortFilters: React.FC<IServicePortFilters> = (props) => {
  return (
    <Filter {...props}>
      <TextInput label="Search" source="term" alwaysOn />
      <ReferenceAutocomplete
        source="serviceId"
        reference="services/list"
        alwaysOn
      />
      <ReferenceAutocomplete
        source="serviceSiteId"
        reference="serviceSites/list"
      />
    </Filter>
  )
}
