import React from 'react'
import {
  Show,
  ShowProps,
  TabbedShowLayout,
  Tab,
  TextField,
  DateField,
  BooleanField,
} from 'react-admin'
import { CurrencyField } from '../../atoms/CurrencyField'
import { PageTitle } from '../../atoms/PageTitle'
import { TextPreSuffixField } from '../../atoms/TextPreSuffixField'
import { MutationsList } from '../../organisms/MutationsList'
import { ServiceSpecificationDisplay } from '../../templates/ServiceSpecificationDisplay'
import { ReferenceText } from '../../molecules/ReferenceText'
import { ReferenceChip } from '../../molecules/ReferenceChip'

/**
 * Show component for the Product resource
 * @param {ShowProps} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 * @example
 * return (
 * <ProductShow />
 * )
 * @see {@link https://marmelab.com/react-admin/Show.html|Show}
 * @see {@link https://marmelab.com/react-admin/Show.html#the-show-component|Show}
 * @see {@link https://marmelab.com/react-admin/Show.html#the-tabbedshowlayout-component|TabbedShowLayout}
 */
export const ProductShow: React.FC<ShowProps> = (props) => {
  return (
    <Show {...props} title={<PageTitle prefix="Product" />}>
      <TabbedShowLayout>
        <Tab label="general">
          <TextField source="code" />
          <TextField source="name" />
          <ReferenceText
            label="Service category"
            source="serviceCategoryId"
            reference="serviceCategories/list"
            emptyText="N/A"
            link={false}
          />
          <ReferenceText
            label="Service type"
            source="serviceTypeId"
            reference="serviceTypes/list"
            emptyText="N/A"
            link={false}
          />
          <ReferenceText
            label="Access technology"
            source="accessTechnologyId"
            reference="accessTechnologies/list"
            emptyText="N/A"
            link={false}
          />
          <ReferenceChip
            label="Vendor"
            source="vendorId"
            tooltipSource=""
            chipSource="name"
            referenceSource="vendorId"
            reference="vendors"
            emptyText="N/A"
            link={'show'}
            removeDoubleLabel
          />
          <ReferenceChip
            label="Price book"
            source="priceBookId"
            tooltipSource=""
            chipSource="name"
            referenceSource="priceBookId"
            reference="priceBooks"
            emptyText="N/A"
            removeDoubleLabel
            link={'show'}
          />
          <ReferenceText
            source="countryCode"
            reference="countries/list"
            emptyText={'N/A'}
            link={false}
          />
          <ReferenceChip
            referenceSource={'coverageZoneId'}
            tooltipSource="description"
            source="name"
            chipSource="name"
            label={'Coverage Zone'}
            reference="coverageZones"
            emptyText={'N/A'}
            removeDoubleLabel
            link={'show'}
          />
          <DateField source="activatedAt" showTime />
          <DateField source="deactivatedAt" showTime />
          <DateField source="updatedAt" showTime />
          <DateField source="createdAt" showTime />
          <BooleanField source="isActive" />
        </Tab>
        <Tab label="specifications">
          <ServiceSpecificationDisplay source="specifications" />
        </Tab>
        <Tab label="terms">
          <TextPreSuffixField
            source="contractPeriod"
            label="Contract period"
            suffix={' months'}
            emptyText="N/A"
          />
          <TextPreSuffixField
            source="renewalPeriod"
            label="Renewal period"
            suffix={' months'}
            emptyText="N/A"
          />
          <TextPreSuffixField
            source="cancellationPeriod"
            label="Cancellation period"
            suffix={' months'}
            emptyText="N/A"
          />
          <TextPreSuffixField
            source="leadTime"
            label="Lead time"
            suffix={' hours'}
            emptyText="N/A"
          />
          <TextPreSuffixField
            source="serviceUptime"
            label="Service uptime"
            suffix={'%'}
            emptyText="N/A"
          />
          <TextPreSuffixField
            source="meanTimeToRepair"
            label="Mean time to repair (MTTR)"
            suffix={' hours'}
            emptyText="N/A"
          />
          <TextField source="supportLevel" emptyText="N/A" />
          <TextField source="serviceMonitoring" emptyText="N/A" />
          <TextField source="extraTerms" emptyText="N/A" />
        </Tab>
        <Tab label="pricing">
          <TextField
            source="priceStatusNrc"
            label="Price status NRC"
            emptyText="N/A"
          />
          <CurrencyField
            source="purchaseNrc"
            currency={'EUR'}
            label="Purchase NRC"
          />
          <TextField
            source="priceStatusMrc"
            label="Price status MRC"
            emptyText="N/A"
          />
          <CurrencyField
            source="purchaseMrc"
            currency={'EUR'}
            label="Purchase MRC"
          />
          <CurrencyField
            source="valueNrc"
            currency={'valueCurrency'}
            label="Value NRC"
          />
          <CurrencyField
            source="valueMrc"
            currency={'valueCurrency'}
            label="Value MRC"
          />
          <BooleanField source="applyVatOnNrc" label="VAT applied on NRC" />
          <BooleanField source="applyVatOnMrc" label="VAT applied on MRC" />
        </Tab>
        <Tab label="history">
          <MutationsList showActor type="history" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
