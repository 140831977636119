import React from 'react'
import {
  Show,
  ShowProps,
  TabbedShowLayout,
  Tab,
  TextField,
  DateField,
  TabbedShowLayoutTabs,
  BooleanField,
} from 'react-admin'
import { MutationsList } from '../../organisms/MutationsList'
import { PageTitle } from '../../atoms/PageTitle'
import { AddressDisplay } from '../../organisms/AddressDisplay'
import { Map } from '../../atoms/Map'

/**
 * Page to show a single datacenter
 * @property {ShowProps} props
 * @returns {React.FC<ShowProps>}
 * @example
 * <ClientShow {...props} />
 * @see https://marmelab.com/react-admin/Show.html
 */
export const DatacenterShow: React.FC<ShowProps> = (props) => {
  return (
    <Show {...props} title={<PageTitle prefix="Datacenter" />}>
      <TabbedShowLayout
        tabs={
          <TabbedShowLayoutTabs variant="scrollable" scrollButtons="auto" />
        }
      >
        <Tab label="general">
          <TextField source="key" fullWidth />
          <TextField source="name" fullWidth />
          <BooleanField source="isActive" fullWidth />
          <DateField source="updatedAt" showTime />
          <DateField source="createdAt" showTime />
        </Tab>
        <Tab label="address">
          <AddressDisplay />
        </Tab>
        <Tab label="geometry">
          <Map style={{ height: '70vh' }} />
        </Tab>
        <Tab label="history">
          <MutationsList showActor type="history" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
