import React from 'react'
import { TextField, Labeled } from 'react-admin'

/**
 * Displays the contact details:
 * - Email Address
 * - Phone Number
 * - Mobile Number
 * @returns React.FC
 * @see https://marmelab.com/react-admin/Fields.html
 * @see https://marmelab.com/react-admin/Inputs.html
 */
export const ContactDetailsDisplay: React.FC = () => {
  return (
    <>
      <Labeled label="Email Address">
        <TextField source="emailAddress" fullWidth />
      </Labeled>
      <Labeled label="Phone Number">
        <TextField source="phoneNumber" fullWidth />
      </Labeled>
      <Labeled label="Mobile Number">
        <TextField source="mobileNumber" fullWidth />
      </Labeled>
    </>
  )
}
