import React from 'react'
import {
  Grid,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material'
import {
  SelectInput,
  NumberInput,
  TextInput,
  NullableBooleanInput,
  maxValue,
  minValue,
} from 'react-admin'
import { useFormContext } from 'react-hook-form'
import {
  connectorChoices,
  modeChoices,
  speedChoices,
} from '../../particles/utils'

export const ServiceSpecificationInputsTable: React.FC<{
  disabled?: boolean
}> = (props) => {
  const form = useFormContext()

  const columns = [
    [
      {
        label: 'IPv4 allocation',
        component: (
          <SelectInput
            disabled={props?.disabled}
            source="specifications.ipAllocationV4"
            fullWidth
            variant="outlined"
            helperText={false}
            label={false}
            choices={[
              { id: 'static', name: 'Static IPv4' },
              { id: 'dynamic', name: 'Dynamic/DHCP IPv4' },
            ]}
          />
        ),
      },
      {
        label: 'IPv4 scope',
        component: (
          <SelectInput
            disabled={props?.disabled}
            source="specifications.ipScopeV4"
            fullWidth
            variant="outlined"
            helperText={false}
            label={false}
            choices={[
              { id: 'global', name: 'Public IP' },
              { id: 'local', name: 'Private IP' },
            ]}
          />
        ),
      },
      {
        label: 'Port count',
        component: (
          <NumberInput
            disabled={props?.disabled}
            source="specifications.portCount"
            validate={[minValue(1), maxValue(10)]}
            step={1}
            fullWidth
            variant="outlined"
            helperText={false}
            label={false}
          />
        ),
      },
      {
        label: 'Port type',
        component: (
          <SelectInput
            disabled={props?.disabled}
            source="specifications.portType"
            fullWidth
            variant="outlined"
            helperText={false}
            label={false}
            choices={[
              { id: 'fiber', name: 'Fiber' },
              { id: 'copper', name: 'Copper' },
            ]}
          />
        ),
      },
      {
        label: 'Port connector',
        component: (
          <SelectInput
            disabled={props?.disabled}
            source="specifications.portConnector"
            fullWidth
            variant="outlined"
            helperText={false}
            label={false}
            choices={connectorChoices(
              form.getValues(),
              'specifications.portType'
            )}
          />
        ),
      },
      {
        label: 'Port mode',
        component: (
          <SelectInput
            disabled={props?.disabled}
            source="specifications.portMode"
            fullWidth
            variant="outlined"
            helperText={false}
            label={false}
            choices={modeChoices(form.getValues(), 'specifications.portType')}
          />
        ),
      },
      {
        label: 'Port speed',
        component: (
          <SelectInput
            disabled={props?.disabled}
            source="specifications.portSpeed"
            fullWidth
            variant="outlined"
            helperText={false}
            label={false}
            choices={speedChoices(form.getValues(), 'specifications.portType')}
          />
        ),
      },
      {
        label: 'Routing configuration',
        component: (
          <SelectInput
            disabled={props?.disabled}
            source="specifications.routingConfig"
            fullWidth
            variant="outlined"
            helperText={false}
            label={false}
            choices={[
              { id: 'static', name: 'Static' },
              { id: 'vrrp', name: 'VRRP' },
              { id: 'bgp', name: 'BGP' },
            ]}
          />
        ),
      },
      {
        label: 'Is burstable',
        component: (
          <NullableBooleanInput
            disabled={props?.disabled}
            source="specifications.isBurstable"
            fullWidth
            variant="outlined"
            helperText={false}
            label={false}
          />
        ),
      },
    ],
    [
      {
        label: 'Bandwidth commit (Mbit 95% pct)',
        component: (
          <NumberInput
            disabled={props?.disabled}
            source="specifications.bandwidthCommit"
            validate={[minValue(0.1), maxValue(100000)]}
            fullWidth
            variant="outlined"
            helperText={false}
            label={false}
          />
        ),
      },
      {
        label: 'Expected round trip delay (ms)',
        component: (
          <NumberInput
            disabled={props?.disabled}
            source="specifications.expectedRtd"
            validate={[minValue(0), maxValue(10000)]}
            step={1}
            fullWidth
            variant="outlined"
            helperText={false}
            label={false}
          />
        ),
      },
      {
        label: 'Guaranteed round trip delay (ms)',
        component: (
          <NumberInput
            disabled={props?.disabled}
            source="specifications.guaranteedRtd"
            validate={[minValue(0), maxValue(10000)]}
            step={1}
            fullWidth
            variant="outlined"
            helperText={false}
            label={false}
          />
        ),
      },
      {
        label: 'Rack space',
        component: (
          <NumberInput
            disabled={props?.disabled}
            source="specifications.rackSpace"
            validate={[minValue(1), maxValue(50)]}
            step={1}
            fullWidth
            variant="outlined"
            helperText={false}
            label={false}
          />
        ),
      },
      {
        label: 'Power commit (kW)',
        component: (
          <NumberInput
            disabled={props?.disabled}
            source="specifications.powerCommit"
            validate={[minValue(0), maxValue(10)]}
            step={0.5}
            fullWidth
            variant="outlined"
            helperText={false}
            label={false}
          />
        ),
      },
      {
        label: 'Cross connects',
        component: (
          <NumberInput
            disabled={props?.disabled}
            source="specifications.crossConnects"
            validate={[minValue(0), maxValue(50)]}
            step={1}
            fullWidth
            variant="outlined"
            helperText={false}
            label={false}
          />
        ),
      },
      {
        label: 'Carrier name',
        component: (
          <TextInput
            disabled={props?.disabled}
            source="specifications.carrier"
            fullWidth
            variant="outlined"
            helperText={false}
            label={false}
          />
        ),
      },
      {
        label: 'Cable systems used',
        component: (
          <TextInput
            disabled={props?.disabled}
            source="specifications.cableSystemsUsed"
            fullWidth
            variant="outlined"
            helperText={false}
            label={false}
          />
        ),
      },
      {
        label: 'Is protected',
        component: (
          <NullableBooleanInput
            disabled={props?.disabled}
            source="specifications.isProtected"
            fullWidth
            variant="outlined"
            helperText={false}
            label={false}
          />
        ),
      },
    ],
  ]

  return (
    <Grid container spacing={4}>
      {columns.map((table, index) => (
        <Grid item xs={12} xl={6} key={index}>
          <TableContainer component={Box}>
            <Table>
              <TableBody
                sx={{
                  'td, th': {
                    border: 0,
                    padding: 0,
                  },
                }}
              >
                {table.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      <Typography variant="body2">{row.label}</Typography>
                    </TableCell>
                    <TableCell>{row.component}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      ))}
    </Grid>
  )
}
