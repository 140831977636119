import { ApplicationCreate } from './Create.component'
import { ApplicationEdit } from './Edit.component'
import { ApplicationList } from './List.component'
import { ApplicationShow } from './Show.component'

export default {
  list: ApplicationList,
  show: ApplicationShow,
  create: ApplicationCreate,
  edit: ApplicationEdit,
}
