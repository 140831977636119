import React from 'react'
import { ITokenList } from './TokensList.types'
import { TextField, DateField, Datagrid, List } from 'react-admin'

/**
 * Returns a list of JWT Access Tokens for a given Application.
 * @property {ITokenList} props
 * @returns {JSX.Element} TokensList component
 * @see {@link https://marmelab.com/react-admin/List.html|List}
 */
export const TokensList: React.FC<ITokenList> = (props) => {
  return (
    <List
      disableSyncWithLocation
      resource={`applications/${props.applicationId}/tokens`}
      actions={false}
      title={' '}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source="name" />
        <TextField source="type" />
        <TextField variant="caption" source="token" />
        <DateField source="createdAt" showTime />
        <DateField source="expiresAt" emptyText="Never" showTime />
      </Datagrid>
    </List>
  )
}
