import React from 'react'
import { ISearchServiceFilter } from './SearchServiceFilter.types'
import { Filter, TextInput } from 'react-admin'
import { ReferenceAutocomplete } from '../../molecules/ReferenceAutocomplete'

/**
 * Filter by search term and service.
 * @param {ISearchServiceFilter} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 * @example
 * return (
 * <SearchServiceFilter />
 * )
 * @see {@link https://marmelab.com/react-admin/List.html|List}
 */
export const SearchServiceFilter: React.FC<ISearchServiceFilter> = (props) => {
  return (
    <Filter {...props}>
      <TextInput label="Search" source="term" alwaysOn />
      <ReferenceAutocomplete
        source="serviceId"
        reference="services/list"
        alwaysOn
      />
    </Filter>
  )
}
