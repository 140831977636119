import React from 'react'
import { DualSelect } from '../../atoms/DualSelect'
import {
  Create,
  CreateProps,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin'

/**
 * Page to create a UserGroup.
 * @property {CreateProps} props
 * @returns {JSX.Element} UserGroupCreate component
 * @see {@link https://marmelab.com/react-admin/CreateEdit.html|CreateEdit}
 * @see {@link https://marmelab.com/react-admin/CreateEdit.html#required-vs-optional-fields|Required vs Optional Fields}
 * @see {@link https://marmelab.com/react-admin/Inputs.html#simpleform|SimpleForm}
 */
export const UserGroupCreate: React.FC<CreateProps> = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" validate={required()} fullWidth />
        <DualSelect
          label="Clients"
          source="clientIds"
          reference="clients/list"
        />
        <DualSelect
          label="Permissions"
          source="permissionIds"
          reference="permissions/list"
        />
      </SimpleForm>
    </Create>
  )
}
