import React from 'react'
import {
  Show,
  ShowProps,
  TabbedShowLayout,
  Tab,
  TextField,
  DateField,
} from 'react-admin'
import { PageTitle } from '../../atoms/PageTitle'
import { ReferenceChip } from '../../molecules/ReferenceChip'
import { StatisticGraphField } from '../../atoms/StatisticGraphField'
import { MutationsList } from '../../organisms/MutationsList'

/**
 * Page to show a single service statistic.
 * @param {ShowProps} props
 * @returns {React.FC}
 * @example
 * import { ServiceStatisticShow } from './ServiceStatisticShow.component.tsx'
 * <ServiceStatisticShow />
 * @see {@link https://marmelab.com/react-admin/Show.html|RA Show}
 */
export const ServiceStatisticShow: React.FC<ShowProps> = (props) => {
  return (
    <Show {...props} title={<PageTitle prefix="Service statistic" />}>
      <TabbedShowLayout {...props}>
        <Tab label="summary">
          <ReferenceChip
            referenceSource="serviceId"
            tooltipSource="serviceNo"
            source="name"
            label={'Service'}
            reference="services"
            emptyText="N/A"
            link="show"
            chipSource="name"
            removeDoubleLabel
          />
          <TextField source="title" />
          <TextField source="type" />
          <TextField source="module" />
          <DateField source="updatedAt" showTime />
          <DateField source="createdAt" showTime />
        </Tab>
        <Tab label={'graph'}>
          <StatisticGraphField />
        </Tab>
        <Tab label={'history'}>
          <MutationsList showActor type="history" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
