import React from 'react'
import { ITermFilter } from './TermFilter.types'
import { TextInput, Filter } from 'react-admin'

/**
 * TermFilter component.
 *
 * A filter component that filters data based on a term.
 * Term is a concept in the backend that searches across multiple string
 * fields at once.
 *
 * @param {ITermFilter} props - The props for the TermFilter component.
 * @returns {JSX.Element} The rendered TermFilter component.
 * @see ITermFilter
 * @see Filter
 * @see TextInput
 */
export const TermFilter: React.FC<ITermFilter> = (props) => {
  return (
    <Filter {...props}>
      <TextInput label="Search" source="term" alwaysOn />
    </Filter>
  )
}
