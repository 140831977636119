import { VendorContactCreate } from './Create.component'
import { VendorContactEdit } from './Edit.component'
import { VendorContactList } from './List.component'
import { VendorContactShow } from './Show.component'

export default {
  list: VendorContactList,
  show: VendorContactShow,
  create: VendorContactCreate,
  edit: VendorContactEdit,
}
