import React from 'react'
import { ServicePortTabsInput } from '../ServicePortTabs'
import { CreateInDialogButton } from '@react-admin/ra-form-layout'
import { useRecordContext } from 'react-admin'
import { IRecord } from './ServicePortModalCreate.types'

/**
 * Modal to create a new ServicePort
 * @property {RaRecord} reference - The reference record
 * @returns {React.FC} - The component
 * @example
 * <ServicePortModalCreate reference={record} />
 */
export const ServicePortModalCreate: React.FC<{ reference: string }> = (
  props
) => {
  const record = useRecordContext()

  let defaultRecord: IRecord = {}
  if (record?.clientId) {
    defaultRecord.clientId = record.clientId
  }
  if (props.reference === 'services') {
    defaultRecord.serviceId = record?.id
  }

  return (
    <CreateInDialogButton
      inline
      fullWidth
      maxWidth="md"
      record={defaultRecord}
      resource={'servicePorts'}
    >
      <ServicePortTabsInput reference={props.reference} />
    </CreateInDialogButton>
  )
}
