import React from 'react'
import {
  Create,
  CreateProps,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin'
import { ReferenceAutocomplete } from '../../molecules/ReferenceAutocomplete'

/**
 * Form to create a monitor metric.
 * @param props The Create component props
 * @see https://marmelab.com/react-admin/Create.html
 */
export const MonitorMetricCreate: React.FC<CreateProps> = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <ReferenceAutocomplete
          label="Probe Type"
          source="monitorProbeTypeId"
          reference="monitorProbeTypes/list"
          required
        />
        <TextInput source="name" validate={required()} fullWidth />
        <TextInput source="dataType" validate={required()} fullWidth />
        <TextInput source="units" validate={required()} fullWidth />
      </SimpleForm>
    </Create>
  )
}
