import React from 'react'
import { IToolbar } from './Toolbar.types'
import {
  TopToolbar,
  FilterButton,
  CreateButton,
  ShowButton,
  EditButton,
  CloneButton,
  SelectColumnsButton,
} from 'react-admin'

/**
 * Returns a toolbar with a optional buttons.
 * @param {IToolbar} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 * @example
 * return (
 * <Toolbar />
 * )
 * @see {@link https://marmelab.com/react-admin/List.html#the-toolbar|Toolbar}
 * @see {@link https://marmelab.com/react-admin/List.html#the-filter-button|Filter Button}
 * @see {@link https://marmelab.com/react-admin/List.html#the-create-button|Create Button}
 */
export const Toolbar: React.FC<IToolbar> = ({
  showSelectColumns,
  showFilter,
  showShow,
  showCreate,
  showEdit,
  showClone,
  ...props
}) => {
  return (
    <TopToolbar {...props}>
      {showSelectColumns && <SelectColumnsButton />}
      {showFilter && <FilterButton />}
      {showCreate && <CreateButton />}
      {showShow && <ShowButton />}
      {showEdit && <EditButton />}
      {showClone && <CloneButton />}
      {props.children}
    </TopToolbar>
  )
}
