import { FieldValues } from 'react-hook-form/dist/types/fields'
import { get } from 'lodash'

/**
 * Returns the choices for the connector field.
 * @param {FieldValues} formData - The form data.
 * @param {string} typeField - The type field.
 * @returns {Array} The connector choices.
 */
export const connectorChoices = (formData: FieldValues, typeField: string) => {
  const typeValue = get(formData, typeField)
  if (typeValue === 'copper') {
    return [{ id: 'rj45', name: 'RJ45' }]
  }
  return [
    { id: 'lc', name: 'LC' },
    { id: 'sc', name: 'SC' },
    { id: 'apc', name: 'APC' },
    { id: 'st', name: 'ST' },
    { id: 'fc', name: 'FC' },
  ]
}
