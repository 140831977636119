import React from 'react'
import {
  TextField,
  BooleanField,
  DateField,
  ShowButton,
  ArrayField,
  useRecordContext,
  FunctionField,
} from 'react-admin'
import { LabelledDatagrid } from '../../atoms/LabelledDatagrid'
import { IContactsArrayField } from './ContactsArrayField.types'
import { TypeStateField } from '../../atoms/TypeStateField'
import { ReferenceChip } from '../../molecules/ReferenceChip'

/**
 * Field of contacts
 * @returns { React.FC }
 * @example
 * import { ContactsArrayField } from './ContactsArrayField'
 * <ContactsArrayField source="clientContacts" reference="serviceId" />
 * @see https://marmelab.com/react-admin/Fields.html#referencemanyfield
 */
export const ContactsArrayField: React.FC<IContactsArrayField> = (props) => {
  const record = useRecordContext()

  return (
    <ArrayField
      source={props.source}
      label={props.label || 'Contacts'}
      sort={{ field: 'role', order: 'ASC' }}
    >
      <LabelledDatagrid bulkActionButtons={false}>
        <TextField source="name" />
        <TypeStateField source="role" />
        <ReferenceChip
          referenceSource="serviceSiteId"
          chipSource="name"
          reference="serviceSites"
          tooltipSource="siteNo"
          label="Site"
          emptyText="N/A"
          link="show"
        >
          <TextField source={'name'} />
        </ReferenceChip>
        <BooleanField source="isActive" />
        {Array.isArray(record.clientContactExtras) && (
          <FunctionField
            label="Receives Updates"
            render={(clientContact: { id: string }) => {
              const clientContactExtras: {
                clientContactId: string
                receivesUpdates: boolean | null
              }[] = record.clientContactExtras

              let extras = clientContactExtras.find(
                (clientContactExtra) =>
                  clientContactExtra.clientContactId === clientContact.id
              )

              if (!extras || extras.receivesUpdates === null) {
                extras = {
                  clientContactId: clientContact.id,
                  receivesUpdates: false,
                }
              }

              return <BooleanField record={extras} source="receivesUpdates" />
            }}
          />
        )}
        <DateField source="createdAt" showTime />
        <ShowButton resource={props.reference} />
      </LabelledDatagrid>
    </ArrayField>
  )
}
