import React from 'react'
import Box from '@mui/material/Box'
import { FormDataConsumerRenderParams, required, TextInput } from 'react-admin'
import { useMetaRowStyles } from './MetaRow.styles'

/**
 * Property and Value inputs for a meta row.
 * @typedef {object} MetaRowInputProps
 * @property {FormDataConsumerRenderParams} props
 * @returns {JSX.Element}
 * @example
 * <MetaRowInput props={props} />
 * @see https://marmelab.com/react-admin/Inputs.html#using-formdataconsumer-to-define-custom-inputs
 * @see https://marmelab.com/react-admin/Inputs.html#textinput
 * @see https://mui.com/components/text-fields/#text-field
 * @see https://mui.com/system/spacing/#api
 */
export const MetaRowInput: React.FC<FormDataConsumerRenderParams> = (props) => {
  const styles = useMetaRowStyles()

  return (
    <Box sx={styles}>
      <Box>
        <TextInput
          source={props.getSource?.('property') || ''}
          validate={[required()]}
          label={'Property'}
          fullWidth
        />
      </Box>
      <Box>
        <TextInput
          source={props.getSource?.('value') || ''}
          label={'Value'}
          fullWidth
        />
      </Box>
    </Box>
  )
}
