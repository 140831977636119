import React from 'react'
import { Edit, EditProps, FormTab, TabbedForm } from 'react-admin'
import { ClientContractForm } from '../../organisms/ClientContractForm'
import { PageTitle } from '../../atoms/PageTitle'
import { ReferenceManyFormTab } from '../../atoms/FormTab'
import { DocumentModalInput } from '../../organisms/DocumentModalInput'
import { DocumentRow } from '../../organisms/DocumentRow'
import { MetaListInput } from '../../organisms/MetaList'

/**
 * Page to edit a client contract.
 * @param {EditProps} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 * @example
 * return (
 * <ClientContractEdit {...props} />
 * )
 * @see {@link https://marmelab.com/react-admin/CreateEdit.html|Edit}
 */
export const ClientContractEdit: React.FC<EditProps> = (props) => {
  return (
    <Edit
      {...props}
      mutationMode="undoable"
      title={<PageTitle prefix="Client contract" />}
    >
      <TabbedForm>
        <FormTab label="general" key="general">
          <ClientContractForm />
        </FormTab>
        <ReferenceManyFormTab
          reference="documents"
          target="relatedId"
          label="documents"
        >
          <DocumentRow />
          <DocumentModalInput reference="clientContracts" />
        </ReferenceManyFormTab>
        <FormTab label={'meta'} key="meta">
          <MetaListInput source="meta" />
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}
