import React from 'react'
import { EditInDialogButton } from '@react-admin/ra-form-layout'
import { ServicePortTabsInput } from '../ServicePortTabs'
import { PageTitle } from '../../atoms/PageTitle/PageTitle.component'

/**
 * Modal to edit a ServicePort.
 * @property {RaRecord} reference - The reference record
 * @returns {React.FC} - The component
 * @example
 * <ServicePortModalEdit reference={record} />
 */
export const ServicePortModalEdit: React.FC<{ reference?: string }> = (
  props
) => {
  return (
    <EditInDialogButton
      fullWidth
      maxWidth="md"
      title={<PageTitle prefix="Service port" />}
    >
      <ServicePortTabsInput {...props} />
    </EditInDialogButton>
  )
}
