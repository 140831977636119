import React from 'react'
import {
  Edit,
  EditProps,
  TabbedForm,
  FormTab,
  TextInput,
  BooleanInput,
  SelectInput,
  required,
  FormDataConsumer,
  ArrayInput,
  SimpleFormIterator,
} from 'react-admin'
import { ReferenceAutocomplete } from '../../molecules/ReferenceAutocomplete'
import { Box } from '@mui/material'
import { TemplatePreviewer } from '../../organisms/TemplatePreviewer'
import { MarkdownInput } from '@react-admin/ra-markdown'

/**
 * Page to edit a template.
 * @param {EditProps} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 * @example
 * return (
 * <TemplateEdit {...props} />
 * )
 */

export const TemplateEdit: React.FC<EditProps> = (props) => {
  return (
    <Edit {...props} mutationMode="undoable">
      <TabbedForm>
        <FormTab label="general">
          <TextInput source="name" validate={required()} fullWidth />
          <TextInput source="key" validate={required()} disabled fullWidth />
          <SelectInput
            source="type"
            validate={required()}
            choices={[
              { id: 'document', name: 'Document' },
              { id: 'email', name: 'Email' },
            ]}
            disabled
            fullWidth
          />
          <FormDataConsumer>
            {({ formData }) =>
              formData.type === 'document' ? (
                <ReferenceAutocomplete
                  label="Document category"
                  required
                  reference="documentCategories/list"
                  source="categoryId"
                />
              ) : null
            }
          </FormDataConsumer>
          <TextInput source="view" fullWidth />
          <BooleanInput source="isDefault" fullWidth />
        </FormTab>
        <FormTab label="context">
          <SelectInput
            source="context.model"
            label="Model Entity"
            defaultValue="ClientContract"
            choices={[
              { id: 'ClientContract', name: 'Client Contract' },
              { id: 'ServiceOrder', name: 'Service Order' },
              { id: 'Client', name: 'Client' },
            ]}
            fullWidth
          />
        </FormTab>
        <FormTab label="variables">
          <ArrayInput source="variables" label={false} fullWidth>
            <SimpleFormIterator
              disableReordering={true}
              getItemLabel={() => ''}
              fullWidth
            >
              <FormDataConsumer>
                {({ formData, getSource, scopedFormData }) => (
                  <Box
                    sx={{
                      display: 'grid',
                      gap: 1,
                      gridTemplateColumns: 'repeat(3, 1fr)',
                      width: '100%',
                    }}
                  >
                    <Box>
                      <SelectInput
                        source={getSource?.('type') || ''}
                        validate={[required()]}
                        defaultValue={'text'}
                        choices={[{ id: 'text', name: 'Text' }]}
                        fullWidth
                      />
                    </Box>
                    <Box>
                      <TextInput
                        source={getSource?.('key') || ''}
                        validate={[required()]}
                        label={'Variable key'}
                        fullWidth
                      />
                    </Box>
                    <Box>
                      <TextInput
                        source={getSource?.('description') || ''}
                        validate={[required()]}
                        label={'Description'}
                        fullWidth
                      />
                    </Box>
                  </Box>
                )}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="content">
          <MarkdownInput
            source="content"
            height="80vh"
            initialEditType="markdown"
            usageStatistics={false}
          />
        </FormTab>
        <FormTab label="preview">
          <TemplatePreviewer />
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}
