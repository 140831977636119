import React from 'react'
import { useRecordContext, Button } from 'react-admin'
import AssessmentIcon from '@mui/icons-material/Assessment'

/**
 * View in Grafana button component.
 * @param props The component props
 * @param props.label The label to use for the Grafana dashboard
 * @example <ViewInGrafanaButton label="My Label" />
 */
export const ViewInGrafanaButton: React.FC = () => {
  const record = useRecordContext()

  const viewInGrafana = () => {
    const label = encodeURIComponent(
      `${record?.monitorProbeType?.name}: ${record?.monitorTarget?.hostname}`
    )
    window.open(`${process.env.REACT_APP_GRAFANA_PROBE_URL}${label}`, '_blank')
  }

  const isGrafanaAvailable =
    record?.monitorTarget?.hostname && record?.monitorProbeType?.name

  return (
    <Button
      color="primary"
      onClick={viewInGrafana}
      disabled={!isGrafanaAvailable}
      label="View in Grafana"
      startIcon={<AssessmentIcon />}
    />
  )
}
