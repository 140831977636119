import React from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Stack,
  Grid,
} from '@mui/material'
import ExpandAccordionIcon from '@mui/icons-material/ExpandMore'
import { ProductSearchQueryField } from '../../organisms/ProductSearchQueryField'
import { FunctionField } from 'react-admin'
import DoneIcon from '@mui/icons-material/CheckBoxOutlined'
import ToDoIcon from '@mui/icons-material/CheckBoxOutlineBlank'

/** Component that displays basic info about customer, preferences, requirements, add-on wishes - uses RequestService as its context */
export const RequestDetailsAccordion: React.FC = () => (
  <Accordion defaultExpanded disableGutters>
    <AccordionSummary expandIcon={<ExpandAccordionIcon color="info" />}>
      <Typography variant="h6" alignItems="center" display="flex" gap={1}>
        <FunctionField
          render={(record: { state: string }) =>
            record.state !== 'pending-search' ? (
              <DoneIcon sx={{ mr: 1 }} />
            ) : (
              <ToDoIcon sx={{ mr: 1 }} />
            )
          }
        />
        Request: Product Search
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Stack spacing={1}>
            <Typography variant="subtitle1">Preferences</Typography>
            <ProductSearchQueryField label={false} source="preferences" />
            <Typography variant="subtitle1">Requirements</Typography>
            <ProductSearchQueryField label={false} source="requirements" />
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack spacing={1}>
            <Typography variant="subtitle1">
              Additional products and configuration
            </Typography>
            <ProductSearchQueryField label={false} source="addons" />
          </Stack>
        </Grid>
      </Grid>
    </AccordionDetails>
  </Accordion>
)
