import React from 'react'
import {
  Show,
  ShowProps,
  TabbedShowLayout,
  Tab,
  TextField,
  ReferenceField,
  NumberField,
  DateField,
} from 'react-admin'
import { PageTitle } from '../../atoms/PageTitle'
import { ReferenceText } from '../../molecules/ReferenceText'
import { MutationsList } from '../../organisms/MutationsList'

/**
 * Page to show a margin rule.
 * @property {ShowProps} props
 * @returns {React.FC<ShowProps>}
 * @example
 * <MarginRuleShow {...props} />
 * @see https://marmelab.com/react-admin/Show.html
 */
export const MarginRuleShow: React.FC<ShowProps> = (props) => {
  return (
    <Show {...props} title={<PageTitle prefix="Margin Rule" />}>
      <TabbedShowLayout {...props}>
        <Tab label="summary">
          <ReferenceText
            source="marginBookId"
            reference="marginBooks"
            link={false}
          />
          <ReferenceText
            source="accessTechnologyId"
            reference="accessTechnologies"
            link={false}
            emptyText={'*'}
          />
          <ReferenceField
            source="serviceTypesId"
            reference="serviceTypes"
            link={false}
            emptyText={'*'}
          >
            <TextField source="name" />
          </ReferenceField>
          <TextField source="priceCategory" />
          <NumberField source="priceFrom" />
          <NumberField source="priceUntil" emptyText={'infinity'} />
          <TextField source="marginType" />
          <NumberField source="marginValue" />
          <DateField source="updatedAt" showTime />
          <DateField source="createdAt" showTime />
        </Tab>
        <Tab label={'history'}>
          <MutationsList showActor type="history" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
