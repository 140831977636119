import React from 'react'
import ServiceTypeIcon from '@mui/icons-material/LanOutlined'
import { useGetList, FilterList, FilterListItem } from 'react-admin'

/**
 * Filters records by the service type.
 * @returns {JSX.Element} The rendered component.
 * @example
 * return (
 * <ServiceTypeFilter />
 * )
 * @see {@link https://marmelab.com/react-admin/List.html#the-filterlist-component|FilterList}
 * @see {@link https://marmelab.com/react-admin/List.html#the-filterlistitem-component|FilterListItem}
 *
 */
export const ServiceTypeFilter: React.FC = () => {
  const { data } = useGetList('serviceTypes/list')

  if (!data) return null

  return (
    <FilterList label="Service type" icon={<ServiceTypeIcon />}>
      {data.map((record) => (
        <FilterListItem
          key={record.id}
          label={record.name}
          value={{ serviceTypeId: record.id }}
        />
      ))}
    </FilterList>
  )
}
