import React from 'react'
import { IRequestFilters } from './RequestFilters.types'
import {
  Filter,
  SelectArrayInput,
  ReferenceInput,
  AutocompleteArrayInput,
  TextInput,
} from 'react-admin'

/**
 * Filters for the Request List.
 * @returns {React.FC<IRequestFilters>} RequestFilters component
 * @property {React.FC<IRequestFilters>} props Component props
 */
export const RequestFilters: React.FC<IRequestFilters> = (props) => {
  return (
    <Filter {...props}>
      <TextInput source="term" label="Search" alwaysOn />
      <SelectArrayInput
        label="State"
        source="state"
        choices={[
          { id: 'draft', name: 'Draft' },
          { id: 'in-progress', name: 'In Progress' },
          { id: 'done', name: 'Done' },
          { id: 'archived', name: 'Archived' },
        ]}
        alwaysOn
      />
      <ReferenceInput source="clientId" reference="clients/list" alwaysOn>
        <AutocompleteArrayInput
          label="Customer"
          optionText="name"
          alwaysOn
          sx={{ minWidth: 200 }}
        />
      </ReferenceInput>
      <ReferenceInput
        source="endCustomerId"
        reference="endCustomers/list"
        alwaysOn
      >
        <AutocompleteArrayInput
          label="End Customer"
          optionText="name"
          alwaysOn
          sx={{ minWidth: 200 }}
        />
      </ReferenceInput>
      <ReferenceInput source="projectId" reference="projects/list">
        <AutocompleteArrayInput
          label="Project"
          optionText="name"
          sx={{ minWidth: 200 }}
        />
      </ReferenceInput>
      <SelectArrayInput
        label="Channel"
        source="channel"
        choices={[
          { id: 'portal', name: 'Portal' },
          { id: 'api', name: 'API' },
        ]}
        alwaysOn
      />
    </Filter>
  )
}
