import React from 'react'
import {
  Edit,
  EditProps,
  TextInput,
  BooleanInput,
  FormDataConsumer,
  NumberInput,
  SimpleForm,
} from 'react-admin'
import { ReferenceAutocomplete } from '../../molecules/ReferenceAutocomplete'
import { PageTitle } from '../../atoms/PageTitle'

/**
 * Page to edit a coverage zone.
 * @property {EditProps} props
 * @example
 * <CoverageZoneEdit />
 * @returns {React.FC<EditProps>}
 * @see https://marmelab.com/react-admin/Edit.html
 */
export const CoverageZoneEdit: React.FC<EditProps> = (props) => {
  return (
    <Edit
      {...props}
      mutationMode="undoable"
      title={<PageTitle prefix="Coverage zone" />}
    >
      <SimpleForm>
        <TextInput source="name" disabled fullWidth />
        <TextInput source="description" fullWidth />
        <NumberInput source="priority" fullWidth />
        <ReferenceAutocomplete
          required
          source={'countryCode'}
          reference="countries/list"
        />
        <ReferenceAutocomplete
          required
          label="Vendor"
          source="vendorId"
          reference="vendors/list"
          fullWidth
        />
        <FormDataConsumer>
          {({ formData }) => (
            <>
              <ReferenceAutocomplete
                label="Service category"
                source="serviceCategoryId"
                reference="serviceCategories"
              />
              <ReferenceAutocomplete
                label={'Service type'}
                reference="serviceTypes"
                source="serviceTypeId"
                filter={{ categoryId: formData?.categoryId }}
              />
              <ReferenceAutocomplete
                label={'Access technology'}
                reference="accessTechnologies"
                source="accessTechnologyId"
              />
            </>
          )}
        </FormDataConsumer>
        <BooleanInput source="isActive" defaultValue={true} fullWidth />
      </SimpleForm>
    </Edit>
  )
}
