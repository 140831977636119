import React from 'react'
import {
  IconMenu,
  MenuItemList,
  MenuItemNode,
} from '@react-admin/ra-navigation'
import { CardContent } from '@mui/material'
import CoverageIcon from '@mui/icons-material/MapOutlined'

/**
 * This is a custom menu item that will be added to the main menu.
 * It will display a list of menu items when clicked.
 * @returns {JSX.Element}
 */
export const CoverageMenuItem: React.FC = () => {
  return (
    <IconMenu.Item name="coverage" icon={<CoverageIcon />} label="Coverage">
      <CardContent>
        <MenuItemList>
          <MenuItemNode
            name="coverageZones"
            to="/coverageZones"
            label="Zones"
          />
          <MenuItemNode
            name="coverageAreas"
            to="/coverageAreas"
            label="Areas"
          />
          <MenuItemNode
            name="coverageAddresses"
            to="/coverageAddresses"
            label="Addresses"
          />
          <MenuItemNode
            name="coverageDatacenters"
            to="/coverageDatacenters"
            label="Datacenters"
          />
        </MenuItemList>
      </CardContent>
    </IconMenu.Item>
  )
}
