import React from 'react'
import {
  Edit,
  EditProps,
  TextInput,
  required,
  FormTab,
  TabbedForm,
  FileInput,
  FileField,
  ReferenceArrayInput,
} from 'react-admin'
import { DualListInput } from '@react-admin/ra-relationships'

/**
 * Page to edit an existing InternetServiceProvider.
 * @param props The page props.
 * @returns JSX.Element to render.
 */
export const InternetServiceProviderEdit: React.FC<EditProps> = (props) => {
  return (
    <Edit {...props} mutationMode="undoable">
      <TabbedForm>
        <FormTab label={'general'}>
          <TextInput source="name" validate={required()} fullWidth />
          <FileInput
            source="file"
            label="Logo in webp format"
            accept="image/webp"
            multiple={false}
          >
            <FileField source="src" title="title" />
          </FileInput>
        </FormTab>
        <FormTab label={'vendors'}>
          <ReferenceArrayInput
            label="Vendors"
            source="vendorIds"
            reference="vendors/list"
          >
            <DualListInput optionText="name" label={false} />
          </ReferenceArrayInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}
