import React from 'react'
import { useRecordContext, useNotify, Button } from 'react-admin'
import Analytics from '@mui/icons-material/Analytics'

export const ViewInMetabase: React.FC = () => {
  const record = useRecordContext()
  const notify = useNotify()

  const viewInGrafana = () => {
    if (!process.env.REACT_APP_METABASE_URL) {
      return notify('There is no Metabase URL on this env', { type: 'error' })
    }
    window.open(
      `${process.env.REACT_APP_METABASE_URL}/dashboard/8-clients-overview?client_id=${record.id}`,
      '_blank'
    )
  }

  return (
    <Button
      color="primary"
      onClick={viewInGrafana}
      label="View in Metabase"
      disabled={!record}
      startIcon={<Analytics />}
    />
  )
}
