import React from 'react'
import {
  IconMenu,
  MenuItemList,
  MenuItemNode,
} from '@react-admin/ra-navigation'
import { CardContent } from '@mui/material'
import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined'

/**
 * This is a custom menu item that will be added to the main menu.
 * It will display a list of menu items when clicked.
 */
export const MonitoringMenuItem: React.FC = () => {
  return (
    <IconMenu.Item
      name="monitoring"
      icon={<MonitorHeartOutlinedIcon />}
      label="Monitoring"
    >
      <CardContent>
        <MenuItemList>
          <MenuItemNode
            name="monitorTargets"
            to="/monitorTargets"
            label="Targets"
          />
          <MenuItemNode
            name="monitorProbes"
            to="/monitorProbes"
            label="Probes"
          />
          <MenuItemNode
            name="monitorProbeTypes"
            to="/monitorProbeTypes"
            label="Probe types"
          />
          <MenuItemNode
            name="monitorNotificationTypes"
            to="/monitorNotificationTypes"
            label="Notification types"
          />
          <MenuItemNode
            name="monitorNotificationContacts"
            to="/monitorNotificationContacts"
            label="Notification contacts"
          />
          <MenuItemNode
            name="monitorMetrics"
            to="/monitorMetrics"
            label="Metrics"
          />
          <MenuItemNode
            name="monitorAlertRules"
            to="/monitorAlertRules"
            label="Alert rules"
          />
          <MenuItemNode
            name="monitorAlertHistories"
            to="/monitorAlertHistories"
            label="Alert history"
          />
        </MenuItemList>
      </CardContent>
    </IconMenu.Item>
  )
}
