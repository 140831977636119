import React from 'react'
import { DualSelect } from '../../atoms/DualSelect'
import {
  Create,
  CreateProps,
  SimpleForm,
  BooleanInput,
  TextInput,
  required,
  SelectInput,
} from 'react-admin'

/**
 * Create Application page.
 * @property {CreateProps} props
 * @returns {JSX.Element}
 * @see {@link https://marmelab.com/react-admin/CreateEdit.html|CreateEdit}
 */
export const ApplicationCreate: React.FC<CreateProps> = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" validate={required()} fullWidth />
        <BooleanInput source="isActive" defaultValue={true} fullWidth />
        <SelectInput
          source="dataView"
          defaultValue={'client'}
          choices={[
            { id: 'client', name: 'Client (default)' },
            { id: 'clientPlusVendor', name: 'Client+Vendor (reveals Vendor)' },
            { id: 'technical', name: 'Client Technical (hides financials)' },
          ]}
          fullWidth
        />
        <DualSelect
          label="Clients"
          source="clientIds"
          reference="clients/list"
        />
        <DualSelect
          label="Permissions"
          source="permissionIds"
          reference="permissions/list"
        />
      </SimpleForm>
    </Create>
  )
}
