import React from 'react'
import {
  Card,
  CardActions,
  CardContent,
  Chip,
  Grid,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material'
import {
  DateField,
  SimpleForm,
  SimpleShowLayout,
  required,
  useGetList,
  useRecordContext,
} from 'react-admin'
import { CreateInDialogButton } from '@react-admin/ra-form-layout'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { MarkdownField, MarkdownInput } from '@react-admin/ra-markdown'
import { NotesDialogToolbar } from '../../atoms/NotesDialogToolbar'
import { RequestProductHistoryProps } from './RequestProductHistory.types'

/** Expects `RequestProduct` as the RecordContext. */
export const RequestProductHistory: React.FC<RequestProductHistoryProps> = (
  props
) => {
  const record = useRecordContext(props)

  const { data: notesList } = useGetList<{
    id: string
    clientId: null
    userId: string
    relatedName: string
    relatedId: string
    content: string
    isInternal: boolean
    createdAt: string
    updatedAt: string
    client: null
    user: {
      name: string
      id: string
    }
  }>('notes', {
    pagination: { page: 1, perPage: 999 },
    sort: { field: 'createdAt', order: 'DESC' },
    filter: { relatedName: 'RequestProduct', relatedId: record?.id },
  })

  return (
    <Card>
      <Typography variant="h6" p={2}>
        History
      </Typography>
      <CardActions sx={{ padding: 2, margin: 0 }}>
        <CreateInDialogButton
          title={
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              paddingRight={3}
            >
              <Grid item>
                <Typography variant="h6">Add Note</Typography>
              </Grid>
              <Grid item>
                <Chip label={props.reference} />
              </Grid>
            </Grid>
          }
          ButtonProps={{
            size: 'small',
            variant: 'contained',
            color: 'info',
            fullWidth: true,
            disabled: !record.requestService?.isReviewConfirmed,
          }}
          label="Add Note"
          icon={<></>}
          fullWidth
          maxWidth="md"
          resource="notes"
        >
          <SimpleForm
            defaultValues={{
              isInternal: true,
              relatedName: 'RequestProduct',
              relatedId: record?.id,
              clientId: props.clientId,
            }}
            toolbar={<NotesDialogToolbar />}
            sx={{ paddingY: 0 }}
          >
            <MarkdownInput
              height="200px"
              fullWidth
              label={false}
              source="content"
              validate={required()}
            />
          </SimpleForm>
        </CreateInDialogButton>
      </CardActions>
      <CardContent sx={{ margin: 0 }}>
        {(notesList?.length ?? 0) > 0 && (
          <Stepper
            orientation="vertical"
            sx={{
              '& .MuiStep-root': {
                '& .MuiStepLabel-root': {
                  paddingTop: 2,
                  paddingBottom: 2,
                  height: '20px',
                },
              },
              p: 2,
            }}
          >
            {notesList?.map((item) => (
              <Step key={item.id} active>
                <StepLabel
                  icon={<AccountCircleIcon sx={{ fontSize: '24px' }} />}
                >
                  <Stack>
                    <Typography>{item.user?.name}</Typography>
                    <DateField
                      source="createdAt"
                      variant="caption"
                      showTime
                      label={false}
                      color={'#8e8e8e'}
                    />
                  </Stack>
                </StepLabel>
                <StepContent sx={{ pLeft: 2 }}>
                  <SimpleShowLayout record={item} sx={{ p: 0 }}>
                    <MarkdownField
                      source="content"
                      sx={{ zoom: 1.1, m: 0, p: 0 }}
                      label={false}
                    />
                  </SimpleShowLayout>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        )}
      </CardContent>
    </Card>
  )
}
