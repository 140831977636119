import React, { useRef } from 'react'
import { ICoverImage } from './CoverImage.types'
import { useStyles } from './CoverImage.styles'
import { Box } from '@mui/system'

/**
 * Automatically loads a background image and sets it as the background of the container.
 * @returns JSX.Element
 */
export const CoverImage: React.FC<ICoverImage> = (props) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const styles = useStyles()

  return (
    <Box sx={styles} ref={containerRef}>
      {props.children}
    </Box>
  )
}
