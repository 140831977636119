import React from 'react'
import {
  ArrayField,
  Datagrid,
  FunctionField,
  SimpleShowLayout,
  TextField,
} from 'react-admin'
import { useMutationDisplayRecord } from './MutationsList.hooks'

/**
 * Toggled by clicking on a mutation in the list.
 * @see https://marmelab.com/react-admin/Fields.html#functionfield
 * @returns {React.FC}
 */
export const MutationDisplay: React.FC = () => {
  const record = useMutationDisplayRecord()
  return (
    <SimpleShowLayout record={record}>
      <ArrayField source="changeList" label={'Changes'} fullWidth>
        <Datagrid bulkActionButtons={false} optimized>
          <FunctionField
            label={'Property'}
            render={(record: any) => <pre>{encodeURI(record.property)}</pre>}
          />
          <FunctionField
            label={'Old value'}
            render={(record: any) =>
              record.from !== undefined ? (
                <pre>
                  {typeof record.from === 'object'
                    ? JSON.stringify(record.from)
                    : record.from}
                </pre>
              ) : (
                <i>N/A</i>
              )
            }
          />
          <FunctionField
            label={'New value'}
            render={(record: any) =>
              record.to !== undefined ? (
                <pre>
                  {typeof record.to === 'object'
                    ? JSON.stringify(record.to)
                    : record.to}
                </pre>
              ) : (
                <i>N/A</i>
              )
            }
          />
        </Datagrid>
      </ArrayField>
      <ArrayField source="environmentList" label={'Environment'} fullWidth>
        <Datagrid bulkActionButtons={false}>
          <TextField source="key" />
          <TextField source="value" />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  )
}
