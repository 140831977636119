import React from 'react'
import { TextInput, required } from 'react-admin'
import { ReferenceAutocomplete } from '../../molecules/ReferenceAutocomplete'
import { IAddressInput } from './AddressInput.types'
/**
 * Address input component.
 * @property {React.FC} props
 * @returns {React.FC}
 * @example
 * import { AddressInput } from './AddressInput'
 * <AddressInput />
 * @see https://marmelab.com/react-admin/Inputs.html
 * @see https://marmelab.com/react-admin/BooleanInput.html
 * @see https://marmelab.com/react-admin/SelectInput.html
 */
export const AddressInput: React.FC<IAddressInput> = (props) => {
  return (
    <>
      <TextInput
        source="address"
        label="Full address"
        validate={required()}
        fullWidth
      />
      <ReferenceAutocomplete
        label="Country"
        source={props.countrySource || 'countryCode'}
        reference="countries/list"
      />
      <TextInput source={props.citySource || 'city'} fullWidth />
      <TextInput source={props.postalCodeSource || 'postalCode'} fullWidth />
      <TextInput source={props.streetNameSource || 'streetName'} fullWidth />
      <TextInput
        source={props.streetNumberSource || 'streetNumber'}
        fullWidth
      />
      <TextInput
        source={props.streetNumberSuffixSource || 'streetNumberSuffix'}
        fullWidth
      />
      <TextInput
        source={props.streetNumberRoomSource || 'streetNumberRoom'}
        fullWidth
      />
    </>
  )
}
