import React from 'react'
import { IUserListFilters } from './UserListFilters.types'
import {
  Filter,
  TextInput,
  ReferenceInput,
  SelectInput,
  BooleanInput,
} from 'react-admin'

/**
 * Filters for a list of users.
 * @property {IUserListFilters} props The properties of the component.
 * @returns {JSX.Element} The JSX to render.
 * @example
 * <UserListFilters />
 * @see https://marmelab.com/react-admin/List.html#the-filter-button
 * @see https://marmelab.com/react-admin/List.html#the-filter-form
 * @see https://marmelab.com/react-admin/List.html#the-filter-button
 */
export const UserListFilters: React.FC<IUserListFilters> = (props) => {
  return (
    <Filter {...props}>
      <TextInput label="Search" source="term" alwaysOn />
      <ReferenceInput source="role" reference="userRoles/list" alwaysOn>
        <SelectInput label={'Role'} optionText="name" alwaysOn />
      </ReferenceInput>
      <BooleanInput source="isActive" label="Only show active" />
    </Filter>
  )
}
