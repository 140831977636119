import { Exporter, downloadCSV } from 'react-admin'
// @ts-ignore
import jsonExport from 'jsonexport/dist'

/**
 * Normalises a list of services for export to a CSV file
 */
const normaliseServices = (services: any[]) => {
  return services.map((service: any) => {
    service.clientContract = service.clientContracts?.[0]
    service.vendorContract = service.vendorContracts?.[0]
    service.site = service.sites?.[0]

    delete service.clientContracts
    delete service.vendorContracts
    delete service.statistics
    delete service.sites
    delete service.meta

    // the following can cause the exporter to 'break out' due to HTML
    delete service.notesForClient
    delete service.deliveryNotes
    delete service.vendorContract?.notes
    delete service.clientContract?.notes
    delete service.clientContract?.notes

    return service
  })
}

/**
 * Exports a list of services to a CSV file
 * @param {IService[]} services - The list of services
 * @returns {void}
 * @example
 * serviceExporter(services)
 * // downloads a CSV file with the list of services.
 */
export const serviceExporter: Exporter = (services: any[]) => {
  jsonExport(
    normaliseServices(services),
    {
      headers: [
        'serviceNo',
        'name',
        'reference',
        'serviceType.name',
        'serviceCategory.name',
        'accessTechnology.name',
        'client.name',
        'endCustomer.name',
        'state',
      ],
      rowDelimiter: ';',
      forceTextDelimiter: true,
      textDelimiter: '"',
    },
    (_err: any, csv: any) => {
      downloadCSV(csv, 'services')
    }
  )
}
