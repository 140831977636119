import React from 'react'
import { Edit, EditProps, SimpleForm, TextInput, required } from 'react-admin'
import { ReferenceAutocomplete } from '../../molecules/ReferenceAutocomplete'

/**
 * Page to edit a project.
 * @property {EditProps} props
 * @returns {React.FC<EditProps>}
 */
export const ProjectEdit: React.FC<EditProps> = (props) => {
  return (
    <Edit {...props} mutationMode="undoable">
      <SimpleForm>
        <TextInput disabled label="Id" source="id" fullWidth />
        <TextInput source="title" validate={required()} fullWidth />
        <ReferenceAutocomplete
          required
          label="Client"
          source="clientId"
          reference="clients/list"
        />
      </SimpleForm>
    </Edit>
  )
}
