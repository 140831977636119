import { VendorCreate } from './Create.component'
import { VendorEdit } from './Edit.component'
import { VendorList } from './List.component'
import { VendorShow } from './Show.component'

export default {
  create: VendorCreate,
  edit: VendorEdit,
  list: VendorList,
  show: VendorShow,
}
