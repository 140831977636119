import React from 'react'
import { UserListFilters } from '../../molecules/UserListFilters'
import {
  List,
  Datagrid,
  DateField,
  ListProps,
  TextField,
  BooleanField,
} from 'react-admin'
import { ReferenceChip } from '../../molecules/ReferenceChip'
/**
 * Page to list all users.
 * @param props The props passed to the component.
 * @returns {JSX.Element} The JSX to render.
 * @example
 * <UserList />
 * @see https://marmelab.com/react-admin/List.html
 * @see https://marmelab.com/react-admin/List.html#the-list-component
 * @see https://marmelab.com/react-admin/List.html#the-datagrid-component
 * @see https://marmelab.com/react-admin/List.html#the-filter-button
 */
export const UserList: React.FC<ListProps> = (props) => {
  return (
    <List
      empty={false}
      perPage={25}
      filters={<UserListFilters />}
      sort={{ field: 'createdAt', order: 'DESC' }}
      {...props}
    >
      <Datagrid rowClick="show">
        <TextField source="email" />
        <ReferenceChip
          referenceSource="role"
          source="Role"
          chipSource="name"
          tooltipSource="name"
          reference="userRoles/list"
          emptyText="N/A"
          link={false}
        />
        <TextField source="name" emptyText={'N/A'} />
        <BooleanField source="isActive" />
        <DateField source="createdAt" showTime />
        <DateField source="updatedAt" showTime />
      </Datagrid>
    </List>
  )
}
