import React from 'react'
import {
  Show,
  ShowProps,
  Tab,
  TabbedShowLayout,
  TextField,
  ArrayField,
  Datagrid,
  FunctionField,
  ReferenceField,
  RaRecord,
} from 'react-admin'
import { PageTitle } from '../../atoms/PageTitle'
import { CurrencyField } from '../../atoms/CurrencyField'
import { TextPreSuffixField } from '../../atoms/TextPreSuffixField'
import { MutationsList } from '../../organisms/MutationsList'
import { QuoteSummary } from '../../templates/QuoteSummary'
import { QuoteItemDisplay } from '../../templates/QuoteItemDisplay'

/**
 * Page component that displays a quote.
 * @param props The component props.
 * @returns {JSX.Element} The rendered component.
 * @example
 * import { QuoteShow } from './QuoteShow.component.tsx'
 * <QuoteShow />
 */
export const QuoteShow: React.FC<ShowProps> = (props) => {
  return (
    <Show {...props} title={<PageTitle prefix="Quote" />}>
      <TabbedShowLayout {...props}>
        <Tab label="summary">
          <QuoteSummary />
        </Tab>
        <Tab label="items">
          <ArrayField source={'items'} label={false}>
            <Datagrid
              sx={{ mb: 2 }}
              bulkActionButtons={false}
              rowClick="expand"
              expand={
                <FunctionField
                  label={false}
                  render={(record: RaRecord) => (
                    <QuoteItemDisplay {...record} />
                  )}
                />
              }
            >
              <TextField source="order" label="#" />
              <TextField source="title" />
              <TextPreSuffixField
                source="contractPeriod"
                textAlign={'right'}
                suffix={' months'}
                label={'Contract'}
              />
              <CurrencyField
                currency={'salesCurrency'}
                source="salesNrc"
                label={'Sales NRC'}
                textAlign="right"
              />
              <CurrencyField
                currency={'salesCurrency'}
                source="salesMrc"
                label={'Sales MRC'}
                textAlign="right"
              />
              <ReferenceField
                source={'salesCurrency'}
                reference="currencies/list"
                link={false}
              >
                <TextField label={'Sales currency'} source={'name'} />
              </ReferenceField>
              <TextPreSuffixField
                source={'salesVat'}
                suffix={'%'}
                label={'Sales VAT'}
              />
              <FunctionField
                label={'Diverse to'}
                render={(record: any) => (
                  <TextField
                    record={{
                      value:
                        record.diverseTo && record.diverseTo.order
                          ? '#' + +record.diverseTo.order
                          : null,
                    }}
                    source="value"
                    emptyText={'No diversity'}
                  />
                )}
              />
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label={'notes'}>
          <TextField source="notesForClient" emptyText={'N/A'} fullWidth />
        </Tab>
        <Tab label={'history'}>
          <MutationsList showActor type="history" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
