import React from 'react'
import { List, ListProps, TextField, DateField } from 'react-admin'
import { LabelledDatagrid } from '../../atoms/LabelledDatagrid'
import { OnboardingFilters } from '../../molecules/OnboardingFilters'
import { TypeStateField } from '../../atoms/TypeStateField'

/**
 * Page to list all onboarding.
 * @param props The properties
 * @returns The jsx component
 * @example <OnboardingList />
 */
export const OnboardingList: React.FC<ListProps> = (props) => {
  return (
    <List
      {...props}
      empty={false}
      perPage={25}
      filters={<OnboardingFilters />}
      sort={{ field: 'createdAt', order: 'DESC' }}
    >
      <LabelledDatagrid rowClick="show">
        <TypeStateField source="type" fullWidth />
        <TextField source="description" />
        <TypeStateField source="state" fullWidth />
        <DateField source="createdAt" showTime />
        <DateField source="updatedAt" showTime />
      </LabelledDatagrid>
    </List>
  )
}
