import React from 'react'
import { Grid } from '@mui/material'
import { ReferenceAutocomplete } from '../../molecules/ReferenceAutocomplete'
import { FieldValues } from 'react-hook-form/dist/types/fields'

/**
 * Selects the client for the template previewer.
 * @param {FieldValues} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 * @example
 * return (
 * <SelectClient />
 * )
 * @see {@link https://mui.com/components/grid/|MUI Grid}
 * @see {@link https://mui.com/components/autocomplete/|MUI Autocomplete}
 */
export const SelectClient: React.FC<FieldValues> = (props) => {
  if (!['ClientContract', 'ServiceOrder'].includes(props.context?.model))
    return null

  return (
    <Grid item xs={4} p={1}>
      <ReferenceAutocomplete
        label="Client"
        reference="clients/list"
        source="clientId"
      />
    </Grid>
  )
}
