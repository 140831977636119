import React from 'react'
import { IFieldTooltip } from './FieldTooltip.types'
import { useRecordContext } from 'react-admin'
import { get } from 'lodash'
import { Tooltip } from '@mui/material'

/**
 * Field tooltip component.
 *
 * @param props - The component props.
 * @returns The rendered component.
 * @see IFieldTooltip
 * @see https://mui.com/components/tooltips/#custom-child-element
 * @see https://reactjs.org/docs/forwarding-refs.html
 */
export const FieldTooltip: React.FC<IFieldTooltip> = (props) => {
  const record = useRecordContext(props)
  const title = get(record, props.titleSource)

  return (
    <Tooltip title={title}>
      <span>{props.children}</span>
    </Tooltip>
  )
}
