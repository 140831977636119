/**
 * Returns a list of supported contact roles.
 * @returns A list of contact role choices.
 */
export const useRoleChoices = () => {
  return [
    { id: 'general', name: 'General' },
    { id: 'order', name: 'Order' },
    { id: 'local', name: 'Local' },
    { id: 'billing', name: 'Billing' },
    { id: 'delivery', name: 'Delivery' },
    { id: 'technical', name: 'Technical' },
    { id: 'maintenance', name: 'Maintenance' },
    { id: 'escalation', name: 'Escalation' },
  ]
}
