import FilterIcon from '@mui/icons-material/FilterListOutlined'
import RequestsFlowIcon from '@mui/icons-material/RouteOutlined'
import {
  Box,
  Breadcrumbs,
  Grid,
  IconButton,
  Stack,
  Tooltip,
} from '@mui/material'
import React from 'react'
import {
  Button,
  DateField,
  FunctionField,
  Link,
  Show,
  SimpleShowLayout,
  TopToolbar,
  useRedirect,
} from 'react-admin'
import { useParams } from 'react-router-dom'
import { EllipsedTextField } from '../../atoms/EllipsedField'
import { RequestFlowServicesList } from '../../molecules/RequestFlowServicesList'
import DownloadIcon from '@mui/icons-material/GetApp'
import CreateIcon from '@mui/icons-material/Create'
import DebugIcon from '@mui/icons-material/BugReportOutlined'
import { TypeStateField } from '../../atoms/TypeStateField'
import { useExportRequestToExcelFunction } from './RequestFlow.hooks'
import { RequestFlowTitle } from './RequestFlowTitle.component'

export const RequestFlowOverview: React.FC = () => {
  const { requestId } = useParams()
  const redirect = useRedirect()

  const [displayFilters, setDisplayFilters] = React.useState(false)
  const exportRequestToExcel = useExportRequestToExcelFunction()

  const handleExportButtonClick = async () => {
    if (requestId) await exportRequestToExcel(requestId)
  }

  const handleDebugClick = () => {
    redirect(`/requests/${requestId}/show/6`)
  }

  const handleEditButtonClick = () => {
    redirect(`/requests/${requestId}`)
  }

  return (
    <Show
      resource="requests"
      id={requestId}
      title={<RequestFlowTitle />}
      component={Box}
      actions={false}
    >
      <Stack gap={1} paddingX={2} paddingY={0}>
        <Stack direction="row" justifyContent="space-between" paddingY={1}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link to="/quote-requests" title="Requests" color="inherit">
              <Grid container alignItems="center">
                <RequestsFlowIcon sx={{ fontSize: '16px' }} />
              </Grid>
            </Link>
            <RequestFlowTitle />
          </Breadcrumbs>
          <TopToolbar>
            <Button
              onClick={handleDebugClick}
              label="Debug"
              startIcon={<DebugIcon />}
            />
            <Button
              onClick={handleEditButtonClick}
              label="Edit"
              startIcon={<CreateIcon />}
            />
            <Button
              label="ra.action.export"
              type="button"
              onClick={handleExportButtonClick}
              startIcon={<DownloadIcon />}
            />
          </TopToolbar>
        </Stack>
        <Grid
          container
          alignItems="center"
          gap={2}
          justifyContent="space-between"
        >
          <Grid item>
            <TypeStateField source="state" label="State" emptyText="N/A" />
          </Grid>
          <Grid item>
            <SimpleShowLayout sx={{ padding: 0 }}>
              <EllipsedTextField
                source="project.title"
                label="Project"
                emptyText="N/A"
              />
            </SimpleShowLayout>
          </Grid>
          <Grid item>
            <SimpleShowLayout sx={{ padding: 0 }}>
              <FunctionField
                source="serviceCategory.name"
                label="Category"
                emptyText="N/A"
                render={(record: {
                  serviceCategory?: { name: string; key: string }
                }) =>
                  `${record.serviceCategory?.name} (${record.serviceCategory?.key})`
                }
              />
            </SimpleShowLayout>
          </Grid>
          <Grid item>
            <SimpleShowLayout sx={{ padding: 0 }}>
              <EllipsedTextField
                source="client.name"
                label="Customer"
                emptyText="N/A"
              />
            </SimpleShowLayout>
          </Grid>
          <Grid item>
            <SimpleShowLayout sx={{ padding: 0 }}>
              <EllipsedTextField
                source="endCustomer.name"
                label="End customer"
                emptyText="N/A"
              />
            </SimpleShowLayout>
          </Grid>
          <Grid item>
            <SimpleShowLayout sx={{ padding: 0 }}>
              <DateField source="createdAt" label="Created at" showTime />
            </SimpleShowLayout>
          </Grid>
          <Grid item></Grid>
          <Grid item>
            <Tooltip title="Toggle Filter" enterDelay={300}>
              <IconButton
                color="inherit"
                onClick={() => setDisplayFilters(!displayFilters)}
                aria-label="Toggle Filter"
              >
                <FilterIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <RequestFlowServicesList
          displayFilters={displayFilters}
          requestId={requestId}
        />
      </Stack>
    </Show>
  )
}
