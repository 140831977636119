import React from 'react'
import { PageTitle } from '../../atoms/PageTitle'
import { MetaListDisplay } from '../../organisms/MetaList'
import { ReferenceChipList } from '../../molecules/ReferenceChipList'
import { ReferenceText } from '../../molecules/ReferenceText'
import {
  Show,
  ShowProps,
  TextField,
  BooleanField,
  TabbedShowLayout,
  Tab,
} from 'react-admin'
import { ActivityDisplayTab } from '../../templates/ActivityDisplayTab'
import { HistoryDisplayTab } from '../../templates/HistoryDisplayTab'

/**
 * Page to show user details.
 * @property {ShowProps} props
 * @returns {React.FC<ShowProps>}
 * @example
 * import { UserShow } from './Show.component'
 * <UserShow {...props} />
 * @see https://marmelab.com/react-admin/Show.html
 * @see https://marmelab.com/react-admin/Fields.html
 * @see https://marmelab.com/react-admin/Inputs.html
 * @see https://marmelab.com/react-admin/TabbedShowLayout.html
 * @see https://marmelab.com/react-admin/Tab.html
 */
export const UserShow: React.FC<ShowProps> = (props) => {
  return (
    <Show {...props} title={<PageTitle prefix="User" noRecordValue="create" />}>
      <TabbedShowLayout {...props}>
        <Tab label="general">
          <TextField source="name" />
          <ReferenceText
            source="role"
            reference="userRoles/list"
            label="Role"
          />
          <TextField source="email" />
          <BooleanField source="isActive" />
          <ReferenceChipList
            label={'Groups'}
            source="userGroupIds"
            reference="userGroups"
          />
          <ReferenceChipList
            label={'Clients'}
            source="clientIds"
            reference="clients"
          />
          <ReferenceChipList
            label={'Permissions'}
            source="permissionIds"
            reference="permissions"
          />
        </Tab>
        <Tab label={'Activity'}>
          <ActivityDisplayTab />
        </Tab>
        <Tab label={'preferences'}>
          <MetaListDisplay source="preferences" />
        </Tab>
        <Tab label={'meta'}>
          <MetaListDisplay source="meta" />
        </Tab>
        <Tab label={'history'}>
          <HistoryDisplayTab />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
