import React from 'react'
import {
  IconMenu,
  MenuItemList,
  MenuItemNode,
} from '@react-admin/ra-navigation'
import { CardContent } from '@mui/material'
import DocumentsIcon from '@mui/icons-material/ArticleOutlined'

/**
 * This is a custom menu item that will be added to the main menu.
 * It will display a list of menu items when clicked.
 * @returns {JSX.Element}
 */
export const DocumentsMenuItem: React.FC = () => {
  return (
    <IconMenu.Item name="documents" icon={<DocumentsIcon />} label="Documents">
      <CardContent>
        <MenuItemList>
          <MenuItemNode name="documents" to="/documents" label="Documents" />
          <MenuItemNode
            name="documentCategories"
            to={'/documentCategories'}
            label="Categories"
          />
          <MenuItemNode name="templates" to={'/templates'} label="Templates" />
          <MenuItemNode name="files" to={'/files'} label="Files" />
        </MenuItemList>
      </CardContent>
    </IconMenu.Item>
  )
}
