import React from 'react'
import { ISearchFilter } from './SearchFilter.types'
import { TextInput, Filter } from 'react-admin'

/**
 * Represents a search filter component.
 * @param props - The component props.
 * @returns The rendered SearchFilter component.
 * @see ISearchFilter
 * @see Filter
 * @see TextInput
 * @see https://marmelab.com/react-admin/List.html#the-filter-button
 *
 */
export const SearchFilter: React.FC<ISearchFilter> = (props) => {
  return (
    <Filter {...props}>
      <TextInput label="Search" source="term" alwaysOn />
    </Filter>
  )
}
