import React from 'react'
import { useValidation } from './DocumentCategory.hooks'
import {
  Create,
  CreateProps,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin'

/**
 * Create a new document category.
 * @property {CreateProps} props
 * @returns {React.FC<CreateProps>}
 * @example
 * <DocumentCategoryCreate />
 * @see {@link https://marmelab.com/react-admin/CreateEdit.html|Create}
 * @see {@link https://marmelab.com/react-admin/Inputs.html|Inputs}
 * @see {@link https://marmelab.com/react-admin/Validation.html|Validation}
 */
export const DocumentCategoryCreate: React.FC<CreateProps> = (props) => {
  const validation = useValidation()

  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="key" validate={validation} fullWidth />
        <TextInput source="name" validate={required()} fullWidth />
      </SimpleForm>
    </Create>
  )
}
