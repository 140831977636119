import React from 'react'
import {
  Edit,
  EditProps,
  TabbedForm,
  FormTab,
  TextInput,
  BooleanInput,
  required,
  AutocompleteInput,
  ReferenceInput,
} from 'react-admin'

/**
 * Page to edit an end customer.
 * @property {EditProps} props
 * @example
 * <EndCustomerEdit />
 * @returns {React.FC<EditProps>}
 * @see https://marmelab.com/react-admin/Edit.html
 */
export const EndCustomerEdit: React.FC<EditProps> = (props) => {
  return (
    <Edit {...props}>
      <TabbedForm>
        <FormTab label={'general'}>
          <TextInput source="name" validate={required()} fullWidth />
          <ReferenceInput source={'clientId'} reference="clients/list">
            <AutocompleteInput
              validate={required()}
              optionText="name"
              label={'Client'}
              fullWidth
            />
          </ReferenceInput>
          <BooleanInput source="isActive" defaultValue={true} fullWidth />
        </FormTab>
        <FormTab label={'contact'}>
          <TextInput source="address" fullWidth />
          <TextInput source="postalCode" fullWidth />
          <ReferenceInput
            source={'countryCode'}
            reference="countries/list"
            emptyText={'N/A'}
          >
            <AutocompleteInput optionText="name" label={'Country'} fullWidth />
          </ReferenceInput>
          <TextInput source="city" fullWidth />
          <TextInput source="phoneNumber" fullWidth />
          <TextInput source="email" fullWidth />
        </FormTab>
        <FormTab label={'financial'}>
          <TextInput source="companyName" fullWidth />
          <TextInput source="companyRegNo" fullWidth />
          <TextInput source="companyVatNo" fullWidth />
          <TextInput source="companyIban" fullWidth />
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}
