import { PriceBookShow } from './Show.component'
import { PriceBookEdit } from './Edit.component'
import { PriceBookCreate } from './Create.component'
import { PriceBookList } from './List.component'

export default {
  list: PriceBookList,
  show: PriceBookShow,
  create: PriceBookCreate,
  edit: PriceBookEdit,
}
