import { ServiceSiteCreate } from './Create.component'
import { ServiceSiteEdit } from './Edit.component'
import { ServiceSiteList } from './List.component'
import { ServiceSiteShow } from './Show.component'

export default {
  list: ServiceSiteList,
  show: ServiceSiteShow,
  create: ServiceSiteCreate,
  edit: ServiceSiteEdit,
}
