/**
 * returns styles for the component
 * @returns {Object} styles
 */
export const useStyles = () => {
  return {
    '& .RaSimpleShowLayout-stack': {
      md: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
        gridGap: '4px',
        gridAutoColumns: '1fr',
        alignSelf: 'center',
        '& .ra-field': {
          marginTop: '8px',
        },
      },
    },
    width: '100%',
    margin: 0,
    padding: 0,
  }
}
