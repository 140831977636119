import React from 'react'
import {
  BooleanInput,
  Edit,
  EditProps,
  NumberInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin'
import { PageTitle } from '../../atoms/PageTitle'
import { ReferenceAutocomplete } from '../../molecules/ReferenceAutocomplete'

/**
 * Form to edit a monitor alert rule.
 * @param props The Edit component props
 * @see https://marmelab.com/react-admin/Edit.html
 */
export const MonitorAlertRuleEdit: React.FC<EditProps> = (props) => {
  return (
    <Edit
      {...props}
      title={<PageTitle prefix="Monitor Alert Rule" />}
      mutationMode="undoable"
    >
      <SimpleForm>
        <TextInput disabled label="Id" source="id" fullWidth />
        <ReferenceAutocomplete
          label="Probe Type"
          source="monitorProbeTypeId"
          reference="monitorProbeTypes/list"
          required
        />
        <TextInput source="name" validate={required()} fullWidth />
        <TextInput source="state" validate={required()} fullWidth />
        <TextInput source="summary" validate={required()} fullWidth />
        <TextInput source="description" validate={required()} fullWidth />
        <SelectInput
          source="severity"
          validate={required()}
          choices={[
            { id: 'none', name: 'None' },
            { id: 'minor', name: 'Minor' },
            { id: 'major', name: 'Major' },
            { id: 'critical', name: 'Critical' },
          ]}
          fullWidth
        />
        <SelectInput
          source="dataSource"
          validate={required()}
          choices={[{ id: 'alertManager', name: 'Alert Manager' }]}
          fullWidth
        />
        <TextInput
          multiline
          source="expression"
          validate={required()}
          fullWidth
        />
        <NumberInput source="forDuration" validate={required()} fullWidth />
        <BooleanInput source="isCustom" fullWidth />
        <BooleanInput source="isMuted" fullWidth />
      </SimpleForm>
    </Edit>
  )
}
