import React from 'react'
import { Filter, TextInput } from 'react-admin'
import { ReferenceAutocomplete } from '../../molecules/ReferenceAutocomplete'
import { ISearchClientCountryFiltersProps } from './SearchClientCountryFilters.types'

/**
 * Filter using text search, client and country.
 * @property {ISearchClientCountryFiltersProps} props
 * @property {string} props.label
 * @property {string} props.source
 * @property {string} props.alwaysOn
 * @property {string} props.reference
 * @property {string} props.tooltipSource
 * @property {string} props.emptyText
 * @property {string} props.link
 * @property {string} props.chipSource
 * @property {string} props.referenceSource
 * @example
 * <SearchClientCountryFilters />
 * @returns {React.FC<ISearchClientCountryFiltersProps>}
 */
export const SearchClientCountryFilters: React.FC<
  ISearchClientCountryFiltersProps
> = (props) => {
  return (
    <Filter {...props}>
      <TextInput label="Search" source="term" alwaysOn />
      <ReferenceAutocomplete
        source="clientId"
        reference="clients/list"
        alwaysOn
      />
      <ReferenceAutocomplete
        label="Country"
        source="countryCode"
        reference="countries/list"
        alwaysOn
      />
    </Filter>
  )
}
