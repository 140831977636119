import React from 'react'
import {
  List,
  ListProps,
  TextField,
  Datagrid,
  BooleanField,
  DateField,
} from 'react-admin'
import { SearchFilter } from '../../atoms/SearchFilter'
import { ReferenceText } from '../../molecules/ReferenceText'

/**
 * Page to list templates.
 * @param {ListProps} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 * @example
 * return (
 * <TemplateList {...props} />
 * )
 */
export const TemplateList: React.FC<ListProps> = (props) => {
  return (
    <List
      {...props}
      empty={false}
      perPage={25}
      filters={<SearchFilter />}
      sort={{ field: 'key', order: 'ASC' }}
    >
      <Datagrid rowClick="show">
        <TextField source="name" />
        <TextField source="key" emptyText="N/A" />
        <TextField source="type" />
        <ReferenceText
          reference="documentCategories/list"
          source="categoryId"
          emptyText="N/A"
          link={false}
        />
        <TextField source="view" emptyText="content" />
        <BooleanField source="isDefault" />
        <DateField source="updatedAt" />
      </Datagrid>
    </List>
  )
}
