import React from 'react'
import {
  Create,
  CreateProps,
  TextInput,
  BooleanInput,
  FormDataConsumer,
  NumberInput,
  SimpleForm,
  SelectInput,
} from 'react-admin'
import { ReferenceAutocomplete } from '../../molecules/ReferenceAutocomplete'
import { useChoices } from './CoverageZone.hooks'

/**
 * Page to create a coverage zone.
 * @property {CreateProps} props
 * @example
 * <CoverageZoneCreate />
 * @returns {React.FC<CreateProps>}
 * @see https://marmelab.com/react-admin/Create.html
 */
export const CoverageZoneCreate: React.FC<CreateProps> = (props) => {
  const choices = useChoices()

  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" required fullWidth />
        <TextInput source="description" fullWidth />
        <SelectInput source="type" required choices={choices} fullWidth />
        <NumberInput source="priority" fullWidth />
        <ReferenceAutocomplete
          required
          source={'countryCode'}
          reference="countries/list"
        />
        <ReferenceAutocomplete
          required
          label="Vendor"
          source="vendorId"
          reference="vendors/list"
          fullWidth
        />
        <FormDataConsumer>
          {({ formData }) => (
            <>
              <ReferenceAutocomplete
                label="Service category"
                source="serviceCategoryId"
                reference="serviceCategories"
              />
              <ReferenceAutocomplete
                label={'Service type'}
                reference="serviceTypes"
                source="serviceTypeId"
                filter={{ categoryId: formData?.categoryId }}
              />
              <ReferenceAutocomplete
                label={'Access technology'}
                reference="accessTechnologies"
                source="accessTechnologyId"
              />
            </>
          )}
        </FormDataConsumer>
        <BooleanInput source="isActive" defaultValue={true} fullWidth />
      </SimpleForm>
    </Create>
  )
}
