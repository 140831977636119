/**
 * Default states for the colours.
 * @type {string[]}
 */
export const defaultStates = ['archived', 'draft', 'pending']

/**
 * Success states for the colours.
 * @type {string[]}
 */
export const successStates = [
  'completed',
  'finished',
  'done',
  'active',
  'ordered',
  'firm',
]

/**
 * Warning states for the colours.
 * @type {string[]}
 */
export const warningStates = [
  'validating',
  'in-progress',
  'provisioning',
  'budgetary',
  'datacenter',
]

/**
 * Error states for the colours.
 * @type {string[]}
 */
export const errorStates = [
  'cancelled',
  'expired',
  'terminated',
  'denied',
  'disabled',
  'inactive',
]
