import { CoverageDatacenterShow } from './Show.component'
import { CoverageDatacenterList } from './List.component'
import { CoverageDatacenterCreate } from './Create.component'
import { CoverageDatacenterEdit } from './Edit.component'

export default {
  list: CoverageDatacenterList,
  show: CoverageDatacenterShow,
  create: CoverageDatacenterCreate,
  edit: CoverageDatacenterEdit,
}
