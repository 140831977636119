import React from 'react'
import { FormDataConsumer, required, SelectInput, TextInput } from 'react-admin'
import { Box } from '@mui/material'
import { ReferenceAutocomplete } from '../../molecules/ReferenceAutocomplete'
import { DateInput } from '../../atoms/DateInput'

/**
 * Form form creating a new service order service.
 * @returns {React.FC}
 * @example <ServiceInput />
 */
export const ServiceInput: React.FC = () => {
  return (
    <>
      <Box display={{ sm: 'block', md: 'flex' }} sx={{ p: 0.5 }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <ReferenceAutocomplete
            required
            label="Client"
            source="clientId"
            reference="clients/list"
            disableSyncWithLocation
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <FormDataConsumer>
            {({ formData }) => (
              <ReferenceAutocomplete
                disabled={!formData?.clientId}
                label="End Customer"
                source="endCustomerId"
                reference="endCustomers/list"
                filter={{ clientId: formData?.clientId }}
                disableSyncWithLocation
              />
            )}
          </FormDataConsumer>
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <FormDataConsumer>
            {({ formData }) => (
              <ReferenceAutocomplete
                label="Project"
                source="projectId"
                reference="projects/list"
                filter={{ clientId: formData?.clientId }}
                disableSyncWithLocation
                disabled={!formData?.clientId}
              />
            )}
          </FormDataConsumer>
        </Box>
      </Box>
      <Box display={{ sm: 'block', md: 'flex' }} sx={{ p: 0.5 }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <TextInput source="name" validate={required()} fullWidth />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <TextInput source="reference" fullWidth />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <DateInput source="deliveryWishDate" defaultValue={null} fullWidth />
        </Box>
      </Box>
      <FormDataConsumer>
        {({ formData }) => (
          <Box display={{ sm: 'block', md: 'flex' }} sx={{ p: 0.5 }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <ReferenceAutocomplete
                required
                label="Service category"
                source="serviceCategoryId"
                reference="serviceCategories"
              />
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <ReferenceAutocomplete
                label={'Service type'}
                reference="serviceTypes"
                source="serviceTypeId"
                filter={{ serviceCategoryId: formData?.serviceCategoryId }}
              />
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <ReferenceAutocomplete
                label={'Access technology'}
                reference="accessTechnologies"
                source="accessTechnologyId"
              />
            </Box>
          </Box>
        )}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData }) => (
          <Box display={{ sm: 'block', md: 'flex' }} sx={{ p: 0.5 }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <SelectInput
                source="diversityRole"
                defaultValue="none"
                validate={required()}
                choices={[
                  { id: 'none', name: 'No diversity' },
                  { id: 'primary', name: 'Primary' },
                  { id: 'secondary', name: 'Secondary' },
                  { id: 'tertiary', name: 'Tertiary' },
                ]}
                fullWidth
              />
            </Box>
            {formData?.diversityRole !== 'primary' &&
              formData?.diversityRole !== 'none' && (
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <ReferenceAutocomplete
                    label={'Diverse to primary'}
                    reference="services/list"
                    source="diverseToId"
                    filter={{
                      id: { not: formData?.id },
                      clientId: formData?.clientId,
                      diversityRole: 'primary',
                    }}
                  />
                </Box>
              )}
          </Box>
        )}
      </FormDataConsumer>
      <Box display={{ sm: 'block', md: 'flex' }} sx={{ p: 0.5 }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <TextInput
            multiline
            source="notes"
            label="Internal notes"
            fullWidth
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <TextInput
            multiline
            source="notesForClient"
            label="Notes for client"
            fullWidth
          />
        </Box>
      </Box>
    </>
  )
}
