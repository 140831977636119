import React from 'react'
import {
  Show,
  ShowProps,
  TabbedShowLayout,
  Tab,
  TextField,
  DateField,
  TabbedShowLayoutTabs,
} from 'react-admin'
import { MutationsList } from '../../organisms/MutationsList'
import { PageTitle } from '../../atoms/PageTitle'
import { ServiceList } from '../../organisms/ServiceList'
import { RelatedResourcesPage, IPageMap } from '../../molecules/RelatedResource'
import { ReferenceText } from '../../molecules/ReferenceText'
import { ReferenceChip } from '../../molecules/ReferenceChip'

/**
 * List of related pages to show in the "Related" tab
 */
const relatedPages: IPageMap = {
  Services: <ServiceList target="endCustomerId" />,
}

/**
 * Page to show a single end customer
 * @property {ShowProps} props
 * @returns {React.FC<ShowProps>}
 * @example
 * <EndCustomerShow {...props} />
 * @see https://marmelab.com/react-admin/Show.html
 */
export const EndCustomerShow: React.FC<ShowProps> = (props) => {
  return (
    <Show {...props} title={<PageTitle prefix="End Customer" />}>
      <TabbedShowLayout
        tabs={
          <TabbedShowLayoutTabs variant="scrollable" scrollButtons="auto" />
        }
      >
        <Tab label="general">
          <TextField source="name" fullWidth />
          <ReferenceChip
            referenceSource="clientId"
            tooltipSource="clientNo"
            source="name"
            chipSource="name"
            label={'Client'}
            reference="clients"
            emptyText="N/A"
            link="show"
            removeDoubleLabel
          />
          <DateField source="updatedAt" showTime />
          <DateField source="createdAt" showTime />
        </Tab>
        <Tab label="contact">
          <TextField source="address" fullWidth />
          <TextField source="postalCode" fullWidth />
          <ReferenceText
            source="countryCode"
            reference="countries/list"
            emptyText={'N/A'}
            link={false}
          />
          <TextField source="city" fullWidth />
          <TextField source="phoneNumber" fullWidth />
          <TextField source="email" fullWidth />
        </Tab>
        <Tab label="financial">
          <TextField source="companyName" fullWidth />
          <TextField source="companyRegNo" fullWidth />
          <TextField source="companyVatNo" fullWidth />
          <TextField source="companyIban" fullWidth />
        </Tab>
        <Tab label="history">
          <MutationsList showActor type="history" />
        </Tab>
        <Tab label="related">
          <RelatedResourcesPage resource="endCustomer" pages={relatedPages} />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
