import { useMediaQuery, Theme } from '@mui/material'

/**
 * Shows a grid of cards on small screens and a flex on larger screens.
 * @returns {React.CSSProperties} The styles.
 */
export const useStyles = () => {
  const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  if (!isXSmall) return { display: 'flex' }

  return {
    display: 'grid',
    gridGap: '4px',
    gridAutoColumns: '1fr',
  }
}
