import React from 'react'
import { Filter, TextInput, BooleanInput } from 'react-admin'
import { ISearchActiveFilters } from './SearchActiveFilters.types'

/**
 * Filter using a search term and a boolean to filter active/inactive
 * @prop {ISearchActiveFilters} props
 * @example
 * <SearchActiveFilters />
 * @returns {React.FC<FilterProps>}
 * @see https://marmelab.com/react-admin/List.html#the-filter-button
 */
export const SearchActiveFilters: React.FC<ISearchActiveFilters> = (props) => {
  return (
    <Filter {...props}>
      <TextInput label="Search" source="term" alwaysOn />
      <BooleanInput source="isActive" label="Only show active" />
    </Filter>
  )
}
