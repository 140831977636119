import { regex } from 'react-admin'

/**
 * User form validation.
 * @returns {Array} Validation rules.
 * @see https://marmelab.com/react-admin/CreateEdit.html#required-validation
 * @see https://marmelab.com/react-admin/CreateEdit.html#input-validation
 * @see https://marmelab.com/react-admin/Inputs.html#validation-rules
 */
export const useValidate = () => {
  return [regex(/(^qm:[a-f0-9:-]*|^[a-f0-9]*)$/)]
}
