import React from 'react'
import {
  FormDataConsumer,
  required,
  SelectInput,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
} from 'react-admin'
import { useFormContext } from 'react-hook-form'
import { Box, Button, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/AddOutlined'
import { Message } from '../../atoms/Message'
import get from 'lodash/get'
import {
  connectorChoices,
  modeChoices,
  speedChoices,
} from '../../particles/utils'

/**
 * Form component for adding ports to a service.
 * @returns {React.FC} Form component for adding ports to a service.
 * @example
 * <PortsInput />
 * @todo refactor into smaller components.
 */
export const PortsInput: React.FC = () => {
  const form = useFormContext()
  const { sites } = form.getValues()

  const siteChoices = Object.keys(sites).map((s) => ({
    id: parseInt(s),
    name:
      `#${parseInt(s) + 1}: ` +
      (sites[s]?.id
        ? `Existing site (${sites[s]?.id})`
        : `New site (${sites[s]?.name})`),
  }))

  if (sites.length === 0)
    return <Message message="Can only add ports if sites are added as well." />

  return (
    <ArrayInput source="ports" label={false} sx={{ mb: 3 }} fullWidth>
      <SimpleFormIterator
        getItemLabel={(index) => `#${index + 1}`}
        addButton={
          <Button>
            <AddIcon />
            &nbsp;Add Port
          </Button>
        }
        fullWidth
      >
        <FormDataConsumer>
          {({ formData, getSource }) => (
            <>
              <Box display={{ xs: 'block', sm: 'flex' }} sx={{ width: '100%' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <TextInput
                    source={getSource?.('name') || ''}
                    defaultValue={`Port ${formData.ports?.length}`}
                    validate={required()}
                    fullWidth
                  />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <SelectInput
                    source={getSource?.('state') || ''}
                    defaultValue="active"
                    validate={required()}
                    choices={[
                      { id: 'pending', name: 'Pending' },
                      { id: 'provisioning', name: 'Provisioning' },
                      { id: 'cancelled', name: 'Cancelled' },
                      { id: 'active', name: 'Active' },
                      { id: 'terminated', name: 'Terminated' },
                    ]}
                    fullWidth
                  />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <SelectInput
                    source={getSource?.('site') || ''}
                    choices={siteChoices}
                    validate={required()}
                    fullWidth
                  />
                </Box>
              </Box>
              <Box display={{ xs: 'block', sm: 'flex' }} sx={{ width: '100%' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <SelectInput
                    source={getSource?.('type') || ''}
                    choices={[
                      { id: 'fiber', name: 'Fiber' },
                      { id: 'copper', name: 'Copper' },
                    ]}
                    validate={required()}
                    fullWidth
                  />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <SelectInput
                    source={getSource?.('connector') || ''}
                    choices={connectorChoices(
                      formData,
                      getSource?.('type') || ''
                    )}
                    fullWidth
                  />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <SelectInput
                    source={getSource?.('mode') || ''}
                    choices={modeChoices(formData, getSource?.('type') || '')}
                    fullWidth
                  />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <SelectInput
                    source={getSource?.('speed') || ''}
                    choices={speedChoices(formData, getSource?.('type') || '')}
                    fullWidth
                  />
                </Box>
              </Box>
              <Typography variant="subtitle1">Patch</Typography>
              <Box display={{ xs: 'block', sm: 'flex' }} sx={{ width: '100%' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <TextInput source={getSource?.('room') || ''} fullWidth />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <TextInput source={getSource?.('rack') || ''} fullWidth />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <TextInput source={getSource?.('panel') || ''} fullWidth />
                </Box>
              </Box>
              <Box display={{ xs: 'block', sm: 'flex' }} sx={{ width: '100%' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <TextInput source={getSource?.('device') || ''} fullWidth />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <TextInput source={getSource?.('port') || ''} fullWidth />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <TextInput source={getSource?.('label') || ''} fullWidth />
                </Box>
              </Box>
              <Typography variant="subtitle1">Equipment</Typography>
              <ArrayInput
                source={getSource?.('equipment') || ''}
                label={false}
                sx={{ mb: 3 }}
                fullWidth
              >
                <SimpleFormIterator
                  getItemLabel={(index) => `#${index + 1}`}
                  addButton={
                    <Button>
                      <AddIcon />
                      &nbsp;Add Equipment
                    </Button>
                  }
                  fullWidth
                >
                  <FormDataConsumer>
                    {({ formData, getSource, scopedFormData }) => (
                      <>
                        <Box
                          display={{ xs: 'block', sm: 'flex' }}
                          sx={{ width: '100%' }}
                        >
                          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <SelectInput
                              source={getSource?.('type') || ''}
                              label="Type"
                              choices={[
                                { id: 'router', name: 'Router' },
                                { id: 'modem', name: 'Modem' },
                                { id: 'ap', name: 'Access Point' },
                              ]}
                              validate={required()}
                              fullWidth
                            />
                          </Box>
                          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                              source={getSource?.('brand') || ''}
                              label="Brand"
                              validate={required()}
                              fullWidth
                            />
                          </Box>
                          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                              source={getSource?.('model') || ''}
                              label="Model"
                              validate={required()}
                              fullWidth
                            />
                          </Box>
                          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                              source={getSource?.('serial') || ''}
                              label="Serial"
                              fullWidth
                            />
                          </Box>
                        </Box>
                        <Box
                          display={{ xs: 'block', sm: 'flex' }}
                          sx={{ width: '100%' }}
                        >
                          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                              source={getSource?.('hostname') || ''}
                              label="Hostname"
                              fullWidth
                            />
                          </Box>
                          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                              source={getSource?.('address') || ''}
                              label="IP address"
                              fullWidth
                            />
                          </Box>
                        </Box>
                        <Box
                          display={{ xs: 'block', sm: 'flex' }}
                          sx={{ width: '100%' }}
                        >
                          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                              multiline
                              source={getSource?.('notes') || ''}
                              label="Notes"
                              fullWidth
                            />
                          </Box>
                        </Box>
                      </>
                    )}
                  </FormDataConsumer>
                </SimpleFormIterator>
              </ArrayInput>
              <Typography variant="subtitle1">Networks</Typography>
              <ArrayInput
                source={getSource?.('networks') || ''}
                label={false}
                sx={{ mb: 3 }}
                fullWidth
              >
                <SimpleFormIterator
                  getItemLabel={(index) => `#${index + 1}`}
                  fullWidth
                  addButton={
                    <Button>
                      <AddIcon />
                      &nbsp;Add Network
                    </Button>
                  }
                >
                  <FormDataConsumer>
                    {({ formData, getSource }) => (
                      <>
                        <Box
                          display={{ xs: 'block', sm: 'flex' }}
                          sx={{ width: '100%' }}
                        >
                          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <SelectInput
                              source={getSource?.('scope') || ''}
                              label="Scope"
                              choices={[
                                { id: 'local', name: 'Local' },
                                { id: 'global', name: 'Global' },
                              ]}
                              validate={required()}
                              fullWidth
                            />
                          </Box>
                          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <SelectInput
                              source={getSource?.('version') || ''}
                              label="Version"
                              choices={[
                                { id: 'ipv4', name: 'IPv4' },
                                { id: 'ipv6', name: 'IPv6' },
                              ]}
                              validate={required()}
                              fullWidth
                            />
                          </Box>
                          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <SelectInput
                              source={getSource?.('allocation') || ''}
                              label="Allocation"
                              choices={[
                                { id: 'manual', name: 'Manual (static)' },
                                { id: 'dhcp', name: 'DHCP (dynamic)' },
                                { id: 'bgp', name: 'BGP session' },
                              ]}
                              validate={required()}
                              fullWidth
                            />
                          </Box>
                        </Box>
                        {get(formData, getSource?.('allocation') || '') ===
                        'bgp' ? (
                          <>
                            <Box
                              display={{ xs: 'block', sm: 'flex' }}
                              sx={{ width: '100%' }}
                            >
                              <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                <TextInput
                                  source={getSource?.('localPeer') || ''}
                                  label="Local BGP peer"
                                  validate={required()}
                                  fullWidth
                                />
                              </Box>
                              <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                <TextInput
                                  source={getSource?.('remotePeer') || ''}
                                  label="Remote BGP peer"
                                  validate={required()}
                                  fullWidth
                                />
                              </Box>
                              <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                <TextInput
                                  source={getSource?.('localAS') || ''}
                                  label="Local AS number"
                                  validate={required()}
                                  fullWidth
                                />
                              </Box>
                              <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                <TextInput
                                  source={getSource?.('remoteAS') || ''}
                                  label="Remote AS number"
                                  validate={required()}
                                  fullWidth
                                />
                              </Box>
                            </Box>
                            <Box
                              display={{ xs: 'block', sm: 'flex' }}
                              sx={{ width: '100%' }}
                            >
                              <TextInput
                                source={getSource?.('network') || ''}
                                label="Network"
                                validate={required()}
                                fullWidth
                              />
                            </Box>
                          </>
                        ) : (
                          <Box
                            display={{ xs: 'block', sm: 'flex' }}
                            sx={{ width: '100%' }}
                          >
                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                              <TextInput
                                source={getSource?.('network') || ''}
                                label="Network"
                                validate={required()}
                                fullWidth
                              />
                            </Box>
                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                              <TextInput
                                source={getSource?.('address') || ''}
                                label="Address"
                                fullWidth
                              />
                            </Box>
                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                              <TextInput
                                source={getSource?.('dns1') || ''}
                                label="DNS server 1"
                                fullWidth
                              />
                            </Box>
                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                              <TextInput
                                source={getSource?.('dns2') || ''}
                                label="DNS server 2"
                                fullWidth
                              />
                            </Box>
                          </Box>
                        )}
                        <Box
                          display={{ xs: 'block', sm: 'flex' }}
                          sx={{ width: '100%' }}
                        >
                          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                              multiline
                              source={getSource?.('notes') || ''}
                              label="Notes"
                              fullWidth
                            />
                          </Box>
                        </Box>
                      </>
                    )}
                  </FormDataConsumer>
                </SimpleFormIterator>
              </ArrayInput>
            </>
          )}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  )
}
