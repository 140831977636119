import React from 'react'
import {
  useRecordContext,
  FormDataConsumer,
  RadioButtonGroupInput,
  required,
} from 'react-admin'
import { ClientContractForm } from '../ClientContractForm'
import { Message } from '../../atoms/Message'

/**
 * Form input fields for selecting or creating a client contract.
 * @returns {React.FC} Form input fields for selecting or creating a client contract.
 * @example
 * <ClientContractInput />
 */
export const ClientContractInput: React.FC = () => {
  const record = useRecordContext()
  const message =
    record.clientContracts?.length > 0
      ? 'There are existing client contracts'
      : 'No client contracts exist yet'

  return (
    <>
      <Message message={message} />
      <RadioButtonGroupInput
        label={'Choose an action to take'}
        source="clientContractAction"
        validate={required()}
        choices={[
          { id: 'create', name: 'Create new contract' },
          { id: 'replace', name: 'Replace (and delete) existing contracts' },
          { id: 'none', name: 'No changes (keep as-is)' },
        ]}
        fullWidth
      />
      <FormDataConsumer>
        {({ formData }) =>
          formData.clientContractAction &&
          formData.clientContractAction !== 'none' && (
            <ClientContractForm
              sourcePrefix="clientContract"
              reference="services"
            />
          )
        }
      </FormDataConsumer>
    </>
  )
}
