import React from 'react'
import { Box } from '@mui/material'
import { Button } from 'react-admin'
import { useFormState } from 'react-hook-form'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

export const NotesDialogToolbar: React.FC = () => {
  const { isDirty } = useFormState()

  return (
    <Box textAlign="right" marginBottom={1} marginX={2}>
      <Button
        size="small"
        label="Confirm"
        type="submit"
        variant="contained"
        color="info"
        disabled={!isDirty}
        startIcon={<CheckCircleIcon />}
      />
    </Box>
  )
}
