import { useRecordContext } from 'react-admin'
import get from 'lodash/get'

/**
 * Custom hook that calculates the file size and formats it with appropriate units.
 * @returns {Object} An object containing the calculated file size.
 * The object has a single property: contentSize.
 * The value of the property is a string containing the formatted file size.
 * If the contentSize property is not present in the record, the value is 'N/A'.
 */
export const useValue = () => {
  const byteUnits = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const record = useRecordContext()
  let value = get(record, 'contentSize')

  if (!value) {
    return { contentSize: 'N/A' }
  }

  let i = -1
  do {
    value /= 1024
    i++
  } while (value > 1024)

  const size = Math.max(value, 0.1).toFixed(1) + byteUnits[i]
  return { contentSize: size }
}
