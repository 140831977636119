import React from 'react'
import {
  List,
  ListProps,
  Datagrid,
  TextField,
  DateField,
  FunctionField,
  ShowButton,
} from 'react-admin'
import Chip from '@mui/material/Chip'
import { EllipsedTextField } from '../../atoms/EllipsedField'
import { TypeStateField } from '../../atoms/TypeStateField'
import { ReferenceChip } from '../../molecules/ReferenceChip'
import { ServicePortFilters } from '../../organisms/ServicePortFilters'

/**
 * Page to list service ports.
 * @param props
 * @returns {React.FC}
 * @example
 * import { ServicePortList } from './components/pages/ServicePort/List.component'
 * <ServicePortList />
 */
export const ServicePortList: React.FC<ListProps> = (props) => {
  return (
    <List
      empty={false}
      perPage={25}
      filters={<ServicePortFilters />}
      sort={{ field: 'createdAt', order: 'DESC' }}
      {...props}
    >
      <Datagrid rowClick="show">
        <EllipsedTextField source="name" />
        <TypeStateField source="state" fullWidth />
        <ReferenceChip
          referenceSource="serviceSiteId"
          source="Service"
          tooltipSource="siteNo"
          reference="serviceSites"
          emptyText="N/A"
          link="show"
          chipSource="name"
        />
        <TextField source="type" />
        <TextField source="speed" />
        <TextField source="label" />
        <FunctionField
          label={'Networks'}
          render={(record: any) =>
            record.networks.length
              ? record.networks.map((net: any) => (
                  <Chip variant="outlined" label={net.network} />
                ))
              : 'N/A'
          }
        />
        <DateField source="updatedAt" showTime />
        <ShowButton />
      </Datagrid>
    </List>
  )
}
