import React from 'react'
import { ISearchClientFilter } from './SearchClientFilter.types'
import {
  Filter,
  TextInput,
  ReferenceInput,
  AutocompleteArrayInput,
} from 'react-admin'

/**
 * Filter using search term and/or client
 * @roperty {React.FC<ISearchClientFilter>} SearchClientFilter
 * @property {string} SearchClientFilter.props.term - Search term
 * @property {string} SearchClientFilter.props.clientId - Client ID
 * @property {React.FC<ISearchClientFilter>} SearchClientFilter - Filter using search term and/or client
 * @returns {React.FC<ISearchClientFilter>} Filter using search term and/or client
 */
export const SearchClientFilter: React.FC<ISearchClientFilter> = (props) => {
  return (
    <Filter {...props}>
      <TextInput label="Search" source="term" alwaysOn />
      <ReferenceInput source="clientId" reference="clients/list" alwaysOn>
        <AutocompleteArrayInput
          label={'Client'}
          optionText="name"
          alwaysOn
          sx={{ minWidth: 250 }}
        />
      </ReferenceInput>
    </Filter>
  )
}
