import React from 'react'
import {
  Create,
  CreateProps,
  FormTab,
  NumberInput,
  TabbedForm,
  TextInput,
  required,
} from 'react-admin'
import { MetaListInput } from '../../organisms/MetaList'

/**
 * Form to create a monitor probe type.
 * @param props The Create component props
 * @see https://marmelab.com/react-admin/Create.html
 */
export const MonitorProbeTypeCreate: React.FC<CreateProps> = (props) => {
  return (
    <Create {...props}>
      <TabbedForm>
        <FormTab label="general">
          <TextInput source="name" validate={required()} fullWidth />
          <NumberInput source="checkInterval" fullWidth />
        </FormTab>
        <FormTab label="config">
          <MetaListInput source="config" />
          <small>ℹ️ This is the config for this probe type itself</small>
        </FormTab>
        <FormTab label="config schema">
          <MetaListInput source="configSchema" />
          <small>
            ℹ️ This is the default config for all probes of this type
          </small>
        </FormTab>
      </TabbedForm>
    </Create>
  )
}
