import React from 'react'
import { Create, CreateProps, SimpleForm } from 'react-admin'
import { VendorContractForm } from '../../organisms/VendorContractForm'
import { PageTitle } from '../../atoms/PageTitle'

/**
 * Page to create a Vendor Contract
 * @param props
 * @returns {JSX.Element}
 * @example
 * import { VendorContractCreate } from './VendorContractCreate'
 * <VendorContractCreate {...props} />
 */
export const VendorContractCreate: React.FC<CreateProps> = (props) => {
  return (
    <Create {...props} title={<PageTitle prefix="Vendor Contract" />}>
      <SimpleForm>
        <VendorContractForm />
      </SimpleForm>
    </Create>
  )
}
