import React from 'react'
import {
  List,
  ListProps,
  TextField,
  DateField,
  BooleanField,
  Datagrid,
} from 'react-admin'
import { ReferenceText } from '../../molecules/ReferenceText'
import { SearchFilter } from '../../atoms/SearchFilter'
import { TypeStateField } from '../../atoms/TypeStateField'

/**
 * Page to list Vendor Contacts
 * @property {ListProps} props
 * @returns {JSX.Element}
 * @example
 * return <VendorContactList {...props} />
 * @see https://marmelab.com/react-admin/List.html
 * @see https://marmelab.com/react-admin/Fields.html
 * @see https://marmelab.com/react-admin/List.html#the-filter-button
 */
export const VendorContactList: React.FC<ListProps> = (props) => {
  return (
    <List
      empty={false}
      perPage={25}
      filters={<SearchFilter />}
      sort={{ field: 'createdAt', order: 'DESC' }}
      {...props}
    >
      <Datagrid rowClick="show">
        <ReferenceText
          source={'vendorId'}
          label={'Vendor'}
          reference="vendors"
          emptyText={'N/A'}
          link="show"
        />
        <TextField source="name" fullWidth />
        <ReferenceText
          source={'countryCode'}
          label={'Country'}
          reference="countries/list"
          emptyText={'N/A'}
          link={false}
        />
        <TypeStateField source="role" fullWidth />
        <BooleanField source="isActive" fullWidth />
        <DateField source="createdAt" showTime />
        <DateField source="updatedAt" showTime />
      </Datagrid>
    </List>
  )
}
