import React from 'react'
import {
  IconMenu,
  MenuItemList,
  MenuItemNode,
} from '@react-admin/ra-navigation'
import { CardContent } from '@mui/material'
import ProductsIcon from '@mui/icons-material/ShoppingCartOutlined'

/**
 * This is a custom menu item that will be added to the main menu.
 * It will display a list of menu items when clicked.
 * @returns {JSX.Element}
 */
export const CatalogMenuItem: React.FC = () => {
  return (
    <IconMenu.Item name="catalog" icon={<ProductsIcon />} label="Catalog">
      <CardContent>
        <MenuItemList>
          <MenuItemNode name="products" to="/products" label="Products" />
          <MenuItemNode
            name="priceBooks"
            to={'/priceBooks'}
            label="Price books"
          />
          <MenuItemNode
            name="marginBooks"
            to={'/marginBooks'}
            label="Margin books"
          />
          <MenuItemNode
            name="marginRules"
            to={'/marginRules'}
            label="Margin rules"
          />
          <MenuItemNode
            name="accessTechnologies"
            to={'/accessTechnologies'}
            label="Access technologies"
          />
          <MenuItemNode
            name="serviceTypes"
            to={'/serviceTypes'}
            label="Service types"
          />
          <MenuItemNode
            name="serviceCategories"
            to={'/serviceCategories'}
            label="Service categories"
          />
        </MenuItemList>
      </CardContent>
    </IconMenu.Item>
  )
}
