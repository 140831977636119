import React from 'react'
import Typography from '@mui/material/Typography'
import { ITextPreSuffixField } from './TextPreSuffixField.types'
import { useValue } from './TextPreSuffixField.hooks'

export const TextPreSuffixField: React.FC<ITextPreSuffixField> = (props) => {
  return (
    <Typography
      {...props}
      component="span"
      variant="body2"
      className={props.className}
    >
      {useValue(props)}
    </Typography>
  )
}
