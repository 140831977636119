import React from 'react'
import {
  List,
  ListProps,
  Datagrid,
  TextField,
  BooleanField,
  DateField,
} from 'react-admin'
import { SearchActiveFilters } from '../../molecules/SearchActiveFilters/SearchActiveFilters.component'
import { ReferenceChip } from '../../molecules/ReferenceChip'

/**
 * Page to list end customers
 * @property {ListProps} props
 * @example
 * <EndCustomerList />
 * @returns {React.FC<ListProps>}
 * @see https://marmelab.com/react-admin/List.html
 */
export const EndCustomerList: React.FC<ListProps> = (props) => {
  return (
    <List
      {...props}
      empty={false}
      perPage={25}
      filters={<SearchActiveFilters />}
      sort={{ field: 'createdAt', order: 'DESC' }}
    >
      <Datagrid rowClick="show">
        <TextField source="name" fullWidth />
        <ReferenceChip
          referenceSource="clientId"
          tooltipSource="clientNo"
          source="Client"
          chipSource="name"
          reference="clients"
          emptyText="N/A"
          link="show"
        />
        <BooleanField source="isActive" fullWidth />
        <DateField source="updatedAt" showTime />
      </Datagrid>
    </List>
  )
}
