import React from 'react'
import { LabelledDatagrid } from '../../../components/atoms/LabelledDatagrid'
import {
  TextField,
  DateField,
  ChipField,
  FunctionField,
  RaRecord,
} from 'react-admin'
import { TypeStateField } from '../../atoms/TypeStateField'
import { ReferenceChip } from '../../molecules/ReferenceChip'
import { EllipsedTextField } from '../../atoms/EllipsedField'

/**
 * Pending Request row for large screens.
 * @example
 * return (
 * <PendingRequestsFull>
 *  <TextField source="id" />
 * </PendingRequestsFull>
 * )
 * @see {@link https://marmelab.com/react-admin/List.html#the-datagrid-component|Datagrid}
 * @see {@link https://marmelab.com/react-admin/Theming.html|Theming}
 * @see {@link https://marmelab.com/react-admin/Theming.html#using-a-custom-theme|Using a Custom Theme}
 */
export const PendingRequestsFull: React.FC = () => {
  return (
    <LabelledDatagrid
      rowClick="show"
      resource="quote-requests"
      optimized
      bulkActionButtons={false}
    >
      <TextField
        source="requestNo"
        sortable={false}
        emptyText={'N/A'}
        sx={{ display: 'block', maxWidth: 100 }}
      />
      <TypeStateField source="state" fullWidth />
      <EllipsedTextField
        source="project.title"
        label="Project"
        emptyText="N/A"
      />
      <ReferenceChip
        referenceSource="clientId"
        tooltipSource="clientNo"
        source="Client"
        reference="clients"
        emptyText={'N/A'}
        link="show"
        chipSource="name"
      />
      <FunctionField
        label={'Services'}
        render={(record: RaRecord) => (
          <ChipField
            size="small"
            record={{ value: record.services ? record.services.length : 0 }}
            source="value"
          />
        )}
      />
      <DateField source="createdAt" showTime />
    </LabelledDatagrid>
  )
}
