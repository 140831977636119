import React from 'react'
import { Edit, EditProps, TabbedForm, FormTab, TextInput } from 'react-admin'
import { ReferenceManyFormTab } from '../../atoms/FormTab'
import { MetaListInput } from '../../organisms/MetaList'
import { SiteInput } from '../../organisms/SiteInput'
import { DocumentRow } from '../../organisms/DocumentRow'
import { DocumentModalInput } from '../../organisms/DocumentModalInput'

/**
 * Page to edit a service site.
 * @property {EditProps} props
 * @returns {JSX.Element}
 * @example
 * import { ServiceSiteEdit } from './ServiceSiteEdit'
 * <ServiceSiteEdit />
 * @see https://marmelab.com/react-admin/CreateEdit.html
 */
export const ServiceSiteEdit: React.FC<EditProps> = (props) => {
  return (
    <Edit {...props} mutationMode="undoable">
      <TabbedForm>
        <FormTab label="general">
          <SiteInput />
        </FormTab>
        <ReferenceManyFormTab
          reference="documents"
          target="relatedId"
          label="documents"
        >
          <DocumentRow />
          <DocumentModalInput reference="serviceSites" />
        </ReferenceManyFormTab>
        <FormTab label="notes">
          <TextInput multiline source="notes" defaultValue={''} fullWidth />
        </FormTab>
        <FormTab label="meta">
          <MetaListInput source="meta" />
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}
