import React, { useEffect } from 'react'
import { Grid, Typography } from '@mui/material'
import {
  Button,
  SimpleForm,
  required,
  useCreate,
  useRecordContext,
} from 'react-admin'
import { useFormState } from 'react-hook-form'
import CheckCircleIcon from '@mui/icons-material/CheckCircleOutlined'
import SaveIcon from '@mui/icons-material/SaveOutlined'
import { ProcurementFormToolbarProps } from './ProcurementFormToolbar.types'
import { MarkdownInput } from '@react-admin/ra-markdown'
import { NotesDialogToolbar } from '../../atoms/NotesDialogToolbar'
import { CreateInDialogButton } from '@react-admin/ra-form-layout'

export const ProcurementFormToolbar: React.FC<ProcurementFormToolbarProps> = (
  props
) => {
  const { isDirty } = useFormState()
  const record = useRecordContext()
  const [create] = useCreate()

  const submitForm = async (data: object) => {
    await create('notes', { data })
    props.onConfirm()
  }

  useEffect(() => {
    props.setIsDirty(isDirty)
  }, [isDirty, props])

  record.offer = record.vendorOffers?.[0]

  const confirmDisabled =
    !record.requestService?.isReviewConfirmed ||
    record?.isOfferConfirmed ||
    isDirty ||
    !record.vendorOffers?.length
  const saveDisabled =
    !record.requestService?.isReviewConfirmed ||
    record?.isOfferConfirmed ||
    (record.vendorOffers?.length && !isDirty)

  return !record.isOfferConfirmed ? (
    <Grid container spacing={1} justifyContent="flex-end">
      <Grid item>
        <Button
          size="small"
          label="Save"
          type="submit"
          variant="outlined"
          color="info"
          disabled={saveDisabled}
          startIcon={<SaveIcon />}
        />
      </Grid>
      <Grid item>
        <CreateInDialogButton
          title={<Typography variant="h6">Add Note</Typography>}
          ButtonProps={{
            size: 'small',
            type: 'button',
            disabled: confirmDisabled,
            variant: 'contained',
            color: 'info',
          }}
          label="Confirm"
          icon={<CheckCircleIcon />}
          fullWidth
          maxWidth="md"
        >
          <SimpleForm
            defaultValues={{
              isInternal: true,
              relatedName: 'RequestProduct',
              relatedId: record?.id,
              clientId: record.request?.clientId,
            }}
            toolbar={<NotesDialogToolbar />}
            sx={{ paddingY: 0 }}
            onSubmit={submitForm}
          >
            <MarkdownInput
              height="200px"
              fullWidth
              label={false}
              source="content"
              validate={required()}
              placeholder={
                '## Before confirming please provide the following info \n\n- Source (e.g. email) \n- Availability (e.g. subject to site survey) \n- What happened? (e.g. catalog data not up to date)'
              }
            />
          </SimpleForm>
        </CreateInDialogButton>
      </Grid>
    </Grid>
  ) : null
}
