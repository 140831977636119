import React from 'react'
import { Edit, EditProps, SimpleForm, TextInput, required } from 'react-admin'
import { PageTitle } from '../../atoms/PageTitle'

/**
 * Form to edit a monitor notification type.
 * @param props The Edit component props
 * @see https://marmelab.com/react-admin/Edit.html
 */
export const MonitorNotificationTypeEdit: React.FC<EditProps> = (props) => {
  return (
    <Edit
      {...props}
      title={<PageTitle prefix="Monitor Notification Type" />}
      mutationMode="undoable"
    >
      <SimpleForm>
        <TextInput disabled label="Id" source="id" fullWidth />
        <TextInput source="name" validate={required()} fullWidth />
        <TextInput source="description" fullWidth />
      </SimpleForm>
    </Edit>
  )
}
