import React from 'react'
import {
  useGetIdentity,
  SimpleForm,
  TextInput,
  Button,
  useNotify,
  useRefresh,
  useDataProvider,
  useRecordContext,
} from 'react-admin'
import { Message } from '../../atoms/Message'
import { FieldValues } from 'react-hook-form'

/**
 * Add a comment to a ticket.
 * @property {React.FC} AddTicketComment - The component for adding a comment to a ticket.
 * @returns {JSX.Element} The rendered component.
 * @example
 * return (
 * <AddTicketComment />
 * )
 * @see {@link https://marmelab.com/react-admin/CreateEdit.html|CreateEdit}
 */
export const AddTicketComment: React.FC = () => {
  const record = useRecordContext()
  const notify = useNotify()
  const refresh = useRefresh()
  const dataProvider = useDataProvider()
  const { identity } = useGetIdentity()
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  const addComment = async (values: FieldValues) => {
    setIsSubmitting(true)
    try {
      await dataProvider.customRequest('tickets', `${record.id}/comments`, {
        method: 'POST',
        data: values,
      })
      setIsSubmitting(false)
      notify('Comment has been added', { type: 'info' })
      setTimeout(refresh, 250)
    } catch (error: any) {
      setIsSubmitting(false)
      notify(error.message || error, { type: 'warning' })
    }
  }

  if (!identity || !identity.jiraId) {
    return (
      <Message
        message="Commenting from Manager is disabled as your user account is not linked
        with a JIRA account."
      />
    )
  }

  return (
    <SimpleForm onSubmit={addComment} toolbar={false}>
      <TextInput
        multiline
        variant="outlined"
        label="Comment content"
        source="content"
        fullWidth
      />
      <Button
        variant="outlined"
        type="submit"
        disabled={isSubmitting}
        label="Add comment"
      />
    </SimpleForm>
  )
}
