import { Layout as RALayout, LayoutProps } from 'react-admin'
import {
  AppLocationContext,
  Breadcrumb,
  ResourceBreadcrumbItems,
} from '@react-admin/ra-navigation'
import { useStyles } from './Layout.styles'
import { Dashboard } from '../../components/pages/Dashboard'
import { AppBar } from '../../components/organisms/AppBar'
import { AppMenu } from '../../components/organisms/AppMenu'

/**
 * Override's the default ReactAdmin Layout component to use our custom AppBar and AppMenu components.
 * @param children
 * @returns {JSX.Element}
 * @see https://marmelab.com/react-admin/Theming.html#using-a-custom-layout
 * @see https://marmelab.com/react-admin/Theming.html#using-a-custom-appbar
 * @see https://marmelab.com/react-admin/Theming.html#using-a-custom-menu
 * @see https://marmelab.com/react-admin/Theming.html#using-a-custom-dashboard
 */
export const Layout: React.FC<LayoutProps> = ({ children, ...rest }) => {
  const styles = useStyles()

  return (
    <AppLocationContext>
      <RALayout
        {...rest}
        dashboard={Dashboard}
        appBar={AppBar}
        menu={AppMenu}
        sx={styles}
      >
        <Breadcrumb hasDashboard={false}>
          <ResourceBreadcrumbItems />
        </Breadcrumb>
        {children}
      </RALayout>
    </AppLocationContext>
  )
}
