import React from 'react'
import {
  Show,
  ShowProps,
  Tab,
  TabbedShowLayout,
  TextField,
  DateField,
  BooleanField,
  Datagrid,
} from 'react-admin'
import { ShowButton } from 'ra-ui-materialui'
import { PageTitle } from '../../atoms/PageTitle'
import { ReferenceChip } from '../../molecules/ReferenceChip'
import { LabelledDatagrid } from '../../atoms/LabelledDatagrid'
import { ReferenceText } from '../../molecules/ReferenceText'
import { TextPreSuffixField } from '../../atoms/TextPreSuffixField'
import { ReferenceManyTab } from '../../atoms/FormTab'
import { TypeStateField } from '../../atoms/TypeStateField'
import { NotesDisplay } from '../../templates/NotesDisplay'
import { MetaListDisplay } from '../../organisms/MetaList'
import { MutationsList } from '../../organisms/MutationsList'

/**
 * Page to show a service order.
 * @param {ShowProps} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 * @example
 * return (
 * <ServiceOrderShow {...props} />
 * )
 * @see {@link https://marmelab.com/react-admin/Show.html|Show}
 */
export const ServiceOrderShow: React.FC<ShowProps> = (props) => {
  return (
    <Show {...props} title={<PageTitle prefix="Service order" />}>
      <TabbedShowLayout {...props}>
        <Tab label="general" key="general">
          <TextField source="orderNo" fullWidth />
          <TypeStateField source="state" />
          <ReferenceChip
            referenceSource="clientId"
            tooltipSource="clientNo"
            source="name"
            label={'Client'}
            reference="clients"
            emptyText="N/A"
            link="show"
            chipSource="name"
          />
          <ReferenceChip
            referenceSource="projectId"
            tooltipSource="projectNo"
            source="title"
            label={'Project'}
            reference="projects"
            emptyText="N/A"
            link="show"
            chipSource="name"
          />
          <TextField source="reference" fullWidth />
          <TextField source="description" fullWidth />
          <BooleanField source="isSigned" fullWidth />
          <DateField source="updatedAt" showTime />
          <DateField source="createdAt" showTime />
        </Tab>
        <ReferenceManyTab
          reference="clientContracts"
          target="orderIds"
          label="contracts"
        >
          <LabelledDatagrid bulkActionButtons={false}>
            <TextField source="contractNo" />
            <TypeStateField source="state" />
            <ReferenceText
              label={'Service'}
              reference="services/list"
              source="serviceId"
              emptyText="N/A"
              link="show"
            />
            <TextField source="description" />
            <DateField source="periodStart" />
            <DateField source="periodEnd" emptyText="N/A" />
            <TextPreSuffixField
              source="contractPeriod"
              suffix=" months"
              emptyText="N/A"
            />
            <ShowButton />
          </LabelledDatagrid>
        </ReferenceManyTab>
        <ReferenceManyTab
          reference="documents"
          target="relatedId"
          label="documents"
        >
          <Datagrid
            sx={{
              width: '100%',
              '& .column-title': {
                maxWidth: '20em',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              },
            }}
          >
            <TextField source="documentNo" fullWidth />
            <TextField source="title" fullWidth />
            <DateField source="createdAt" showTime />
            <ShowButton />
          </Datagrid>
        </ReferenceManyTab>
        <Tab label={'notes'}>
          <NotesDisplay />
        </Tab>
        <Tab label={'meta'} key="meta">
          <MetaListDisplay source="meta" />
        </Tab>
        <Tab label={'history'} key="history">
          <MutationsList showActor type="history" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
