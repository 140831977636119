import React from 'react'
import { Create, CreateProps, FormDataConsumer, SimpleForm } from 'react-admin'
import { ReferenceAutocomplete } from '../../molecules/ReferenceAutocomplete'

/**
 * Form to create a monitor notification contact.
 * @param props The Create component props
 * @see https://marmelab.com/react-admin/Create.html
 */
export const MonitorNotificationContactCreate: React.FC<CreateProps> = (
  props
) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <ReferenceAutocomplete
          label="Notification Type"
          source="monitorNotificationTypeId"
          reference="monitorNotificationTypes/list"
          required
        />
        <ReferenceAutocomplete
          label="Client"
          source="clientId"
          reference="clients/list"
          required
        />
        <FormDataConsumer>
          {({ formData, getSource, scopedFormData, ...rest }) =>
            formData.clientId ? (
              <ReferenceAutocomplete
                label="Client Contact"
                source="clientContactId"
                reference="clientContacts/list"
                required
                filter={{ clientId: formData.clientId }}
                disableSyncWithLocation
              />
            ) : null
          }
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  )
}
