/**
 * Mutations list styles.
 * @returns {React.CSSProperties} The styles.
 */
export const useStyles = () => {
  return {
    '& .MuiToolbar-gutters': { display: 'none' },
    '& .MuiTablePagination-toolbar': { display: 'flex' },
  }
}
