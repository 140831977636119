import React from 'react'
import {
  Create,
  CreateProps,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin'

/**
 * Create a new service category record.
 * @property {CreateProps} props
 * @returns {React.FC<CreateProps>}
 * @example
 * import {ServiceCategoryCreate} from './ServiceCategory'
 * return <ServiceCategoryCreate {...props} />
 * @see https://marmelab.com/react-admin/Create.html
 * @see https://marmelab.com/react-admin/Fields.html
 */
export const ServiceCategoryCreate: React.FC<CreateProps> = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="key" validate={required()} fullWidth />
        <TextInput source="name" validate={required()} fullWidth />
        <TextInput source="description" fullWidth />
      </SimpleForm>
    </Create>
  )
}
