import { MarkdownField } from '@react-admin/ra-markdown'
import {
  Datagrid,
  TextField,
  DateField,
  useRecordContext,
  List,
  FunctionField,
  RaRecord,
  BooleanField,
  ReferenceField,
} from 'react-admin'
import { EllipsedTextField } from '../../atoms/EllipsedField'
import { startCase } from 'lodash'

export const ProvisioningUpdatesList: React.FC = () => {
  const record = useRecordContext()

  return (
    <List
      actions={false}
      resource={`services/${record.id}/updates`}
      empty={false}
      sort={{ field: 'createdAt', order: 'DESC' }}
    >
      <Datagrid
        bulkActionButtons={false}
        expand={
          <MarkdownField source="notes" sx={{ wordBreak: 'break-word' }} />
        }
        rowClick={'expand'}
      >
        <DateField source="createdAt" label="Updated at" />
        <FunctionField
          source="provisioningStatus"
          render={(record: RaRecord) => startCase(record?.provisioningStatus)}
        />
        <BooleanField source="isOnHold" />
        <DateField source="estimatedDeliveryDate" />
        <FunctionField
          source="ragStatus"
          label="RAG status"
          sortable={false}
          render={(record: RaRecord) => {
            if (record.ragStatus === 'red') {
              return (
                <span
                  style={{
                    display: 'inline-block',
                    width: '20px',
                    height: '20px',
                    borderRadius: '50%',
                    backgroundColor: 'red',
                  }}
                ></span>
              )
            }
            if (record.ragStatus === 'amber') {
              return (
                <span
                  style={{
                    display: 'inline-block',
                    width: '20px',
                    height: '20px',
                    borderRadius: '50%',
                    backgroundColor: '#ffbf00',
                  }}
                ></span>
              )
            }
            return (
              <span
                style={{
                  display: 'inline-block',
                  width: '20px',
                  height: '20px',
                  borderRadius: '50%',
                  backgroundColor: 'green',
                }}
              ></span>
            )
          }}
        />
        <EllipsedTextField source="notes" sortable={false} />
        <ReferenceField
          source="userId"
          label="Update by"
          reference="users/list"
        >
          <TextField source="name" />
        </ReferenceField>
      </Datagrid>
    </List>
  )
}
