import React from 'react'
import { IDualSelect } from './DualSelect.types'
import { ReferenceArrayInput } from 'react-admin'
import { DualListInput } from '@react-admin/ra-relationships'

/**
 * Duel select area. Select from left and place input right.
 * @property {string} optionText - The text of the option.
 * @returns The rendered component.
 * @see IDualSelect
 * @see https://marmelab.com/react-admin/Inputs.html#referencearrayinput
 * @see https://marmelab.com/react-admin/Inputs.html#duallistinput
 * @see https://mui.com/components/tooltips/#custom-child-element
 */
export const DualSelect: React.FC<IDualSelect> = (props) => {
  return (
    <ReferenceArrayInput {...props}>
      <DualListInput optionText="name" />
    </ReferenceArrayInput>
  )
}
