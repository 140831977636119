import React from 'react'
import {
  ReferenceManyField,
  TextField,
  DateField,
  ShowButton,
  Pagination,
} from 'react-admin'
import { LabelledDatagrid } from '../../atoms/LabelledDatagrid'
import { ReferenceChip } from '../../molecules/ReferenceChip'
import { TextPreSuffixField } from '../../atoms/TextPreSuffixField'
import { TypeStateField } from '../../atoms/TypeStateField'

import { IVendorContractsList } from './VendorContractsList.types'

/**
 * List of vendor contracts
 * @returns {React.FC}
 * @example
 * import { VendorContractsList } from './VendorContractsList'
 * <VendorContractsList />
 * @see https://marmelab.com/react-admin/Fields.html
 */
export const VendorContractsList: React.FC<IVendorContractsList> = (props) => {
  return (
    <ReferenceManyField
      reference="vendorContracts"
      target={props.target}
      label="vendor contracts"
      pagination={<Pagination />}
      sort={{ field: 'createdAt', order: 'DESC' }}
    >
      <LabelledDatagrid bulkActionButtons={false}>
        <TextField source="contractNo" />
        <TypeStateField source="state" />
        <ReferenceChip
          referenceSource="vendorId"
          tooltipSource="vendorNo"
          source="Vendor"
          reference="vendors"
          emptyText="N/A"
          link="show"
          chipSource="name"
        />
        <ReferenceChip
          referenceSource="vendorOfferId"
          tooltipSource="description"
          source="offerNo"
          reference="vendorOffers"
          emptyText="N/A"
          link="show"
          chipSource="offerNo"
        />
        <DateField source="periodStart" emptyText="N/A" />
        <DateField source="periodEnd" emptyText="N/A" />
        <TextPreSuffixField
          source="contractPeriod"
          label="Contract"
          suffix=" months"
          emptyText="N/A"
        />
        <ShowButton />
      </LabelledDatagrid>
    </ReferenceManyField>
  )
}
