import React from 'react'
import { IReferenceAutocomplete } from './ReferenceAutocomplete.types'
import { ReferenceInput, AutocompleteInput, required } from 'react-admin'

/**
 * Autocomplete input for a reference.
 * @property {IReferenceAutocomplete} props
 * @returns {React.FC<IReferenceAutocomplete>}
 * @example
 * import { ReferenceAutocomplete } from './ReferenceAutocomplete'
 * <ReferenceAutocomplete {...props}>
 * @see https://marmelab.com/react-admin/Inputs.html
 * @see https://marmelab.com/react-admin/ReferenceInput.html
 * @see https://marmelab.com/react-admin/AutocompleteInput.html
 */
export const ReferenceAutocomplete: React.FC<IReferenceAutocomplete> = (
  props
) => {
  const validate = (props: IReferenceAutocomplete) => {
    if (props.validate) return props.validate
    if (props.required) return required()
    return undefined
  }

  return (
    <ReferenceInput {...props}>
      <AutocompleteInput
        optionText="name"
        validate={validate(props)}
        label={props.label}
        fullWidth
        emptyText={props.emptyText}
        defaultValue={props.defaultValue}
        disabled={props.disabled}
        sx={props.sx}
      />
    </ReferenceInput>
  )
}
