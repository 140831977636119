import { RaRecord, ReferenceInput, SelectInput } from 'react-admin'
import { useProductFilters } from '../../particles/filters'

export const useCustomProductFilters = (record: RaRecord) => {
  const productFilters = useProductFilters()

  productFilters.push(
    <ReferenceInput
      source="requestServiceId"
      reference="requestServices/list"
      filter={{ requestId: record.id }}
      alwaysOn={true}
    >
      <SelectInput label={'Service'} optionText="name" sx={{ minWidth: 250 }} />
    </ReferenceInput>
  )

  productFilters.push(
    <ReferenceInput
      source="requestSiteId"
      reference="requestSites/list"
      filter={{ requestId: record.id }}
      alwaysOn={true}
    >
      <SelectInput label={'Site'} optionText="name" sx={{ minWidth: 250 }} />
    </ReferenceInput>
  )

  return productFilters
}
