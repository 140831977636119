import React from 'react'
import {
  Create,
  CreateProps,
  TextInput,
  required,
  TabbedForm,
  FormTab,
  SelectInput,
  FormDataConsumer,
  regex,
} from 'react-admin'
import { ServiceSpecificationInput } from '../../templates/ServiceSpecificationInput'
import { ReferenceAutocomplete } from '../../molecules/ReferenceAutocomplete'
import { MarkdownInput } from '@react-admin/ra-markdown'
import { DateInput } from '../../atoms/DateInput'

export const ServiceCreate: React.FC<CreateProps> = (props) => {
  return (
    <Create {...props}>
      <TabbedForm>
        <FormTab label="general">
          <ReferenceAutocomplete
            required
            label="Client"
            source="clientId"
            reference="clients/list"
          />
          <FormDataConsumer>
            {({ formData }) =>
              formData.clientId && (
                <ReferenceAutocomplete
                  label="End Customer"
                  source="endCustomerId"
                  reference="endCustomers/list"
                  filter={{ clientId: formData.clientId }}
                  disableSyncWithLocation
                />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData }) =>
              formData.clientId && (
                <ReferenceAutocomplete
                  label="Project"
                  source="projectId"
                  reference="projects/list"
                  filter={{ clientId: formData.clientId }}
                  disableSyncWithLocation
                />
              )
            }
          </FormDataConsumer>
          <TextInput
            source="serviceNo"
            label="Service no. (leave empty for auto generation)"
            validate={[regex(/^SID[0-9]{5,12}$/)]}
            fullWidth
          />
          <ReferenceAutocomplete
            label="Vendor"
            source="vendorId"
            reference="vendors/list"
            fullWidth
          />
          <TextInput
            source="vendorServiceNo"
            label="Vendor service no."
            fullWidth
          />
          <TextInput source="name" validate={required()} fullWidth />
          <TextInput source="reference" fullWidth />
          <FormDataConsumer>
            {({ formData }) => (
              <>
                <ReferenceAutocomplete
                  required
                  label="Service category"
                  source="serviceCategoryId"
                  reference="serviceCategories"
                />
                <ReferenceAutocomplete
                  label={'Service type'}
                  reference="serviceTypes"
                  source="serviceTypeId"
                  filter={{ serviceCategoryId: formData?.serviceCategoryId }}
                />
                <ReferenceAutocomplete
                  label={'Access technology'}
                  reference="accessTechnologies"
                  source="accessTechnologyId"
                />
              </>
            )}
          </FormDataConsumer>
          <SelectInput
            source="state"
            defaultValue="pending"
            validate={required()}
            choices={[
              { id: 'pending', name: 'Pending' },
              { id: 'provisioning', name: 'Provisioning' },
              { id: 'cancelled', name: 'Cancelled' },
              { id: 'active', name: 'Active' },
              { id: 'terminated', name: 'Terminated' },
            ]}
            fullWidth
          />
          <FormDataConsumer>
            {({ formData }) => (
              <>
                <SelectInput
                  source="diversityRole"
                  defaultValue="none"
                  validate={required()}
                  choices={[
                    { id: 'none', name: 'No diversity' },
                    { id: 'primary', name: 'Primary' },
                    { id: 'secondary', name: 'Secondary' },
                    { id: 'tertiary', name: 'Tertiary' },
                  ]}
                  fullWidth
                />
                {formData.diversityRole !== 'primary' &&
                  formData.diversityRole !== 'none' && (
                    <ReferenceAutocomplete
                      label={'Diverse to primary'}
                      reference="services/list"
                      source="diverseToId"
                      filter={{
                        id: { not: formData?.id },
                        clientId: formData?.clientId,
                        diversityRole: 'primary',
                      }}
                    />
                  )}
                <ReferenceAutocomplete
                  label={'Related to'}
                  reference="services/list"
                  source="relatedToId"
                  filter={{
                    id: { not: formData?.id },
                    clientId: formData?.clientId,
                  }}
                />
              </>
            )}
          </FormDataConsumer>
          <DateInput source="deliveryWishDate" fullWidth />
          <DateInput source="provisionedAt" fullWidth />
          <DateInput source="deliveredAt" fullWidth />
          <DateInput source="cancelledAt" fullWidth />
          <DateInput source="decommissionedAt" fullWidth />
        </FormTab>
        <FormTab label="specifications">
          <ServiceSpecificationInput />
        </FormTab>
        <FormTab label={'notes'}>
          <TextInput
            source="notes"
            label="Internal notes"
            multiline
            fullWidth
          />
          <TextInput
            source="notesForClient"
            label="Notes for client"
            multiline
            fullWidth
          />
          <MarkdownInput source="deliveryNotes" />
        </FormTab>
      </TabbedForm>
    </Create>
  )
}
