import { OnboardingCreate } from './Create.component'
import { OnboardingEdit } from './Edit.component'
import { OnboardingList } from './List.component'
import { OnboardingShow } from './Show.component'

export default {
  list: OnboardingList,
  show: OnboardingShow,
  create: OnboardingCreate,
  edit: OnboardingEdit,
}
