import React from 'react'
import {
  BooleanInput,
  Create,
  CreateProps,
  NumberInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin'
import { ReferenceAutocomplete } from '../../molecules/ReferenceAutocomplete'

/**
 * Form to create a monitor alert rule.
 * @param props The Create component props
 * @see https://marmelab.com/react-admin/Create.html
 */
export const MonitorAlertRuleCreate: React.FC<CreateProps> = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <ReferenceAutocomplete
          label="Probe Type"
          source="monitorProbeTypeId"
          reference="monitorProbeTypes/list"
          required
        />
        <TextInput source="name" validate={required()} fullWidth />
        <TextInput source="state" validate={required()} fullWidth />
        <TextInput source="summary" validate={required()} fullWidth />
        <TextInput source="description" validate={required()} fullWidth />
        <SelectInput
          source="severity"
          validate={required()}
          choices={[
            { id: 'none', name: 'None' },
            { id: 'minor', name: 'Minor' },
            { id: 'major', name: 'Major' },
            { id: 'critical', name: 'Critical' },
          ]}
          fullWidth
        />
        <SelectInput
          source="dataSource"
          validate={required()}
          choices={[{ id: 'alertManager', name: 'Alert Manager' }]}
          fullWidth
        />
        <TextInput
          multiline
          source="expression"
          validate={required()}
          fullWidth
        />
        <NumberInput source="forDuration" validate={required()} fullWidth />
        <BooleanInput source="isCustom" fullWidth defaultValue={false} />
        <BooleanInput source="isMuted" fullWidth defaultValue={false} />
      </SimpleForm>
    </Create>
  )
}
