import { UserRoleCreate } from './Create.component'
import { UserRoleEdit } from './Edit.component'
import { UserRoleList } from './List.component'
import { UserRoleShow } from './Show.component'

export default {
  list: UserRoleList,
  show: UserRoleShow,
  edit: UserRoleEdit,
  create: UserRoleCreate,
}
