import { useRecordContext } from 'react-admin'
import { ISiteModalReferences, IReference } from './SiteModalInput.types'

/**
 * Returns an object with ids based on the provided reference.
 * If there is no record for the reference, returns an empty object.
 * @param reference - The reference key used to access the record data.
 * @returns An object with ids based on the reference or an empty object if no record is found.
 * @example
 * const record = { id: 1, clientId: 2 }
 * const { clientId } = useRecord('clients')
 * console.log(clientId) // { clientId: 1 }
 * const { projectIds } = useRecord('projects')
 * console.log(projectIds) // { projectIds: [1] }
 * const { serviceIds } = useRecord('services')
 * console.log(serviceIds) // { serviceIds: [1] }
 * const { unknownIds } = useRecord('unknown')
 * console.log(unknownIds) // {}
 * const { id } = useRecord('clients')
 * console.log(id) // {}
 * const { id } = useRecord('projects')
 * console.log(id) // {}
 * const { id } = useRecord('services')
 * console.log(id) // {}
 * const { id } = useRecord('unknown')
 * console.log(id) // {}
 * const { clientId, projectIds, serviceIds } = useRecord('clients')
 * console.log(clientId, projectIds, serviceIds) // { clientId: 1 }
 * const { clientId, projectIds, serviceIds } = useRecord('projects')
 * console.log(clientId, projectIds, serviceIds) // { projectIds: [1] }
 * const { clientId, projectIds, serviceIds } = useRecord('services')
 * console.log(clientId, projectIds, serviceIds) // { serviceIds: [1] }
 * const { clientId, projectIds, serviceIds } = useRecord('unknown')
 * console.log(clientId, projectIds, serviceIds) // {}
 * const { id, clientId, projectIds, serviceIds } = useRecord('clients')
 * console.log(id, clientId, projectIds, serviceIds) // { clientId: 1 }
 * const { id, clientId, projectIds, serviceIds } = useRecord('projects')
 * console.log(id, clientId, projectIds, serviceIds) // { projectIds: [1] }
 * const { id, clientId, projectIds, serviceIds } = useRecord('services')
 * console.log(id, clientId, projectIds, serviceIds) // { serviceIds: [1] }
 * const { id, clientId, projectIds, serviceIds } = useRecord('unknown')
 */
export const useRecord = (reference: ISiteModalReferences['reference']) => {
  const record = useRecordContext()
  let defaultRecord: IReference = {}

  if (record?.clientId) {
    defaultRecord.clientId = record.clientId
  }

  if (reference === 'projects') {
    defaultRecord.projectIds = [record?.id]
  }

  if (reference === 'clients') {
    defaultRecord.clientId = record?.id
  }

  if (reference === 'services') {
    defaultRecord.serviceIds = [record?.id]
  }

  return defaultRecord
}
