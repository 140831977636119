import React from 'react'
import { List, ListProps, Datagrid, TextField, DateField } from 'react-admin'
import { TypeStateField } from '../../atoms/TypeStateField'
import { ReferenceChip } from '../../molecules/ReferenceChip'
import { ReferenceText } from '../../molecules/ReferenceText'
import { PriceBookFilters } from '../../molecules/PriceBookFilters'

/**
 * Page to list price books
 * @param props
 * @prop {ListProps} props
 * @returns {JSX.Element}
 * @example
 * <PriceBookList {...props}/>
 * @see https://marmelab.com/react-admin/List.html
 * @see https://marmelab.com/react-admin/Fields.html
 */
export const PriceBookList: React.FC<ListProps> = (props) => {
  return (
    <List
      {...props}
      empty={false}
      sort={{ field: 'appliedAt', order: 'desc' }}
      perPage={25}
      filters={<PriceBookFilters />}
    >
      <Datagrid rowClick="show">
        <TextField source="name" fullWidth />
        <TypeStateField source="state" fullWidth />
        <ReferenceChip
          referenceSource="vendorId"
          tooltipSource=""
          source="vendorId"
          label="Vendor"
          reference="vendors"
          emptyText={'N/A'}
          chipSource="name"
          link={'show'}
        />
        <ReferenceText
          source="countryCode"
          reference="countries/list"
          emptyText={'N/A'}
          link={false}
        />
        <DateField source="createdAt" showTime />
        <DateField source="appliedAt" showTime />
      </Datagrid>
    </List>
  )
}
