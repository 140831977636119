import React from 'react'
import {
  List,
  DateField,
  Datagrid,
  useRecordContext,
  FunctionField,
  ArrayField,
  SingleFieldList,
  ChipField,
  ReferenceArrayField,
} from 'react-admin'
import { TypeStateField } from '../../atoms/TypeStateField'
import { EllipsedTextField } from '../../atoms/EllipsedField'
import Tooltip from '@mui/material/Tooltip'
import { Chip, LinearProgress } from '@mui/material'
import { RequestServiceFilters } from '../RequestServiceFilters'

export const RequestFlowServicesList: React.FC<{
  displayFilters?: boolean
  requestId?: string
}> = (props) => {
  const record = useRecordContext()

  const requestId = props.requestId ?? record.id

  return (
    <List
      title=" "
      {...props}
      sort={{ field: 'name', order: 'asc' }}
      filter={{ requestId }}
      actions={false}
      empty={false}
      resource="requestServices"
      storeKey={`requestServices.${requestId}`}
      filters={props.displayFilters ? <RequestServiceFilters /> : undefined}
      disableSyncWithLocation
    >
      <FunctionField
        render={(record: { sites?: { state: string }[] }) => {
          let progressPercentage = 0
          let confirmedSites = 0
          let totalSites = 0

          if (record.sites) {
            totalSites = record.sites.length

            confirmedSites = record.sites.filter(
              (site) => site.state === 'complete'
            ).length

            progressPercentage = (confirmedSites / totalSites) * 100
          }

          return (
            <Tooltip
              title={`${confirmedSites} of ${totalSites} sites completed`}
              followCursor
            >
              <LinearProgress
                value={progressPercentage}
                variant="determinate"
                color="info"
                sx={{
                  margin: 0,
                  width: '100%',
                  borderRadius: '4px 4px 0 0',
                }}
              />
            </Tooltip>
          )
        }}
      />
      <Datagrid
        rowClick={(id) => `/quote-requests/${props.requestId}/services/${id}`}
        optimized
        bulkActionButtons={false}
        expandSingle
      >
        <EllipsedTextField source="name" emptyText="N/A" />
        <TypeStateField source="state" fullWidth />
        <FunctionField
          sortable={false}
          source="sites"
          render={(record: { sites: [] }) =>
            record.sites?.map(
              (site: { address: string; country?: { name: string } }) => {
                const addressParts =
                  site.address?.split(',').map((p) => p.trim()) ?? []
                if (
                  site.country &&
                  addressParts[addressParts.length - 1].toLowerCase() !==
                    site.country.name.toLowerCase()
                )
                  addressParts[addressParts.length - 1] = site.country.name
                return <Chip label={addressParts.join(', ')} />
              }
            )
          }
        />
        <TypeStateField source="diversityRole" fullWidth emptyText="N/A" />
        <FunctionField
          label="Flow step"
          render={(record: {
            products: {
              isQuoteSent: boolean
              isOfferConfirmed: boolean
            }[]
            state: string
            isRequested: boolean
            isReviewConfirmed: boolean
          }) => {
            if (['pending-search', 'pending-results'].includes(record.state)) {
              return 'Request: Product Search'
            } else if (record.state === 'pending-request') {
              return 'Customer: Request Firm'
            } else {
              if (
                record.products?.some(
                  (requestProduct) => requestProduct.isQuoteSent
                )
              ) {
                return 'Customer: Order'
              }
              if (
                record.products?.some(
                  (requestProduct) => requestProduct.isOfferConfirmed
                )
              ) {
                return 'Sales: Confirm Margin'
              }
              if (record.isReviewConfirmed) {
                return 'Procurement: Firm Offer'
              }
            }
            return 'N/A'
          }}
        />
        <ReferenceArrayField
          source="assigneeIds"
          reference="users/list"
          label="Assignee(s)"
          sortable={false}
        >
          <ArrayField source="assignees" sortable={false}>
            <SingleFieldList linkType={false}>
              <ChipField source="name" />
            </SingleFieldList>
          </ArrayField>
        </ReferenceArrayField>
        <DateField source="updatedAt" showTime />
      </Datagrid>
    </List>
  )
}
