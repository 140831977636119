import React from 'react'
import {
  useRecordContext,
  FormDataConsumer,
  RadioButtonGroupInput,
  required,
} from 'react-admin'
import { VendorContractForm } from '../../organisms/VendorContractForm'
import { Message } from '../../atoms/Message'

/**
 * Form input fields for selecting a vendor contract.
 * @returns {React.FC}
 * @example
 * <VendorContractInput />
 */
export const VendorContractInput: React.FC = () => {
  const record = useRecordContext()
  const message =
    record.vendorContracts?.length > 0
      ? 'There are existing vendor contracts'
      : 'No vendor contracts exist yet'

  return (
    <>
      <Message message={message} />
      <RadioButtonGroupInput
        label={'Choose an action to take'}
        source="vendorContractAction"
        validate={required()}
        choices={[
          { id: 'create', name: 'Create new contract' },
          { id: 'replace', name: 'Replace (and delete) existing contracts' },
          { id: 'none', name: 'No changes (keep as-is)' },
        ]}
        fullWidth
      />
      <FormDataConsumer>
        {({ formData }) =>
          formData.vendorContractAction &&
          formData.vendorContractAction !== 'none' && (
            <VendorContractForm
              sourcePrefix="vendorContract"
              reference="services"
            />
          )
        }
      </FormDataConsumer>
    </>
  )
}
