import React from 'react'
import {
  NumberField,
  TextField,
  useRecordContext,
  List,
  DatagridConfigurable,
  SelectColumnsButton,
} from 'react-admin'
import { ReferenceText } from '../ReferenceText'
import { IRequestProductsList } from './RequestProductsList.types'
import { CurrencyField } from '../../atoms/CurrencyField'
import { ProductDetailsExpand } from '../../organisms/ProductDetailsExpand'
import { FilterExportToolbar } from '../FilterExportToolbar'
import { ReferenceChip } from '../ReferenceChip'
import { TypeStateField } from '../../atoms/TypeStateField'
import { useCustomProductFilters } from './RequestProductsList.hooks'

/**
 * List of sites for a request
 * @param {IRequestSitesList} props
 * @returns {React.FC<IRequestSitesList>}
 * @example
 * <RequestSitesList />
 */
export const RequestProductsList: React.FC<IRequestProductsList> = (props) => {
  const record = useRecordContext(props)
  const productFilters = useCustomProductFilters(record)
  return (
    <List
      title={' '}
      {...props}
      empty={props.empty ?? false}
      perPage={props.perPage ?? 50}
      filters={props.filters ?? productFilters}
      actions={
        <FilterExportToolbar>
          <SelectColumnsButton />
        </FilterExportToolbar>
      }
      resource={props.resource ?? 'requestProducts'}
      filter={props.filter ?? { requestId: record.id }}
      storeKey={props.storeKey ?? `requestProducts.${record.id}`}
      disableSyncWithLocation
    >
      <DatagridConfigurable
        rowClick="expand"
        expand={<ProductDetailsExpand />}
        bulkActionButtons={false}
        omit={[
          'name',
          'countryCode',
          'serviceCategoryId',
          'serviceTypeId',
          'accessTechnologyId',
          'requestSiteId',
          'specifications.bandwidthUp',
          'specifications.bandwidthDown',
          'contractTerms.contractPeriod',
          'contractTerms.leadTime',
          'contractTerms.meanTimeToRepair',
          'contractTerms.serviceUptime',
          'purchaseNrc',
          'purchaseMrc',
          'createdAt',
          'scores.relevance',
        ]}
      >
        <NumberField
          source="scores.position"
          label={'Position'}
          sortable={false}
        />
        <TextField source="code" sortable={false} />
        <TextField source="name" sortable={false} />
        <ReferenceChip
          referenceSource={'vendorId'}
          tooltipSource="name"
          source="vendorId"
          chipSource="name"
          label={'Vendor'}
          reference="vendors"
          emptyText={'N/A'}
          removeDoubleLabel
          link={'show'}
          sortable={false}
        />
        <TypeStateField source="source" sortable={false} />
        <ReferenceText
          source="countryCode"
          reference="countries/list"
          emptyText={'N/A'}
          link={false}
          sortable={false}
        />
        <ReferenceChip
          referenceSource={'requestServiceId'}
          tooltipSource="diversityRole"
          source="requestServiceId"
          chipSource="name"
          label={'Service'}
          reference="requestServices"
          emptyText={'N/A'}
          removeDoubleLabel
          link={false}
          sortable={false}
        />
        <ReferenceChip
          referenceSource={'requestSiteId'}
          tooltipSource="name"
          source="requestSiteId"
          chipSource="address"
          label={'Site'}
          reference="requestSites"
          emptyText={'N/A'}
          removeDoubleLabel
          link={false}
          sortable={false}
        />
        <ReferenceChip
          referenceSource={'serviceTypeId'}
          tooltipSource="name"
          source="serviceTypeId"
          chipSource="key"
          label={'Service Type'}
          reference="serviceTypes/list"
          emptyText={'N/A'}
          removeDoubleLabel
          link={false}
          sortable={false}
        />
        <ReferenceChip
          referenceSource={'serviceCategoryId'}
          tooltipSource="name"
          source="serviceCategoryId"
          chipSource="key"
          label={'Service Category'}
          reference="serviceCategories/list"
          emptyText={'N/A'}
          removeDoubleLabel
          link={false}
          sortable={false}
        />
        <ReferenceChip
          referenceSource={'accessTechnologyId'}
          tooltipSource="name"
          source="accessTechnologyId"
          chipSource="key"
          label={'Technology'}
          reference="accessTechnologies/list"
          emptyText={'N/A'}
          removeDoubleLabel
          link={false}
          sortable={false}
        />
        <NumberField
          source="specifications.bandwidthDown"
          label="Bandwidth down"
          sortable={false}
        />
        <NumberField
          source="specifications.bandwidthUp"
          label="Bandwidth up"
          sortable={false}
        />
        <NumberField
          source="contractTerms.contractPeriod"
          label="Contract period"
          sortable={false}
        />
        <NumberField
          source="contractTerms.meanTimeToRepair"
          label="MTTR"
          sortable={false}
        />
        <NumberField
          source="contractTerms.leadTime"
          label="Lead time"
          sortable={false}
        />
        <NumberField
          source="contractTerms.serviceUptime"
          label="Service uptime"
          sortable={false}
        />
        <CurrencyField
          currency={'EUR'}
          source="valueNrc"
          label={'Value NRC'}
          sortable={false}
        />
        <CurrencyField
          currency={'EUR'}
          source="valueMrc"
          label={'Value MRC'}
          sortable={false}
        />
        <CurrencyField
          currency={'purchaseCurrency'}
          source="purchaseNrc"
          label={'Purchase NRC'}
          sortable={false}
        />
        <CurrencyField
          currency={'purchaseCurrency'}
          source="purchaseMrc"
          label={'Purchase MRC'}
          sortable={false}
        />
      </DatagridConfigurable>
    </List>
  )
}
