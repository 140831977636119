import React from 'react'
import {
  Filter,
  TextInput,
  ReferenceInput,
  AutocompleteArrayInput,
} from 'react-admin'
import { IClientContractFilters } from './ClientContractFilters.types'

/**
 * Search, Client, and Service filters for the ClientContract list page.
 * @param {IClientContractFilters} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 * @example
 * return (
 * <ClientContractFilters />
 * )
 * @see {@link https://marmelab.com/react-admin/List.html#the-filter-buttonform-combination|Filter Button/Filter Form Combination}
 */
export const ClientContractFilters: React.FC<IClientContractFilters> = (
  props
) => {
  return (
    <Filter {...props}>
      <TextInput label="Search" source="term" alwaysOn />
      <ReferenceInput source="clientId" reference="clients/list" alwaysOn>
        <AutocompleteArrayInput label={'Client'} optionText="name" />
      </ReferenceInput>
      <ReferenceInput source="serviceId" reference="services/list" alwaysOn>
        <AutocompleteArrayInput label={'Service'} optionText="name" />
      </ReferenceInput>
    </Filter>
  )
}
