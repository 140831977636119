import React, { useEffect } from 'react'
import {
  ILabelledDatagrid,
  IConfigurableLabelledDatagrid,
} from './LabelledDatagrid.types'
import {
  Datagrid,
  DatagridBody,
  DatagridRow,
  DatagridRowProps,
  DatagridBodyProps,
  Identifier,
  DatagridConfigurable,
} from 'react-admin'
import { useRowLeftBorderStyle } from './LabelledDatagrid.hooks'

/**
 * A labeled row component.
 * Adds a coloured border to the left of a Datagrid row.
 * @component
 * @param {ILabelledDatagrid} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 * @example
 * return (
 * <LabelledDatagrid>
 *    <TextField source="id" />
 *   <TextField source="name" />
 * </LabelledDatagrid>
 * )
 * @see {@link https://marmelab.com/react-admin/List.html#the-datagrid-component|Datagrid}
 * @see {@link https://marmelab.com/react-admin/Theming.html|Theming}
 * @see {@link https://marmelab.com/react-admin/Theming.html#using-a-custom-theme|Using a Custom Theme}
 * @see {@link https://marmelab.com/react-admin/Theming.html#using-the-withstyles-hoc|Using the withStyles() HOC}
 * @see {@link https://material-ui.com/styles/api/#withstyles-options-higher-order-component|withStyles()}
 * @see {@link https://material-ui.com/customization/palette/#color|Color}
 */
export const LabelledDatagridRow: React.FC<DatagridRowProps> = (props) => {
  const [selected, setSelected] = React.useState(props.selected || false)
  const styles = useRowLeftBorderStyle(selected, props.record)

  useEffect(() => {
    setSelected(props.selected || false)
  }, [props.selected])

  const toggleSelected = (
    id: Identifier,
    event: React.TouchEvent<Element> | React.MouseEvent<Element, MouseEvent>
  ) => {
    if (props.onToggleItem) {
      props.onToggleItem(id, event)
    }
    setSelected(!selected)
  }

  return (
    <DatagridRow
      {...props}
      sx={styles}
      hover={true}
      onToggleItem={toggleSelected}
      selected={selected}
    >
      {props.children}
    </DatagridRow>
  )
}

/**
 * A labeled body component.
 * Wraps the LabaledDatagridRow component.
 * @component
 * @param {DatagridBodyProps} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 * @example
 * return (
 * <DatagridBody {...props}  row={<LabelledDatagridRow />} />
 * )
 * @see {@link https://marmelab.com/react-admin/List.html#the-datagrid-component|Datagrid}
 * @see {@link https://marmelab.com/react-admin/Theming.html|Theming}
 * @see {@link https://marmelab.com/react-admin/Theming.html#using-a-custom-theme|Using a Custom Theme}
 * @see {@link https://marmelab.com/react-admin/Theming.html#using-the-withstyles-hoc|Using the withStyles() HOC}
 * @see {@link https://material-ui.com/styles/api/#withstyles-options-higher-order-component|withStyles()}
 * @see {@link https://material-ui.com/customization/palette/#color|Color}
 */
export const LabelledDatagridBody: React.FC<DatagridBodyProps> = (props) => {
  return <DatagridBody {...props} row={<LabelledDatagridRow />} />
}

/**
 * A labeled Datagrid component.
 * Wraps the LabaledDatagridBody component.
 * @component
 * @param {ILabelledDatagrid} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 * @example
 * return (
 * <Datagrid {...props} body={<LabelledDatagridBody />} />
 * )
 * @see {@link https://marmelab.com/react-admin/List.html#the-datagrid-component|Datagrid}
 * @see {@link https://marmelab.com/react-admin/Theming.html|Theming}
 * @see {@link https://marmelab.com/react-admin/Theming.html#using-a-custom-theme|Using a Custom Theme}
 * @see {@link https://marmelab.com/react-admin/Theming.html#using-the-withstyles-hoc|Using the withStyles() HOC}
 * @see {@link https://material-ui.com/styles/api/#withstyles-options-higher-order-component|withStyles()}
 */
export const LabelledDatagrid: React.FC<ILabelledDatagrid> = (props) => {
  return <Datagrid {...props} body={<LabelledDatagridBody />} />
}

/**
 * A configurable labeled row component.
 * Wraps the LabaledDatagridRow component.
 *
 * @component
 * @param {IConfigurableLabelledDatagrid} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 * @example
 * return (
 *  <ConfigurableLabelledDatagrid>
 *     <TextField source="id" />
 *    <TextField source="name" />
 * </ConfigurableLabelledDatagrid>
 * )
 * @see {@link https://marmelab.com/react-admin/List.html#the-datagrid-component|Datagrid}
 * @see {@link https://marmelab.com/react-admin/Theming.html|Theming}
 * @see {@link https://marmelab.com/react-admin/Theming.html#using-a-custom-theme|Using a Custom Theme}
 * @see {@link https://marmelab.com/react-admin/Theming.html#using-the-withstyles-hoc|Using the withStyles() HOC}
 * @see {@link https://material-ui.com/styles/api/#withstyles-options-higher-order-component|withStyles()}
 * @see {@link https://material-ui.com/customization/palette/#color|Color}
 * @see {@link https://material-ui.com/customization/palette/#dark-mode|Dark Mode}
 */
export const ConfigurableLabelledDatagrid: React.FC<
  IConfigurableLabelledDatagrid
> = (props) => {
  return <DatagridConfigurable {...props} body={<LabelledDatagridBody />} />
}
