import React from 'react'
import {
  Edit,
  EditProps,
  FormDataConsumer,
  SimpleForm,
  TextInput,
} from 'react-admin'
import { PageTitle } from '../../atoms/PageTitle'
import { ReferenceAutocomplete } from '../../molecules/ReferenceAutocomplete'

/**
 * Form to edit a monitor notification contact.
 * @param props The Edit component props
 * @see https://marmelab.com/react-admin/Edit.html
 */
export const MonitorNotificationContactEdit: React.FC<EditProps> = (props) => {
  return (
    <Edit
      {...props}
      title={<PageTitle prefix="Monitor Notification Contact" />}
      mutationMode="undoable"
    >
      <SimpleForm>
        <TextInput disabled label="Id" source="id" fullWidth />
        <ReferenceAutocomplete
          label="Notification Type"
          source="monitorNotificationTypeId"
          reference="monitorNotificationTypes/list"
          required
        />
        <ReferenceAutocomplete
          label="Client"
          source="clientId"
          reference="clients/list"
          required
        />
        <FormDataConsumer>
          {({ formData, getSource, scopedFormData, ...rest }) =>
            formData.clientId ? (
              <ReferenceAutocomplete
                label="Client Contact"
                source="clientContactId"
                reference="clientContacts/list"
                required
                filter={{ clientId: formData.clientId }}
                disableSyncWithLocation
              />
            ) : null
          }
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  )
}
