import { MarginBookShow } from './Show.component'
import { MarginBookEdit } from './Edit.component'
import { MarginBookCreate } from './Create.component'
import { MarginBookList } from './List.component'

export default {
  list: MarginBookList,
  show: MarginBookShow,
  create: MarginBookCreate,
  edit: MarginBookEdit,
}
