import React from 'react'
import { CreateInDialogButton } from '@react-admin/ra-form-layout'
import { CreateEditStatsForm } from '../../molecules/CreateEditStatsForm'
import { useRecord } from './StatsModalInput.hooks'

/**
 * This component is responsible for rendering a button that opens a dialog to create or edit a service statistic.
 * It receives a reference prop that is used to fetch the record to be edited.
 * @returns a React component that renders a button to open a dialog to create or edit a service statistic.
 */
export const StatsModalInput: React.FC<{ reference: string }> = (props) => {
  const record = useRecord(props.reference)

  return (
    <CreateInDialogButton
      inline
      fullWidth
      maxWidth="md"
      record={record}
      resource={'serviceStatistics'}
    >
      <CreateEditStatsForm />
    </CreateInDialogButton>
  )
}
