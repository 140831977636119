import React from 'react'
import { Filter, SelectInput } from 'react-admin'
import { ReferenceAutocomplete } from '../ReferenceAutocomplete'
import { IMarginRuleFilters } from './MarginRuleFilters.types'

/**
 * Filters for MarginRule
 * @property {string} props
 * @returns {React.FC<IMarginRuleFilters>}
 * @example
 * <MarginRuleFilters />
 * @see https://marmelab.com/react-admin/List.html#the-filter-button
 */
export const MarginRuleFilters: React.FC<IMarginRuleFilters> = (props) => {
  return (
    <Filter {...props}>
      <ReferenceAutocomplete
        source="marginBookId"
        reference="marginBooks/list"
        alwaysOn
      />
      <SelectInput
        source="priceCategory"
        choices={[
          { id: 'nrc', name: 'Non-recurring' },
          { id: 'mrc', name: 'Monthly recurring' },
        ]}
        alwaysOn
      />
      <SelectInput
        source="marginType"
        choices={[
          { id: 'absolute', name: 'Absolute' },
          { id: 'percentage', name: 'Percent' },
        ]}
        alwaysOn
      />
    </Filter>
  )
}
