import { MonitorNotificationTypeCreate } from './Create.component'
import { MonitorNotificationTypeEdit } from './Edit.component'
import { MonitorNotificationTypeList } from './List.component'
import { MonitorNotificationTypeShow } from './Show.component'

export default {
  list: MonitorNotificationTypeList,
  show: MonitorNotificationTypeShow,
  create: MonitorNotificationTypeCreate,
  edit: MonitorNotificationTypeEdit,
}
