import React from 'react'
import { NameFilter } from '../../atoms/NameFilter'
import {
  List,
  ListProps,
  Datagrid,
  DateField,
  BooleanField,
  TextField,
} from 'react-admin'

/**
 * Page to list all Vendors.
 * @param props The page props.
 * @returns JSX.Element to render.
 */
export const VendorList: React.FC<ListProps> = (props) => {
  return (
    <List
      {...props}
      empty={false}
      perPage={25}
      filters={<NameFilter />}
      sort={{ field: 'name', order: 'ASC' }}
    >
      <Datagrid rowClick="show">
        <TextField source="name" fullWidth />
        <BooleanField source="isActive" fullWidth />
        <DateField source="createdAt" showTime />
        <DateField source="updatedAt" showTime />
      </Datagrid>
    </List>
  )
}
