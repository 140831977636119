import React from 'react'
import { IMonitorAlertHistoryFilters } from './MonitorAlertHistoryFilters.types'
import {
  Filter,
  TextInput,
  ReferenceInput,
  BooleanInput,
  AutocompleteInput,
} from 'react-admin'

/**
 * Filters for a list of monitor alert history.
 * @property {IMonitorAlertHistoryFilters} props The properties of the component.
 * @returns {JSX.Element} The JSX to render.
 * @example
 * <MonitorAlertHistoryFilters />
 * @see https://marmelab.com/react-admin/List.html#the-filter-button
 * @see https://marmelab.com/react-admin/List.html#the-filter-form
 * @see https://marmelab.com/react-admin/List.html#the-filter-button
 */
export const MonitorAlertHistoryFilters: React.FC<
  IMonitorAlertHistoryFilters
> = (props) => {
  return (
    <Filter {...props}>
      <TextInput label="Search" source="term" alwaysOn />
      <ReferenceInput
        source="monitorProbeId"
        reference="monitorProbes/list"
        alwaysOn
      >
        <AutocompleteInput label={'Probe'} optionText="name" alwaysOn />
      </ReferenceInput>
      <ReferenceInput
        source="monitorAlertRuleId"
        reference="monitorAlertRules/list"
        alwaysOn
      >
        <AutocompleteInput label={'Rule'} optionText="name" alwaysOn />
      </ReferenceInput>
      <BooleanInput source="isActive" label="Only show active" />
    </Filter>
  )
}
