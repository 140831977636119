/**
 * Styles for the title in the app bar.
 * @returns {React.CSSProperties} The row left border style.
 * @exports useStyles
 * @see https://mui.com/styles/api/#css
 * @see https://mui.com/system/typography/#typography
 */
export const useStyles = () => {
  return {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    display: { xs: 'none', sm: 'block' },
    mt: 0.5,
    ml: 0.5,
  }
}
