import React from 'react'
import {
  IconMenu,
  MenuItemList,
  MenuItemNode,
} from '@react-admin/ra-navigation'
import { CardContent } from '@mui/material'
import ClientsIcon from '@mui/icons-material/BadgeOutlined'

/**
 * This is a custom menu item that will be added to the main menu.
 * It will display a list of menu items when clicked.
 * @returns {JSX.Element}
 */
export const ClientsMenuItem: React.FC = () => {
  return (
    <IconMenu.Item name="clients" icon={<ClientsIcon />} label="Clients">
      <CardContent>
        <MenuItemList>
          <MenuItemNode name="clients" to="/clients" label="Clients" />
          <MenuItemNode
            name="endCustomers"
            to="/endCustomers"
            label="End Customers"
          />
          <MenuItemNode
            name="clientContracts"
            to={'/clientContracts'}
            label="Contracts"
          />
          <MenuItemNode
            name="clientContacts"
            to={'/clientContacts'}
            label="Contacts"
          />
          <MenuItemNode name="projects" to={'/projects'} label="Projects" />
        </MenuItemList>
      </CardContent>
    </IconMenu.Item>
  )
}
