import React from 'react'
import { List, ListProps, SimpleList } from 'react-admin'
import { SortCreateToolbar } from '../../molecules/SortCreateToolbar'
import { ClientVisibilityFilters } from '../../molecules/ClientVisibilityFilters'
import { PrimaryText, SecondaryText, TertiaryText } from '../../atoms/Note'
import { styles } from './Note.styles'
import { RaRecord, Identifier } from 'react-admin'

/**
 * Page for listing notes.
 * @returns {React.FC}
 * @example
 * import { NoteList } from './List.component.tsx'
 * <NoteList />
 * @see https://marmelab.com/react-admin/List.html
 */
export const NoteList: React.FC<ListProps> = (props) => {
  return (
    <List
      filters={<ClientVisibilityFilters />}
      sort={{ field: 'createdAt', order: 'DESC' }}
      actions={<SortCreateToolbar />}
    >
      <SimpleList
        primaryText={<PrimaryText />}
        secondaryText={(record: RaRecord<Identifier>) => (
          <SecondaryText {...record} />
        )}
        tertiaryText={(record: RaRecord<Identifier>) => (
          <TertiaryText {...record} />
        )}
        linkType={'edit'}
        rowStyle={(record: RaRecord<Identifier>) => styles(record.isInternal)}
      />
    </List>
  )
}
