import React from 'react'
import { List, ListProps, Datagrid, TextField, DateField } from 'react-admin'
import { NameFilter } from '../../atoms/NameFilter'
import { ReferenceChip } from '../../molecules/ReferenceChip'

/**
 * List of monitor metrics.
 * @param props The List component props
 * @see https://marmelab.com/react-admin/List.html
 */
export const MonitorMetricList: React.FC<ListProps> = (props) => {
  return (
    <List
      {...props}
      empty={false}
      filters={<NameFilter />}
      sort={{ field: 'name', order: 'ASC' }}
      perPage={25}
    >
      <Datagrid rowClick="show">
        <ReferenceChip
          referenceSource="monitorProbeTypeId"
          tooltipSource="name"
          source="monitorProbeType"
          chipSource="name"
          reference="monitorProbeTypes"
          emptyText="N/A"
          link="show"
        />
        <TextField source="name" fullWidth />
        <TextField source="dataType" />
        <TextField source="units" />
        <DateField source="updatedAt" showTime />
      </Datagrid>
    </List>
  )
}
