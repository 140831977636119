import React from 'react'
import { useStyles } from './Onboarding.styles'
import {
  TextField,
  BooleanField,
  ArrayField,
  Datagrid,
  FormDataConsumer,
  SimpleShowLayout,
} from 'react-admin'
import { ReferenceText } from '../../molecules/ReferenceText'
import { AddressDisplay } from '../../organisms/AddressDisplay'
import { ContactDetailsDisplay } from '../../organisms/ContactDetailsDisplay'
import { Box, Typography } from '@mui/material'

/**
 * Summary of the onboarding request.
 * @returns {React.ReactElement}
 * @example <OnboardingSummary />
 * @see https://marmelab.com/react-admin/Fields.html
 */
export const OnboardingSummary: React.FC = () => {
  const styles = useStyles()

  return (
    <FormDataConsumer>
      {({ formData }) => (
        <>
          {['user', 'full'].includes(formData?.type) && (
            <Box mb={3}>
              <Typography variant="subtitle1" ml={2}>
                User Details
              </Typography>
              <SimpleShowLayout
                sx={{
                  '& .RaSimpleShowLayout-stack': styles,
                }}
                record={formData?.user}
              >
                <TextField source="name" />
                <TextField source="email" fullWidth />
                <ReferenceText
                  source={'role'}
                  reference="userRoles/list"
                  emptyText={'N/A'}
                  link={false}
                />
                <BooleanField source="isActive" emptyText={'N/A'} fullWidth />
              </SimpleShowLayout>
            </Box>
          )}
          {['client', 'full'].includes(formData?.type) && (
            <Box mb={3}>
              <Typography variant="subtitle1" ml={2}>
                Client Details
              </Typography>
              <SimpleShowLayout
                sx={{
                  '& .RaSimpleShowLayout-stack': styles,
                }}
                record={formData?.client}
              >
                <TextField source="name" />
                <AddressDisplay />
                <TextField source="phoneNumber" emptyText={'N/A'} fullWidth />
                <TextField source="email" emptyText={'N/A'} fullWidth />
                <TextField source="companyName" emptyText={'N/A'} fullWidth />
                <TextField source="companyRegNo" emptyText={'N/A'} fullWidth />
                <TextField source="companyVatNo" emptyText={'N/A'} fullWidth />
                <TextField source="companyIban" emptyText={'N/A'} fullWidth />
                <BooleanField source="isActive" emptyText={'N/A'} fullWidth />
              </SimpleShowLayout>
            </Box>
          )}
          {['client', 'full'].includes(formData?.type) && (
            <Box mb={3}>
              <Typography variant="subtitle1" ml={2}>
                Client Contacts
              </Typography>
              {formData?.contacts.length > 0 ? (
                <ArrayField source="contacts">
                  <Datagrid bulkActionButtons={false}>
                    <TextField source="category" />
                    <TextField source="name" />
                    <ContactDetailsDisplay />
                    <BooleanField source="isPrimary" emptyText={'N/A'} />
                  </Datagrid>
                </ArrayField>
              ) : (
                <Typography variant="subtitle2" ml={2}>
                  None will be created
                </Typography>
              )}
            </Box>
          )}
        </>
      )}
    </FormDataConsumer>
  )
}
