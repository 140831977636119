import { DocumentShow } from './Show.component'
import { DocumentEdit } from './Edit.component'
import { DocumentCreate } from './Create.component'
import { DocumentList } from './List.component'

export default {
  list: DocumentList,
  show: DocumentShow,
  create: DocumentCreate,
  edit: DocumentEdit,
}
