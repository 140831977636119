import React from 'react'
import { Button as MUIButton, CircularProgress } from '@mui/material'
import { IButton } from './Button.types'

/**
 * Button loading state.
 *
 */
const Loader: React.FC = () => {
  return <CircularProgress sx={{ m: 0.3 }} size={19} thickness={3} />
}

/**
 * Override of the MUI Button component that adds a loading state.
 * @property {boolean} loading - If true, the button will be disabled and a loading indicator will be shown.
 * @property {React.ReactNode} children - The content of the button.
 * @property {string} variant - The variant to use.
 * @property {string} color - The color to use.
 * @returns {React.ReactElement}
 * @example
 * <Button loading={true} variant="contained" color="primary">Save</Button>
 * <Button loading={true} variant="outlined" color="primary">Save</Button>
 * <Button loading={true} variant="text" color="primary">Save</Button>
 * <Button loading={true} variant="contained" color="secondary">Save</Button>
 * <Button loading={true} variant="outlined" color="secondary">Save</Button>
 * <Button loading={true} variant="text" color="secondary">Save</Button>
 */
export const Button: React.FC<IButton> = (props) => {
  return (
    <MUIButton {...props}>
      {props.loading ? <Loader /> : props.children}
    </MUIButton>
  )
}
