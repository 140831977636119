import { ServiceCreate } from './Create.component'
import { ServiceList } from './List.component'
import { ServiceEdit } from './Edit.component'
import { ServiceShow } from './Show.component'

export default {
  list: ServiceList,
  show: ServiceShow,
  create: ServiceCreate,
  edit: ServiceEdit,
}
