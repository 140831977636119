import React, { useState } from 'react'
import {
  useNotify,
  SimpleForm,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  SelectArrayInput,
  useDataProvider,
  Toolbar,
} from 'react-admin'
import { Button, Dialog, DialogTitle, DialogContent } from '@mui/material'
import DownloadIcon from '@mui/icons-material/DownloadOutlined'
import CloseIcon from '@mui/icons-material/Close'

/**
 * Modal for downloading an overview of services
 * @returns {React.FC}
 * @example <ServiceDownloadOverviewModal />
 */
export const ServiceDownloadOverviewModal: React.FC = () => {
  const [showDialog, setShowDialog] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const dataProvider = useDataProvider()
  const notify = useNotify()

  const handleClick = () => {
    setShowDialog(true)
  }

  const handleCloseClick = (
    _event: React.MouseEvent<HTMLButtonElement>,
    _reason?: string
  ) => {
    setShowDialog(false)
  }

  const handleSubmit = async (filters: Record<string, unknown>) => {
    setIsSubmitting(true)
    notify('Requested Download')
    try {
      const { json } = await dataProvider.customRequest(
        'services',
        `downloadOverview?filter=${encodeURI(JSON.stringify(filters))}`,
        {
          method: 'POST',
        }
      )
      if (json && json.data?.content) {
        notify('Starting Download', { type: 'success' })

        const link = document.createElement('a')
        link.href = `data:application/octet-stream;base64,${json.data.content}`
        link.download = json.data.name ?? 'service-overview.xlsx'
        link.target = '_blank'
        link.click()

        setIsSubmitting(false)
        setShowDialog(false)
      } else if (json && json.message) {
        throw new Error(json.error ?? 'Something went wrong')
      }
    } catch (error: any) {
      notify(error.message, { type: 'error' })
      setIsSubmitting(false)
    }
  }

  return (
    <>
      <Button color="primary" onClick={handleClick}>
        <DownloadIcon />
        &nbsp;{'Overview'}
      </Button>
      <Dialog
        fullWidth
        maxWidth="md"
        open={showDialog}
        onClose={handleCloseClick}
      >
        <DialogTitle>
          Download Service Overview
          <Button
            sx={{ margin: 0, float: 'right' }}
            size={'small'}
            onClick={handleCloseClick}
          >
            <CloseIcon />
            Close
          </Button>
        </DialogTitle>
        <DialogContent>
          <SimpleForm
            onSubmit={handleSubmit}
            toolbar={
              <Toolbar>
                <Button type="submit" disabled={isSubmitting}>
                  Submit
                </Button>
              </Toolbar>
            }
          >
            <ReferenceArrayInput source="clientId" reference="clients/list">
              <AutocompleteArrayInput
                optionText="name"
                optionValue="id"
                fullWidth
                label="Select one or multiple clients"
              />
            </ReferenceArrayInput>
            <SelectArrayInput
              choices={[
                { id: 'active', name: 'Active' },
                { id: 'provisioning', name: 'Provisioning' },
                { id: 'cancelled', name: 'Cancelled' },
                { id: 'terminated', name: 'Terminated' },
              ]}
              optionText="name"
              optionValue="id"
              source="state"
              fullWidth
              label="Select one or multiple service states"
            />
          </SimpleForm>
        </DialogContent>
      </Dialog>
    </>
  )
}
