import { ILastActivityTextField } from './LastActivityTextField.types'
import { useHours, useStylesFromRecord } from './LastActivityTextField.hooks'
import { TextField, useRecordContext } from 'react-admin'
import { get } from 'lodash'

/**
 * Renders a TextField component to display the last activity with appropriate styles.
 * @param props - The component props.
 * @returns The rendered TextField component.
 * @see ILastActivityTextField
 * @see useHours
 * @see useStylesFromRecord
 * @see https://marmelab.com/react-admin/Fields.html#functionfield
 */
const LastActivityTextField: React.FC<ILastActivityTextField> = ({
  stateSource,
  updatedAtSource,
  label,
  ...rest
}) => {
  if (!stateSource) stateSource = 'state'
  if (!updatedAtSource) updatedAtSource = 'updatedAt'

  const record = useRecordContext(rest)

  const state = get(record, stateSource)
  const updatedAt = get(record, updatedAtSource)
  const hours = useHours(updatedAt)
  const styles = useStylesFromRecord({ state, updatedAt })

  return (
    <TextField
      {...rest}
      sx={styles}
      record={{ value: hours > 0 ? hours + ' hours ago' : 'Past hour' }}
      source="value"
    />
  )
}

/**
 * Default prop values for the LastActivityTextField component
 */
LastActivityTextField.defaultProps = {
  label: 'Last activity',
  updatedAtSource: 'updatedAt',
  stateSource: 'state',
}

export { LastActivityTextField }
