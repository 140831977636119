import React from 'react'
import {
  FunctionField,
  TextField,
  RaRecord,
  SimpleShowLayout,
  useRecordContext,
} from 'react-admin'
import { IProductDetailsExpand } from './ProductDetailsExpand.types'
import { ProductInformationGrid } from '../../molecules/ProductInformationGrid'
import { Toolbar } from '../../molecules/Toolbar'
import { ServiceSpecificationDisplay } from '../../templates/ServiceSpecificationDisplay'
import Divider from '@mui/material/Divider'
import { useStyles } from '../../molecules/ProductInformationGrid/ProductInformationGrid.styles'

/**
 * Product details section.
 * @param props ProductDetailsExpand properties.
 * @returns ReactElement
 */
export const ProductDetailsExpand: React.FC<IProductDetailsExpand> = (
  props
) => {
  const record = useRecordContext(props)
  const gridStyles = useStyles()
  return (
    <>
      {record.scores ? (
        <FunctionField
          label={false}
          render={(record: RaRecord) => (
            <SimpleShowLayout sx={gridStyles} record={record}>
              {Object.keys(record.scores).map((k) => (
                <TextField
                  label={`${k[0].toUpperCase() + k.slice(1)} score`}
                  source={`scores.${k}`}
                  emptyText="N/A"
                />
              ))}
            </SimpleShowLayout>
          )}
        />
      ) : null}
      <ProductInformationGrid />
      <Divider light sx={{ m: 1 }} />
      <ServiceSpecificationDisplay />
      {props.buttons === true && <Toolbar showShow />}
    </>
  )
}
