import React from 'react'
import {
  ReferenceManyField,
  Datagrid,
  TextField,
  DateField,
  ShowButton,
  Pagination,
} from 'react-admin'
import { IDocumentList } from './DocumentList.types'

/**
 * List of documents
 * @returns {React.FC}
 * @example
 * import { DocumentList } from './DocumentList.component'
 * <DocumentList />
 * @see https://marmelab.com/react-admin/List.html
 */
export const DocumentList: React.FC<IDocumentList> = (props) => {
  return (
    <ReferenceManyField
      reference="documents"
      target={props.target}
      label="documents"
      pagination={<Pagination />}
      sort={{ field: 'createdAt', order: 'DESC' }}
    >
      <Datagrid
        sx={{
          width: '100%',
          '& .column-title': {
            maxWidth: '20em',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          },
        }}
      >
        <TextField source="documentNo" fullWidth />
        <TextField source="title" fullWidth />
        <DateField source="createdAt" showTime />
        <ShowButton />
      </Datagrid>
    </ReferenceManyField>
  )
}
