import React from 'react'
import { NameFilter } from '../../atoms/NameFilter'
import {
  List,
  ListProps,
  TextField,
  BooleanField,
  DateField,
  Datagrid,
} from 'react-admin'

/**
 * List Applications page.
 * @property {ListProps} props
 * @returns {JSX.Element} ApplicationList component
 * @see {@link https://marmelab.com/react-admin/List.html|List}
 */
export const ApplicationList: React.FC<ListProps> = (props) => {
  return (
    <List
      {...props}
      empty={false}
      perPage={25}
      filters={<NameFilter />}
      sort={{ field: 'createdAt', order: 'DESC' }}
    >
      <Datagrid rowClick="show">
        <TextField source="name" />
        <BooleanField source="isActive" />
        <TextField source="dataView" emptyText="default (client)" />
        <DateField source="updatedAt" showTime />
        <DateField source="createdAt" showTime />
      </Datagrid>
    </List>
  )
}
