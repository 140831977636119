import merge from 'lodash/merge'
import { RaThemeOptions } from 'react-admin'
import { defaultDarkTheme } from '@react-admin/ra-enterprise'
import { baseTheme } from './baseTheme.styles'
import { green, orange, red } from '@mui/material/colors'

/**
 * The light theme styles.
 * @exports lightTheme
 * @see https://mui.com/customization/default-theme/
 * @see https://mui.com/customization/palette/
 * @see https://mui.com/system/palette/#color
 */
export const darkTheme: RaThemeOptions = merge(
  {},
  baseTheme,
  defaultDarkTheme,
  {
    palette: {
      mode: 'dark',
      primary: {
        main: '#FFA40F',
      },
      secondary: {
        main: '#5068AD',
      },
      warning: {
        main: orange[800],
      },
      info: {
        main: 'rgba(80, 104, 173, 1)',
      },
      success: {
        main: green[800],
      },
      error: {
        main: red[800],
      },
    },
  }
)
