import React from 'react'
import { IRequestServiceFilters } from './RequestServiceFilters.types'
import {
  Filter,
  TextInput,
  BooleanInput,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  useRecordContext,
} from 'react-admin'

/**
 * Filters for a list of request services.
 * @property {IMonitorProbeFilters} props The properties of the component.
 * @returns {JSX.Element} The JSX to render.
 * @example
 * <RequestServiceFilters />
 * @see https://marmelab.com/react-admin/List.html#the-filter-button
 * @see https://marmelab.com/react-admin/List.html#the-filter-form
 * @see https://marmelab.com/react-admin/List.html#the-filter-button
 */
export const RequestServiceFilters: React.FC<IRequestServiceFilters> = (
  props
) => {
  const record = useRecordContext(props)
  return (
    <Filter {...props}>
      <TextInput label="Search" source="term" alwaysOn />
      <ReferenceArrayInput
        source="assigneeIds"
        reference="users/list"
        filter={{
          role: {
            in: [
              'gnx-procurement',
              'gnx-sales',
              'gnx-admin',
              'admin',
              'sales',
              'procurement',
            ],
          },
        }}
        alwaysOn
      >
        <AutocompleteArrayInput
          sx={{ minWidth: 300 }}
          label="Assignee(s)"
          optionText="name"
          alwaysOn
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        source="siteIds"
        reference="requestSites/list"
        filter={{ requestId: record.id }}
        alwaysOn
      >
        <AutocompleteArrayInput
          label={'Sites'}
          optionText="name"
          alwaysOn
          sx={{ minWidth: 300 }}
        />
      </ReferenceArrayInput>
      <BooleanInput source="isRequested" />
    </Filter>
  )
}
