import { InternetServiceProviderCreate } from './Create.component'
import { InternetServiceProviderEdit } from './Edit.component'
import { InternetServiceProviderList } from './List.component'
import { InternetServiceProviderShow } from './Show.component'

export default {
  create: InternetServiceProviderCreate,
  edit: InternetServiceProviderEdit,
  list: InternetServiceProviderList,
  show: InternetServiceProviderShow,
}
