import React from 'react'
import { List, ListProps, Datagrid, TextField, DateField } from 'react-admin'
import { TermFilter } from '../../atoms/TermFilter'
import { ReferenceChip } from '../../molecules/ReferenceChip'

/**
 * Page to list Service Types.
 * @property { ListProps } props The properties of the List component.
 * @returns { React.ReactNode } The Service Type List page.
 * @example
 * import { ServiceTypeList } from './ServiceTypeList';
 * <ServiceTypeList {...props} />
 * @see https://marmelab.com/react-admin/List.html
 * @see https://marmelab.com/react-admin/Fields.html
 */
export const ServiceTypeList: React.FC<ListProps> = (props) => (
  <List
    {...props}
    empty={false}
    filters={<TermFilter />}
    sort={{ field: 'name', order: 'ASC' }}
    perPage={25}
  >
    <Datagrid rowClick="show">
      <TextField source="name" />
      <TextField source="key" emptyText="N/A" />
      <ReferenceChip
        referenceSource="serviceCategoryId"
        tooltipSource="key"
        source="name"
        label={'Service category'}
        reference="serviceCategories"
        emptyText="N/A"
        link="show"
        chipSource="name"
        removeDoubleLabel
      />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </Datagrid>
  </List>
)
