import React from 'react'
import { SimpleForm, TextInput, SelectInput, required } from 'react-admin'
import { ReferenceAutocomplete } from '../ReferenceAutocomplete'

/**
 * Form to create a new, or edit an existing, service statistic.
 * @returns {React.FC} The component.
 * @example
 * import { CreateEditStatsForm } from './CreateEditStatsForm.component.tsx'
 * <CreateEditStatsForm />
 * @see {@link https://marmelab.com/react-admin/CreateEdit.html|CreateEdit}
 */
export const CreateEditStatsForm: React.FC = () => {
  return (
    <SimpleForm>
      <ReferenceAutocomplete
        required
        label="Service"
        source="serviceId"
        reference="services/list"
      />
      <TextInput source="title" validate={required()} fullWidth />
      <SelectInput
        source="type"
        choices={[
          { id: 'traffic', name: 'Traffic' },
          { id: 'bandwidth', name: 'Bandwidth' },
        ]}
        fullWidth
      />
      <SelectInput
        source="module"
        choices={[{ id: 'nms', name: 'LibreNMS' }]}
        fullWidth
      />
      <TextInput source="config.host" label={'NMS host address'} fullWidth />
      <TextInput source="config.device" label={'Device name'} fullWidth />
      <TextInput source="config.port" label={'Port name'} fullWidth />
      <TextInput
        source="config.type"
        label={'Stats type'}
        defaultValue={'port_bits'}
        fullWidth
      />
    </SimpleForm>
  )
}
