import React from 'react'
import {
  List,
  Datagrid,
  useRecordContext,
  TextField,
  BooleanField,
  DateField,
  ShowButton,
  Pagination,
} from 'react-admin'
import { TypeStateField } from '../../atoms/TypeStateField'
import { IContactsList } from './ContactsList.types'
import { SearchFilter } from '../../atoms/SearchFilter'

/**
 * List of contacts
 * @returns { React.FC }
 * @example
 * import { ContactsList } from './ContactsList'
 * <ContactsList />
 * @see https://marmelab.com/react-admin/Fields.html#referencemanyfield
 */
export const ContactsList: React.FC<IContactsList> = (props) => {
  const record = useRecordContext()

  return (
    <List
      resource={props.reference}
      filter={{ [props.target]: record?.id }}
      filters={<SearchFilter />}
      pagination={<Pagination />}
      sort={{ field: 'createdAt', order: 'DESC' }}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source="name" />
        <TypeStateField source="role" />
        <BooleanField source="isActive" />
        <DateField source="createdAt" showTime />
        <ShowButton />
      </Datagrid>
    </List>
  )
}
