import { AccessTechnologyCreate } from './Create.component'
import { AccessTechnologyEdit } from './Edit.component'
import { AccessTechnologyList } from './List.component'
import { AccessTechnologyShow } from './Show.component'

export default {
  list: AccessTechnologyList,
  show: AccessTechnologyShow,
  create: AccessTechnologyCreate,
  edit: AccessTechnologyEdit,
}
