import React from 'react'
import { TokensList } from '../../organisms/TokensList'
import {
  Show,
  ShowProps,
  TextField,
  BooleanField,
  DateField,
  TabbedShowLayout,
  Tab,
  FunctionField,
} from 'react-admin'
import { HistoryDisplayTab } from '../../templates/HistoryDisplayTab'
import { ActivityDisplayTab } from '../../templates/ActivityDisplayTab'

/**
 * Show Application page.
 * @todo refactor the tokens tab into a template.
 * @property {ShowProps} props
 * @returns {JSX.Element} ApplicationShow component
 * @see {@link https://marmelab.com/react-admin/Show.html|Show}
 */
export const ApplicationShow: React.FC<ShowProps> = (props) => {
  return (
    <Show {...props}>
      <TabbedShowLayout {...props}>
        <Tab label="summary">
          <TextField source="name" />
          <BooleanField source="isActive" />
          <TextField source="dataView" emptyText="default (client)" />
          <DateField source="updatedAt" showTime />
          <DateField source="createdAt" showTime />
        </Tab>
        <Tab label="tokens">
          <FunctionField
            label={false}
            render={(record: { id: string }) => (
              <TokensList applicationId={record.id} />
            )}
          />
        </Tab>
        <Tab label={'history'}>
          <HistoryDisplayTab />
        </Tab>
        <Tab label={'Activity'}>
          <ActivityDisplayTab />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
