import React from 'react'
import {
  ReferenceManyField,
  TextField,
  ShowButton,
  Pagination,
  BooleanField,
} from 'react-admin'
import { LabelledDatagrid } from '../../atoms/LabelledDatagrid'
import { TypeStateField } from '../../atoms/TypeStateField'

import { ICoverageAreasList } from './CoverageAreasList.types'

/**
 * List of coverage areas, used in related context
 * @returns {React.FC}
 * @example
 * import { CoverageAreasList } from './CoverageAreasList'
 * <CoverageAreasList />
 * @see https://marmelab.com/react-admin/Fields.html
 */
export const CoverageAreasList: React.FC<ICoverageAreasList> = (props) => {
  return (
    <ReferenceManyField
      {...props}
      reference="coverageAreas"
      target={props.target}
      label={props.label || 'Coverage areas'}
      pagination={<Pagination />}
      sort={props.sort || { field: 'createdAt', order: 'DESC' }}
    >
      <LabelledDatagrid bulkActionButtons={false}>
        <TextField source="name" />
        <TypeStateField source="type" emptyText="N/A" />
        <TextField source="availability" emptyText="N/A" />
        <BooleanField source="isActive" />
        <ShowButton />
      </LabelledDatagrid>
    </ReferenceManyField>
  )
}
