import React from 'react'
import { IHistoryDisplayTab } from './HistoryDisplayTab.types'
import { MutationsList } from '../../organisms/MutationsList'

/**
 * Template for displaying the history tab.
 * @property {IHistoryDisplayTab} props
 * @returns {JSX.Element} HistoryDisplayTab component
 * @see {@link https://marmelab.com/react-admin/List.html|List}
 * @see {@link https://marmelab.com/react-admin/TabbedShowLayout.html|TabbedShowLayout}
 * @see {@link https://marmelab.com/react-admin/Tab.html|Tab}
 */
export const HistoryDisplayTab: React.FC<IHistoryDisplayTab> = () => {
  return <MutationsList showActor type="history" />
}
