import React from 'react'
import NotesIcon from '@mui/icons-material/TextSnippet'
import Tooltip from '@mui/material/Tooltip'

import { IconButton } from '@mui/material'
import { useRedirect } from 'react-admin'

/**
 * Notes button that redirects to the notes page.
 * @returns {React.FC} Notes button.
 * @example <NotesButton />
 * @see https://mui.com/components/material-icons/
 */
export const NotesButton: React.FC = React.forwardRef(() => {
  const redirect = useRedirect()

  const buttonclick = () => {
    redirect('/notes')
  }

  return (
    <Tooltip title="Notes" enterDelay={300}>
      <IconButton color="inherit" onClick={buttonclick} aria-label={'Notes'}>
        <NotesIcon />
      </IconButton>
    </Tooltip>
  )
})
