import React from 'react'
import { DualSelect } from '../../atoms/DualSelect'
import { Edit, EditProps, SimpleForm, TextInput, required } from 'react-admin'

/**
 * Page to edit a UserGroup.
 * @property {EditProps} props
 * @returns {JSX.Element} UserGroupEdit component
 * @see {@link https://marmelab.com/react-admin/CreateEdit.html|CreateEdit}
 * @see {@link https://marmelab.com/react-admin/CreateEdit.html#required-vs-optional-fields|Required vs Optional Fields}
 * @see {@link https://marmelab.com/react-admin/Inputs.html#simpleform|SimpleForm}
 */
export const UserGroupEdit: React.FC<EditProps> = (props) => {
  return (
    <Edit {...props} mutationMode="undoable">
      <SimpleForm>
        <TextInput disabled label="Id" source="id" fullWidth />
        <TextInput source="name" validate={required()} fullWidth />
        <DualSelect
          label="Clients"
          source="clientIds"
          reference="clients/list"
        />
        <DualSelect source="permissionIds" reference="permissions/list" />
      </SimpleForm>
    </Edit>
  )
}
