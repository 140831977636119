import React from 'react'
import { useRecordContext } from 'react-admin'
import { IRequestServiceDetails } from './RequestServiceDetails.types'
import { ProductSearchQueryField } from '../ProductSearchQueryField'
import { RequestProductsList } from '../../molecules/RequestProductsList'
import { RequestSitesList } from '../../molecules/RequestSitesList'
import { Typography, Box, Divider, Chip } from '@mui/material'

/**
 * Request service details section.
 * @param props RequestServiceDetails properties.
 * @returns ReactElement
 */
export const RequestServiceDetails: React.FC<IRequestServiceDetails> = ({
  showSites,
  showProducts,
  ...props
}) => {
  const record = useRecordContext(props)

  return (
    <Box>
      {showSites !== false ? (
        <Box display={{ sm: 'block', md: 'flex' }} sx={{ p: 0.5, mt: 1 }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <Divider sx={{ fontWeight: 'bold' }}>
              <Chip variant="outlined" label="Sites" />
            </Divider>
            <RequestSitesList
              context="requestServices"
              filter={{ id: record.siteIds }}
              storeKey=" "
              disableSyncWithLocation
              empty={false}
              exporter={false}
              perPage={10}
              sx={{
                '& .MuiToolbar-gutters': { display: 'none' },
                '& .MuiTablePagination-toolbar': { display: 'flex' },
              }}
            />
          </Box>
        </Box>
      ) : null}
      {showProducts !== false ? (
        <Box
          display={{ sm: 'block', md: 'flex' }}
          sx={{ p: 0.5, mt: 1, mb: 1 }}
        >
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <Divider sx={{ fontWeight: 'bold' }}>
              <Chip variant="outlined" label="Requested products" />
            </Divider>
            <RequestProductsList
              empty={
                <Typography variant="inherit" sx={{ p: 1 }}>
                  ℹ️ Customer has not made a firm offer request for any product
                  (yet)
                </Typography>
              }
              filter={{ id: record.productIds }}
              storeKey=" "
              disableSyncWithLocation
              exporter={false}
              perPage={10}
              sx={{
                '& .MuiToolbar-gutters': { display: 'none' },
                '& .MuiTablePagination-toolbar': { display: 'flex' },
              }}
            />
          </Box>
        </Box>
      ) : null}
      <Box display={{ sm: 'block', md: 'flex' }} sx={{ p: 0.5, mt: 1, mb: 2 }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <Divider sx={{ fontWeight: 'bold' }}>
            <Chip variant="outlined" label="Service preferences" />
          </Divider>
          <ProductSearchQueryField label={false} source="preferences" />
        </Box>
      </Box>
      <Box display={{ sm: 'block', md: 'flex' }} sx={{ p: 0.5, mt: 1, mb: 2 }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <Divider sx={{ fontWeight: 'bold' }}>
            <Chip variant="outlined" label="Service requirements" />
          </Divider>
          <ProductSearchQueryField label={false} source="requirements" />
        </Box>
      </Box>
      <Box display={{ sm: 'block', md: 'flex' }} sx={{ p: 0.5, mt: 1, mb: 4 }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <Divider sx={{ fontWeight: 'bold' }}>
            <Chip variant="outlined" label="Service add-ons" />
          </Divider>
          <ProductSearchQueryField label={false} source="addons" />
        </Box>
      </Box>
    </Box>
  )
}
