import React from 'react'
import {
  Show,
  ShowProps,
  TabbedShowLayout,
  Tab,
  TextField,
  DateField,
} from 'react-admin'
import { PageTitle } from '../../atoms/PageTitle'
import { MutationsList } from '../../organisms/MutationsList'

/**
 * Page to show a margin book
 * @param props
 * @prop {ShowProps} props
 * @returns {JSX.Element}
 * @example
 * <MarginBookShow {...props}/>
 * @see https://marmelab.com/react-admin/Show.html
 * @see https://marmelab.com/react-admin/Fields.html
 */
export const MarginBookShow: React.FC<ShowProps> = (props) => {
  return (
    <Show {...props} title={<PageTitle prefix="Margin Book" />}>
      <TabbedShowLayout {...props}>
        <Tab label="summary">
          <TextField source="name" />
          <TextField source="type" />
          <TextField source="offsetNrc" emptyText={'N/A'} />
          <TextField source="offsetMrc" emptyText={'N/A'} />
          <DateField source="updatedAt" showTime />
          <DateField source="createdAt" showTime />
        </Tab>
        <Tab label={'history'}>
          <MutationsList showActor type="history" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
