import { ClientContractCreate } from './Create.component'
import { ClientContractEdit } from './Edit.component'
import { ClientContractList } from './List.component'
import { ClientContractShow } from './Show.component'

export default {
  list: ClientContractList,
  show: ClientContractShow,
  create: ClientContractCreate,
  edit: ClientContractEdit,
}
