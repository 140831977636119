import React from 'react'
import { useIndicator, useState } from './ServiceHealthIndicatorField.hooks'
import { IServiceHealthIndicatorField } from './ServiceHealthIndicatorField.types'
import { useStyle } from './ServiceHealthIndicatorField.styles'

import Tooltip from '@mui/material/Tooltip'
import Chip from '@mui/material/Chip'

/**
 * Health indicator component
 * @returns {JSX.Element}
 * @example
 * <ServiceHealthIndicatorField />
 */
export const ServiceHealthIndicatorField: React.FC<
  IServiceHealthIndicatorField
> = (props: IServiceHealthIndicatorField) => {
  const { icon, text } = useIndicator()
  const state = useState(props?.source || 'health')
  const styles = useStyle()

  if (state) {
    return (
      <Chip
        variant="outlined"
        size="small"
        avatar={<span>{icon}</span>}
        label={`${text}: ${state}`}
        sx={styles}
      />
    )
  }

  if (props?.onlyIcon) {
    return (
      <Tooltip title={text}>
        <span>{icon}</span>
      </Tooltip>
    )
  }

  return (
    <Chip
      variant="outlined"
      size="small"
      avatar={<span>{icon}</span>}
      label={text}
      sx={styles}
    />
  )
}
