import React from 'react'
import { IconMenu } from '@react-admin/ra-navigation'
import DashboardIcon from '@mui/icons-material/DashboardOutlined'
import RequestsIcon from '@mui/icons-material/AllInboxOutlined'
import ApplicationsIcon from '@mui/icons-material/AppsOutlined'
import TicketsIcon from '@mui/icons-material/ConfirmationNumberOutlined'
import {
  AccessMenuItem,
  CatalogMenuItem,
  ClientsMenuItem,
  CoverageMenuItem,
  DocumentsMenuItem,
  MonitoringMenuItem,
  ServicesMenuItem,
  VendorsMenuItem,
} from '../../molecules/MenuItems'

/**
 * Main menu component
 * @returns {React.ReactElement}
 */
export const AppMenu: React.FC = () => {
  return (
    <IconMenu>
      <IconMenu.Item
        name="dashboard"
        icon={<DashboardIcon />}
        to="/"
        label="Dashboard"
      />
      <IconMenu.Item
        name="requests"
        icon={<RequestsIcon />}
        to="/quote-requests"
        label="Requests"
      />
      <ClientsMenuItem />
      <DocumentsMenuItem />
      <ServicesMenuItem />
      <CoverageMenuItem />
      <VendorsMenuItem />
      <CatalogMenuItem />
      <AccessMenuItem />
      <MonitoringMenuItem />
      <IconMenu.Item
        name="tickets"
        icon={<TicketsIcon />}
        to="/tickets"
        label="Tickets"
      />
      <IconMenu.Item
        name="applications"
        icon={<ApplicationsIcon />}
        to="/applications"
        label="Applications"
      />
    </IconMenu>
  )
}
