import React from 'react'
import {
  IconMenu,
  MenuItemList,
  MenuItemNode,
} from '@react-admin/ra-navigation'
import { CardContent } from '@mui/material'
import UserIcon from '@mui/icons-material/PersonOutlined'

/**
 * This is a custom menu item that will be added to the main menu.
 * It will display a list of menu items when clicked.
 * @returns {JSX.Element}
 */
export const AccessMenuItem: React.FC = () => {
  return (
    <IconMenu.Item name="access" icon={<UserIcon />} label="Access">
      <CardContent>
        <MenuItemList>
          <MenuItemNode name="users" to="/users" label="Users" />
          <MenuItemNode name="userGroups" to={'/userGroups'} label="Groups" />
          <MenuItemNode name="userRoles" to={'/userRoles'} label="Roles" />
          <MenuItemNode
            name="permissions"
            to="/permissions"
            label="Permissions"
          />
          <MenuItemNode
            name="onboardings"
            to="/onboardings"
            label="Onboardings"
          />
        </MenuItemList>
      </CardContent>
    </IconMenu.Item>
  )
}
