import React from 'react'
import { FormDataConsumer, SelectInput } from 'react-admin'
import { FieldValues } from 'react-hook-form/dist/types/fields'
import { ReferenceAutocomplete } from '../ReferenceAutocomplete'
import { IRelatedObjects } from './RelatedInputs.types'

/**
 * Selects the related entity and then displays the related entity's ID.
 * @returns {React.FC}
 * @example
 * import { RelatedInputs } from './RelatedInputs.component.tsx'
 * <RelatedInputs />
 * @see {@link https://marmelab.com/react-admin/Inputs.html#selectinput|RA SelectInput}
 */
export const RelatedInputs: React.FC = () => {
  return (
    <FormDataConsumer fullWidth>
      {(values: FieldValues) => {
        let relatedObjects: IRelatedObjects = {
          Service: 'services/list',
          Quote: 'quotes/list',
          Request: 'requests/list',
          RequestProduct: 'requestProducts/list',
          Document: 'documents/list',
        }

        if (!values.formData.clientId) return null

        return (
          <>
            <SelectInput
              source="relatedName"
              label={'Related Entity'}
              choices={Object.keys(relatedObjects).map((object) => ({
                id: object,
                name: object,
              }))}
              fullWidth
            />
            {values.formData.relatedName && (
              <ReferenceAutocomplete
                source="relatedId"
                label={`Related ${values.formData.relatedName}`}
                reference={`${relatedObjects[values.formData.relatedName]}`}
                filter={{ clientId: values.formData.clientId }}
                disableSyncWithLocation
              />
            )}
          </>
        )
      }}
    </FormDataConsumer>
  )
}
