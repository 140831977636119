import React from 'react'
import { TextPreSuffixField } from '../../atoms/TextPreSuffixField'
import { CurrencyField } from '../../atoms/CurrencyField'
import { IMarginMetric } from './MarginMetric.types'

/**
 * Returns a formatted Margin MRC/NRC metric.
 * @param {string} absoluteSource - The source for the absolute value of the margin field
 * @param {string} currency - The currency
 * @param {string} relativeSource - The source for the relative value of the margin field
 * @returns {JSX.Element} - The component
 *
 */
export const MarginMetric: React.FC<IMarginMetric> = (props) => {
  return (
    <>
      <CurrencyField source={props.absoluteSource} currency={props.currency} />
      &nbsp;{' '}
      <small>
        (<TextPreSuffixField source={props.relativeSource} />
        %)
      </small>
    </>
  )
}
