import { useRecordContext } from 'react-admin'

/**
 * useValue custom hook.
 *
 * A custom hook that returns the value based on the provided record and noRecordValue.
 *
 * @param {string} [noRecordValue] - The value to use when no record is available.
 * @returns {string} The value based on the record or noRecordValue.
 * @example
 * const value = useValue('No record')
 * // value = 'No record'
 */
export const useValue = (noRecordValue?: string) => {
  const record = useRecordContext()
  return record ? record.name || record.id : noRecordValue || ''
}
