import { EndCustomerShow } from './Show.component'
import { EndCustomerList } from './List.component'
import { EndCustomerCreate } from './Create.component'
import { EndCustomerEdit } from './Edit.component'

export default {
  list: EndCustomerList,
  show: EndCustomerShow,
  create: EndCustomerCreate,
  edit: EndCustomerEdit,
}
