import React from 'react'
import { Edit, EditProps, SimpleForm, TextInput, required } from 'react-admin'
import { PageTitle } from '../../../components/atoms/PageTitle'

/**
 * Edit the details of a single access technology row.
 * @property {EditProps} props
 * @returns {React.FC<EditProps>}
 * @example
 * import {AccessTechnologyEdit} from './AccessTechnology'
 * return <AccessTechnologyEdit {...props} />
 * @see https://marmelab.com/react-admin/Edit.html
 * @see https://marmelab.com/react-admin/Fields.html
 */
export const AccessTechnologyEdit: React.FC<EditProps> = (props) => {
  return (
    <Edit
      {...props}
      title={<PageTitle prefix="Access Technology" />}
      mutationMode="undoable"
    >
      <SimpleForm>
        <TextInput disabled label="Id" source="id" fullWidth />
        <TextInput source="name" validate={required()} fullWidth />
        <TextInput source="key" validate={required()} fullWidth />
        <TextInput source="description" fullWidth />
      </SimpleForm>
    </Edit>
  )
}
