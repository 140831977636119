import React from 'react'
import {
  TextField,
  DateField,
  Show,
  ShowProps,
  TabbedShowLayout,
  Tab,
} from 'react-admin'
import { MutationsList } from '../../organisms/MutationsList'
import { PageTitle } from '../../atoms/PageTitle'

/**
 * Show the service category details of a single row.
 * @property {ShowProps} props
 * @returns {React.FC<ShowProps>}
 * @example
 * import {ServiceCategoryShow} from './ServiceCategory'
 * return <ServiceCategoryShow {...props} />
 * @see https://marmelab.com/react-admin/Show.html
 * @see https://marmelab.com/react-admin/Fields.html
 * @see https://marmelab.com/react-admin/TabbedShowLayout.html
 */
export const ServiceCategoryShow: React.FC<ShowProps> = (props) => {
  return (
    <Show {...props} title={<PageTitle prefix="Service category" />}>
      <TabbedShowLayout {...props}>
        <Tab label="summary">
          <TextField source="key" />
          <TextField source="name" />
          <TextField source="description" emptyText={'N/A'} />
          <DateField source="createdAt" showTime />
          <DateField source="updatedAt" showTime />
        </Tab>
        <Tab label={'history'}>
          <MutationsList showActor type="history" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
