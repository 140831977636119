import React from 'react'
import { ITypeStateField } from './TypeStateField.types'
import { useRecordContext, ChipField, Labeled } from 'react-admin'
import { upperCase, get } from 'lodash'
import { useStateToColorString } from '../../../components/particles/color'

/**
 * React component that displays a chip field representing the state of a specific type.
 * @param {ITypeStateField} props - The props for the component.
 * @returns {ReactElement} The rendered component.
 * @see ITypeStateField
 */
const Chip: React.FC<ITypeStateField> = (props) => {
  return (
    <ChipField
      sx={{
        '&.RaChipField-chip': {
          width: props.fullWidth ? '100%' : 'auto',
          fontWeight: 'bold',
          maxWidth: 150,
        },
      }}
      {...props}
      record={props.record}
      source={props.source}
      color={props.color}
      size={'small'}
    />
  )
}

/**
 * Chip wrapped in a label.
 * @param {ITypeStateField} props - The props for the component.
 * @returns {ReactElement} The rendered component.
 * @see ITypeStateField
 * @see https://marmelab.com/react-admin/Fields.html#labeled
 */
const LabeledChip: React.FC<ITypeStateField> = (props) => {
  return (
    <Labeled label={props.label}>
      <Chip
        {...props}
        record={props.record}
        source={props.source}
        color={props.color}
      />
    </Labeled>
  )
}

/**
 * React component that displays a chip field representing the state of a specific type.
 * @param {ITypeStateField} props - The props for the component.
 * @returns {ReactElement} The rendered component.
 * @see ITypeStateField
 * @see https://marmelab.com/react-admin/Fields.html#chipfield
 */
export const TypeStateField: React.FC<ITypeStateField> = (props) => {
  const record = useRecordContext(props)
  const value = upperCase(get(record, props.source))
  const color = useStateToColorString(value.toLocaleLowerCase())

  if (!value) {
    return <span>{'N/A'}</span>
  }

  if (props.label) {
    return (
      <span>
        <LabeledChip
          {...props}
          record={{ [props.source]: value }}
          source={props.source}
          color={color}
        />
      </span>
    )
  }

  return (
    <span>
      <Chip
        {...props}
        record={{ [props.source]: value }}
        source={props.source}
        color={color}
      />
    </span>
  )
}
