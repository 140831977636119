import React from 'react'
import { Create, CreateProps } from 'react-admin'
import { CreateEditStatsForm } from '../../molecules/CreateEditStatsForm'

/**
 * Page to create a service statistic.
 * @param {CreateProps} props
 * @returns {React.FC}
 * @example
 * import { ServiceStatisticCreate } from './ServiceStatisticCreate.component.tsx'
 * <ServiceStatisticCreate />
 * @see {@link https://marmelab.com/react-admin/Create.html|RA Create}
 */
export const ServiceStatisticCreate: React.FC<CreateProps> = (props) => {
  return (
    <Create {...props}>
      <CreateEditStatsForm />
    </Create>
  )
}
