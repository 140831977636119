import React from 'react'
import { List, ListProps, TextField, DateField, Datagrid } from 'react-admin'
import { SearchClientCountryFilters } from '../../organisms/SearchClientCountryFilters'
import { ReferenceChip } from '../../molecules/ReferenceChip'

/**
 * Page to display a list of service sites.
 * @property {ListProps} props
 * @example
 * <ServiceSiteList />
 * @returns {React.FC<ListProps>}
 * @see https://marmelab.com/react-admin/List.html
 */
export const ServiceSiteList: React.FC<ListProps> = (props) => {
  return (
    <List
      empty={false}
      perPage={25}
      filters={<SearchClientCountryFilters />}
      sort={{ field: 'createdAt', order: 'DESC' }}
      {...props}
    >
      <Datagrid rowClick="show">
        <TextField source="siteNo" />
        <TextField source="address" />
        <TextField source="type" sx={{ width: '100%' }} />
        <ReferenceChip
          referenceSource="clientId"
          tooltipSource="clientNo"
          chipSource="name"
          source="Client"
          reference="clients"
          emptyText="N/A"
          link="show"
        />
        <DateField source="createdAt" showTime />
        <DateField source="updatedAt" showTime />
      </Datagrid>
    </List>
  )
}
