import React from 'react'
import { IMetaList } from './MetaList.types'
import { useGenerateMetaListRecord } from './MetaList.hooks'
import { ArrayField, Datagrid, TextField } from 'react-admin'
import { Message } from '../../atoms/Message'
import get from 'lodash/get'

/**
 * List of meta data rows.
 * @component
 * @param {IMetaList} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 * @example
 * return (
 * <MetaListDisplay source="metaList" />
 * )
 * @see {@link https://marmelab.com/react-admin/Fields.html#arrayfield|ArrayField}
 * @see {@link https://marmelab.com/react-admin/Fields.html#datagrid|Datagrid}
 * @see {@link https://marmelab.com/react-admin/Fields.html#textfield|TextField}
 * @see {@link https://marmelab.com/react-admin/List.html#the-datagrid-component|Datagrid}
 */
export const MetaListDisplay: React.FC<IMetaList> = (props) => {
  const { record, source } = useGenerateMetaListRecord(props.source)

  if (get(record, source).length <= 0) {
    return <Message message="No data available" />
  }

  return (
    <ArrayField source={source} label={false} fullWidth>
      <Datagrid bulkActionButtons={false}>
        <TextField source="property" />
        <TextField source="value" />
      </Datagrid>
    </ArrayField>
  )
}
