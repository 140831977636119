import {
  ReferenceInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  SelectInput,
  NumberInput,
  TextInput,
  BooleanInput,
} from 'react-admin'
import { ReferenceAutocomplete } from '../../molecules/ReferenceAutocomplete'

/**
 * Returns a list of inputs that can be used to filter the product list.
 * @returns {JSX.Element[]} The rendered component.
 * @example
 * const filters = useProductFilters()
 */
export const useProductFilters = (defaultFilters: string[] = ['term']) => {
  return [
    <TextInput
      source="term"
      label="Search"
      alwaysOn={defaultFilters?.includes('term')}
    />,
    <ReferenceAutocomplete
      source="countryCode"
      reference="countries/list"
      label={'Country'}
      sx={{ width: 200 }}
      alwaysOn={defaultFilters?.includes('countryCode')}
    />,
    <ReferenceInput
      source="serviceTypeId"
      reference="serviceTypes/list"
      alwaysOn={defaultFilters?.includes('serviceTypeId')}
    >
      <SelectInput
        label={'Service type'}
        optionText="name"
        sx={{ width: 100 }}
      />
    </ReferenceInput>,
    <ReferenceInput
      source="serviceCategoryId"
      reference="serviceCategories/list"
      alwaysOn={defaultFilters?.includes('serviceCategoryId')}
    >
      <SelectInput
        label={'Service category'}
        optionText="name"
        sx={{ width: 100 }}
      />
    </ReferenceInput>,
    <ReferenceInput
      alwaysOn={defaultFilters?.includes('accessTechnologyId.in')}
      source="accessTechnologyId.in"
      reference="accessTechnologies/list"
      label="Access technology in"
    >
      <AutocompleteArrayInput
        label={'Technology'}
        optionText="name"
        sx={{ width: 150 }}
      />
    </ReferenceInput>,
    <TextInput
      alwaysOn={defaultFilters?.includes('specifications.bandwidthDown.eq')}
      source={'specifications.bandwidthDown.eq'}
      label={'Bandwidth down eq'}
      sx={{ width: 160 }}
    />,
    <NumberInput
      alwaysOn={defaultFilters?.includes('contractTerms.contractPeriod.eq')}
      source={'contractTerms.contractPeriod.eq'}
      label={'Contract period eq'}
      sx={{ width: 160 }}
    />,
    <TextInput
      source={'specifications.bandwidthUp.eq'}
      label={'Bandwidth up eq'}
    />,
    <TextInput
      source={'specifications.bandwidthUp.lte'}
      label={'Bandwidth up lte'}
    />,
    <TextInput
      source={'specifications.bandwidthDown.lte'}
      label={'Bandwidth down lte'}
    />,
    <TextInput
      source={'specifications.bandwidthUp.gte'}
      label={'Bandwidth up gte'}
    />,
    <TextInput
      source={'specifications.bandwidthDown.gte'}
      label={'Bandwidth down gte'}
    />,
    <NumberInput
      source={'contractTerms.contractPeriod.lte'}
      label={'Contract period lte'}
    />,
    <NumberInput
      source={'contractTerms.serviceUptime.gte'}
      label={'Uptime gte'}
    />,
    <NumberInput
      source={'contractTerms.meanTimeToRepair.lte'}
      label={'MTTR lte'}
    />,
    <NumberInput source={'valueNrc.lte'} label={'Value NRC lte'} />,
    <NumberInput source={'valueMrc.lte'} label={'Value MRC lte'} />,
    <ReferenceArrayInput
      label="Vendors in"
      source="vendorId.in"
      reference="vendors/list"
    >
      <AutocompleteArrayInput
        label={'Vendors in'}
        optionText="name"
        sx={{ width: 150 }}
      />
    </ReferenceArrayInput>,
    <ReferenceArrayInput
      label="Vendors not in"
      source="vendorId.not"
      reference="vendors/list"
    >
      <AutocompleteArrayInput
        label={'Vendors not in'}
        optionText="name"
        sx={{ width: 150 }}
      />
    </ReferenceArrayInput>,
    <BooleanInput source="isActive" defaultValue={true} />,
  ]
}
