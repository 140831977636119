import React from 'react'
import {
  TextField,
  DateField,
  Show,
  ShowProps,
  TabbedShowLayout,
  Tab,
  BooleanField,
} from 'react-admin'
import { PageTitle } from '../../atoms/PageTitle'
import { HistoryDisplayTab } from '../../templates/HistoryDisplayTab'
import { ReferenceChip } from '../../molecules/ReferenceChip'

/**
 * Component to show a monitor alert rule.
 * @param props The Show component props
 * @see https://marmelab.com/react-admin/Show.html
 */
export const MonitorAlertRuleShow: React.FC<ShowProps> = (props) => {
  return (
    <Show {...props} title={<PageTitle prefix="Monitor Alert Rule" />}>
      <TabbedShowLayout {...props}>
        <Tab label="summary">
          <TextField source="name" fullWidth />
          <ReferenceChip
            referenceSource="monitorProbeTypeId"
            tooltipSource="name"
            source="name"
            chipSource="name"
            reference="monitorProbeTypes"
            emptyText="N/A"
            link="show"
          />
          <TextField source="state" />
          <TextField source="summary" />
          <TextField source="description" />
          <TextField source="severity" />
          <TextField source="dataSource" />
          <BooleanField source="isMuted" />
          <BooleanField source="isCustom" />
          <DateField source="createdAt" showTime />
          <DateField source="updatedAt" showTime />
        </Tab>
        <Tab label="history">
          <HistoryDisplayTab />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
