import merge from 'lodash/merge'
import { RaThemeOptions } from 'react-admin'
import { defaultLightTheme } from '@react-admin/ra-enterprise'
import { baseTheme } from './baseTheme.styles'
import { green, orange, red } from '@mui/material/colors'

/**
 * The light theme styles.
 * @exports lightTheme
 * @see https://mui.com/customization/default-theme/
 * @see https://mui.com/customization/palette/
 * @see https://mui.com/system/palette/#color
 */
export const lightTheme: RaThemeOptions = merge(
  {},
  baseTheme,
  defaultLightTheme,
  {
    palette: {
      mode: 'light',
      primary: {
        main: '#FFA40F',
      },
      secondary: {
        main: '#5068AD',
      },
      warning: {
        main: orange[300],
      },
      info: {
        main: 'rgba(80, 104, 173, 0.8)',
      },
      success: {
        main: green[300],
      },
      error: {
        main: red[300],
      },
    },
  }
)
