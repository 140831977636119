import React from 'react'
import {
  FormDataConsumer,
  TextInput,
  BooleanInput,
  required,
} from 'react-admin'
import { ReferenceAutocomplete } from '../../molecules/ReferenceAutocomplete'
import { Typography } from '@mui/material'

/**
 * Displays the form for creating a new user.
 * @returns {React.ReactElement}
 * @example <OnboardingUserForm />
 * @see https://marmelab.com/react-admin/Inputs.html
 */
export const OnboardingUserForm: React.FC = () => {
  return (
    <FormDataConsumer>
      {({ formData }) =>
        ['user', 'full'].includes(formData?.type) ? (
          <>
            <TextInput
              source="user.name"
              label="User name"
              validate={[required()]}
              fullWidth
            />
            <TextInput
              source="user.email"
              label="User e-mail"
              disabled
              validate={[required()]}
              fullWidth
            />
            <ReferenceAutocomplete
              required
              source="user.role"
              reference="userRoles/list"
              filter={{ isEmployee: false, isSuperAdmin: false }}
              sort={{ field: 'level', order: 'ASC' }}
            />
            <BooleanInput
              source="user.isActive"
              defaultValue={true}
              fullWidth
            />
          </>
        ) : (
          <Typography variant="body2" p={2}>
            This type of on-boarding flow does not involve creating a new user.
          </Typography>
        )
      }
    </FormDataConsumer>
  )
}
