import React from 'react'
import { Breadcrumbs as RaBreadcrumbs, Grid } from '@mui/material'
import { TextField, Link, FunctionField, ReferenceField } from 'react-admin'
import RequestsFlowIcon from '@mui/icons-material/RouteOutlined'
import LocationIcon from '@mui/icons-material/LocationOn'

/** Expects `Request` as the RecordContext. */
export const Breadcrumbs: React.FC = () => (
  <RaBreadcrumbs aria-label="breadcrumb">
    <Link to="/quote-requests" title="Requests" color="inherit">
      <RequestsFlowIcon
        sx={{ fontSize: '16px', display: 'flex', alignItems: 'center' }}
      />
    </Link>
    <ReferenceField source="requestId" reference="requests" link={false}>
      <FunctionField
        render={(request: { id: string; requestNo: string }) => (
          <ReferenceField
            source="serviceCategoryId"
            reference="serviceCategories"
            link={false}
          >
            <FunctionField
              source="name"
              render={(serviceCategory: { key: string }) => (
                <Link
                  to={`/quote-requests/${request?.id ?? ''}`}
                  title="Request"
                  variant="body1"
                  color="inherit"
                >
                  {serviceCategory?.key} Request {request?.requestNo}
                </Link>
              )}
            />
          </ReferenceField>
        )}
      />
    </ReferenceField>
    <Grid
      container
      alignItems="center"
      alignContent="center"
      spacing={0.5}
      color="text.primary"
    >
      <Grid item>
        <LocationIcon
          sx={{ fontSize: '16px', display: 'flex', alignItems: 'center' }}
        />
      </Grid>
      <Grid item>
        <TextField source="sites.0.address" variant="body1" />
      </Grid>
    </Grid>
  </RaBreadcrumbs>
)
