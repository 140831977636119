import React from 'react'
import {
  Show,
  ShowProps,
  Tab,
  TabbedShowLayout,
  TextField,
  BooleanField,
  DateField,
} from 'react-admin'
import { PageTitle } from '../../atoms/PageTitle'
import { MarkdownField } from '@react-admin/ra-markdown'
import { MutationsList } from '../../organisms/MutationsList'
import { ReferenceText } from '../../molecules/ReferenceText'

/**
 * Page to show a template.
 * @param {ShowProps} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 * @example
 * return (
 * <TemplateShow {...props} />
 * )
 */
export const TemplateShow: React.FC<ShowProps> = (props) => {
  return (
    <Show {...props} title={<PageTitle prefix="Template" />}>
      <TabbedShowLayout {...props}>
        <Tab label="general">
          <TextField source="id" />
          <TextField source="name" />
          <TextField source="key" />
          <TextField source="type" />
          <ReferenceText
            reference="documentCategories/list"
            source="categoryId"
            emptyText="N/A"
            link={false}
          />
          <TextField source="view" emptyText="content" />
          <BooleanField source="isDefault" />
          <DateField source="createdAt" />
          <DateField source="updatedAt" />
        </Tab>
        <Tab label="content">
          <MarkdownField source="content" />
        </Tab>
        <Tab label="history">
          <MutationsList showActor type="history" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
