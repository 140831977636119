import React from 'react'
import { Typography } from '@mui/material'
import {
  SimpleShowLayout,
  TextField,
  WrapperField,
  ReferenceField,
} from 'react-admin'
import { EllipsedTextField } from '../../atoms/EllipsedField'
import { CurrencyField } from '../../atoms/CurrencyField'
import { TextPreSuffixField } from '../../atoms/TextPreSuffixField'
import { RaRecord } from 'react-admin'
import { useStyles } from './QuoteItemDisplay.styles'

/**
 * Component that displays a quote item.
 * @param {RaRecord} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 * @example
 * import { QuoteItemDisplay } from './QuoteItemDisplay.component.tsx'
 * <QuoteItemDisplay />
 * @todo create a common component for the display grid which can be reused
 * in all the display components.
 */
export const QuoteItemDisplay: React.FC<RaRecord> = (props) => {
  const styles = useStyles()

  return (
    <>
      <SimpleShowLayout sx={{ m: 0, p: 0 }}>
        <TextField
          source={'description'}
          label={'Description on quote'}
          fullWidth
        />
      </SimpleShowLayout>
      <Typography variant={'subtitle1'} sx={{ pt: 2 }}>
        Site
      </Typography>
      <SimpleShowLayout sx={styles} record={props.site}>
        <TextField source={'streetName'} />
        <WrapperField label={'Street number'}>
          <TextField source={'streetNumber'} />
          &nbsp;
          <TextField source={'streetNumberSuffix'} />
          &nbsp;
          <TextField source={'streetNumberRoom'} />
        </WrapperField>
        <TextField source={'city'} />
        <TextField source={'postalCode'} />
        <TextField source={'countryCode'} />
      </SimpleShowLayout>
      <Typography variant={'subtitle1'} sx={{ pt: 2 }}>
        Specifications
      </Typography>
      <SimpleShowLayout sx={styles} record={props.offer}>
        <TextField source={'offerNo'} />
        <TextField source={'type'} />
        <EllipsedTextField source={'vendor.name'} />
        <EllipsedTextField source={'product.name'} label={'Product name'} />
        <TextPreSuffixField
          source={'specifications.leadTime'}
          suffix={' hours'}
          label={'Lead time'}
        />
        <TextPreSuffixField
          source={'specifications.meanTimeToRepair'}
          suffix={' hours'}
          label={'Mean time to repair'}
        />
        <TextPreSuffixField
          source={'specifications.bandwidthDown'}
          label={'Bandwidth (down)'}
        />
        <TextPreSuffixField
          source={'specifications.bandwidthUp'}
          label={'Bandwidth (up)'}
        />
        <TextPreSuffixField
          source={'specifications.contentionRatio'}
          prefix={'1:'}
          label={'Contention ratio'}
        />
      </SimpleShowLayout>
      <Typography variant={'subtitle1'} sx={{ pt: 2 }}>
        Pricing
      </Typography>
      <SimpleShowLayout sx={styles} record={props.offer}>
        <CurrencyField
          currency={'productCurrency'}
          source={'productNrc'}
          label={'Product NRC'}
        />
        <CurrencyField
          currency={'productCurrency'}
          source={'productMrc'}
          label={'Product MRC'}
        />
        <CurrencyField
          currency={'vendorCurrency'}
          source={'vendorNrc'}
          label={'Vendor NRC'}
        />
        <CurrencyField
          currency={'vendorCurrency'}
          source={'vendorMrc'}
          label={'Vendor MRC'}
        />
        <ReferenceField
          source={'productCurrency'}
          reference="currencies/list"
          link={false}
        >
          <TextField label={'Product currency'} source={'name'} />
        </ReferenceField>
        <ReferenceField
          source={'vendorCurrency'}
          reference="currencies/list"
          link={false}
        >
          <TextField label={'Vendor currency'} source={'name'} />
        </ReferenceField>
        <TextPreSuffixField
          source={'productVat'}
          suffix={'%'}
          label={'Product VAT'}
        />
        <TextPreSuffixField
          source={'vendorVat'}
          suffix={'%'}
          label={'Vendor VAT'}
        />
        <CurrencyField
          currency={'EUR'}
          source={'value'}
          label={'Margin value NRC'}
          record={{ value: props.marginValueNrc }}
        />
        <CurrencyField
          currency={'EUR'}
          source={'value'}
          label={'Margin value MRC'}
          record={{ value: props.marginValueMrc }}
        />
      </SimpleShowLayout>
    </>
  )
}
