import React from 'react'
import {
  TextField,
  ArrayField,
  useRecordContext,
  Datagrid,
  RaRecord,
  FunctionField,
  ReferenceArrayField,
  ChipField,
  SingleFieldList,
} from 'react-admin'
import { IProductSearchQueryField } from './ProductSearchQueryField.types'
import { useParsedQuery } from './ProductSearchQueryField.hooks'
import get from 'lodash/get'
import { Grid, Tooltip, Typography } from '@mui/material'

/**
 * Product Search Query Field
 * @returns { React.FC }
 * @example
 * import { ProductSearchQueryField } from './ProductSearchQueryField'
 * <ProductSearchQueryField source="preferences" />
 */
export const ProductSearchQueryField: React.FC<IProductSearchQueryField> = ({
  source,
}) => {
  const record = useRecordContext()
  const query = get(record, source, {})
  const parsed = useParsedQuery(query)

  return (
    <ArrayField record={{ parsed }} source="parsed">
      <Datagrid
        bulkActionButtons={false}
        empty={
          <Typography variant="body2" sx={{ paddingX: 2 }}>
            None
          </Typography>
        }
        sx={{
          '& .MuiTableCell-head': {
            display: 'none',
          },
          '& .MuiTableCell-root': {
            border: 'none',
          },
        }}
      >
        <TextField source="property" sortable={false} label={false} />
        <FunctionField
          label={false}
          render={({
            resource,
            comparator,
            comparatorSymbol,
            source,
          }: RaRecord) =>
            resource && source === 'id' ? (
              <ReferenceArrayField
                source="value"
                resource={resource}
                reference={resource}
                emptyText={
                  (
                    <Typography variant="body2" fontWeight="bold">
                      N/A
                    </Typography>
                  ) as unknown as string
                }
              >
                <Tooltip title={comparator}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item p={1}>
                      <Typography variant="body2" fontFamily="monospace">
                        {comparatorSymbol}
                      </Typography>
                    </Grid>
                    <Grid item p={1} sx={{ maxWidth: 350 }}>
                      <SingleFieldList linkType={false}>
                        <ChipField source="name" />
                      </SingleFieldList>
                    </Grid>
                  </Grid>
                </Tooltip>
              </ReferenceArrayField>
            ) : (
              <FunctionField
                sortable={false}
                render={({ value, comparator, comparatorSymbol }: RaRecord) => (
                  <Tooltip title={`${comparator} ${value}`}>
                    <Grid container spacing={1}>
                      <Grid item>
                        <Typography variant="body2" fontFamily="monospace">
                          {comparatorSymbol}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body2" fontWeight="bold">
                          {value}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Tooltip>
                )}
              />
            )
          }
        />
      </Datagrid>
    </ArrayField>
  )
}
