import {
  AutocompleteArrayInput,
  ReferenceInput,
  SelectArrayInput,
  TextInput,
} from 'react-admin'
import { ReferenceAutocomplete } from '../../molecules/ReferenceAutocomplete'

/**
 * List of inputs that can be used to filter the quote list.
 * @returns {JSX.Element[]} The rendered component.
 * @example
 * const filters = useQuoteFilters()
 * @see {@link https://marmelab.com/react-admin/List.html#the-filter-button|Filter Button}
 */
export const useQuoteFilters = () => {
  return [
    <TextInput label="Search" source="term" alwaysOn />,
    <SelectArrayInput
      label="State"
      source="state"
      alwaysOn
      choices={[
        { name: 'Offered', id: 'offered' },
        { name: 'Accepted', id: 'accepted' },
        { name: 'Cancelled', id: 'cancelled' },
      ]}
    />,
    <ReferenceAutocomplete
      label="Client"
      source="clientId"
      reference="clients/list"
    />,
    <ReferenceInput source="projectId" reference="projects/list">
      <AutocompleteArrayInput
        label={'Project'}
        optionText="name"
        sx={{ minWidth: 250 }}
      />
    </ReferenceInput>,
  ]
}
