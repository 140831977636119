import React from 'react'
import {
  Create,
  CreateProps,
  FileField,
  FileInput,
  SelectInput,
  SimpleForm,
  required,
} from 'react-admin'
import { ReferenceAutocomplete } from '../../molecules/ReferenceAutocomplete'
import { useChoices } from './PriceBook.hooks'

/**
 * Page to create a new price book.
 * @property {CreateProps} props The properties passed to the component.
 * @returns {React.FC<CreateProps>} A react functional component.
 * @see https://marmelab.com/react-admin/Create.html
 * @see https://marmelab.com/react-admin/Inputs.html
 */
export const PriceBookCreate: React.FC<CreateProps> = (props) => {
  const choices = useChoices()

  return (
    <Create {...props} redirect="show">
      <SimpleForm>
        <SelectInput
          source="state"
          defaultValue="draft"
          required
          choices={choices}
          fullWidth
        />
        <ReferenceAutocomplete
          required
          label="Vendor"
          source="vendorId"
          reference="vendors/list"
          fullWidth
        />
        <ReferenceAutocomplete
          label="Country"
          source={'countryCode'}
          reference="countries/list"
          fullWidth
        />
        <ReferenceAutocomplete
          label="Currency"
          source="currencyCode"
          reference="currencies/list"
          fullWidth
        />
        <FileInput
          source="file"
          label="JSON file"
          multiple={false}
          validate={required()}
        >
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  )
}
