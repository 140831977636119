import { MonitorNotificationContactCreate } from './Create.component'
import { MonitorNotificationContactEdit } from './Edit.component'
import { MonitorNotificationContactList } from './List.component'
import { MonitorNotificationContactShow } from './Show.component'

export default {
  list: MonitorNotificationContactList,
  show: MonitorNotificationContactShow,
  create: MonitorNotificationContactCreate,
  edit: MonitorNotificationContactEdit,
}
