import React from 'react'
import {
  Create,
  CreateProps,
  SimpleForm,
  BooleanInput,
  required,
} from 'react-admin'
import { ReferenceAutocomplete } from '../../molecules/ReferenceAutocomplete'
import { MarkdownInput } from '@react-admin/ra-markdown'
import { RelatedInputs } from '../../molecules/RelatedInputs'

/**
 * Page for creating a new note.
 * @property {CreateProps} props
 * @returns {React.FC<CreateProps>}
 * @example
 * return <NoteCreate {...props} />
 * @see https://marmelab.com/react-admin/Create.html
 */
export const NoteCreate: React.FC<CreateProps> = (props) => {
  return (
    <Create {...props} redirect={'list'}>
      <SimpleForm>
        <ReferenceAutocomplete
          required
          label="Client"
          source="clientId"
          reference="clients/list"
        />
        <RelatedInputs />
        <BooleanInput source="isInternal" defaultValue={true} />
        <MarkdownInput source="content" validate={required()} />
      </SimpleForm>
    </Create>
  )
}
