import React from 'react'
import {
  Create,
  CreateProps,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin'
import { ReferenceAutocomplete } from '../../molecules/ReferenceAutocomplete'

/**
 * Page to create a Service Type.
 * @property { CreateProps } props The properties of the Create component.
 * @returns { React.ReactNode } The Service Type Create page.
 * @example
 * import { ServiceTypeCreate } from './ServiceTypeCreate';
 * <ServiceTypeCreate {...props} />
 * @see https://marmelab.com/react-admin/Create.html
 * @see https://marmelab.com/react-admin/Fields.html
 */
export const ServiceTypeCreate: React.FC<CreateProps> = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="key" validate={required()} fullWidth />
      <TextInput source="name" validate={required()} fullWidth />
      <TextInput source="description" fullWidth />
      <ReferenceAutocomplete
        required
        fullWidth
        label="Service category"
        source="serviceCategoryId"
        reference="serviceCategories"
      />
    </SimpleForm>
  </Create>
)
