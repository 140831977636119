/**
 * Select options for the margin book
 * @returns {Array} Array of options
 * @example
 * const options = useChoices()
 */
export const useChoices = () => {
  return [
    { id: 'default', name: 'Default' },
    { id: 'offset', name: 'Offset' },
    { id: 'custom', name: 'Custom' },
  ]
}
