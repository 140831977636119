import React from 'react'
import {
  IconMenu,
  MenuItemList,
  MenuItemNode,
} from '@react-admin/ra-navigation'
import { CardContent } from '@mui/material'
import ServicesIcon from '@mui/icons-material/WifiTetheringOutlined'

/**
 * This is a custom menu item that will be added to the main menu.
 * It will display a list of menu items when clicked.
 * @returns {JSX.Element}
 */
export const ServicesMenuItem: React.FC = () => {
  return (
    <IconMenu.Item name="services" label="Services" icon={<ServicesIcon />}>
      <CardContent>
        <MenuItemList>
          <MenuItemNode name="services" to={'/services'} label="Services" />
          <MenuItemNode
            name="serviceOrders"
            to={'/serviceOrders'}
            label="Orders"
          />
          <MenuItemNode
            name="serviceSites"
            to={'/serviceSites'}
            label="Sites"
          />
          <MenuItemNode
            name="servicePorts"
            to={'/servicePorts'}
            label="Ports"
          />
          <MenuItemNode
            name="serviceStatistics"
            to={'/serviceStatistics'}
            label="Statistics"
          />
        </MenuItemList>
      </CardContent>
    </IconMenu.Item>
  )
}
