import React from 'react'
import { Edit, EditProps, TabbedForm, FormTab } from 'react-admin'
import { VendorContractForm } from '../../organisms/VendorContractForm'
import { DocumentModalInput } from '../../organisms/DocumentModalInput'
import { ReferenceManyFormTab } from '../../atoms/FormTab'
import { DocumentRow } from '../../organisms/DocumentRow'
import { MetaListInput } from '../../organisms/MetaList'

/**
 * Page to edit a Vendor Contract.
 * @param props
 * @returns {JSX.Element}
 * @example
 * import { VendorContractEdit } from './VendorContractEdit'
 * <VendorContractEdit {...props} />
 */
export const VendorContractEdit: React.FC<EditProps> = (props) => {
  return (
    <Edit {...props} mutationMode="undoable">
      <TabbedForm>
        <FormTab label="general" key="general">
          <VendorContractForm />
        </FormTab>
        <ReferenceManyFormTab
          reference="documents"
          target="relatedId"
          label="documents"
        >
          <DocumentRow />
          <DocumentModalInput reference="vendorContracts" />
        </ReferenceManyFormTab>
        <FormTab label={'meta'} key="meta">
          <MetaListInput source="meta" />
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}
