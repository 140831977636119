/**
 * If the note is internal, it will be styled with orange dashed border.
 * If the note is external, it will be styled with grey dashed border.
 * @param isInternal - boolean
 * @returns object
 */
export const styles = (isInternal: boolean) => {
  const basicStyle = { borderRadius: '4px', margin: '0.5em' }

  if (isInternal) {
    return {
      ...basicStyle,
      color: 'darkorange',
      border: '1px dashed darkorange',
    }
  }

  return {
    ...basicStyle,
    color: 'darkgrey',
    border: '1px dashed darkgrey',
  }
}
