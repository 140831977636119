import React, { useEffect, useMemo } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Divider,
  InputAdornment,
} from '@mui/material'
import {
  SimpleForm,
  SimpleShowLayout,
  TextField,
  NumberInput,
  useRecordContext,
  useRefresh,
  useNotify,
  useDataProvider,
  ChipField,
  ReferenceField,
} from 'react-admin'
import ExpandAccordionIcon from '@mui/icons-material/ExpandMore'
import DoneIcon from '@mui/icons-material/CheckBoxOutlined'
import ToDoIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { CurrencyField } from '../../atoms/CurrencyField'
import { ProductSalesMarginFormToolbar } from './ProductSalesMarginFormToolbar.component'

export const ProductSalesMarginAccordion: React.FC<{
  lastRecalculationRequestTimestamp?: number
}> = ({ lastRecalculationRequestTimestamp }) => {
  const record = useRecordContext()
  const refresh = useRefresh()
  const notify = useNotify()
  const dataProvider = useDataProvider()
  const [isLoading, setIsLoading] = React.useState(false)
  const [marginAbsoluteNrc, setMarginAbsoluteNrc] = React.useState()
  const [marginAbsoluteMrc, setMarginAbsoluteMrc] = React.useState()
  const [marginRelativeNrc, setMarginRelativeNrc] = React.useState()
  const [marginRelativeMrc, setMarginRelativeMrc] = React.useState()
  const [priceCalculation, setPriceCalculation] = React.useState()
  const [lastRecalculationTimestamp, setLastRecalculationTimestamp] =
    React.useState(lastRecalculationRequestTimestamp ?? Date.now())

  const handleConfirm = async () => {
    setIsLoading(true)

    try {
      const data = await dataProvider.customRequest(
        'requestProducts',
        `${record.id}/quote`,
        {
          data: { action: 'confirm', priceCalculation },
          method: 'PUT',
        }
      )
      if (data?.json?.data?.id) {
        notify('Margin has been confirmed')
        refresh()
      }
    } catch (error: any) {
      notify(error.message, { type: 'error' })
    }

    setIsLoading(false)
  }

  const handleSend = async () => {
    setIsLoading(true)

    try {
      const data = await dataProvider.customRequest(
        'requestProducts',
        `${record.id}/quote`,
        {
          data: { action: 'send' },
          method: 'PUT',
        }
      )
      if (data?.json?.data?.id) {
        notify('Quote has been sent')
        refresh()
      }
    } catch (error: any) {
      notify(error.message, { type: 'error' })
    }

    setIsLoading(false)
  }

  const recalculatePrice = React.useCallback(async () => {
    setIsLoading(true)

    try {
      let input: string | undefined

      if (
        marginAbsoluteMrc ||
        marginAbsoluteNrc ||
        marginRelativeMrc ||
        marginRelativeNrc
      ) {
        input = 'custom'
      }

      const result = await dataProvider.customRequest(
        'requestProducts',
        `${record.id}/recalculatePrice`,
        {
          data: {
            input,
            marginAbsoluteMrc,
            marginAbsoluteNrc,
            marginRelativeMrc,
            marginRelativeNrc,
          },
          method: 'PUT',
        }
      )
      if (result?.json?.data) {
        setPriceCalculation(result?.json?.data)
      }
    } catch (error: any) {
      notify(error.message, { type: 'error' })
      setPriceCalculation(undefined)
    }
    setIsLoading(false)
  }, [
    marginAbsoluteMrc,
    marginAbsoluteNrc,
    marginRelativeNrc,
    marginRelativeMrc,
    dataProvider,
    notify,
    record.id,
  ])

  const onChangeMarginRelativeMrc = (event: any) => {
    setMarginRelativeMrc(event.target.value)
    setMarginAbsoluteMrc(undefined)
  }

  const onChangeMarginRelativeNrc = (event: any) => {
    setMarginRelativeNrc(event.target.value)
    setMarginAbsoluteNrc(undefined)
  }

  const onChangeMarginAbsoluteMrc = (event: any) => {
    setMarginAbsoluteMrc(event.target.value)
    setMarginRelativeMrc(undefined)
  }

  const onChangeMarginAbsoluteNrc = (event: any) => {
    setMarginAbsoluteNrc(event.target.value)
    setMarginRelativeNrc(undefined)
  }

  React.useEffect(() => {
    if (
      lastRecalculationRequestTimestamp &&
      lastRecalculationRequestTimestamp > lastRecalculationTimestamp
    ) {
      setLastRecalculationTimestamp(lastRecalculationRequestTimestamp)
    }
  }, [lastRecalculationRequestTimestamp, lastRecalculationTimestamp])

  React.useEffect(() => {
    if (!record) return

    if (record.isQuoteConfirmed) {
      setPriceCalculation(record.quotes?.[0]?.items?.[0]?.priceCalculation)
    } else {
      recalculatePrice()
    }
  }, [record, recalculatePrice, lastRecalculationTimestamp])

  // combine both budgetary and firm into one
  const calculationData = {
    budgetary: record.priceCalculation,
    firm: priceCalculation,
    record,
  }

  const [isExpanded, setIsExpanded] = React.useState(false)

  useEffect(() => {
    if (record.isOfferConfirmed && !record?.isQuoteConfirmed) {
      setIsExpanded(true)
    }
  }, [record])

  const isDisabled = useMemo(() => {
    return (
      !record.isOfferConfirmed ||
      !record.requestService?.isReviewConfirmed ||
      record?.isQuoteConfirmed ||
      record?.isQuoteSent
    )
  }, [record])

  const toggleAccordion = () => {
    setIsExpanded(!isExpanded)
  }

  if (!record) return null

  return (
    <Accordion expanded={isExpanded} disableGutters>
      <AccordionSummary
        expandIcon={<ExpandAccordionIcon color="info" />}
        onClick={toggleAccordion}
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h6" alignItems="center" display="flex" gap={1}>
              {record.isQuoteConfirmed ? (
                <DoneIcon sx={{ mr: 1 }} />
              ) : (
                <ToDoIcon sx={{ mr: 1 }} />
              )}{' '}
              Sales: Confirm Margin
            </Typography>
          </Grid>
          <Grid item>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <Typography variant="subtitle2">Margin Book</Typography>
              </Grid>
              <Grid item>
                <ReferenceField
                  reference="marginBooks/list"
                  source="marginBookId"
                  record={calculationData.firm}
                >
                  <ChipField label={false} source="name" />
                </ReferenceField>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <SimpleForm
          record={calculationData}
          onSubmit={handleConfirm}
          toolbar={
            <ProductSalesMarginFormToolbar
              onConfirm={handleConfirm}
              onSend={handleSend}
              isLoading={isLoading}
            />
          }
          sx={{ padding: 0, '& legend': { display: 'none' } }}
        >
          <SimpleShowLayout sx={{ padding: 0 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} xl={6}>
                <TableContainer component={Box}>
                  <Table>
                    <TableBody
                      sx={{
                        'td, th': {
                          border: 0,
                          padding: '6px 16px',
                        },
                      }}
                    >
                      <TableRow>
                        <TableCell component="th" scope="row" width={'30%'}>
                          <Typography fontWeight="bold" color="GrayText">
                            NRC
                          </Typography>
                        </TableCell>
                        <TableCell width={'25%'}>
                          <Typography variant="body1" color="GrayText">
                            Budgetary
                          </Typography>
                        </TableCell>
                        <TableCell width={'45%'}>
                          <Typography variant="body1" color="GrayText">
                            Firm
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={4}>
                          <Divider />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Typography variant="body2" fontWeight="bold">
                            Purchase (local)
                          </Typography>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <CurrencyField
                            currency="budgetary.purchaseCurrency"
                            variant="body2"
                            source="budgetary.purchaseTotalNrc"
                            emptyText="N/A"
                          />
                        </TableCell>
                        <TableCell>
                          <CurrencyField
                            currency="firm.purchaseCurrency"
                            variant="body2"
                            source="firm.purchaseTotalNrc"
                            emptyText="N/A"
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Typography variant="body2" fontWeight="bold">
                            Purchase (EUR)
                          </Typography>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <CurrencyField
                            currency="budgetary.valueCurrency"
                            variant="body2"
                            source="budgetary.purchaseValueNrc"
                            emptyText="N/A"
                          />
                        </TableCell>
                        <TableCell>
                          <CurrencyField
                            currency="firm.valueCurrency"
                            variant="body2"
                            source="firm.purchaseValueNrc"
                            emptyText="N/A"
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={4}>
                          <Divider />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Typography variant="body2" fontWeight="bold">
                            Margin
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ width: 150 }}>
                          <CurrencyField
                            currency="EUR"
                            variant="body2"
                            source="budgetary.marginAbsoluteNrc"
                            emptyText="N/A"
                          />
                          &nbsp;
                          <Typography variant="caption">
                            (
                            <TextField
                              variant="caption"
                              source="budgetary.marginRelativeNrc"
                              emptyText="N/A"
                            />
                            %)
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <NumberInput
                            disabled={isDisabled}
                            helperText={false}
                            size="small"
                            source="firm.marginAbsoluteNrc"
                            label={false}
                            variant="outlined"
                            onChange={onChangeMarginAbsoluteNrc}
                            sx={{ mr: 1, width: '45%' }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end" sx={{ m: 0 }}>
                                  &euro;
                                </InputAdornment>
                              ),
                            }}
                          />
                          <NumberInput
                            disabled={isDisabled}
                            helperText={false}
                            size="small"
                            source="firm.marginRelativeNrc"
                            label={false}
                            variant="outlined"
                            fullWidth
                            onChange={onChangeMarginRelativeNrc}
                            sx={{ width: '45%' }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  %
                                </InputAdornment>
                              ),
                            }}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={4}>
                          <Divider />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Typography variant="body2" fontWeight="bold">
                            Sales (EUR)
                          </Typography>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <CurrencyField
                            currency="budgetary.valueCurrency"
                            variant="body2"
                            source="budgetary.salesValueNrc"
                            emptyText="N/A"
                          />
                        </TableCell>
                        <TableCell>
                          <CurrencyField
                            currency="budgetary.valueCurrency"
                            variant="body2"
                            source="firm.salesValueNrc"
                            emptyText="N/A"
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Typography variant="body2" fontWeight="bold">
                            Sales (local)
                          </Typography>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <CurrencyField
                            currency="budgetary.salesCurrency"
                            variant="body2"
                            source="budgetary.salesNrc"
                            emptyText="N/A"
                          />
                        </TableCell>
                        <TableCell>
                          <CurrencyField
                            currency="firm.salesCurrency"
                            variant="body2"
                            source="firm.salesNrc"
                            emptyText="N/A"
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12} xl={6}>
                <TableContainer component={Box}>
                  <Table>
                    <TableBody
                      sx={{
                        'td, th': {
                          border: 0,
                          padding: '6px 16px',
                        },
                      }}
                    >
                      <TableRow>
                        <TableCell component="th" scope="row" width={'30%'}>
                          <Typography fontWeight="bold" color="GrayText">
                            MRC
                          </Typography>
                        </TableCell>
                        <TableCell width={'25%'}>
                          <Typography variant="body1" color="GrayText">
                            Budgetary
                          </Typography>
                        </TableCell>
                        <TableCell width={'45%'}>
                          <Typography variant="body1" color="GrayText">
                            Firm
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={4}>
                          <Divider />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Typography variant="body2" fontWeight="bold">
                            Purchase (local)
                          </Typography>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <CurrencyField
                            currency="budgetary.purchaseCurrency"
                            variant="body2"
                            source="budgetary.purchaseTotalMrc"
                            emptyText="N/A"
                          />
                        </TableCell>
                        <TableCell>
                          <CurrencyField
                            currency="firm.purchaseCurrency"
                            variant="body2"
                            source="firm.purchaseTotalMrc"
                            emptyText="N/A"
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Typography variant="body2" fontWeight="bold">
                            Purchase (EUR)
                          </Typography>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <CurrencyField
                            currency="budgetary.valueCurrency"
                            variant="body2"
                            source="budgetary.purchaseValueMrc"
                            emptyText="N/A"
                          />
                        </TableCell>
                        <TableCell>
                          <CurrencyField
                            currency="firm.valueCurrency"
                            variant="body2"
                            source="firm.purchaseValueMrc"
                            emptyText="N/A"
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={4}>
                          <Divider />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Typography variant="body2" fontWeight="bold">
                            Margin
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ width: 150 }}>
                          <CurrencyField
                            currency="EUR"
                            variant="body2"
                            source="budgetary.marginAbsoluteMrc"
                            emptyText="N/A"
                          />
                          &nbsp;
                          <Typography variant="caption">
                            (
                            <TextField
                              variant="caption"
                              source="budgetary.marginRelativeMrc"
                              emptyText="N/A"
                            />
                            %)
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ width: 300 }}>
                          <NumberInput
                            disabled={isDisabled}
                            helperText={false}
                            size="small"
                            source="firm.marginAbsoluteMrc"
                            label={false}
                            variant="outlined"
                            fullWidth
                            onChange={onChangeMarginAbsoluteMrc}
                            sx={{ width: '45%', mr: 1 }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="start" sx={{ m: 0 }}>
                                  &euro;
                                </InputAdornment>
                              ),
                            }}
                          />
                          <NumberInput
                            disabled={isDisabled}
                            helperText={false}
                            size="small"
                            source="firm.marginRelativeMrc"
                            label={false}
                            variant="outlined"
                            fullWidth
                            onChange={onChangeMarginRelativeMrc}
                            sx={{ mr: 1, width: '45%' }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end" sx={{ m: 0 }}>
                                  %
                                </InputAdornment>
                              ),
                            }}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={4}>
                          <Divider />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Typography variant="body2" fontWeight="bold">
                            Sales (EUR)
                          </Typography>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <CurrencyField
                            currency="budgetary.valueCurrency"
                            variant="body2"
                            source="budgetary.salesValueMrc"
                            emptyText="N/A"
                          />
                        </TableCell>
                        <TableCell>
                          <CurrencyField
                            currency="budgetary.valueCurrency"
                            variant="body2"
                            source="firm.salesValueMrc"
                            emptyText="N/A"
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Typography variant="body2" fontWeight="bold">
                            Sales (local)
                          </Typography>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <CurrencyField
                            currency="budgetary.salesCurrency"
                            variant="body2"
                            source="budgetary.salesMrc"
                            emptyText="N/A"
                          />
                        </TableCell>
                        <TableCell>
                          <CurrencyField
                            currency="firm.salesCurrency"
                            variant="body2"
                            source="firm.salesMrc"
                            emptyText="N/A"
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12} textAlign={'center'}>
                <Typography variant="caption">
                  Note: sales prices exclude any taxes applicable between GNX
                  and customer
                </Typography>
              </Grid>
            </Grid>
          </SimpleShowLayout>
        </SimpleForm>
      </AccordionDetails>
    </Accordion>
  )
}
