import React from 'react'
import { TextField } from 'react-admin'
import { IFileSizeField } from './FileSizeField.types'
import { useValue } from './FileSizeField.hooks'

/**
 * React component that renders a file size field.
 * @param {IFileSizeField} props - The props for the component.
 * @returns {ReactElement} The rendered component.
 * @see IFileSizeField
 * @see TextField
 * @see useValue
 * @see https://marmelab.com/react-admin/Inputs.html#using-a-custom-input-component
 */

export const FileSizeField: React.FC<IFileSizeField> = (props) => {
  return (
    <TextField
      label="Size"
      source="contentSize"
      {...props}
      record={useValue()}
    />
  )
}
