import { flattenDeep, startCase } from 'lodash'
import {
  QueryComparator,
  QueryComparatorSymbol,
  ResourcesToReference,
} from './ProductSearchQueryField.types'

// convert a query into something this component understands
export const useParsedQuery = (query: any) => flattenDeep(recursiveParse(query))

// helper function to recursively parse query with comparators
const recursiveParse = (value: any, path?: string[]): any[] => {
  return Object.keys(value).map((key) => {
    if (['in', 'notIn', 'eq', 'lte', 'gte', 'lt', 'gt'].includes(key)) {
      let resource = undefined
      let source = path?.length ? path[path.length - 1] : undefined
      if (path && Object.keys(ResourcesToReference).includes(path[0])) {
        resource =
          ResourcesToReference[path[0] as keyof typeof ResourcesToReference]
        path = [path[0]]
      }
      const property = path?.map((v) => startCase(v)).join(': ')
      path = []
      return {
        comparator: QueryComparator[key as keyof typeof QueryComparator],
        comparatorSymbol:
          QueryComparatorSymbol[key as keyof typeof QueryComparatorSymbol],
        value: value[key],
        property,
        source,
        resource,
      }
    } else if (typeof value[key] === 'object') {
      return recursiveParse(value[key], [...(path || []), key])
    } else {
      const property = startCase([...(path || []), key].join(': '))
      return {
        comparator: QueryComparator.eq,
        value: value[key],
        property,
      }
    }
  })
}
