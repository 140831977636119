import React from 'react'
import {
  TextField,
  ReferenceManyField,
  SingleFieldList,
  DateField,
} from 'react-admin'
import { LabelledDatagrid } from '../../atoms/LabelledDatagrid'
import { TypeStateField } from '../../atoms/TypeStateField'
import { EllipsedTextField } from '../../atoms/EllipsedField'
import { ReferenceChip } from '../../molecules/ReferenceChip'

/**
 * This component is used in the Dashboard to display the newest tickets.
 * It is used in the "Full" variant, which displays on large screens.
 * @example
 * return <NewestTicketsFull />
 * @see {@link https://marmelab.com/react-admin/List.html#the-datagrid-component|Datagrid}
 * @see {@link https://marmelab.com/react-admin/Fields.html#referencefield|ReferenceField}
 * @see {@link https://marmelab.com/react-admin/Fields.html#singlefieldlist|SingleFieldList}
 */
export const NewestTicketsFull: React.FC = () => {
  return (
    <LabelledDatagrid optimized rowClick="show" bulkActionButtons={false}>
      <TextField
        source="ticketNo"
        sortable={false}
        emptyText={'N/A'}
        sx={{ display: 'block', maxWidth: 100 }}
      />
      <TypeStateField source="state" fullWidth />
      <EllipsedTextField source="summary" maxWidth={500} />
      <ReferenceManyField
        label={'Client(s)'}
        reference="clients"
        target="id"
        source="clientIds"
      >
        <SingleFieldList>
          <ReferenceChip
            referenceSource="id"
            tooltipSource="clientNo"
            source="Client"
            reference="clients"
            emptyText={'N/A'}
            link="show"
            chipSource="name"
          />
        </SingleFieldList>
      </ReferenceManyField>
      <DateField source="createdAt" showTime />
    </LabelledDatagrid>
  )
}
