import { IHandleButtonClick } from './DownloadFileButton.types'

/**
 * Handles the click event of the download file button.
 * Downloads the file associated with the current record.
 *
 * @param {IHandleButtonClick} options - The options for handling the button click.
 * @param {string} options.notify - The notification function.
 * @param {Record} options.record - The record.
 * @param {string} options.resource - The resource.
 * @param {DataProvider} options.dataProvider - The data provider.
 * @returns {Promise<void>} - A promise that resolves when the download starts.
 * @throws {Error} - An error.
 */
export const handleDownloadFileButtonClick = async ({
  notify,
  record,
  resource,
  dataProvider,
}: IHandleButtonClick) => {
  try {
    notify('Starting download')
    const id = record.id + '/download'
    const { data } = await dataProvider.getOne(resource, { id: id })
    if (data && data.content) {
      const link = document.createElement('a')
      link.href = `data:application/octet-stream;base64,${data.content}`
      link.download = data.name
      link.target = '_blank'
      link.click()
    }
  } catch (error: any) {
    notify(error.message, { type: 'error' })
  }
}
