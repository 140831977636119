import React from 'react'
import { Create, CreateProps } from 'react-admin'
import { TabbedForm, FormTab, TextInput } from 'react-admin'
import { SiteInput } from '../../organisms/SiteInput'
import { MetaListInput } from '../../organisms/MetaList'

/**
 * Page to create a new service site.
 * @property {CreateProps} props
 * @returns {JSX.Element}
 * @example
 * import { ServiceSiteCreate } from './ServiceSiteCreate'
 * <ServiceSiteCreate />
 * @see https://marmelab.com/react-admin/CreateEdit.html
 */
export const ServiceSiteCreate: React.FC<CreateProps> = (props) => {
  return (
    <Create {...props}>
      <TabbedForm>
        <FormTab label="general">
          <SiteInput />
        </FormTab>
        <FormTab label="notes">
          <TextInput multiline source="notes" defaultValue={''} fullWidth />
        </FormTab>
        <FormTab label="meta">
          <MetaListInput source="meta" />
        </FormTab>
      </TabbedForm>
    </Create>
  )
}
