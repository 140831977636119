import React from 'react'
import { IAppBar } from './AppBar.types'
import { AppBar as RAAppBar } from 'react-admin'
import { Search } from '@react-admin/ra-search'
import Toolbar from '@mui/material/Toolbar'
import Box from '@mui/material/Box'
import { AppBarTitle } from '../../atoms/AppBarTitle'
import { Logo } from '../../atoms/Logo'
import { NotesButton } from '../../atoms/NotesButton'

/**
 * A toolbar with the logo.
 * @returns {React.FC} The logo toolbar.
 * @example
 * import { LogoToolbar } from './LogoToolbar.component.tsx'
 */
const LogoToolbar: React.FC = React.forwardRef(() => {
  return (
    <Toolbar disableGutters>
      <Logo height={24} />
    </Toolbar>
  )
})

/**
 * The application bar.
 * @param {IAppBar} props The properties.
 * @returns {React.FC} The application bar.
 * @example
 * import { AppBar } from './AppBar.component.tsx'
 * <AppBar />
 * @see https://marmelab.com/react-admin/Theming.html
 * @see https://marmelab.com/react-admin/Theming.html#using-a-custom-theme
 * @see https://marmelab.com/react-admin/Theming.html#using-the-withstyles-hoc
 * @see https://material-ui.com/styles/api/#withstyles-options-higher-order-component
 */
export const AppBar: React.FC<IAppBar> = (props) => {
  return (
    <RAAppBar {...props}>
      <LogoToolbar />
      <AppBarTitle />
      <Box flex="1" />
      <Search />
      <NotesButton />
    </RAAppBar>
  )
}
