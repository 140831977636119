/**
 * Prefix source with prefix if prefix is defined
 * @param field
 * @param prefix
 */
export const prefixSource = (field: string, prefix?: string) => {
  if (prefix) {
    return prefix + '.' + field
  }
  return field
}
