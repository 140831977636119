import React from 'react'
import { IReferenceText } from './ReferenceText.types'
import { ReferenceField, TextField } from 'react-admin'
import { EllipsedTextField } from '../../atoms/EllipsedField'

/**
 * Text that references another resource.
 * @param {IReferenceText} props
 * @returns {React.FC<IReferenceText>}
 * @example
 * import { ReferenceText } from './ReferenceText.component'
 * <ReferenceText {...props} />
 * @see https://marmelab.com/react-admin/Fields.html#referencefield
 * @see https://marmelab.com/react-admin/Fields.html#textfield
 * @see https://marmelab.com/react-admin/Fields.html#referencefield
 * @see https://marmelab.com/react-admin/Fields.html#textfield
 */
export const ReferenceText: React.FC<IReferenceText> = (props) => {
  return (
    <ReferenceField {...props}>
      {props.ellipsed ? (
        <EllipsedTextField source="name" label={props.label} />
      ) : (
        <TextField source="name" label={props.label} />
      )}
    </ReferenceField>
  )
}
