import React from 'react'
import {
  SingleFieldList,
  TextField,
  useRecordContext,
  List,
  BooleanField,
  ChipField,
  ReferenceArrayField,
  Filter,
  TextInput,
  BooleanInput,
} from 'react-admin'
import { LabelledDatagrid } from '../../atoms/LabelledDatagrid'
import { TypeStateField } from '../../atoms/TypeStateField'
import { IRequestServicesList } from './RequestServicesList.types'
import { RequestServiceDetails } from '../../organisms/RequestServiceDetails'

/**
 * List of services for a request
 * @param {IRequestServicesList} props
 * @returns {React.FC<IRequestServicesList>}
 * @example
 * <RequestServicesList />
 */
export const RequestServicesList: React.FC<IRequestServicesList> = (props) => {
  const record = useRecordContext(props)

  return (
    <List
      title={' '}
      {...props}
      sort={props.sort ?? { field: 'createdAt', order: 'DESC' }}
      empty={false}
      resource={'requestServices'}
      perPage={props.perPage ?? 1000}
      filters={
        props.filters ?? (
          <Filter>
            <TextInput label="Search" source="term" alwaysOn />
            <BooleanInput source="isRequested" label="Only show requested" />
          </Filter>
        )
      }
      filter={props.filter ?? { requestId: record.id }}
      sx={
        props.sx ?? {
          '& .MuiToolbar-gutters': { display: 'none' },
          '& .MuiTablePagination-toolbar': { display: 'flex' },
        }
      }
      storeKey={`requestServices.${record.id}`}
      disableSyncWithLocation
    >
      <LabelledDatagrid
        rowClick="expand"
        optimized
        bulkActionButtons={false}
        expand={
          <RequestServiceDetails
            showSites={props.context !== 'requestSites'}
            source="id"
          />
        }
      >
        <TypeStateField source="diversityRole" fullWidth />
        <TextField source="name" emptyText="N/A" />
        <TextField source="reference" emptyText="N/A" />
        <TypeStateField source="state" />
        <BooleanField source="isRequested" />
        <ReferenceArrayField
          source="siteIds"
          reference="requestSites"
          label="Sites"
          sortable={false}
        >
          <SingleFieldList linkType={false}>
            <ChipField source="address" />
          </SingleFieldList>
        </ReferenceArrayField>
      </LabelledDatagrid>
    </List>
  )
}
