import { Exporter, downloadCSV } from 'react-admin'
import { IVendor } from './vendorExporter.types'
// @ts-ignore
import jsonExport from 'jsonexport/dist'

/**
 * Returns a list of vendors without the fields that are not needed for export
 * @param {IVendor[]} vendors - The list of vendors
 * @returns {IVendor[]} The list of vendors without the fields that are not needed for export
 * @example
 * vendorsList(vendors)
 * // returns [{id: 1, name: 'Vendor 1', isActive: true, createdAt: '2021-01-01', updatedAt: '2021-01-01'}]
 */
const vendorsList = (vendors: IVendor[]) => {
  return vendors.map((vendor: IVendor) => {
    const { id, name, isActive, createdAt, updatedAt, ...productForExport } =
      vendor
    return productForExport
  })
}

/**
 * Exports a list of vendors to a CSV file
 * @param {IVendor[]} vendors - The list of vendors
 * @returns {void}
 * @example
 * vendorExporter(vendors)
 * // downloads a CSV file with the list of vendors
 * // with the fields id, name, isActive, createdAt, updatedAt
 * // and the values of the vendors
 */
export const vendorExporter: Exporter = (vendors: IVendor[]) => {
  jsonExport(
    vendorsList,
    {
      headers: ['id', 'name', 'isActive, createdAt, updatedAt'],
      rowDelimiter: ';',
    },
    (err: any, csv: any) => {
      downloadCSV(csv, 'vendors')
    }
  )
}
