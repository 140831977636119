import React from 'react'
import { IFilterExportToolbar } from './FilterExportToolbar.types'
import { ExportButton, useListContext } from 'react-admin'
import { Toolbar } from '../Toolbar'

/**
 * Returns a toolbar with a filter and export buttons.
 * @param {IFilterExportToolbar} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 * @example
 * return (
 * <FilterExportToolbar />
 * )
 * @see {@link https://marmelab.com/react-admin/List.html#the-toolbar|Toolbar}
 * @see {@link https://marmelab.com/react-admin/List.html#the-filter-button|Filter Button}
 */
export const FilterExportToolbar: React.FC<IFilterExportToolbar> = ({
  maxResults,
  children,
  ...rest
}) => {
  const { total } = useListContext()

  return (
    <Toolbar {...rest} showFilter>
      {children}
      <ExportButton disabled={total === 0} maxResults={maxResults} />
    </Toolbar>
  )
}
