import { useRecordContext } from 'react-admin'
import { ICurrencySource, ICurrency } from './CurrencyField.types'
import get from 'lodash/get'

/**
 * Custom hook to format and display currency values
 *
 * @param source - The source of the currency value
 * @param currency - The currency to be used
 * @param divide - The value to divide the source by (optional)
 * @param suffix - The suffix to be added to the formatted value (optional)
 * @returns An object containing the formatted currency value
 * @example
 * const record = { price: 1000, currency: 'EUR' }
 * const { price } = useValue('price', 'currency')
 * console.log(price) // €1,000.00
 */
export const useValue = (
  source: ICurrencySource['source'],
  currency: ICurrency['currency'],
  divide?: number,
  suffix?: string
) => {
  const record = useRecordContext()
  const rawValue = get(record, source)

  if (!rawValue && rawValue !== 0) {
    return 'N/A'
  }

  let currencyValue = get(
    record,
    currency,
    currency.length === 3 ? currency : null
  )
  if (!currencyValue) {
    currencyValue = 'EUR'
  }

  const value = get(record, source) / (divide || 1)

  const text = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: currencyValue,
    maximumFractionDigits: 2,
  }).format(value)

  return text + (suffix || '')
}
