import { useRecordContext } from 'react-admin'
import {
  IContactModalInputReferences,
  IReference,
  IContactModalInput,
} from './ContactModalInput.types'

/**
 * Returns an object with ids based on the provided reference.
 * If there is no record for the reference, returns an empty object.
 * @param reference - The reference key used to access the record data.
 * @returns An object with ids based on the reference or an empty object if no record is found.
 */
export const useRecord = (
  reference: IContactModalInputReferences['reference'],
  resource: IContactModalInput['resource']
) => {
  const record = useRecordContext()
  let defaultRecord: IReference = {}

  if (resource === 'clientContacts' && record?.clientId) {
    defaultRecord.clientId = record.clientId
  }

  if (resource === 'vendorContacts' && record?.vendorId) {
    defaultRecord.vendorId = record.vendorId
  }

  if (reference === 'services') {
    defaultRecord.serviceIds = [record?.id]
  }

  return defaultRecord
}

/**
 * Returns a list of supported contact roles.
 * @returns A list of contact role choices.
 */
export const useRoleChoices = () => {
  return [
    { id: 'general', name: 'General' },
    { id: 'local', name: 'Local' },
    { id: 'billing', name: 'Billing' },
    { id: 'delivery', name: 'Delivery' },
    { id: 'technical', name: 'Technical' },
    { id: 'maintenance', name: 'Maintenance' },
    { id: 'escalation', name: 'Escalation' },
  ]
}
