import React from 'react'
import { IMutationsList } from './MutationsList.types'
import { useStyles } from './MutationsList.styles'
import { useFilter, useResource } from './MutationsList.hooks'
import { MutationDisplay } from './MutationDisplay.component'
import {
  ChipField,
  Datagrid,
  DateField,
  List,
  FunctionField,
  TextField,
  WrapperField,
  useRecordContext,
} from 'react-admin'

/**
 * Returns a list of mutations for a given entity type.
 * @returns {React.FC<IMutationsList>}
 * @see https://marmelab.com/react-admin/List.html
 */
export const MutationsList: React.FC<IMutationsList> = (props) => {
  const record = useRecordContext()
  const styles = useStyles()
  const filter = useFilter(props.type)
  const resource = useResource(props.type)

  if (!record) return null

  return (
    <List
      {...props}
      sort={{ field: 'id', order: 'DESC' }}
      empty={false}
      exporter={false}
      sx={styles}
      resource={resource}
      filter={filter}
      disableSyncWithLocation
      title={': History'}
    >
      <Datagrid
        bulkActionButtons={false}
        expand={<MutationDisplay />}
        rowClick="expand"
        size={'small'}
      >
        <DateField source="happenedAt" showTime />
        {props.showActor && (
          <WrapperField label="Actor">
            <TextField source="actor" /> (<TextField source="actorName" />)
          </WrapperField>
        )}
        <ChipField source="action" />
        {props.showEntity && <TextField source={'entityName'} />}
        {props.showEntity && (
          <TextField source={'entityId'} label={'Entity ID'} />
        )}
        <FunctionField
          label={'Changes'}
          render={(record: any) => (
            <ChipField
              record={{ value: Object.keys(record.changes).length }}
              source="value"
            />
          )}
        />
      </Datagrid>
    </List>
  )
}
