/**
 * Styles for ServiceHealthIndicatorField
 * @returns {React.CSSProperties} The style of the component.
 */
export const useStyle = () => {
  return {
    padding: '3px',
    display: 'inline',
  }
}
