import React from 'react'
import { IReferenceChip } from './ReferenceChip.types'
import { ReferenceField, ChipField } from 'react-admin'
import { FieldTooltip } from '../../atoms/FieldTooltip'

/**
 * Chip that refences another resource with a label.
 * @property {string} label - The label of the chip.
 * @property {string} source - The value to be added to the column header.
 * @returns The rendered component.
 */
const LabeledChip: React.FC<IReferenceChip> = (props) => {
  return <ChipField label={props.label} source={props.source} size={'small'} />
}

/**
 * Chip that refences another resource.
 * @property {string} referenceSource - The source of the reference.
 * @property {string} tooltipSource - The source of the tooltip.
 * @property {string} chipSource - The source of the chip.
 * @property {string} source - The value to be added to the column header.
 * @returns The rendered component.
 * @see IReferenceChip
 * @see https://marmelab.com/react-admin/Fields.html#referencefield
 * @see https://marmelab.com/react-admin/Fields.html#chipfield
 * @see https://mui.com/components/tooltips/#custom-child-element
 */
export const ReferenceChip: React.FC<IReferenceChip> = ({
  children,
  ...props
}) => {
  return (
    <ReferenceField {...props} source={props.referenceSource}>
      <FieldTooltip titleSource={props.tooltipSource || props.chipSource || ''}>
        {props.label ? (
          <LabeledChip {...props} source={props.chipSource} />
        ) : (
          <ChipField
            {...props}
            sx={
              props.sx ?? {
                '&.RaChipField-chip': {
                  width: props.fullWidth ? '100%' : 'auto',
                  fontWeight: 'bold',
                  maxWidth: 150,
                },
              }
            }
            source={props.chipSource}
            size={'small'}
          />
        )}
      </FieldTooltip>
    </ReferenceField>
  )
}
