import { useRecordContext } from 'react-admin'
import { IReferenceOptions, IDefaultRecord } from './DocumentModalInput.types'

/**
 * Hook to get the default record for the document modal input.
 * @param {IReferenceOptions['reference']} reference
 * @returns {IDefaultRecord}
 * @description
 * This hook is used to get the default record for the document modal input.
 * It is used to set the default values for the document modal input.
 */
export const useRecord = (reference: IReferenceOptions['reference']) => {
  const record = useRecordContext()
  let defaultRecord: IDefaultRecord = {}

  if (record?.serviceId) {
    defaultRecord.serviceId = record.serviceId
  }

  if (record?.clientId) {
    defaultRecord.clientId = record.clientId
  }

  if (reference === 'services') {
    defaultRecord.serviceId = record.id
  }

  if (reference === 'clients') {
    defaultRecord.clientId = record.id
  }

  if (reference === 'clientContracts') {
    defaultRecord.relatedName = 'ClientContract'
    defaultRecord.relatedId = record.id
    defaultRecord.documentNo = record.contractNo
  }

  if (reference === 'vendorContracts') {
    defaultRecord.relatedName = 'VendorContract'
    defaultRecord.relatedId = record.id
    defaultRecord.documentNo = record.contractNo
  }

  if (reference === 'serviceSites') {
    defaultRecord.relatedName = 'ServiceSite'
    defaultRecord.relatedId = record.id
    defaultRecord.documentNo = record.siteNo
  }

  if (reference === 'serviceOrders') {
    defaultRecord.relatedName = 'ServiceOrder'
    defaultRecord.relatedId = record.id
    defaultRecord.documentNo = record.orderNo
    defaultRecord.category = 'CSO'
  }

  return defaultRecord
}
