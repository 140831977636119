import React from 'react'
import { useGetList } from 'react-admin'
import { IconCard } from '../../molecules/IconCard/IconCard.component'
import ProvisioningServicesIcon from '@mui/icons-material/HourglassTopOutlined'

/**
 * A card with provisioning services metrics.
 * @example <ProvisioningServicesCard />
 */
export const ProvisioningServicesCard: React.FC = () => {
  const provisioningServices = useGetList('services/list', {
    filter: { state: 'provisioning' },
    pagination: { page: 1, perPage: 10 },
    sort: { field: 'createdAt', order: 'DESC' },
  })

  return (
    <IconCard
      icon={ProvisioningServicesIcon}
      to={'/services?filter=%7B"state"%3A%5B"provisioning"%5D%7D'}
      subtitle={`${provisioningServices?.total || 'N/A'}`}
      title={'Provisioning services'}
    />
  )
}
