import React from 'react'
import { Box, Card, CardContent } from '@mui/material'
import { ServiceCategoryFilter } from '../../atoms/ServiceCategoryFilter'
import { ServiceTypeFilter } from '../../atoms/ServiceTypeFilter'
import { AccessTechnologyFilter } from '../../atoms/AccessTechnologyFilter'
import { SavedQueriesList } from 'react-admin'
import { useStyles } from './ProductFilterSidebar.styles'
import { IProductFilterSidebar } from './ProductFilterSidebar.types'

/**
 * Sidebar containing filters for the product list.
 * @returns {JSX.Element} The rendered component.
 * @example
 * return (
 * <ProductFilterSidebar />
 * )
 * @see {@link https://marmelab.com/react-admin/List.html#the-filterlist-component|FilterList}
 * @see {@link https://marmelab.com/react-admin/List.html#the-filterlistitem-component|FilterListItem}
 */
export const ProductFilterSidebar: React.FC<IProductFilterSidebar> = (
  props
) => {
  const styles = useStyles()

  return (
    <Box sx={styles}>
      <Card>
        <CardContent sx={{ paddingTop: 0 }}>
          {props?.serviceCategoryFilter === false ? null : (
            <ServiceCategoryFilter />
          )}
          {props?.serviceTypeFilter === false ? null : <ServiceTypeFilter />}
          {props?.accessTechnologyFilter === false ? null : (
            <AccessTechnologyFilter />
          )}
          <SavedQueriesList />
        </CardContent>
      </Card>
    </Box>
  )
}
