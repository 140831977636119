import React from 'react'
import {
  ReferenceManyField,
  TextField,
  ShowButton,
  Pagination,
  BooleanField,
} from 'react-admin'
import { LabelledDatagrid } from '../../atoms/LabelledDatagrid'

import { ICoverageAddressList } from './CoverageAddressList.types'

/**
 * List of coverage addresses, used in related context
 * @returns {React.FC}
 * @example
 * import { CoverageAddressList } from './CoverageAddressList'
 * <CoverageAddressList />
 * @see https://marmelab.com/react-admin/Fields.html
 */
export const CoverageAddressList: React.FC<ICoverageAddressList> = (props) => {
  return (
    <ReferenceManyField
      {...props}
      reference="coverageAddresses"
      target={props.target}
      label={props.label || 'Coverage addresses'}
      pagination={<Pagination />}
      sort={props.sort || { field: 'createdAt', order: 'DESC' }}
    >
      <LabelledDatagrid bulkActionButtons={false}>
        <TextField source="name" />
        <TextField source="availability" emptyText="N/A" />
        <BooleanField source="isActive" />
        <ShowButton />
      </LabelledDatagrid>
    </ReferenceManyField>
  )
}
