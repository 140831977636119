import React from 'react'
import {
  TextField,
  DateField,
  Show,
  ShowProps,
  TabbedShowLayout,
  Tab,
} from 'react-admin'
import { MutationsList } from '../../organisms/MutationsList'
import { PageTitle } from '../../../components/atoms/PageTitle'

/**
 * Show the access technology details of a single row.
 * @property {ShowProps} props
 * @returns {React.FC<ShowProps>}
 * @example
 * import {AccessTechnologyShow} from './AccessTechnology'
 * return <AccessTechnologyShow {...props} />
 * @see https://marmelab.com/react-admin/Show.html
 * @see https://marmelab.com/react-admin/Fields.html
 * @see https://marmelab.com/react-admin/TabbedShowLayout.html
 */
export const AccessTechnologyShow: React.FC<ShowProps> = (props) => {
  return (
    <Show {...props} title={<PageTitle prefix="Access Technology" />}>
      <TabbedShowLayout {...props}>
        <Tab label="summary">
          <TextField source="name" />
          <TextField source="key" emptyText={'N/A'} />
          <TextField source="description" emptyText={'N/A'} />
          <DateField source="createdAt" showTime />
          <DateField source="updatedAt" showTime />
        </Tab>
        <Tab label={'history'}>
          <MutationsList showActor type="history" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
