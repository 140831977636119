import { TemplateCreate } from './Create.component'
import { TemplateEdit } from './Edit.component'
import { TemplateList } from './List.component'
import { TemplateShow } from './Show.component'

export default {
  list: TemplateList,
  show: TemplateShow,
  edit: TemplateEdit,
  create: TemplateCreate,
}
