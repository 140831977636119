import get from 'lodash/get'
import { useRecordContext } from 'react-admin'

/**
 * Get the overall health status of the service.
 * @returns {string} The overall health status of the service.
 */
export const useStatus = (source: string) => {
  const record = useRecordContext()
  const health = get(record, source)
  if (typeof health === 'string') return health
  if (typeof health === 'object' && health?.status) return health?.status
}

/**
 * Get the state of the monitor target/probe.
 * @returns {string} The state of the monitor target/probe.
 */
export const useState = (source: string) => {
  const record = useRecordContext()
  const health = get(record, source)
  if (typeof health === 'string') return null
  if (typeof health === 'object' && health?.state) return health.state
}

/**
 * Get the indicator of the service.
 * @returns {string} The indicator of the service.
 */
export const useIndicator = (source: string = 'health') => {
  const status = useStatus(source)

  let icon = '⚪'
  let text = 'Unmonitored'

  switch (status) {
    case 'ok':
      icon = '🟢'
      text = 'OK'
      break
    case 'error':
      icon = '🔴'
      text = 'Error'
      break
    case 'degraded':
      icon = '🟡'
      text = 'Degraded'
      break
  }

  return { icon, text }
}
