import React from 'react'
import {
  TextField,
  DateField,
  Show,
  ShowProps,
  TabbedShowLayout,
  Tab,
} from 'react-admin'
import { PageTitle } from '../../atoms/PageTitle'
import { HistoryDisplayTab } from '../../templates/HistoryDisplayTab'
import { MetaListDisplay } from '../../organisms/MetaList'

/**
 * Component to show a monitor probe type.
 * @param props The Show component props
 * @see https://marmelab.com/react-admin/Show.html
 */
export const MonitorProbeTypeShow: React.FC<ShowProps> = (props) => {
  return (
    <Show {...props} title={<PageTitle prefix="Probe Type" />}>
      <TabbedShowLayout {...props}>
        <Tab label="summary">
          <TextField source="name" />
          <TextField source="checkInterval" />
          <DateField source="createdAt" showTime />
          <DateField source="updatedAt" showTime />
        </Tab>
        <Tab label="config" key="config">
          <MetaListDisplay source="config" />
          <small>ℹ️ This is the config for this probe type itself</small>
        </Tab>
        <Tab label="configSchema" key="configSchema">
          <MetaListDisplay source="configSchema" />
          <small>
            ℹ️ This is the default config for all probes of this type
          </small>
        </Tab>
        <Tab label="history">
          <HistoryDisplayTab />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
