import { Exporter, downloadCSV } from 'react-admin'
import { IProduct } from './productExporter.types'
// @ts-ignore
import jsonExport from 'jsonexport/dist'

/**
 * Returns a CSV file with the products data.
 * @param {IProduct[]} products - The products data.
 * @returns {IProduct[]} The products data.
 * @exports productsList
 * @see https://marmelab.com/react-admin/DataProviders.html#fetching-the-data-yourself
 * @see https://www.npmjs.com/package/jsonexport
 * @see https://www.npmjs.com/package/jsonexport#options
 */
const productsList = (products: IProduct[]) => {
  return products?.map((product: IProduct) => {
    const {
      name,
      code,
      country,
      coverageZone,
      vendor,
      serviceType,
      serviceCategory,
      accessTechnology,
      specifications,
      contractTerms,
      priceCalculation,
      ...productDetails
    } = product

    const productForExport: Record<string, unknown> = {
      name,
      code,
      country: {
        name: country?.name,
        continent: country?.continent,
      },
      coverageZone: {
        name: coverageZone?.name,
        type: coverageZone?.type,
      },
      vendor: { name: vendor.name },
      serviceCategory: {
        name: serviceCategory?.name,
        key: serviceCategory?.key,
      },
      serviceType: { name: serviceType?.name, key: serviceType?.key },
      accessTechnology: {
        name: accessTechnology?.name,
        key: accessTechnology?.key,
      },
      specifications: specifications,
      contractTerms: contractTerms,
      ...productDetails,
    }

    return productForExport
  })
}

/**
 * Returns a CSV file with the products data.
 * @param {IProduct[]} products - The products data.
 * @returns {void}
 * @exports productExporter
 * @see https://marmelab.com/react-admin/DataProviders.html#fetching-the-data-yourself
 * @see https://www.npmjs.com/package/jsonexport
 */
export const productExporter: Exporter = (products: IProduct[]) => {
  jsonExport(
    productsList(products),
    {
      rowDelimiter: ';',
    },
    (err: any, csv: any) => {
      if (err) {
        console.error(err)
      } else if (csv) {
        downloadCSV(csv, 'products')
      }
    }
  )
}
