import React from 'react'
import {
  BooleanInput,
  Edit,
  EditProps,
  FormTab,
  TabbedForm,
  TextInput,
  required,
} from 'react-admin'
import { PageTitle } from '../../atoms/PageTitle'
import { ReferenceAutocomplete } from '../../molecules/ReferenceAutocomplete'
import { MetaListInput } from '../../organisms/MetaList'

/**
 * Form to edit a monitor probe.
 * @param props The Edit component props
 * @see https://marmelab.com/react-admin/Edit.html
 */
export const MonitorProbeEdit: React.FC<EditProps> = (props) => {
  return (
    <Edit
      {...props}
      title={<PageTitle prefix="Monitor Probe" />}
      mutationMode="undoable"
    >
      <TabbedForm>
        <FormTab label="general">
          <TextInput disabled label="Id" source="id" fullWidth />
          <ReferenceAutocomplete
            label="Target"
            source="monitorTargetId"
            reference="monitorTargets/list"
            required
          />
          <ReferenceAutocomplete
            label="Probe Type"
            source="monitorProbeTypeId"
            reference="monitorProbeTypes/list"
            required
          />
          <BooleanInput
            source="isActive"
            fullWidth
            defaultValue={true}
            validate={required()}
          />
          <BooleanInput source="isMuted" fullWidth defaultValue={false} />
        </FormTab>
        <FormTab label="config">
          <MetaListInput source="config" />
          <small>
            ℹ️ This is the custom config for this probe (overrides default
            config-schema of probe type)
          </small>
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}
