import { UserGroupShow } from './Show.component'
import { UserGroupCreate } from './Create.component'
import { UserGroupEdit } from './Edit.component'
import { UserGroupList } from './List.component'

export default {
  list: UserGroupList,
  show: UserGroupShow,
  edit: UserGroupEdit,
  create: UserGroupCreate,
}
