import React from 'react'
import { List, ListProps, TextField, DateField } from 'react-admin'
import { LabelledDatagrid } from '../../atoms/LabelledDatagrid'
import { ReferenceChip } from '../../molecules/ReferenceChip'
import { TypeStateField } from '../../atoms/TypeStateField'
import { LastActivityTextField } from '../../atoms/LastActivityTextField'
import { QuoteToolbar } from '../../molecules/QuoteToolbar'
import { useQuoteFilters } from '../../particles/filters/quoteFilters.hooks'

/**
 * Page that displays a list of quotes.
 * @param {ListProps} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 * @example
 * import { QuoteList } from './QuoteList.component.tsx'
 * <QuoteList />
 * @see {@link https://marmelab.com/react-admin/List.html|List}
 */
export const QuoteList: React.FC<ListProps> = (props) => {
  return (
    <List
      empty={false}
      perPage={25}
      filters={useQuoteFilters()}
      actions={<QuoteToolbar />}
      sort={{ field: 'offeredAt', order: 'DESC' }}
      {...props}
    >
      <LabelledDatagrid rowClick="show" optimized>
        <TextField source="quoteNo" />
        <TypeStateField source="state" fullWidth />
        <ReferenceChip
          referenceSource={'clientId'}
          tooltipSource="clientNo"
          source="Client"
          reference="clients"
          emptyText={'N/A'}
          link="show"
          chipSource="name"
        />
        <ReferenceChip
          referenceSource={'projectId'}
          tooltipSource="projectNo"
          source="Project"
          reference="projects"
          emptyText={'N/A'}
          link="show"
          chipSource="title"
        />
        <TypeStateField source="type" fullWidth />
        <LastActivityTextField />
        <DateField source="offeredAt" showTime />
      </LabelledDatagrid>
    </List>
  )
}
