import React from 'react'
import { List } from 'react-admin'
import { ISideBar } from './RelatedResource.types'
import { RelatedResourcesSideBar } from './SideBar.component'
import { useStore } from 'react-admin'
import { Box } from '@mui/material'

/**
 * Related resource page.
 * @property {ISideBar} props
 * @returns {JSX.Element}
 * @example
 * <RelatedResourcesPage pages={pages} />
 */
export const RelatedResourcesPage: React.FC<ISideBar> = (props) => {
  const [selectedPage] = useStore(
    `selected.${props.resource}.related.resource`,
    Object.keys(props.pages)[0]
  )

  return (
    <List
      aside={<RelatedResourcesSideBar {...props} />}
      actions={false}
      pagination={false}
      title={`: ${selectedPage}`}
      disableSyncWithLocation
      component={Box}
    >
      {props.pages[selectedPage]}
    </List>
  )
}
