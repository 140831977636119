import React from 'react'
import {
  DatagridConfigurable,
  List,
  ListProps,
  NumberField,
  SelectColumnsButton,
  TextField,
} from 'react-admin'
import { ProductFilterSidebar } from '../../organisms/ProductFilterSidebar'
import { useProductFilters } from '../../particles/filters'
import { productExporter } from '../../particles/exporters/productExporter'
import { FilterExportToolbar } from '../../molecules/FilterExportToolbar'
import { ProductDetailsExpand } from '../../organisms/ProductDetailsExpand'
import { CurrencyField } from '../../atoms/CurrencyField'
import { ReferenceChip } from '../../molecules/ReferenceChip'
import { ReferenceText } from '../../molecules/ReferenceText'

/**
 * List component for the Product resource
 * @param {ListProps} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 * @example
 * return (
 *  <ProductList />
 * )
 * @see {@link https://marmelab.com/react-admin/List.html|List}
 * @see {@link https://marmelab.com/react-admin/List.html#the-list-component|List}
 * @see {@link https://marmelab.com/react-admin/List.html#the-datagrid-component|Datagrid}
 */
export const ProductList: React.FC<ListProps> = (props) => {
  const productFilters = useProductFilters()
  return (
    <List
      {...props}
      empty={false}
      perPage={25}
      aside={<ProductFilterSidebar />}
      filters={productFilters}
      exporter={productExporter}
      actions={
        <FilterExportToolbar>
          <SelectColumnsButton />
        </FilterExportToolbar>
      }
      sort={{ field: 'code', order: 'ASC' }}
    >
      <DatagridConfigurable
        rowClick="expand"
        expand={<ProductDetailsExpand buttons={true} />}
        bulkActionButtons={false}
        omit={[
          'countryCode',
          'vendorId',
          'serviceCategoryId',
          'serviceTypeId',
          'accessTechnologyId',
          'specifications.bandwidthUp',
          'specifications.bandwidthDown',
          'contractTerms.leadTime',
          'contractTerms.meanTimeToRepair',
          'contractTerms.serviceUptime',
          'purchaseNrc',
          'purchaseMrc',
          'updatedAt',
        ]}
      >
        <TextField source="code" />
        <ReferenceChip
          referenceSource={'vendorId'}
          tooltipSource="name"
          source="vendorId"
          chipSource="name"
          label={'Vendor'}
          reference="vendors"
          emptyText={'N/A'}
          removeDoubleLabel
          link={'show'}
        />
        <TextField source="name" />
        <ReferenceText
          source="countryCode"
          reference="countries/list"
          emptyText={'N/A'}
          link={false}
        />
        <ReferenceChip
          referenceSource={'coverageZoneId'}
          tooltipSource="description"
          source="coverageZoneId"
          chipSource="name"
          label={'Coverage'}
          reference="coverageZones"
          emptyText={'N/A'}
          removeDoubleLabel
          link={'show'}
        />
        <ReferenceChip
          referenceSource={'serviceTypeId'}
          tooltipSource="name"
          source="serviceTypeId"
          chipSource="key"
          label={'Type'}
          reference="serviceTypes/list"
          emptyText={'N/A'}
          removeDoubleLabel
          link={false}
        />
        <ReferenceChip
          referenceSource={'serviceCategoryId'}
          tooltipSource="name"
          source="serviceCategoryId"
          chipSource="key"
          label={'Category'}
          reference="serviceCategories/list"
          emptyText={'N/A'}
          removeDoubleLabel
          link={false}
        />
        <ReferenceChip
          referenceSource={'accessTechnologyId'}
          tooltipSource="name"
          source="accessTechnologyId"
          chipSource="key"
          label={'Category'}
          reference="accessTechnologies/list"
          emptyText={'N/A'}
          removeDoubleLabel
          link={false}
        />
        <NumberField
          source="specifications.bandwidthUp"
          label="Bandwidth down"
        />
        <NumberField
          source="specifications.bandwidthDown"
          label="Bandwidth up"
        />
        <NumberField
          source="contractTerms.contractPeriod"
          label="Contract period"
        />
        <NumberField source="contractTerms.meanTimeToRepair" label="MTTR" />
        <NumberField source="contractTerms.leadTime" label="Lead time" />
        <NumberField
          source="contractTerms.serviceUptime"
          label="Service uptime"
        />
        <CurrencyField currency={'EUR'} source="valueNrc" label={'Value NRC'} />
        <CurrencyField currency={'EUR'} source="valueMrc" label={'Value MRC'} />
        <CurrencyField
          currency={'purchaseCurrency'}
          source="purchaseNrc"
          label={'Purchase NRC'}
        />
        <CurrencyField
          currency={'purchaseCurrency'}
          source="purchaseMrc"
          label={'Purchase MRC'}
        />
      </DatagridConfigurable>
    </List>
  )
}
