import Chip from '@mui/material/Chip'
import { IFormTabLabel, IFormTabLabelWithSource } from './FormTab.types'
import { useWatch } from 'react-hook-form'

/**
 * Represents a form tab label component.
 * If the loading prop is true, the component will not be rendered.
 * @param props - The component props.
 * @returns The rendered FormTabLabel component.
 * @see IFormTabLabel
 * @see https://mui.com/components/chips/#chip
 */
export const FormTabLabel: React.FC<IFormTabLabel> = (props) => {
  if (props.loading) {
    return null
  }

  return (
    <span>
      {props.label}
      <> </>
      {props.count && <Chip label={props.count} size="small" />}
    </span>
  )
}

/**
 * Represents a form tab label with source component.
 * If the loading prop is true, the component will not be rendered.
 * @param props - The component props.
 * @returns The rendered FormTabLabel component.
 * @see FormTabLabel
 * @see IFormTabLabelWithSource
 * @see useWatch
 * @see https://react-hook-form.com/api/usewatch\
 * @see https://marmelab.com/react-admin/Inputs.html#using-a-custom-input-component
 * @see https://mui.com/components/chips/#chip
 */
export const FormTabLabelBySource: React.FC<IFormTabLabelWithSource> = (
  props
) => {
  const value = useWatch({ name: props.source || null })
  return <FormTabLabel {...props} count={value?.length || 0} />
}
