import React from 'react'
import { SortButton } from 'react-admin'
import { Toolbar } from '../Toolbar'
/**
 * Toolbar for sorting and creating records
 * @see https://marmelab.com/react-admin/List.html#the-datagrid-component
 */
export const SortCreateToolbar: React.FC = () => {
  return (
    <Toolbar showCreate>
      <SortButton fields={['createdAt', 'clientId']} />
    </Toolbar>
  )
}
