import React from 'react'
import { TextField, useRecordContext, SimpleShowLayout } from 'react-admin'
import { ReferenceChip } from '../../molecules/ReferenceChip'
import { Map } from '../../atoms/Map'
import { Box, Divider, Chip } from '@mui/material'

import { IRequestSiteDetails } from './RequestSiteDetails.types'
import { useStyles } from './RequestSiteDetails.styles'
import { RequestServicesList } from '../../molecules/RequestServicesList'

/**
 * Display request site information (address components + map)
 *
 * @example <RequestSiteDetails />
 * @returns {React.FC} React component
 * @see https://marmelab.com/react-admin/Inputs.html#using-a-custom-input-component
 * @see https://marmelab.com/react-admin/Inputs.html#using-a-custom-input-component
 * @see https://marmelab.com/react-admin/Inputs.html#using-a-custom-input-component
 */
export const RequestSiteDetails: React.FC<IRequestSiteDetails> = ({
  showServices,
  showSites,
  ...props
}) => {
  const record = useRecordContext(props)
  const gridStyles = useStyles()

  return (
    <>
      {showSites !== false ? (
        <Box display={{ sm: 'block', md: 'flex' }} sx={{ p: 0.5, mt: 1 }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <SimpleShowLayout sx={gridStyles} record={record}>
              <ReferenceChip
                referenceSource={'countryCode'}
                tooltipSource="name"
                source="name"
                chipSource="name"
                label={'Country'}
                reference="countries/list"
                emptyText={'N/A'}
                link={false}
              />
              <TextField source={'city'} emptyText="N/A" />
              <TextField source={'postalCode'} emptyText="N/A" />
              <TextField source={'streetName'} emptyText="N/A" />
              <TextField source={'streetNumber'} emptyText="N/A" />
              <TextField source={'streetNumberSuffix'} emptyText="N/A" />
              <TextField source={'streetNumberRoom'} emptyText="N/A" />
            </SimpleShowLayout>
          </Box>
          {record.geometry ? (
            <Box flex={1}>
              <Map />
            </Box>
          ) : null}
        </Box>
      ) : null}
      {showServices !== false ? (
        <Box display={{ sm: 'block', md: 'flex' }} sx={{ p: 0.5, mt: 1 }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <Divider sx={{ fontWeight: 'bold' }}>
              <Chip variant="outlined" label="Services" />
            </Divider>
            <RequestServicesList
              context="requestSites"
              filter={{ id: record.serviceIds }}
              storeKey=" "
              disableSyncWithLocation
              empty={false}
              exporter={false}
              perPage={50}
              sx={{
                '& .MuiToolbar-gutters': { display: 'none' },
                '& .MuiTablePagination-toolbar': { display: 'flex' },
              }}
            />
          </Box>
        </Box>
      ) : null}
    </>
  )
}
