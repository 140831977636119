import React from 'react'
import {
  Show,
  ShowProps,
  Datagrid,
  TabbedShowLayout,
  Tab,
  TextField,
  DateField,
} from 'react-admin'
import { PageTitle } from '../../atoms/PageTitle'
import { TypeStateField } from '../../atoms/TypeStateField'
import { ReferenceChip } from '../../molecules/ReferenceChip'
import { ReferenceManyTab } from '../../atoms/FormTab'
import { MetaListDisplay } from '../../organisms/MetaList'
import { MutationsList } from '../../organisms/MutationsList'
import { FileSizeField } from '../../atoms/FileSizeField'
import { DownloadFileButton } from '../../atoms/DownloadFileButton'
import { Toolbar } from '../../molecules/Toolbar'

/**
 * Page component for displaying a single document.
 * @param {ShowProps} props
 * @returns {React.FC}
 * @example
 * import { DocumentShow } from './DocumentShow.component.tsx'
 * <DocumentShow />
 */
export const DocumentShow: React.FC<ShowProps> = (props) => {
  return (
    <Show
      {...props}
      title={<PageTitle prefix="Document" />}
      actions={<Toolbar showEdit />}
    >
      <TabbedShowLayout {...props}>
        <Tab label="summary">
          <TextField source="documentNo" />
          <TextField source="title" />
          <TypeStateField source="state" />

          <ReferenceChip
            referenceSource="categoryId"
            tooltipSource="key"
            source="name"
            chipSource="name"
            label={'Category'}
            reference="documentCategories"
            emptyText="N/A"
            link="show"
            removeDoubleLabel
          />
          <ReferenceChip
            referenceSource="clientId"
            tooltipSource="clientNo"
            source="name"
            chipSource="name"
            label={'Client'}
            reference="clients"
            emptyText="N/A"
            link="show"
            removeDoubleLabel
          />
          <ReferenceChip
            referenceSource="vendorId"
            tooltipSource="name"
            source="name"
            chipSource="name"
            label={'Vendor'}
            reference="vendors"
            emptyText="N/A"
            link="show"
            removeDoubleLabel
          />
          <ReferenceChip
            referenceSource="projectId"
            tooltipSource="projectNo"
            source="title"
            chipSource="title"
            label={'Project'}
            reference="projects"
            emptyText="N/A"
            link="show"
            removeDoubleLabel
          />
          <DateField source="updatedAt" showTime />
          <DateField source="createdAt" showTime />
        </Tab>
        <ReferenceManyTab
          reference="files"
          target="documentId"
          label="files"
          path="files"
        >
          <Datagrid rowClick="" bulkActionButtons={false}>
            <TextField source="name" variant="caption" />
            <TextField source="contentType" label={'Type'} />
            <FileSizeField />
            <DateField source="createdAt" showTime />
            <DownloadFileButton resource="files" />
          </Datagrid>
        </ReferenceManyTab>
        <Tab label={'meta'} path="meta">
          <MetaListDisplay source="meta" />
        </Tab>
        <Tab label={'history'} path="history">
          <MutationsList showActor type="history" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
