import { useTheme } from '@mui/material/styles'
import { useStateToColorHex } from '../../particles/color'
import { RaRecord } from 'react-admin'

/**
 * Hook that returns the row left border style.
 * - The border left color is based on the state of the row.
 * - The border left width is 5.
 * - The border left style is solid.
 * - The background color is based on the theme.
 * @props {IRowLeftBorderStyle} record The record of the row.
 * @returns {React.CSSProperties} The row left border style.
 * @exports useRowLeftBorderStyle
 * @see https://mui.com/styles/api/#css
 * @see https://mui.com/customization/palette/
 * @see https://mui.com/system/palette/#color
 * @see https://mui.com/system/palette/#palette
 *
 */
export const useRowLeftBorderStyle = (selected: boolean, record?: RaRecord) => {
  const color = useStateToColorHex(record?.state)
  const theme = useTheme()

  let style = {
    borderLeftColor: color,
    borderLeftWidth: 5,
    borderLeftStyle: 'solid',
    backgroundColor: theme.palette.background.paper,
    hover: {
      backgroundColor: theme.palette.action.selected,
    },
  }

  if (selected) {
    style = {
      ...style,
      backgroundColor: theme.palette.action.selected,
    }
  }

  return style
}
