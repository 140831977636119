import { ServiceCategoryCreate } from './Create.component'
import { ServiceCategoryEdit } from './Edit.component'
import { ServiceCategoryList } from './List.component'
import { ServiceCategoryShow } from './Show.component'

export default {
  list: ServiceCategoryList,
  show: ServiceCategoryShow,
  create: ServiceCategoryCreate,
  edit: ServiceCategoryEdit,
}
