import React from 'react'
import {
  FormDataConsumer,
  required,
  SelectInput,
  TextInput,
  regex,
} from 'react-admin'
import { Box } from '@mui/material'
import { ReferenceAutocomplete } from '../../molecules/ReferenceAutocomplete/ReferenceAutocomplete.component'
import { DateInput } from '../../atoms/DateInput'

/**
 * Form input fields for creating or editing a service.
 * @returns {React.FC} Form input fields for creating or editing a service.
 * @example
 * <ServiceInput />
 */
export const ServiceInput: React.FC = () => {
  return (
    <>
      <Box display={{ sm: 'block', md: 'flex' }} sx={{ p: 0.5 }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <ReferenceAutocomplete
            required
            label="Client"
            source="clientId"
            reference="clients/list"
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <ReferenceAutocomplete
            label="Vendor"
            source="vendorId"
            reference="vendors/list"
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <FormDataConsumer>
            {({ formData }) =>
              formData.clientId && (
                <ReferenceAutocomplete
                  label="Project"
                  source="projectId"
                  reference="projects/list"
                  filter={{ clientId: formData?.clientId }}
                  disableSyncWithLocation
                />
              )
            }
          </FormDataConsumer>
        </Box>
      </Box>
      <Box display={{ sm: 'block', md: 'flex' }} sx={{ p: 0.5 }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <TextInput source="name" validate={required()} fullWidth />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <TextInput source="reference" fullWidth />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <TextInput
            source="serviceNo"
            fullWidth
            validate={[regex(/^SID[0-9]{5,12}$/)]}
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <TextInput source="vendorServiceNo" fullWidth />
        </Box>
      </Box>
      <Box display={{ sm: 'block', md: 'flex' }} sx={{ p: 0.5 }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <SelectInput
            source="state"
            defaultValue="active"
            validate={required()}
            choices={[
              { id: 'pending', name: 'Pending' },
              { id: 'provisioning', name: 'Provisioning' },
              { id: 'cancelled', name: 'Cancelled' },
              { id: 'active', name: 'Active' },
              { id: 'terminated', name: 'Terminated' },
            ]}
            fullWidth
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <ReferenceAutocomplete
            required
            label="Service category"
            source="serviceCategoryId"
            reference="serviceCategories"
          />
        </Box>
      </Box>
      <FormDataConsumer>
        {({ formData }) => (
          <Box display={{ sm: 'block', md: 'flex' }} sx={{ p: 0.5 }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <ReferenceAutocomplete
                label={'Service type'}
                reference="serviceTypes"
                source="serviceTypeId"
                filter={{ serviceCategoryId: formData?.serviceCategoryId }}
              />
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <ReferenceAutocomplete
                label={'Access technology'}
                reference="accessTechnologies"
                source="accessTechnologyId"
              />
            </Box>
          </Box>
        )}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData }) => (
          <Box display={{ sm: 'block', md: 'flex' }} sx={{ p: 0.5 }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <SelectInput
                source="diversityRole"
                defaultValue="none"
                validate={required()}
                choices={[
                  { id: 'none', name: 'No diversity' },
                  { id: 'primary', name: 'Primary' },
                  { id: 'secondary', name: 'Secondary' },
                  { id: 'tertiary', name: 'Tertiary' },
                ]}
                fullWidth
              />
            </Box>
            {formData?.diversityRole !== 'primary' &&
              formData?.diversityRole !== 'none' && (
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <ReferenceAutocomplete
                    label={'Diverse to primary'}
                    reference="services/list"
                    source="diverseToId"
                    filter={{
                      id: { not: formData?.id },
                      clientId: formData?.clientId,
                      diversityRole: 'primary',
                    }}
                  />
                </Box>
              )}
          </Box>
        )}
      </FormDataConsumer>
      <Box display={{ sm: 'block', md: 'flex' }} sx={{ p: 0.5 }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <DateInput source="provisionedAt" fullWidth />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <DateInput source="deliveredAt" fullWidth />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <DateInput source="cancelledAt" fullWidth />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <DateInput source="decommissionedAt" fullWidth />
        </Box>
      </Box>
    </>
  )
}
