import React from 'react'
import {
  Show,
  ShowProps,
  Tab,
  TabbedShowLayout,
  TextField,
  DateField,
} from 'react-admin'
import { MarkdownField } from '@react-admin/ra-markdown'
import { PageTitle } from '../../atoms/PageTitle'
import { TicketCommentTab } from '../../templates/TicketCommentTab'
import { MutationsList } from '../../organisms/MutationsList'
import { ReferenceChipList } from '../../molecules/ReferenceChipList'

/**
 * Page that displays a single ticket.
 * @param {ShowProps} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 * @example
 * return (
 * <TicketShow />
 * )
 * @see {@link https://marmelab.com/react-admin/Show.html|Show}
 */
export const TicketShow: React.FC<ShowProps> = (props) => {
  return (
    <Show {...props} title={<PageTitle prefix="Ticket" />}>
      <TabbedShowLayout {...props}>
        <Tab label="summary">
          <TextField source="ticketNo" />
          <TextField source="state" emptyText={'N/A'} />
          <TextField source="summary" />
          <TextField source="status" emptyText={'N/A'} />
          <MarkdownField source="description" />
          <ReferenceChipList
            label={'Related client(s)'}
            source="clientIds"
            reference="clients"
          />
          <ReferenceChipList
            label={'Related service(s)'}
            source="serviceIds"
            reference="services"
          />
          <DateField source="updatedAt" showTime />
          <DateField source="createdAt" showTime />
        </Tab>
        <Tab label="comments">
          <TicketCommentTab />
        </Tab>
        <Tab label={'history'}>
          <MutationsList showActor type="history" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
