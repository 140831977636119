import { useTheme } from '@mui/material/styles'

/**
 * Hook that returns the card styles.
 * @returns {React.CSSProperties} The card styles.
 * @exports useCardStyles
 * @see https://mui.com/styles/api/#css
 * @see https://mui.com/system/palette/#palette
 */
export const useCardStyles = () => {
  return {
    minHeight: 52,
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    '& a': {
      textDecoration: 'none',
      color: 'inherit',
    },
  }
}

/**
 * Hook that returns the box styles.
 * @returns {React.CSSProperties} The box styles.
 * @exports useBoxStyles
 * @see https://mui.com/styles/api/#css
 * @see https://mui.com/system/palette/#palette
 */
export const useBoxStyles = () => {
  const theme = useTheme()

  return {
    overflow: 'inherit',
    padding: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .icon': {
      color: theme.palette.secondary.main,
      opacity: 0.85,
    },
  }
}
