import { ServiceStatisticCreate } from './Create.component'
import { ServiceStatisticEdit } from './Edit.component'
import { ServiceStatisticList } from './List.component'
import { ServiceStatisticShow } from './Show.component'

export default {
  list: ServiceStatisticList,
  show: ServiceStatisticShow,
  create: ServiceStatisticCreate,
  edit: ServiceStatisticEdit,
}
