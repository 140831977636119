import React from 'react'
import {
  Edit,
  EditProps,
  TextInput,
  BooleanInput,
  SimpleForm,
} from 'react-admin'
import { PageTitle } from '../../atoms/PageTitle'

/**
 * Page to edit a coverage address.
 * @property {EditProps} props
 * @example
 * <ClientEdit />
 * @returns {React.FC<EditProps>}
 * @see https://marmelab.com/react-admin/Edit.html
 */
export const CoverageAddressEdit: React.FC<EditProps> = (props) => {
  return (
    <Edit
      {...props}
      mutationMode="undoable"
      title={<PageTitle prefix="Coverage address" />}
    >
      <SimpleForm>
        <TextInput source="name" disabled fullWidth />
        <BooleanInput source="isActive" defaultValue={true} fullWidth />
      </SimpleForm>
    </Edit>
  )
}
