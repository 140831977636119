import React from 'react'
import { useGetList } from 'react-admin'
import { IconCard } from '../../molecules/IconCard/IconCard.component'
import UserIcon from '@mui/icons-material/PersonOutlined'

/**
 * A card with active client metrics.
 * @example <ActiveClientsCard />
 */
export const ActiveClientsCard: React.FC = () => {
  const activeClients = useGetList('clients/list', {
    filter: { isActive: true },
    pagination: { page: 1, perPage: 10 },
    sort: { field: 'createdAt', order: 'DESC' },
  })

  return (
    <IconCard
      icon={UserIcon}
      to={'/clients?filter=%7B"isActive"%3A%5Btrue%5D%7D'}
      subtitle={`${activeClients?.total || 'N/A'}`}
      title={'Active clients'}
    />
  )
}
