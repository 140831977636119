import React from 'react'
import { EditInDialogButton } from '@react-admin/ra-form-layout'
import { CreateEditStatsForm } from '../../molecules/CreateEditStatsForm'
import { IStatsModalEdit } from './StatsModalEdit.types'

/**
 * Component to edit a service statistic in a dialog.
 * @param {IStatsModalEdit} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 * @example
 * return (
 * <StatsModalEdit />
 * )
 * @see {@link https://marmelab.com/react-admin/CreateEdit.html|CreateEdit}
 */
export const StatsModalEdit: React.FC<IStatsModalEdit> = (props) => {
  return (
    <EditInDialogButton
      fullWidth
      maxWidth="md"
      resource={'serviceStatistics'}
      {...props}
    >
      <CreateEditStatsForm />
    </EditInDialogButton>
  )
}
