import React from 'react'
import { TextField, Labeled } from 'react-admin'
import { ReferenceChip } from '../../molecules/ReferenceChip'
import { IAddressDisplay } from './AddressDisplay.types'
/**
 * Display address information
 *
 * Currently uses a Label component to display the label and the value.
 * This is due to the fact that the TextField component does not allow for
 * a label to be displayed when not directly inside simple form context.
 *
 * @example <AddressDisplay />
 * @returns {React.FC} React component
 * @see https://marmelab.com/react-admin/Inputs.html#using-a-custom-input-component
 * @see https://marmelab.com/react-admin/Inputs.html#using-a-custom-input-component
 * @see https://marmelab.com/react-admin/Inputs.html#using-a-custom-input-component
 */
export const AddressDisplay: React.FC<IAddressDisplay> = (props) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Labeled label="Address">
        <TextField source={props.addressSource || 'address'} />
      </Labeled>
      <ReferenceChip
        referenceSource={props.countrySource || 'countryCode'}
        tooltipSource="name"
        source="name"
        chipSource="name"
        label={'Country'}
        reference="countries/list"
        emptyText={'N/A'}
        link={false}
      />
      <Labeled label="City">
        <TextField source={props.citySource || 'city'} label="City" />
      </Labeled>
      <Labeled label="Postal Code">
        <TextField source={props.postalCodeSource || 'postalCode'} />
      </Labeled>
      <Labeled label="Street Name">
        <TextField
          source={props.streetNameSource || 'streetName'}
          emptyText="N/A"
        />
      </Labeled>
      <Labeled label="Street Number">
        <TextField
          source={props.streetNumberSource || 'streetNumber'}
          emptyText="N/A"
        />
      </Labeled>
      <Labeled label="Street Number Suffix">
        <TextField
          source={props.streetNumberSuffixSource || 'streetNumberSuffix'}
          emptyText="N/A"
        />
      </Labeled>
      <Labeled label="Street Number Room">
        <TextField
          source={props.streetNumberRoomSource || 'streetNumberRoom'}
          emptyText="N/A"
        />
      </Labeled>
    </div>
  )
}
