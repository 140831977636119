import React from 'react'
import { IVendorContractFilters } from './VendorContractFilters.types'
import { Filter, TextInput } from 'react-admin'
import { ReferenceAutocomplete } from '../../molecules/ReferenceAutocomplete/ReferenceAutocomplete.component'

/**
 * Filter for VendorContract
 * @property {IVendorContractFilters} props properties
 * @returns {React.FC<IVendorContractFilters>} component
 * @example
 * <VendorContractFilters />
 * @see https://marmelab.com/react-admin/List.html#the-filter-button
 */
export const VendorContractFilters: React.FC<IVendorContractFilters> = (
  props
) => {
  return (
    <Filter {...props}>
      <TextInput label="Search" source="term" alwaysOn />
      <ReferenceAutocomplete
        source="vendorId"
        reference="vendors/list"
        alwaysOn
      />
      <ReferenceAutocomplete
        source="serviceId"
        reference="services/list"
        alwaysOn
      />
    </Filter>
  )
}
