import red from '@mui/material/colors/red'
import orange from '@mui/material/colors/orange'
import { IActivityRecord, IActivityState } from './LastActivityTextField.types'

/**
 * Calculates the number of hours passed since the given timestamp.
 * @param updatedAt - The timestamp of the last update.
 * @returns The number of hours passed.
 * @example
 * const hours = useHours('2021-10-01T12:00:00.000Z')
 * // hours = 2
 */
export const useHours = (updatedAt: string) => {
  return Math.floor((Date.now() - Date.parse(updatedAt)) / 1000 / 60 / 60)
}

/**
 * Determines the color based on the number of hours.
 * @param hours - The number of hours passed.
 * @returns The color value or undefined if no color is applicable.
 * @example
 * const color = useColor(72)
 * // color = red[800]
 */
export const useColor = (hours: number) => {
  if (hours > 72) return red[800]
  if (hours > 48) return orange[800]
  return undefined
}

/**
 * Determines the font weight based on the number of hours.
 * @param hours - The number of hours passed.
 * @returns The font weight value or undefined if no font weight is applicable.
 * @example
 * const fontWeight = useFontWeight(72)
 * // fontWeight = 800
 */
export const useFontWeight = (hours: number) => {
  if (hours > 72) return 800
  if (hours > 48) return 600
  return undefined
}

/**
 * Checks if the state is not one of the specified values.
 * @param state - The state value to check.
 * @returns True if the state is not in the specified values, false otherwise.
 *  @example
 * const stateNotFoud = useStateNotMet('expired')
 * // stateNotFoud = false
 */
export const useStateNotMet = (state: IActivityState['state']) => {
  return (
    [
      'expired',
      'completed',
      'cancelled',
      'offered',
      'done',
      'finished',
    ].indexOf(state) < 0
  )
}

/**
 * Determines the styles (color and font weight) based on the record.
 * @param record - The activity record.
 * @returns The CSS properties for styling or undefined if no styles are applicable.
 * @example
 * const styles = useStylesFromRecord({ state: 'expired', updatedAt: '2021-10-01T12:00:00.000Z' })
 * // styles = { color: red[800], fontWeight: 800 }
 */
export const useStylesFromRecord = ({ updatedAt, state }: IActivityRecord) => {
  const hours = useHours(updatedAt)
  const stateNotFoud = useStateNotMet(state)
  const color = useColor(hours)
  const fontWeight = useFontWeight(hours)

  if (state && stateNotFoud) {
    return { color, fontWeight }
  }

  return undefined
}
