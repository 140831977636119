import React from 'react'
import {
  SimpleShowLayout,
  NumberField,
  TextField,
  BooleanField,
  useRecordContext,
} from 'react-admin'
import get from 'lodash/get'

/**
 * Display a service specifications.
 * @property {string} source The source of the service specifications.
 * @returns {React.FC} The component.
 * @example
 * <ServiceSpecificationDisplay source="serviceSpecification" />
 */
export const ServiceSpecificationDisplay: React.FC<{ source?: string }> = (
  props
) => {
  const record = useRecordContext()
  const specification = get(record, props.source || 'specifications')

  return (
    <SimpleShowLayout
      record={specification}
      sx={{
        '& .RaSimpleShowLayout-stack': {
          md: {
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
            gridGap: '4px',
            gridAutoColumns: '1fr',
            alignSelf: 'center',
            '& .ra-field': {
              marginTop: '8px',
            },
          },
        },
        width: '100%',
        margin: 0,
        padding: 0,
      }}
    >
      <NumberField
        source="bandwidthDown"
        label="Download bandwidth (Mbit)"
        emptyText="N/A"
        fullWidth
      />
      <NumberField
        source="bandwidthUp"
        label="Upload bandwidth (Mbit)"
        emptyText="N/A"
        fullWidth
      />
      <NumberField
        source="contentionRatio"
        label="Contention ratio (1:<value>)"
        emptyText="N/A"
        fullWidth
      />
      <NumberField
        source="bandwidthCommit"
        label="Bandwidth commit (Mbit 95% pct)"
        emptyText="N/A"
        fullWidth
      />
      <NumberField
        source="ipPrefixV4"
        label="Public IPv4 subnet prefix size"
        emptyText="N/A"
        fullWidth
      />
      <NumberField
        source="ipPrefixV6"
        label="Public IPv6 subnet prefix size"
        emptyText="N/A"
        fullWidth
      />
      <NumberField
        source="expectedRtd"
        label="Expected round trip delay (ms)"
        emptyText="N/A"
        fullWidth
      />
      <NumberField
        source="guaranteedRtd"
        label="Guaranteed round trip delay (ms)"
        emptyText="N/A"
        fullWidth
      />
      <NumberField
        source="rackSpace"
        label="Rack space"
        emptyText="N/A"
        fullWidth
      />
      <NumberField
        source="powerCommit"
        label="Power commit (kW)"
        emptyText="N/A"
        fullWidth
      />
      <NumberField
        source="crossConnects"
        label="Cross connects"
        emptyText="N/A"
        fullWidth
      />
      <NumberField
        source="portCount"
        label="Port count"
        emptyText="N/A"
        fullWidth
      />
      <TextField source="cpeType" label="CPE" emptyText="N/A" fullWidth />
      <TextField
        source="portType"
        label="Port type"
        emptyText="N/A"
        fullWidth
      />
      <TextField
        source="portConnector"
        label="Port connector"
        emptyText="N/A"
        fullWidth
      />
      <TextField
        source="portMode"
        label="Port mode"
        emptyText="N/A"
        fullWidth
      />
      <TextField
        source="portSpeed"
        label="Port speed"
        emptyText="N/A"
        fullWidth
      />
      <TextField
        source="portCount"
        label="Port count"
        emptyText="N/A"
        fullWidth
      />
      <TextField
        source="routingConfig"
        label="Routing config"
        emptyText="N/A"
        fullWidth
      />
      <TextField
        source="carrier"
        label="Carrier name"
        emptyText="N/A"
        fullWidth
      />
      <NumberField
        source="lastMileProvider"
        label="Last mile provider (LMP)"
        emptyText="N/A"
        fullWidth
      />
      <TextField
        source="cableSystemsUsed"
        label="Cable systems used"
        emptyText="N/A"
        fullWidth
      />
      <BooleanField
        source="isProtected"
        label="Is protected"
        emptyText="N/A"
        fullWidth
      />
      <BooleanField
        source="isBurstable"
        label="Is burstable"
        emptyText="N/A"
        fullWidth
      />
      <BooleanField
        source="isOnSiteInstallation"
        label="Is on-site installation"
        emptyText="N/A"
        fullWidth
      />
      <BooleanField
        source="isNovationOrder"
        label="Is novation order"
        emptyText="N/A"
        fullWidth
      />
    </SimpleShowLayout>
  )
}
