import React from 'react'
import {
  TextField,
  DateField,
  Show,
  ShowProps,
  TabbedShowLayout,
  Tab,
  BooleanField,
} from 'react-admin'
import { PageTitle } from '../../atoms/PageTitle'
import { MetaListDisplay } from '../../organisms/MetaList'
import { ReferenceChip } from '../../molecules/ReferenceChip'
import { HistoryDisplayTab } from '../../templates/HistoryDisplayTab'

/**
 * Component to show a monitor notification contact.
 * @param props The Show component props
 * @see https://marmelab.com/react-admin/Show.html
 */
export const MonitorNotificationContactShow: React.FC<ShowProps> = (props) => {
  return (
    <Show
      {...props}
      title={<PageTitle prefix="Monitor Notification Contact" />}
    >
      <TabbedShowLayout {...props}>
        <Tab label="summary">
          <TextField source="name" />
          <ReferenceChip
            referenceSource="monitorNotificationTypeId"
            tooltipSource="name"
            source="name"
            chipSource="name"
            label="Notification Type"
            reference="monitorNotificationTypes"
            emptyText="N/A"
            link="show"
            removeDoubleLabel
          />
          <ReferenceChip
            referenceSource="clientId"
            tooltipSource="name"
            source="clientId"
            chipSource="name"
            reference="clients"
            emptyText="N/A"
            link="show"
          />
          <ReferenceChip
            referenceSource="clientContactId"
            tooltipSource="name"
            source="clientContact"
            chipSource="name"
            reference="clientContacts"
            emptyText="N/A"
            link="show"
          />
          <BooleanField source="isGlobal" />
          <DateField source="createdAt" showTime />
          <DateField source="updatedAt" showTime />
        </Tab>
        <Tab label="extras" key="extras">
          <MetaListDisplay source="extras" />
        </Tab>
        <Tab label="history">
          <HistoryDisplayTab />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
