import React from 'react'
import {
  Show,
  ShowProps,
  TabbedShowLayout,
  Tab,
  TextField,
  DateField,
  TabbedShowLayoutTabs,
  BooleanField,
} from 'react-admin'
import { MutationsList } from '../../organisms/MutationsList'
import { PageTitle } from '../../atoms/PageTitle'
import { ReferenceChip } from '../../molecules/ReferenceChip'
import { TypeStateField } from '../../atoms/TypeStateField'
import { Map } from '../../atoms/Map'

/**
 * Page to show a single coverage zone
 * @property {ShowProps} props
 * @returns {React.FC<ShowProps>}
 * @example
 * <CoverageAreaShow {...props} />
 * @see https://marmelab.com/react-admin/Show.html
 */
export const CoverageAreaShow: React.FC<ShowProps> = (props) => {
  return (
    <Show {...props} title={<PageTitle prefix="Coverage zone" />}>
      <TabbedShowLayout
        tabs={
          <TabbedShowLayoutTabs variant="scrollable" scrollButtons="auto" />
        }
      >
        <Tab label="general">
          <TextField source="name" fullWidth />
          <TypeStateField source="type" fullWidth />
          <ReferenceChip
            referenceSource="coverageZoneId"
            tooltipSource="name"
            source="CoverageZone"
            chipSource="name"
            reference="coverageZones"
            emptyText={'N/A'}
          />
          <BooleanField source="isActive" fullWidth />
          <DateField source="updatedAt" showTime />
          <DateField source="createdAt" showTime />
        </Tab>
        <Tab label="geometry">
          <Map style={{ height: '70vh' }} />
        </Tab>
        <Tab label="history">
          <MutationsList showActor type="history" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
