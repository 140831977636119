import React from 'react'
import { ReferenceChip } from '../../molecules/ReferenceChip'
import { PageTitle } from '../../atoms/PageTitle'
import {
  Show,
  ShowProps,
  TextField,
  DateField,
  TabbedShowLayout,
  Tab,
} from 'react-admin'
import { HistoryDisplayTab } from '../../templates/HistoryDisplayTab'

/**
 * Page to show a project.
 * @property {ShowProps} props
 * @returns {React.FC<ShowProps>}
 */
export const ProjectShow: React.FC<ShowProps> = (props) => {
  return (
    <Show {...props} title={<PageTitle prefix="Project" />}>
      <TabbedShowLayout {...props}>
        <Tab label="summary">
          <TextField source="projectNo" />
          <TextField source="title" />
          <ReferenceChip
            referenceSource="clientId"
            tooltipSource="clientNo"
            source="name"
            chipSource="name"
            label={'Client'}
            reference="clients"
            emptyText="N/A"
            link="show"
            removeDoubleLabel
          />
          <DateField source="updatedAt" showTime />
          <DateField source="createdAt" showTime />
        </Tab>
        <Tab label={'history'}>
          <HistoryDisplayTab />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
