import React from 'react'
import { IServiceDetails } from './ServiceDetails.types'
import { TypeStateField } from '../../atoms/TypeStateField'
import { ServiceHealthIndicatorField } from '../../atoms/ServiceHealthIndicatorField'
import { DateField, SimpleShowLayout, TextField } from 'react-admin'
import { ReferenceChip } from '../../molecules/ReferenceChip'
import { ReferenceText } from '../../molecules/ReferenceText'

/**
 * Service details section.
 * @param props ServiceDetails properties.
 * @returns ReactElement
 */
export const ServiceDetails: React.FC<IServiceDetails> = (props) => {
  return (
    <>
      <SimpleShowLayout
        sx={{
          width: '100%',
          margin: 0,
          padding: 0,
        }}
      >
        <TextField
          source="notes"
          label="Internal notes"
          emptyText={'N/A'}
          fullWidth
        />
        <TextField source="notesForClient" emptyText={'N/A'} fullWidth />
      </SimpleShowLayout>
      <SimpleShowLayout
        sx={{
          '& .RaSimpleShowLayout-stack': {
            md: {
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
              gridGap: '4px',
              gridAutoColumns: '1fr',
              alignSelf: 'center',
              '& .ra-field': {
                marginTop: '8px',
              },
            },
          },
          width: '100%',
          margin: 0,
          padding: 0,
        }}
      >
        <TypeStateField source="state" />
        <ServiceHealthIndicatorField label="Health" />
        <TextField source="name" fullWidth />
        <TextField source="serviceNo" fullWidth />
        <TextField source="vendorServiceNo" emptyText="N/A" fullWidth />
        <TextField source="reference" emptyText="N/A" fullWidth />
        <ReferenceChip
          referenceSource="clientId"
          tooltipSource="clientNo"
          source="name"
          reference="clients"
          label="Client"
          emptyText="N/A"
          link="show"
          chipSource="name"
          removeDoubleLabel
        />
        <ReferenceText
          source="vendorId"
          reference="vendors"
          label="Vendor"
          emptyText="N/A"
          link="show"
        />
        <ReferenceChip
          referenceSource="projectId"
          tooltipSource="projectNo"
          source="title"
          reference="projects"
          label="Project"
          emptyText="N/A"
          link="show"
          chipSource="name"
          removeDoubleLabel
        />
        <TextField source="category" emptyText="N/A" />
        <ReferenceText
          label={'Service type'}
          reference="serviceTypes/list"
          source="serviceTypeId"
          emptyText="N/A"
          link={false}
        />
        <ReferenceText
          label={'Access technology'}
          reference="accessTechnologies/list"
          source="accessTechnologyId"
          emptyText="N/A"
          link={false}
        />
        <TextField source="diversityRole" fullWidth />
        <ReferenceText
          label="Diverse to"
          source="diverseToId"
          reference="services"
          emptyText="N/A"
          link="show"
        />
        <DateField source="deliveryWishDate" emptyText="N/A" fullWidth />
        <DateField source="provisionedAt" emptyText="N/A" fullWidth />
        <DateField source="deliveredAt" emptyText="N/A" fullWidth />
        <DateField source="cancelledAt" emptyText="N/A" fullWidth />
        <DateField source="decommissionedAt" emptyText="N/A" fullWidth />
        <DateField source="updatedAt" showTime />
        <DateField source="createdAt" showTime />
      </SimpleShowLayout>
    </>
  )
}
