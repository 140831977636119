import React from 'react'
import {
  Show,
  ShowProps,
  DateField,
  BooleanField,
  SimpleShowLayout,
} from 'react-admin'
import { PageTitle } from '../../atoms/PageTitle'
import { ReferenceChip } from '../../molecules/ReferenceChip'
import { AddressDisplay } from '../../organisms/AddressDisplay'

/**
 * Page to show a single coverage address.
 * @property {ShowProps} props
 * @returns {React.FC<ShowProps>}
 * @example
 * <CoverageAddressShow {...props} />
 * @see https://marmelab.com/react-admin/Show.html
 */
export const CoverageAddressShow: React.FC<ShowProps> = (props) => {
  return (
    <Show {...props} title={<PageTitle prefix="Coverage address" />}>
      <SimpleShowLayout>
        <ReferenceChip
          referenceSource="coverageZoneId"
          tooltipSource="name"
          source="CoverageZone"
          chipSource="name"
          reference="coverageZones/list"
          emptyText={'N/A'}
        />
        <AddressDisplay />
        <BooleanField source="isActive" fullWidth />
        <DateField source="updatedAt" showTime />
        <DateField source="createdAt" showTime />
      </SimpleShowLayout>
    </Show>
  )
}
