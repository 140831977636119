import React from 'react'
import { LabelledDatagrid } from '../../../components/atoms/LabelledDatagrid'
import {
  TextField,
  DateField,
  ChipField,
  FunctionField,
  RaRecord,
} from 'react-admin'
import { TypeStateField } from '../../atoms/TypeStateField'
import { ReferenceChip } from '../../molecules/ReferenceChip'

/**
 * Pending Request row for small screens.
 * @example
 * return (
 * <PendingRequestsSlim>
 *    <TextField source="id" />
 * </PendingRequestsSlim>
 * )
 * @see {@link https://marmelab.com/react-admin/List.html#the-datagrid-component|Datagrid}
 * @see {@link https://marmelab.com/react-admin/Theming.html|Theming}
 * @see {@link https://marmelab.com/react-admin/Theming.html#using-a-custom-theme|Using a Custom Theme}
 */
export const PendingRequestsSlim: React.FC<{ children?: React.ReactNode }> = (
  props
) => {
  return (
    <LabelledDatagrid optimized rowClick="show" bulkActionButtons={false}>
      <TextField
        source="requestNo"
        sortable={false}
        emptyText={'N/A'}
        sx={{ display: 'block', width: 80 }}
      />
      <TypeStateField source="state" fullWidth />
      <ReferenceChip
        referenceSource="clientId"
        tooltipSource="clientNo"
        source="Client"
        reference="clients"
        emptyText={'N/A'}
        link="show"
        chipSource="name"
      />
      <FunctionField
        label={'Services'}
        render={(record: RaRecord) => (
          <ChipField
            size="small"
            record={{ value: record.services ? record.services.length : 0 }}
            source="value"
          />
        )}
      />
      <DateField source="createdAt" showTime />
    </LabelledDatagrid>
  )
}
