import React from 'react'
import { INameFilter } from './NameFilter.types'
import { TextInput, Filter } from 'react-admin'

/**
 * NameFilter component.
 *
 * A filter component that filters data based on a name.
 *
 * @param {INameFilter} props - The props for the NameFilter component.
 * @returns {JSX.Element} The rendered NameFilter component.
 * @see INameFilter
 * @see Filter
 * @see TextInput
 */
export const NameFilter: React.FC<INameFilter> = (props) => {
  return (
    <Filter {...props}>
      <TextInput label="Name" source="name.like" alwaysOn />
    </Filter>
  )
}
