import React from 'react'
import { List, ListProps, Datagrid, DateField } from 'react-admin'
import { ReferenceChip } from '../../molecules/ReferenceChip'
import { SearchClientFilter } from '../../molecules/SearchClientFilter'

/**
 * List of monitor notification contacts.
 * @param props The List component props
 * @see https://marmelab.com/react-admin/List.html
 */
export const MonitorNotificationContactList: React.FC<ListProps> = (props) => {
  return (
    <List
      {...props}
      empty={false}
      filters={<SearchClientFilter />}
      sort={{ field: 'name', order: 'ASC' }}
      perPage={25}
    >
      <Datagrid rowClick="show">
        <ReferenceChip
          referenceSource="monitorNotificationTypeId"
          tooltipSource="name"
          source="monitorNotificationType"
          chipSource="name"
          reference="monitorNotificationTypes"
          emptyText="N/A"
          link="show"
        />
        <ReferenceChip
          referenceSource="clientId"
          tooltipSource="name"
          source="clientId"
          chipSource="name"
          reference="clients"
          emptyText="N/A"
          link="show"
        />
        <ReferenceChip
          referenceSource="clientContactId"
          tooltipSource="name"
          source="clientContact"
          chipSource="name"
          reference="clientContacts"
          emptyText="N/A"
          link="show"
        />
        <DateField source="updatedAt" showTime />
      </Datagrid>
    </List>
  )
}
