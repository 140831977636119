import { IMutationsListTypes } from './MutationsList.types'
import { useRecordContext, useShowContext } from 'react-admin'

/**
 * Returns the filter.
 * @param {IMutationsListTypes['type']} type The type.
 * @returns {object} The filter.
 * @exports useFilter
 * @see https://marmelab.com/react-admin/Fields.html#useRecordContext
 * @see https://marmelab.com/react-admin/Fields.html#useShowContext
 */
export const useFilter = (type: IMutationsListTypes['type']) => {
  const record = useRecordContext()

  if (type === 'history') {
    return {}
  }

  return {
    actorId: record.id,
  }
}

/**
 * Returns the resource name.
 * @param {IMutationsListTypes['type']} type The type.
 * @returns {string} The resource name.
 * @exports useResource
 * @see https://marmelab.com/react-admin/Fields.html#useRecordContext
 */
export const useResource = (type: IMutationsListTypes['type']) => {
  const record = useRecordContext()
  const show = useShowContext()

  if (type === 'history') {
    return `${show.resource}/${record.id}/history`
  }

  return 'mutations'
}

/**
 * Adds the changes and environment to the record.
 * @returns {IMutationsListTypes} The mutations list types.
 * @exports useMutationDisplayRecord
 * @see https://marmelab.com/react-admin/Fields.html#useRecordContext
 * @see https://marmelab.com/react-admin/Fields.html#useShowContext
 */
export const useMutationDisplayRecord = () => {
  const record = useRecordContext()

  if (record) {
    record.changeList = record.changes
      ? Object.keys(record?.changes).map((f) => ({
          property: f,
          ...record.changes[f],
        }))
      : []

    record.environmentList = record.environment
      ? Object.keys(record?.environment).map((e) => ({
          key: e,
          value: record.environment[e],
        }))
      : []
  }

  return record
}
