import React from 'react'
import {
  PendingRequestsCard,
  OfferedQuotesCard,
  ActiveClientsCard,
  OpenTicketsCard,
  ProvisioningServicesCard,
  ActiveServicesCard,
} from '../../organisms/MetricsCards'
import { Box } from '@mui/material'
import { HorizontalSpacer } from '../../atoms/Spacer'
import { useStyles } from './OverviewMetricsSegment.styles'
import { Segment } from '../../atoms/Segment'

/**
 * A segment with overview metric cards.
 * @example <OverviewMetricsSegment />
 * @see {@link https://mui.com/components/box/#main-content|Box}
 * @see {@link https://mui.com/components/grid/#main-content|Grid}
 * @see {@link https://mui.com/components/card/#main-content|Card}
 * @see {@link https://mui.com/components/typography/#main-content|Typography}
 */
export const OverviewMetricsSegment: React.FC = () => {
  const styles = useStyles()

  return (
    <Segment>
      <Box style={styles}>
        <PendingRequestsCard />
        <HorizontalSpacer />
        <OfferedQuotesCard />
        <HorizontalSpacer />
        <ActiveClientsCard />
      </Box>
      <Box style={styles} mt={1}>
        <OpenTicketsCard />
        <HorizontalSpacer />
        <ProvisioningServicesCard />
        <HorizontalSpacer />
        <ActiveServicesCard />
      </Box>
    </Segment>
  )
}
