import { FieldValues } from 'react-hook-form'
import { get } from 'lodash'

/**
 * Returns the choices for the speed field.
 * @param {FieldValues} formData - The form data.
 * @param {string} typeField - The type field.
 * @returns {Array} The speed choices.
 */
export const speedChoices = (formData: FieldValues, typeField: string) => {
  const typeValue = get(formData, typeField)
  if (typeValue === 'copper') {
    return [
      { id: 10, name: '10M' },
      { id: 100, name: '100M' },
      { id: 1000, name: '1G' },
      { id: 2500, name: '2.5G' },
      { id: 5000, name: '5G' },
      { id: 10000, name: '10G' },
    ]
  }
  return [
    { id: 100, name: '100M' },
    { id: 1000, name: '1G' },
    { id: 10000, name: '10G' },
    { id: 25000, name: '25G' },
    { id: 40000, name: '40G' },
    { id: 50000, name: '50G' },
    { id: 100000, name: '100G' },
  ]
}
