import React from 'react'
import Typography from '@mui/material/Typography'
import { useStyles } from './AppBarTitle.styles'

/**
 * The Page title that is shown in the AppBar
 * @see https://marmelab.com/react-admin/Theming.html#using-the-withstyles-hoc
 * @see https://material-ui.com/styles/api/#withstyles-options-higher-order-component
 */
export const AppBarTitle: React.FC = () => {
  const styles = useStyles()
  return (
    <Typography noWrap variant="subtitle1" color="inherit" sx={styles}>
      <span id="react-admin-title" />
    </Typography>
  )
}
