import React from 'react'
import { IIconCard } from './IconCard.types'
import { createElement } from 'react'
import { Card, Box, Typography, Divider } from '@mui/material'
import { Link } from 'react-router-dom'
import { useBoxStyles, useCardStyles } from './IconCard.styles'

/**
 * A card component with an icon on the left side.
 * @typedef {object} IconCardProps
 * @property {React.ComponentType} icon The icon component to display.
 * @property {string} to The URL to link to.
 * @property {string} title The title to display.
 * @property {string} subtitle The subtitle to display.
 * @property {React.ReactNode} children The children to display.
 * @example
 * <IconCard
 *    icon={BusinessOutlinedIcon}
 *   to="/vendors"
 *  title="Vendors"
 * subtitle=" "
 * >
 *  some content
 * </IconCard>
 * @returns {React.FC<IconCardProps>}
 */
export const IconCard: React.FC<IIconCard> = (props) => {
  const boxStyles = useBoxStyles()
  const cardStyles = useCardStyles()

  return (
    <Card sx={cardStyles}>
      <Link to={props.to}>
        <Box sx={boxStyles}>
          <Box width="3em" className="icon">
            {createElement(props.icon, { fontSize: 'large' })}
          </Box>
          <Box textAlign="right">
            <Typography color="textSecondary">{props.title}</Typography>
            <Typography variant="h5" component="h2">
              {props.subtitle || ' '}
            </Typography>
          </Box>
        </Box>
      </Link>
      {props.children && <Divider />}
      {props.children}
    </Card>
  )
}
