import React from 'react'
import {
  ArrayInput,
  FormTab,
  required,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
  FormDataConsumer,
} from 'react-admin'
import { get } from 'lodash'
import AddIcon from '@mui/icons-material/AddOutlined'
import { ReferenceAutocomplete } from '../../molecules/ReferenceAutocomplete'
import { Button } from '@mui/material'
import {
  connectorChoices,
  modeChoices,
  speedChoices,
} from '../../particles/utils'

/**
 * Tabs for creating & editing a service port
 * @todo requires heavy refactoring.
 * @property {RaRecord} reference - The record to reference
 * @example <ServicePortTabsInput reference={record} />
 * @see https://marmelab.com/react-admin/CreateEdit.html#tabbed-form
 */
export const ServicePortTabsInput: React.FC<{ reference?: string }> = (
  props
) => {
  return (
    <TabbedForm syncWithLocation={false}>
      <FormTab label="general">
        <FormDataConsumer>
          {({ formData }) => (
            <>
              <TextInput source={'name'} validate={required()} fullWidth />
              <ReferenceAutocomplete
                required
                label="Client"
                source="clientId"
                reference="clients/list"
              />
              <ReferenceAutocomplete
                required
                label="Service"
                source="serviceId"
                reference="services/list"
                filter={{ clientId: formData?.clientId }}
              />
              <ReferenceAutocomplete
                required
                label="Site"
                source="serviceSiteId"
                reference="serviceSites/list"
                filter={{
                  serviceIds: formData?.serviceId,
                  clientId: formData?.clientId,
                }}
              />
            </>
          )}
        </FormDataConsumer>
        <SelectInput
          source="state"
          validate={required()}
          choices={[
            { id: 'pending', name: 'Pending' },
            { id: 'provisioning', name: 'Provisioning' },
            { id: 'cancelled', name: 'Cancelled' },
            { id: 'active', name: 'Active' },
            { id: 'terminated', name: 'Terminated' },
          ]}
          fullWidth
        />
        <FormDataConsumer>
          {({ formData }) => (
            <>
              <SelectInput
                source={'type'}
                label="Type"
                choices={[
                  { id: 'fiber', name: 'Fiber' },
                  { id: 'copper', name: 'Copper' },
                ]}
                validate={required()}
                fullWidth
              />
              <SelectInput
                source={'connector'}
                label="Connector"
                choices={connectorChoices(formData, 'type')}
                fullWidth
              />
              <SelectInput
                source={'mode'}
                label="Mode"
                choices={modeChoices(formData, 'type')}
                fullWidth
              />
              <SelectInput
                source={'speed'}
                label="Speed"
                choices={speedChoices(formData, 'type')}
                fullWidth
              />
            </>
          )}
        </FormDataConsumer>
      </FormTab>
      <FormTab label="patch">
        <TextInput source={'room'} label="Room" fullWidth />
        <TextInput source={'rack'} label="Rack" fullWidth />
        <TextInput source={'panel'} label="Panel" fullWidth />
        <TextInput source={'device'} label="Device" fullWidth />
        <TextInput source={'port'} label="Port" fullWidth />
        <TextInput source={'label'} label="Label" fullWidth />
      </FormTab>
      <FormTab label="equipment">
        <ArrayInput source={'equipment'} label={false} sx={{ mb: 3 }} fullWidth>
          <SimpleFormIterator
            getItemLabel={(index) => `#${index + 1}`}
            addButton={
              <Button>
                <AddIcon />
                &nbsp;Add Equipment
              </Button>
            }
            fullWidth
          >
            <FormDataConsumer>
              {({ getSource }) => (
                <>
                  <SelectInput
                    source={getSource?.('type') || ''}
                    label="Type"
                    choices={[
                      { id: 'router', name: 'Router' },
                      { id: 'modem', name: 'Modem' },
                      { id: 'ap', name: 'Access Point' },
                    ]}
                    validate={required()}
                    fullWidth
                  />
                  <TextInput
                    source={getSource?.('brand') || ''}
                    label="Brand"
                    validate={required()}
                    fullWidth
                  />
                  <TextInput
                    source={getSource?.('model') || ''}
                    label="Model"
                    validate={required()}
                    fullWidth
                  />
                  <TextInput
                    source={getSource?.('serial') || ''}
                    label="Serial"
                    fullWidth
                  />
                  <TextInput
                    source={getSource?.('hostname') || ''}
                    label="Hostname"
                    fullWidth
                  />
                  <TextInput
                    source={getSource?.('address') || ''}
                    label="IP address"
                    fullWidth
                  />
                  <TextInput
                    multiline
                    source={getSource?.('notes') || ''}
                    label="Notes"
                    fullWidth
                  />
                </>
              )}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="networks">
        <ArrayInput source={'networks'} label={false} sx={{ mb: 3 }} fullWidth>
          <SimpleFormIterator
            getItemLabel={(index) => `#${index + 1}`}
            fullWidth
            addButton={
              <Button>
                <AddIcon />
                &nbsp;Add Network
              </Button>
            }
          >
            <FormDataConsumer>
              {({ formData, getSource }) => (
                <>
                  <SelectInput
                    source={getSource?.('scope') || ''}
                    label="Scope"
                    choices={[
                      { id: 'local', name: 'Local' },
                      { id: 'global', name: 'Global (public)' },
                    ]}
                    validate={required()}
                    fullWidth
                  />
                  <SelectInput
                    source={getSource?.('version') || ''}
                    label="Version"
                    choices={[
                      { id: 'ipv4', name: 'IPv4' },
                      { id: 'ipv6', name: 'IPv6' },
                    ]}
                    validate={required()}
                    fullWidth
                  />
                  <SelectInput
                    source={getSource?.('allocation') || ''}
                    label="Allocation"
                    defaultValue="manual"
                    choices={[
                      { id: 'manual', name: 'Manual (static)' },
                      { id: 'dhcp', name: 'DHCP (dynamic)' },
                      { id: 'bgp', name: 'BGP session' },
                    ]}
                    validate={required()}
                    fullWidth
                  />
                  {get(formData, getSource?.('allocation') || '') === 'bgp' ? (
                    <>
                      <TextInput
                        source={getSource?.('localPeer') || ''}
                        label="Local BGP peer"
                        validate={required()}
                        fullWidth
                      />
                      <TextInput
                        source={getSource?.('remotePeer') || ''}
                        label="Remote BGP peer"
                        validate={required()}
                        fullWidth
                      />
                      <TextInput
                        source={getSource?.('localAS') || ''}
                        label="Local AS number"
                        validate={required()}
                        fullWidth
                      />
                      <TextInput
                        source={getSource?.('remoteAS') || ''}
                        label="Remote AS number"
                        validate={required()}
                        fullWidth
                      />
                      <TextInput
                        source={getSource?.('network') || ''}
                        label="Network"
                        validate={required()}
                        fullWidth
                      />
                    </>
                  ) : (
                    <>
                      <TextInput
                        source={getSource?.('network') || ''}
                        label="Network"
                        validate={required()}
                        fullWidth
                      />
                      <TextInput
                        source={getSource?.('address') || ''}
                        label="Primary address"
                        fullWidth
                      />
                      <TextInput
                        source={getSource?.('usableRange') || ''}
                        label="Usable range"
                        fullWidth
                      />
                      <TextInput
                        source={getSource?.('dns1') || ''}
                        label="DNS server 1"
                        fullWidth
                      />
                      <TextInput
                        source={getSource?.('dns2') || ''}
                        label="DNS server 2"
                        fullWidth
                      />
                    </>
                  )}
                  <TextInput
                    multiline
                    source={getSource?.('notes') || ''}
                    label="Notes"
                    fullWidth
                  />
                </>
              )}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="notes">
        <TextInput multiline source="notes" />
      </FormTab>
    </TabbedForm>
  )
}
