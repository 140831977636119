import React from 'react'
import { ILoginForm, IError } from './LoginForm.types'
import { TextInput } from 'react-admin'
import { CardContent } from '@mui/material'
import {
  Form,
  required,
  useTranslate,
  useLogin,
  useNotify,
  useSafeSetState,
} from 'ra-core'
import { Button } from '../../atoms/Button'
import { SubmitHandler, FieldValues } from 'react-hook-form'

/**
 * Login form template.
 * @property {ILoginForm} props The login form properties.
 * @returns {JSX.Element} The login form template.
 * @see https://marmelab.com/react-admin/Theming.html
 */
export const LoginForm: React.FC<ILoginForm> = (props) => {
  const [loading, setLoading] = useSafeSetState(false)
  const [twoFactorRequired, setTwoFactorRequired] = useSafeSetState(false)

  const login = useLogin()
  const translate = useTranslate()
  const notify = useNotify()

  const errorNotification = (error: string | IError) => {
    let errorMessage = 'ra.auth.sign_in_error'
    if (typeof error === 'string') {
      errorMessage = error
    } else if (error && error.message) {
      errorMessage = error.message
    }

    notify(errorMessage, {
      type: 'warning',
      messageArgs: {
        _: errorMessage,
      },
    })
  }

  const handleError = (error: string | IError) => {
    if (error === 'Two factor authentication required') {
      setTwoFactorRequired(true)
    } else {
      errorNotification(error)
    }
  }

  const submit: SubmitHandler<FieldValues> = async (values) => {
    setLoading(true)
    try {
      await login(values, props.redirectTo)
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
      handleError(error)
    }
  }

  return (
    <Form
      onSubmit={submit}
      mode="onChange"
      noValidate
      className={props.className}
    >
      <CardContent sx={{ width: 300 }}>
        <TextInput
          autoFocus={!twoFactorRequired}
          source="username"
          label={translate('ra.auth.username')}
          validate={required()}
          disabled={twoFactorRequired}
          fullWidth
        />
        <TextInput
          source="password"
          label={translate('ra.auth.password')}
          type="password"
          autoComplete="current-password"
          validate={required()}
          disabled={twoFactorRequired}
          fullWidth
        />
        {twoFactorRequired && (
          <TextInput
            autoFocus={twoFactorRequired}
            source="code"
            label={'Verification code'}
            validate={required()}
            disabled={loading}
            fullWidth
          />
        )}
        <Button
          variant="contained"
          type="submit"
          color="primary"
          disabled={loading}
          fullWidth
          sx={{ mt: 2 }}
          loading={loading}
        >
          {translate('ra.auth.sign_in')}
        </Button>
      </CardContent>
    </Form>
  )
}
