import React from 'react'
import { get } from 'lodash'
import { FunctionField, RaRecord } from 'react-admin'
import { IAlertStatusField } from './AlertStatus.types'

/**
 * Component to display the status of an alert.
 * @param props The properties
 * @returns The jsx component
 * @example <AlertStatusField />
 */
export const AlertStatusField: React.FC<IAlertStatusField> = (props) => {
  return (
    <FunctionField
      {...props}
      render={(record: RaRecord) =>
        get(record, props.source) === true ? '🔥 Firing' : '✅ Resolved'
      }
    />
  )
}
