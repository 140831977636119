import React from 'react'
import { List, ListProps, TextField, DateField, Datagrid } from 'react-admin'
import { EllipsedTextField } from '../../atoms/EllipsedField'
import { TypeStateField } from '../../atoms/TypeStateField'
import { TicketFilters } from '../../molecules/TicketFilters'
import { ReferenceChipList } from '../../molecules/ReferenceChipList'

/**
 * Page that displays a list of tickets.
 * @param {ListProps} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 * @example
 * return (
 * <TicketList />
 * )
 * @see {@link https://marmelab.com/react-admin/List.html|List}
 */
export const TicketList: React.FC<ListProps> = (props) => {
  return (
    <List
      empty={false}
      perPage={25}
      filters={<TicketFilters />}
      sort={{ field: 'createdAt', order: 'DESC' }}
      {...props}
    >
      <Datagrid rowClick="show">
        <TextField source="ticketNo" />
        <TypeStateField source="state" fullWidth />
        <EllipsedTextField source="summary" />
        <ReferenceChipList
          label={'Related client(s)'}
          source="clientIds"
          reference="clients"
        />
        <TextField source="status" fullWidth />
        <DateField source="createdAt" showTime />
        <DateField source="updatedAt" showTime />
      </Datagrid>
    </List>
  )
}
