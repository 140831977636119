import { MonitorTargetCreate } from './Create.component'
import { MonitorTargetEdit } from './Edit.component'
import { MonitorTargetList } from './List.component'
import { MonitorTargetShow } from './Show.component'

export default {
  list: MonitorTargetList,
  show: MonitorTargetShow,
  create: MonitorTargetCreate,
  edit: MonitorTargetEdit,
}
