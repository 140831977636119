import React from 'react'
import { IMetaList } from './MetaList.types'
import { ArrayInput, FormDataConsumer, SimpleFormIterator } from 'react-admin'
import { MetaRowInput } from '../../molecules/MetaRow'
import { useGenerateMetaListRecord } from './MetaList.hooks'

/**
 * Displays an editable list of meta data rows.
 * @component
 * @param {IMetaList} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 * @example
 * return (
 * <MetaListInput source="metaList" />
 * )
 * @example
 * return (
 * <MetaListInput source="metaList" label="Meta" />
 * )
 * @example
 * return (
 * <MetaListInput source="metaList" label="Meta" fullWidth />
 * )
 * @example
 * return (
 * <MetaListInput source="metaList" label="Meta" fullWidth helperText="This is a helper text" />
 * )
 * @example
 * return (
 * <MetaListInput source="metaList" label="Meta" fullWidth helperText="This is a helper text" validate={required()} />
 * )
 * @see {@link https://marmelab.com/react-admin/CreateEdit.html#full-width-forms|Full Width Forms}
 * @see {@link https://marmelab.com/react-admin/Inputs.html#arrayinput|Array Input}
 * @see {@link https://marmelab.com/react-admin/Inputs.html#simpleformiterator|Simple Form Iterator}
 * @see {@link https://marmelab.com/react-admin/Inputs.html#formdataconsumer|Form Data Consumer}
 * @see {@link https://marmelab.com/react-admin/Inputs.html#arrayinput|Array Input}
 */
export const MetaListInput: React.FC<IMetaList> = (props) => {
  const { source } = useGenerateMetaListRecord(props.source)

  return (
    <ArrayInput source={source} label={false} fullWidth>
      <SimpleFormIterator disableReordering={true}>
        <FormDataConsumer fullWidth>
          {(props) => <MetaRowInput {...props} />}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  )
}
