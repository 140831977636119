import React from 'react'
import { SearchClientFilter } from '../../molecules/SearchClientFilter'
import { ReferenceChip } from '../../molecules/ReferenceChip'
import { List, ListProps, Datagrid, TextField, DateField } from 'react-admin'

/**
 * Page to list projects.
 * @property {ListProps} props
 * @returns {React.FC<ListProps>}
 */
export const ProjectList: React.FC<ListProps> = (props) => {
  return (
    <List
      empty={false}
      perPage={25}
      filters={<SearchClientFilter />}
      sort={{ field: 'createdAt', order: 'DESC' }}
      {...props}
    >
      <Datagrid rowClick="show">
        <TextField source="projectNo" />
        <ReferenceChip
          referenceSource="clientId"
          tooltipSource="clientNo"
          source="Client"
          reference="clients"
          emptyText="N/A"
          link="show"
          chipSource="name"
        />
        <TextField source="title" />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
      </Datagrid>
    </List>
  )
}
