import React from 'react'
import {
  useCreate,
  useNotify,
  useRecordContext,
  useRefresh,
  Confirm,
} from 'react-admin'
import { Button, Dialog, DialogTitle, DialogContent } from '@mui/material'
import { WizardForm, WizardFormStep } from '@react-admin/ra-form-layout'
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedInOutlined'
import CloseIcon from '@mui/icons-material/Close'
import { OnboardingRequest } from './Request.component'
import { OnboardingUserForm } from './UserForm.component'
import { OnboardingClientForm } from './ClientForm.component'
import { OnboardingContactsForm } from './ContactsForm.component'
import { OnboardingSummary } from './Summary.component'
import { FieldValues } from 'react-hook-form'

/**
 * Modal for completing an onboarding request.
 * @returns {React.ReactElement}
 * @example <OnboardingModal />
 * @see https://marmelab.com/react-admin/Fields.html
 */
export const OnboardingModal: React.FC = () => {
  const [showDialog, setShowDialog] = React.useState(false)
  const [, setIsSubmitting] = React.useState(false)
  const [confirmClose, setConfirmClose] = React.useState(false)
  const record = useRecordContext()
  const notify = useNotify()
  const refresh = useRefresh()
  const [create] = useCreate()

  const handleClick = () => {
    setShowDialog(true)
  }

  const handleCloseClick = (event?: any, reason?: string) => {
    if (reason && reason === 'backdropClick') {
      // prevent backdrop click close
      return false
    }
    setConfirmClose(true)
  }

  const handleClose = () => {
    setConfirmClose(false)
    setShowDialog(false)
  }

  const handleSubmit = async (values: FieldValues) => {
    setIsSubmitting(true)
    try {
      await create(
        `onboardings/${values.id}/complete`,
        { data: values },
        { returnPromise: true }
      )
      notify('Onboarding has been completed', { type: 'info' })
      setIsSubmitting(false)
      setShowDialog(false)
      setTimeout(refresh, 500)
    } catch (error: any) {
      notify(error.message || error, { type: 'warning' })
    }
  }

  return (
    <>
      <Button
        color="primary"
        aria-label="complete"
        onClick={handleClick}
        disabled={!['pending', 'verified'].includes(record?.state)}
      >
        <AssignmentTurnedInIcon />
        &nbsp;{'Complete'}
      </Button>
      <Confirm
        isOpen={confirmClose}
        title="Cancel On-boarding Completion"
        content="Are you sure you want to cancel completing this on-boarding? Any changed data will be lost."
        onConfirm={handleClose}
        onClose={() => {
          setConfirmClose(false)
        }}
      />
      <Dialog
        fullWidth
        maxWidth="lg"
        open={showDialog}
        onClose={handleCloseClick}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          On-boarding Completion
          <Button
            sx={{ margin: 0, float: 'right' }}
            size={'small'}
            onClick={handleCloseClick}
          >
            <CloseIcon />
            Close
          </Button>
        </DialogTitle>
        <DialogContent>
          <WizardForm onSubmit={handleSubmit}>
            <WizardFormStep label="Request">
              <OnboardingRequest />
            </WizardFormStep>
            <WizardFormStep label="User">
              <OnboardingUserForm />
            </WizardFormStep>
            <WizardFormStep label="Client">
              <OnboardingClientForm />
            </WizardFormStep>
            <WizardFormStep label="Contacts">
              <OnboardingContactsForm />
            </WizardFormStep>
            <WizardFormStep label="Summary">
              <OnboardingSummary />
            </WizardFormStep>
          </WizardForm>
        </DialogContent>
      </Dialog>
    </>
  )
}
