import React from 'react'
import { Show, ShowProps } from 'react-admin'
import { PageTitle } from '../../atoms/PageTitle'
import { ServicePortTabsDisplay } from '../../templates/ServicePortTabs'

/**
 * Page to show a service port.
 * @param props
 * @returns {React.FC}
 * @example
 * import { ServicePortShow } from './components/pages/ServicePort/Show.component'
 * <ServicePortShow />
 */
export const ServicePortShow: React.FC<ShowProps> = (props) => {
  return (
    <Show {...props} title={<PageTitle prefix="Service port" />}>
      <ServicePortTabsDisplay {...props} />
    </Show>
  )
}
