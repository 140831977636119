import React from 'react'
import { IReferenceMany } from './FormTab.types'
import {
  Tab,
  Pagination,
  useGetManyReference,
  useRecordContext,
  ReferenceManyField,
} from 'react-admin'
import { FormTabLabel } from './FormTabLabel.component'

/**
 * Represents a reference many tab component.
 * If the reference or target props are undefined, the component will not be rendered.
 * @param props - The component props.
 * @returns The rendered ReferenceManyTab component.
 * @see IReferenceMany
 * @see Tab
 * @see Pagination
 * @see useGetManyReference
 * @see useRecordContext
 * @see ReferenceManyField
 * @see FormTabLabel
 * @see https://marmelab.com/react-admin/Inputs.html#using-a-custom-input-component
 * @see https://mui.com/components/tabs/#tabs
 * @see https://mui.com/components/pagination/#pagination
 * @see https://mui.com/components/chips/#chip
 *
 */
export const ReferenceManyTab: React.FC<IReferenceMany> = (props) => {
  const record = useRecordContext()

  let { target, ...rest } = props

  const { isLoading, total } = useGetManyReference(
    props.reference,
    {
      target: target,
      id: record.id,
      pagination: props.pagination || { page: 1, perPage: 25 },
      sort: { field: 'createdAt', order: 'DESC' },
    },
    {
      enabled: !!record,
    }
  )

  if (!props.reference || !target) {
    return null
  }

  return (
    <Tab
      {...rest}
      label={
        <FormTabLabel
          loading={isLoading || total === undefined}
          label={props.label || props.reference}
          count={total}
        />
      }
    >
      <ReferenceManyField
        label={false}
        reference={props.reference}
        target={target}
        pagination={<Pagination />}
      >
        {props.children}
      </ReferenceManyField>
    </Tab>
  )
}
