import React from 'react'
import { ISiteModalInput } from './SiteModalInput.types'
import { CreateInDialogButton } from '@react-admin/ra-form-layout'
import { SimpleForm, TextInput, SelectInput, required } from 'react-admin'
import { AddressInput } from '../AddressInput'
import { ReferenceAutocomplete } from '../../molecules/ReferenceAutocomplete'
import { useRecord } from './SiteModalInput.hooks'

/**
 * Form for creating a new site.
 * @property {string} resource The resource name.
 * @property {string} reference The reference name
 * @example
 * <SiteModalInput resource="servicesites" reference="servicesites/list" />
 * @see https://marmelab.com/react-admin/CreateEdit.html
 * @see https://marmelab.com/react-admin/Inputs.html
 * @see https://marmelab.com/react-admin/Inputs.html#referenceinput
 */
export const SiteModalInput: React.FC<ISiteModalInput> = (props) => {
  const record = useRecord(props.reference)

  return (
    <CreateInDialogButton
      inline
      fullWidth
      record={record}
      resource={props.resource}
    >
      <SimpleForm {...props}>
        <ReferenceAutocomplete
          required
          label="Client"
          source="clientId"
          reference="clients/list"
        />
        <SelectInput
          source="type"
          choices={[
            { id: 'building', name: 'Building' },
            { id: 'datacenter', name: 'Datacenter' },
          ]}
          defaultValue="building"
          fullWidth
          validate={[required()]}
        />
        <TextInput source="name" validate={required()} fullWidth />
        <AddressInput />
        <TextInput multiline source="notes" defaultValue={''} fullWidth />
      </SimpleForm>
    </CreateInDialogButton>
  )
}
