import React from 'react'
import { List, TextField, DateField, useRecordContext } from 'react-admin'
import { LabelledDatagrid } from '../../atoms/LabelledDatagrid'
import { TypeStateField } from '../../atoms/TypeStateField'
import { EllipsedTextField } from '../../atoms/EllipsedField'
import { LastActivityTextField } from '../../atoms/LastActivityTextField'
import { IRequestQuotesList } from './RequestQuotesList.types'
/**
 * List of requested quotes
 * @property {ListProps} props
 * @property {string} props.record.id
 * @returns {JSX.Element}
 * @example
 * <RequestQuotesList />
 */
export const RequestQuotesList: React.FC<IRequestQuotesList> = (props) => {
  const record = useRecordContext(props)

  return (
    <List
      title={' '}
      {...props}
      sort={{ field: 'createdAt', order: 'DESC' }}
      empty={false}
      exporter={false}
      sx={{
        '& .MuiToolbar-gutters': { display: 'none' },
        '& .MuiTablePagination-toolbar': { display: 'flex' },
      }}
      resource={'quotes'}
      filter={{ requestId: record.id }}
      storeKey={`requestQuotes.${record.id}`}
      disableSyncWithLocation
    >
      <LabelledDatagrid rowClick="show" optimized bulkActionButtons={false}>
        <TextField source="quoteNo" />
        <TypeStateField source="state" fullWidth />
        <TextField source="type" />
        <EllipsedTextField source="client.name" />
        <LastActivityTextField />
        <DateField source="offeredAt" showTime />
      </LabelledDatagrid>
    </List>
  )
}
