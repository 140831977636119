import React from 'react'
import { List, ListProps, Datagrid, TextField, DateField } from 'react-admin'
import { TermFilter } from '../../atoms/TermFilter'

/**
 * List all service category rows.
 * @property {ListProps} props
 * @returns {React.FC<ListProps>}
 * @example
 * import {ServiceCategoryList} from './ServiceCategory'
 * return <ServiceCategoryList {...props} />
 * @see https://marmelab.com/react-admin/List.html
 * @see https://marmelab.com/react-admin/Fields.html
 */
export const ServiceCategoryList: React.FC<ListProps> = (props) => {
  return (
    <List
      {...props}
      empty={false}
      filters={<TermFilter />}
      sort={{ field: 'name', order: 'ASC' }}
      perPage={25}
    >
      <Datagrid rowClick="show">
        <TextField source="name" fullWidth />
        <TextField source="key" emptyText="N/A" />
        <DateField source="createdAt" showTime />
        <DateField source="updatedAt" showTime />
      </Datagrid>
    </List>
  )
}
