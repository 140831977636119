import { VendorContractCreate } from './Create.component'
import { VendorContractEdit } from './Edit.component'
import { VendorContractList } from './List.component'
import { VendorContractShow } from './Show.component'

export default {
  list: VendorContractList,
  show: VendorContractShow,
  create: VendorContractCreate,
  edit: VendorContractEdit,
}
