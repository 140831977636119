import React, { useEffect } from 'react'
import { LoginForm } from '../../templates/LoginForm'
import { ILogin } from './Login.types'
import { useNavigate } from 'react-router-dom'
import { useCheckAuth } from 'ra-core'
import { Card } from '@mui/material'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { Logo } from '../../atoms/Logo'
import {
  useVersionLabelStyles,
  useAvatarStyles,
  useLoginCardStyles,
} from './Login.styles'
import { VersionLabel } from '../../molecules/VersionLabel'
import { CoverImage } from '../../atoms/CoverImage'

/**
 * Login page component.
 * @returns JSX.Element
 * @example
 * return (
 * <Login />
 * )
 * @see {@link https://marmelab.com/react-admin/CreateEdit.html|CreateEdit}
 * @see {@link https://marmelab.com/react-admin/Theming.html|Theming}
 * @see {@link https://marmelab.com/react-admin/Theming.html#using-a-custom-theme|Using a Custom Theme}
 */
export const Login: React.FC<ILogin> = (props) => {
  const { children } = props
  const versionLabelStyles = useVersionLabelStyles()
  const avatarStyles = useAvatarStyles()
  const loginCardStyles = useLoginCardStyles()
  const checkAuth = useCheckAuth()
  const navigate = useNavigate()

  // If the user is already authenticated, redirect to the home page.
  useEffect(() => {
    const authenticate = async () => {
      try {
        await checkAuth({}, false)
        navigate('/')
      } catch {}
    }
    authenticate()
  }, [checkAuth, navigate])

  return (
    <CoverImage>
      <Card sx={loginCardStyles}>
        <Box sx={avatarStyles}>
          <Logo height={64} />
        </Box>
        <Typography variant="body1">
          {props.subtitle || 'Please sign in to continue'}
        </Typography>
        {children || <LoginForm />}
      </Card>
      <VersionLabel sx={versionLabelStyles} />
    </CoverImage>
  )
}
