import React from 'react'
import { Filter, TextInput, SelectArrayInput } from 'react-admin'
import { ReferenceAutocomplete } from '../../molecules/ReferenceAutocomplete'
import { ITicketFilters } from './TicketFilters.types'

/**
 * Filters for the ticket list page.
 * @returns {React.FC}
 * @example
 * import { TicketFilters } from './TicketFilters.component.tsx'
 * <TicketFilters />
 * @see {@link https://marmelab.com/react-admin/List.html|List}
 * @see {@link https://marmelab.com/react-admin/Filter.html|Filter}
 */
export const TicketFilters: React.FC<ITicketFilters> = (props) => {
  return (
    <Filter {...props}>
      <TextInput label="Search" source="term" alwaysOn />
      <SelectArrayInput
        source="state"
        alwaysOn
        choices={[
          { id: 'pending', name: 'Pending' },
          { id: 'in-progress', name: 'In Progress' },
          { id: 'done', name: 'Done' },
        ]}
        fullWidth
      />
      <ReferenceAutocomplete
        label="Client"
        source="clientId"
        reference="clients/list"
        alwaysOn
      />
      <ReferenceAutocomplete
        label="Service"
        source="serviceId"
        reference="services/list"
      />
    </Filter>
  )
}
