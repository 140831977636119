import React from 'react'
import {
  ReferenceManyField,
  TextField,
  ShowButton,
  Pagination,
  BooleanField,
} from 'react-admin'
import { LabelledDatagrid } from '../../atoms/LabelledDatagrid'

import { ICoverageDatacentersList } from './CoverageDatacentersList.types'
import { ReferenceChip } from '../../molecules/ReferenceChip'

/**
 * List of coverage datacenters, used in related context
 * @returns {React.FC}
 * @example
 * import { CoverageDatacentersList } from './CoverageDatacentersList'
 * <CoverageDatacentersList />
 * @see https://marmelab.com/react-admin/Fields.html
 */
export const CoverageDatacentersList: React.FC<ICoverageDatacentersList> = (
  props
) => {
  return (
    <ReferenceManyField
      {...props}
      reference="coverageDatacenters"
      target={props.target}
      label={props.label || 'Coverage datacenters'}
      pagination={<Pagination />}
      sort={props.sort || { field: 'createdAt', order: 'DESC' }}
    >
      <LabelledDatagrid bulkActionButtons={false}>
        <TextField source="name" />
        <ReferenceChip
          referenceSource="datacenterId"
          tooltipSource="name"
          source="datacenterId"
          label="Datacenter"
          chipSource="name"
          reference="datacenters"
          emptyText={'N/A'}
          link={'show'}
        />
        <TextField source="availability" emptyText="N/A" />
        <BooleanField source="isActive" />
        <ShowButton />
      </LabelledDatagrid>
    </ReferenceManyField>
  )
}
