import React from 'react'
import { ListBase, ListButton } from 'react-admin'
import {
  useMediaQuery,
  Theme,
  Card,
  CardHeader,
  CardContent,
} from '@mui/material'
import { Segment } from '../../atoms/Segment'
import { NewestTicketsSlim } from './NewestTicketsSlim.component'
import { NewestTicketsFull } from './NewestTicketsFull.component'

/**
 * This component is used to display the newest tickets in the dashboard.
 * @example
 * return <NewestTicketsSegment />
 */
export const NewestTicketsSegment: React.FC = () => {
  const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))

  return (
    <Segment>
      <Card sx={{ flex: 1 }}>
        <CardHeader
          subheader={'Newest tickets'}
          action={<ListButton resource="tickets" label={'Tickets'} />}
        />
        <CardContent>
          <ListBase
            resource="tickets"
            perPage={5}
            sort={{ field: 'createdAt', order: 'DESC' }}
            disableSyncWithLocation
          >
            {isXSmall || isSmall ? (
              <NewestTicketsSlim />
            ) : (
              <NewestTicketsFull />
            )}
          </ListBase>
        </CardContent>
      </Card>
    </Segment>
  )
}
