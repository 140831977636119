import React from 'react'
import { ReferenceAutocomplete } from '../../molecules/ReferenceAutocomplete'
import { SelectInput, TextInput, required } from 'react-admin'
import { AddressInput } from '../AddressInput'

/**
 * Create and edit form for a site.
 * @returns {React.FC}
 * @example
 * import { SiteInput } from './SiteInput'
 * <SiteInput />
 * @see https://marmelab.com/react-admin/CreateEdit.html
 * @see https://marmelab.com/react-admin/Inputs.html
 */
export const SiteInput: React.FC = () => {
  return (
    <>
      <ReferenceAutocomplete
        required
        label="Client"
        source="clientId"
        reference="clients/list"
      />
      <SelectInput
        source="type"
        choices={[
          { id: 'building', name: 'Building' },
          { id: 'datacenter', name: 'Datacenter' },
        ]}
        defaultValue="building"
        fullWidth
        validate={[required()]}
      />
      <TextInput source="name" validate={required()} fullWidth />
      <AddressInput />
      <TextInput multiline source="notes" defaultValue={''} fullWidth />
    </>
  )
}
