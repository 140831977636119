export const useStyles = () => {
  return {
    display: {
      xs: 'none',
      sm: 'block',
    },
    order: -1, // display on the left rather than on the right of the list
    width: '15em',
    marginRight: '1em',
  }
}
