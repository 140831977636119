import { DocumentCategoryCreate } from './Create.component'
import { DocumentCategoryEdit } from './Edit.component'
import { DocumentCategoryList } from './List.component'
import { DocumentCategoryShow } from './Show.component'

export default {
  list: DocumentCategoryList,
  show: DocumentCategoryShow,
  create: DocumentCategoryCreate,
  edit: DocumentCategoryEdit,
}
