import React from 'react'
import { TextField, TextFieldProps } from 'react-admin'
import { useEllipsedFieldStyles } from './EllipsedField.styles'

/**
 * Adds ellipsis to a TextField.
 * @see https://marmelab.com/react-admin/Fields.html#textfield
 */
export const EllipsedTextField: React.FC<
  TextFieldProps & { maxWidth?: number }
> = (props) => {
  const styles = useEllipsedFieldStyles(props)
  return (
    <TextField {...props} sx={styles}>
      {props.children}
    </TextField>
  )
}
