import React from 'react'
import {
  Show,
  ShowProps,
  Datagrid,
  TabbedShowLayout,
  Tab,
  TextField,
  DateField,
  BooleanField,
  SimpleShowLayout,
  WithRecord,
  ArrayField,
} from 'react-admin'
import { MutationsList } from '../../organisms/MutationsList'
import { MetaListDisplay } from '../../organisms/MetaList'
import { TypeStateField } from '../../atoms/TypeStateField'
import { PageTitle } from '../../atoms/PageTitle'
import { ReferenceChip } from '../../molecules/ReferenceChip'
import { AddressDisplay } from '../../organisms/AddressDisplay'
import { ContactDetailsDisplay } from '../../organisms/ContactDetailsDisplay'
import { OnboardingModal } from '../../organisms/OnboardingModal'
import { Toolbar } from '../../molecules/Toolbar'

/**
 * Page to show a single onboarding.
 * @todo general tab shares common design with others components.
 * It should be extracted to a common component.
 * @param props The properties
 * @returns The jsx component
 * @example <OnboardingShow />
 */
export const OnboardingShow: React.FC<ShowProps> = (props) => {
  return (
    <Show
      {...props}
      title={<PageTitle prefix="Onboarding" />}
      actions={
        <Toolbar showEdit>
          <OnboardingModal />
        </Toolbar>
      }
    >
      <TabbedShowLayout {...props}>
        <Tab label="general" key="general">
          <TextField source="description" emptyText={'N/A'} />
          <TypeStateField source="state" />
          <ReferenceChip
            referenceSource="clientId"
            tooltipSource="clientNo"
            source="name"
            chipSource="name"
            label="Related Client"
            link="show"
            reference="clients"
            removeDoubleLabel
          />
          <ReferenceChip
            referenceSource="userId"
            tooltipSource="email"
            source="name"
            chipSource="name"
            label="Related User"
            link="show"
            reference="users"
            removeDoubleLabel
          />
          <TypeStateField source="type" />
          <BooleanField source="requireApproval" />
          <BooleanField source="requireVerification" />
          <DateField source="verifiedAt" emptyText={'N/A'} showTime />
          <DateField source="completedAt" emptyText={'N/A'} showTime />
          <DateField source="updatedAt" showTime />
          <DateField source="createdAt" showTime />
        </Tab>
        <Tab label="user" key="user">
          <WithRecord
            render={(record) => (
              <SimpleShowLayout sx={{ m: 0, p: 0 }} record={record.user}>
                <TextField source="name" />
                <TextField source="email" />
              </SimpleShowLayout>
            )}
          />
        </Tab>
        <Tab label="client" key="client">
          <WithRecord
            render={(record) => (
              <SimpleShowLayout sx={{ m: 0, p: 0 }} record={record.client}>
                <TextField source="name" emptyText={'N/A'} />
                <AddressDisplay />
                <TextField source="phoneNumber" emptyText={'N/A'} fullWidth />
                <TextField source="email" fullWidth />
                <TextField source="companyName" fullWidth />
                <TextField source="companyRegNo" emptyText={'N/A'} fullWidth />
                <TextField source="companyVatNo" emptyText={'N/A'} fullWidth />
                <TextField source="companyIban" emptyText={'N/A'} fullWidth />
              </SimpleShowLayout>
            )}
          />
        </Tab>
        <Tab label="contacts" key="contacts">
          <ArrayField source="contacts" emptyText={'N/A'} label={false}>
            <Datagrid bulkActionButtons={false}>
              <TextField source="category" />
              <TextField source="name" />
              <ContactDetailsDisplay />
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label="environment" key="environment">
          <MetaListDisplay source="environment" />
        </Tab>
        <Tab label={'history'} key="history">
          <MutationsList showActor type="history" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
