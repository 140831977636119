import React from 'react'
import { Show, ShowProps, Tab, TabbedShowLayout, DateField } from 'react-admin'
import { MarkdownField } from '@react-admin/ra-markdown'
import { PageTitle } from '../../atoms/PageTitle'
import { LastActivityTextField } from '../../atoms/LastActivityTextField'
import { RequestQuotesList } from '../../molecules/RequestQuotesList'
import { MutationsList } from '../../organisms/MutationsList'
import { RequestSitesList } from '../../molecules/RequestSitesList'
import { RequestServicesList } from '../../molecules/RequestServicesList'
import { ReferenceChip } from '../../molecules/ReferenceChip'
import { RequestProductsList } from '../../molecules/RequestProductsList'
import { JsonField } from 'react-admin-json-view'

/**
 * Page to show a single request
 * @param props
 * @example <RequestShow />
 */
export const RequestShow: React.FC<ShowProps> = (props) => {
  return (
    <Show {...props} title={<PageTitle prefix="Request" />}>
      <TabbedShowLayout {...props}>
        <Tab label="general">
          <ReferenceChip
            referenceSource="projectId"
            tooltipSource="projectNo"
            source="title"
            reference="projects"
            label="Project"
            emptyText="N/A"
            link="show"
            chipSource="title"
            removeDoubleLabel
          />
          <ReferenceChip
            referenceSource="clientId"
            tooltipSource="name"
            source="name"
            reference="clients"
            label="Client"
            emptyText="N/A"
            link="show"
            chipSource="name"
            removeDoubleLabel
          />
          <ReferenceChip
            referenceSource="endCustomerId"
            tooltipSource="name"
            source="name"
            reference="endCustomers"
            label="End Customer"
            emptyText="N/A"
            link="show"
            chipSource="name"
            removeDoubleLabel
          />
          <LastActivityTextField />
          <DateField source="updatedAt" showTime />
          <DateField source="createdAt" showTime />
        </Tab>
        <Tab label={'services'}>
          <RequestServicesList sx={{}} />
        </Tab>
        <Tab label={'sites'}>
          <RequestSitesList sx={{}} />
        </Tab>
        <Tab label={'product results'}>
          <RequestProductsList />
        </Tab>
        <Tab label={'quotes'}>
          <RequestQuotesList sx={{}} />
        </Tab>
        <Tab label={'notes'}>
          <MarkdownField source="notes" emptyText="N/A" />
          <MarkdownField source="notesForClient" emptyText="N/A" />
        </Tab>
        <Tab label={'debug'}>
          <JsonField source="debugData" />
        </Tab>
        <Tab label={'history'}>
          <MutationsList showActor type="history" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
