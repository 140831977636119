import React from 'react'
import {
  BooleanField,
  TextField,
  Datagrid,
  FunctionField,
  ListContextProvider,
  ShowButton,
  useGetList,
  useGetRecordId,
  useList,
} from 'react-admin'
import { ServiceHealthIndicatorField } from '../../atoms/ServiceHealthIndicatorField'
import { ServiceHealthProbeList } from '../../molecules/ServiceHealthProbeList'
import { Message } from '../../atoms/Message'

/**
 * List of service health statuses
 * @returns {JSX.Element}
 * @example
 * import { ServicesHealthList } from './ServicesHealthList.component'
 * <ServicesHealthList />
 */
export const ServicesHealthList: React.FC = () => {
  const recordId = useGetRecordId()
  const { data, isLoading, error } = useGetList(`services/${recordId}/health`)
  const listContext = useList({ data, isLoading })

  if (isLoading) {
    return <Message message="Loading health details.." />
  }
  if (error) {
    ;<Message message="Error" />
  }

  return (
    <ListContextProvider value={listContext}>
      <Datagrid
        sx={{
          width: '100%',
          '& .column-title': {
            maxWidth: '20em',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          },
        }}
        bulkActionButtons={false}
        rowClick="expand"
        expand={<ServiceHealthProbeList />}
      >
        <TextField source="hostname" label="Target hostname" />
        <FunctionField
          render={(record: any) => record.monitorProbes?.length}
          label="Probes"
          sortable={false}
        />
        <ServiceHealthIndicatorField
          source="health"
          label="Health"
          sortable={false}
        />
        <TextField
          source="health.severity"
          emptyText="N/A"
          label="Severity"
          sortable={false}
        />
        <BooleanField source="isActive" emptyText="N/A" />
        <BooleanField source="isMuted" emptyText="N/A" />
        <ShowButton resource="monitorTargets" />
      </Datagrid>
    </ListContextProvider>
  )
}
