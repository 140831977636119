import React from 'react'
import {
  Edit,
  EditProps,
  Datagrid,
  TextInput,
  TextField,
  DateField,
  required,
  TabbedForm,
  FormTab,
  DeleteButton,
  FormDataConsumer,
  SelectInput,
} from 'react-admin'
import { MetaListInput } from '../../organisms/MetaList'
import { ReferenceManyFormTab } from '../../atoms/FormTab'
import { FileSizeField } from '../../atoms/FileSizeField'
import { DownloadFileButton } from '../../atoms/DownloadFileButton'
import { ReferenceAutocomplete } from '../../molecules/ReferenceAutocomplete'
import { DocumentFileToolbar } from '../../organisms/DocumentFileToolbar'

/**
 * Page to edit a document.
 * @param {EditProps} props
 * @returns {JSX.Element}
 * @example
 * return (
 * <DocumentEdit {...props} />
 * )
 * @see {@link https://marmelab.com/react-admin/Edit.html|Edit}
 * @see {@link https://marmelab.com/react-admin/Inputs.html|Inputs}
 */
export const DocumentEdit: React.FC<EditProps> = (props) => {
  return (
    <Edit {...props} mutationMode="undoable">
      <TabbedForm>
        <FormTab label="general">
          <ReferenceAutocomplete
            required
            disabled
            label="Category"
            source="categoryId"
            reference="documentCategories/list"
          />
          <TextInput disabled source="documentNo" fullWidth />
          <TextInput source="title" validate={required()} fullWidth />
          <SelectInput
            source="state"
            validate={required()}
            choices={[
              { id: 'draft', name: 'Draft' },
              { id: 'pending', name: 'Pending' },
              { id: 'awaiting-client', name: 'Awaiting Client' },
              { id: 'final', name: 'Final' },
            ]}
            fullWidth
          />
          <ReferenceAutocomplete
            label="Client"
            source="clientId"
            reference="clients/list"
          />
          <ReferenceAutocomplete
            label="Vendor"
            source="vendorId"
            reference="vendors/list"
          />
          <FormDataConsumer>
            {({ formData }) => (
              <ReferenceAutocomplete
                label="Service"
                source="serviceId"
                reference="services/list"
                filter={{
                  clientId: formData.clientId,
                  vendorId: formData.vendorId,
                }}
                disableSyncWithLocation
              />
            )}
          </FormDataConsumer>
        </FormTab>
        <ReferenceManyFormTab
          reference="files"
          target="documentId"
          label="files"
          path="files"
        >
          <Datagrid
            sx={{
              width: '100%',
              '& .column-title': {
                maxWidth: '20em',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              },
            }}
            rowClick={''}
          >
            <TextField source="name" variant="caption" />
            <TextField source="contentType" label={'Type'} />
            <FileSizeField />
            <DateField source="createdAt" showTime />
            <DownloadFileButton resource="files" />
            <DeleteButton redirect={false} />
          </Datagrid>
          <DocumentFileToolbar />
        </ReferenceManyFormTab>
        <FormTab label="meta" path="meta">
          <MetaListInput source="meta" />
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}
