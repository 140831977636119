import React from 'react'
import {
  Edit,
  EditProps,
  TabbedForm,
  FormTab,
  TextInput,
  BooleanInput,
  required,
} from 'react-admin'
import { AddressInput } from '../../organisms/AddressInput'

/**
 * Page to edit a datacenter.
 * @property {EditProps} props
 * @example
 * <DatacenterEdit />
 * @returns {React.FC<EditProps>}
 * @see https://marmelab.com/react-admin/Edit.html
 */
export const DatacenterEdit: React.FC<EditProps> = (props) => {
  return (
    <Edit {...props}>
      <TabbedForm>
        <FormTab label={'general'}>
          <TextInput source="key" validate={required()} fullWidth />
          <TextInput source="name" validate={required()} fullWidth />
          <BooleanInput source="isActive" defaultValue={true} fullWidth />
        </FormTab>
        <FormTab label={'address'}>
          <AddressInput />
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}
