import { LastMileProviderCreate } from './Create.component'
import { LastMileProviderEdit } from './Edit.component'
import { LastMileProviderList } from './List.component'
import { LastMileProviderShow } from './Show.component'

export default {
  create: LastMileProviderCreate,
  edit: LastMileProviderEdit,
  list: LastMileProviderList,
  show: LastMileProviderShow,
}
