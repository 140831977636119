import React from 'react'
import { IDocumentModalInput } from './DocumentModalInput.types'
import { useRecord } from './DocumentModalInput.hooks'
import { CreateInDialogButton } from '@react-admin/ra-form-layout'
import {
  SimpleForm,
  TextInput,
  SelectInput,
  FileInput,
  FileField,
  FormDataConsumer,
  regex,
  required,
} from 'react-admin'
import { ReferenceAutocomplete } from '../../molecules/ReferenceAutocomplete'

/**
 * Button and Modal to create a new document.
 * @property {string} titlePrefix - The prefix to the title of the modal.
 * @example
 * <DocumentModalInput titlePrefix="Document" />
 * @returns {React.FC<IDocumentModalInput>}
 */
export const DocumentModalInput: React.FC<IDocumentModalInput> = (props) => {
  const record = useRecord(props.reference)

  return (
    <CreateInDialogButton
      inline
      fullWidth
      maxWidth="md"
      record={record}
      resource={'documents'}
    >
      <SimpleForm {...props}>
        <ReferenceAutocomplete
          required
          label="Category"
          source="categoryId"
          reference="documentCategories/list"
        />
        <TextInput
          source="documentNo"
          fullWidth
          label="Document no. (leave empty to auto generate)"
          validate={[regex(/^[A-Z]{3}[0-9]{5,12}$/)]}
        />
        <TextInput source="title" fullWidth />
        <SelectInput
          source="state"
          defaultValue="draft"
          validate={required()}
          choices={[
            { id: 'draft', name: 'Draft' },
            { id: 'pending', name: 'Pending' },
            { id: 'awaiting-client', name: 'Awaiting Client' },
            { id: 'final', name: 'Final' },
          ]}
          fullWidth
        />
        <ReferenceAutocomplete
          label="Client"
          source="clientId"
          reference="clients/list"
        />
        <FormDataConsumer>
          {({ formData, getSource, scopedFormData, ...rest }) =>
            formData.clientId ? (
              <ReferenceAutocomplete
                label="Service"
                source="serviceId"
                reference="services/list"
                filter={{ clientId: formData.clientId }}
                disableSyncWithLocation
              />
            ) : null
          }
        </FormDataConsumer>
        <FileInput source="file" label="Upload file" multiple={true}>
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </CreateInDialogButton>
  )
}
