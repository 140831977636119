import React from 'react'
import {
  ArrayField,
  BooleanField,
  DateField,
  Datagrid,
  ShowButton,
  SimpleList,
  TextField,
} from 'react-admin'
import { AlertStatusField } from '../../atoms/AlertStatusField'

/**
 * List of service health probes.
 * @returns {JSX.Element}
 * @example
 * import { ServiceHealthProbeList } from './ServiceHealthProbeList.component'
 * <ServiceHealthProbeList />
 */
export const ServiceHealthProbeList: React.FC = () => {
  return (
    <ArrayField source="monitorProbes">
      <SimpleList
        linkType={false}
        primaryText={(record) => `Probe: ${record.name}`}
        secondaryText={() => (
          <>
            <strong>History (most recent 10)</strong>
            <ArrayField source="monitorAlertHistory">
              <Datagrid bulkActionButtons={false}>
                <TextField source="fingerprint" />
                <TextField source="description" />
                <AlertStatusField label="Status" source="isFiring" />
                <BooleanField source="isMuted" emptyText="N/A" />
                <DateField showTime source="startedAt" />
                <DateField showTime source="endedAt" emptyText="ongoing" />
                <ShowButton resource="monitorAlertHistories" />
              </Datagrid>
            </ArrayField>
          </>
        )}
      />
    </ArrayField>
  )
}
