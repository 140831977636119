import React from 'react'
import {
  BooleanInput,
  Edit,
  EditProps,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin'
import { PageTitle } from '../../atoms/PageTitle'
import { ReferenceAutocomplete } from '../../molecules/ReferenceAutocomplete'
import { DateInput } from '../../atoms/DateInput'

/**
 * Form to edit a monitor alert history.
 * @param props The Edit component props
 * @see https://marmelab.com/react-admin/Edit.html
 */
export const MonitorAlertHistoryEdit: React.FC<EditProps> = (props) => {
  return (
    <Edit
      {...props}
      title={<PageTitle prefix="Monitor Alert History" />}
      mutationMode="undoable"
    >
      <SimpleForm>
        <TextInput disabled label="Id" source="id" fullWidth />
        <TextInput source="description" fullWidth />
        <ReferenceAutocomplete
          label="Alert Rule"
          source="monitorAlertRuleId"
          reference="monitorAlertRules/list"
          required
        />
        <ReferenceAutocomplete
          label="Alert Probe"
          source="monitorProbeId"
          reference="monitorProbes/list"
          required
        />
        <TextInput source="fingerprint" fullWidth validate={required()} />
        <BooleanInput source="isFiring" fullWidth validate={required()} />
        <DateInput source="startedAt" fullWidth validate={required()} />
        <DateInput source="endedAt" fullWidth />
      </SimpleForm>
    </Edit>
  )
}
