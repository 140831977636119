import React from 'react'
import {
  FormDataConsumer,
  required,
  SelectInput,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
} from 'react-admin'
import { Box, Button } from '@mui/material'
import AddIcon from '@mui/icons-material/AddOutlined'
import { ReferenceAutocomplete } from '../ReferenceAutocomplete/ReferenceAutocomplete.component'

/**
 * Form input fields for adding a site to a service.
 * @returns {React.FC} Form input fields for adding a site to a service.
 * @example
 * <SitesInput />
 */
export const SitesInput: React.FC = () => {
  return (
    <ArrayInput source="sites" label={false} sx={{ mb: 3 }} fullWidth>
      <SimpleFormIterator
        getItemLabel={(index) => `#${index + 1}`}
        addButton={
          <Button>
            <AddIcon />
            &nbsp;Add Site
          </Button>
        }
        fullWidth
      >
        <FormDataConsumer>
          {({ formData, getSource, scopedFormData }) => (
            <Box sx={{ width: '100%', p: 1 }}>
              <Box display={{ xs: 'block', sm: 'flex' }} sx={{ width: '100%' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <ReferenceAutocomplete
                    label={'Use existing site'}
                    source={getSource?.('id') || ''}
                    reference="serviceSites/list"
                    filter={{ clientId: formData?.clientId }}
                  />
                </Box>
              </Box>
              {!scopedFormData?.id && (
                <>
                  <Box
                    display={{ xs: 'block', sm: 'flex' }}
                    sx={{ width: '100%' }}
                  >
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                      <TextInput
                        source={getSource?.('name') || ''}
                        defaultValue={`Site ${formData.sites?.length}`}
                        fullWidth
                        disabled={scopedFormData?.id}
                        validate={!scopedFormData?.id ? [required()] : []}
                      />
                    </Box>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                      <SelectInput
                        source={getSource?.('type') || ''}
                        choices={[
                          { id: 'building', name: 'Building' },
                          { id: 'datacenter', name: 'Datacenter' },
                        ]}
                        defaultValue="building"
                        fullWidth
                        disabled={scopedFormData?.id}
                        validate={!scopedFormData?.id ? [required()] : []}
                      />
                    </Box>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                      <ReferenceAutocomplete
                        label={'Country'}
                        source={getSource?.('countryCode') || ''}
                        reference="countries/list"
                        disabled={scopedFormData?.id}
                        validate={!scopedFormData?.id ? [required()] : []}
                      />
                    </Box>
                  </Box>
                  <Box
                    display={{ xs: 'block', sm: 'flex' }}
                    sx={{ width: '100%' }}
                  >
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                      <TextInput
                        source={getSource?.('streetName') || ''}
                        label={'Street name'}
                        fullWidth
                        disabled={scopedFormData?.id}
                        validate={!scopedFormData?.id ? [required()] : []}
                      />
                    </Box>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                      <TextInput
                        source={getSource?.('postalCode') || ''}
                        label={'Postal code'}
                        fullWidth
                        disabled={scopedFormData?.id}
                        validate={!scopedFormData?.id ? [required()] : []}
                      />
                    </Box>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                      <TextInput
                        source={getSource?.('city') || ''}
                        label={'City'}
                        fullWidth
                        disabled={scopedFormData?.id}
                        validate={!scopedFormData?.id ? [required()] : []}
                      />
                    </Box>
                  </Box>
                  <Box
                    display={{ xs: 'block', sm: 'flex' }}
                    sx={{ width: '100%' }}
                  >
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                      <TextInput
                        source={getSource?.('streetNumber') || ''}
                        label={'Street number'}
                        fullWidth
                        disabled={scopedFormData?.id}
                        validate={!scopedFormData?.id ? [required()] : []}
                      />
                    </Box>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                      <TextInput
                        source={getSource?.('streetNumberSuffix') || ''}
                        label={'Suffix'}
                        fullWidth
                        disabled={scopedFormData?.id}
                      />
                    </Box>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                      <TextInput
                        source={getSource?.('streetNumberRoom') || ''}
                        label={'Room'}
                        fullWidth
                        disabled={scopedFormData?.id}
                      />
                    </Box>
                  </Box>
                </>
              )}
            </Box>
          )}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  )
}
