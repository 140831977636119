import { ProjectCreate } from './Create.component'
import { ProjectEdit } from './Edit.component'
import { ProjectList } from './List.component'
import { ProjectShow } from './Show.component'

export default {
  list: ProjectList,
  show: ProjectShow,
  create: ProjectCreate,
  edit: ProjectEdit,
}
