import React, { useState } from 'react'
import {
  FormDataConsumer,
  useCreate,
  useNotify,
  useRefresh,
  Confirm,
} from 'react-admin'
import { WizardForm, WizardFormStep } from '@react-admin/ra-form-layout'
import { ServiceSpecificationInput } from '../../templates/ServiceSpecificationInput'
import { Button, Dialog, DialogTitle, DialogContent } from '@mui/material'
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart'
import CloseIcon from '@mui/icons-material/Close'
import { ClientContractForm } from '../../organisms/ClientContractForm'
import { ServiceInput } from './ServiceInput.component'
import { SitesInput } from '../../molecules/SitesInput'
import { FieldValues } from 'react-hook-form/dist/types/fields'

/**
 * Modal for creating a new Service Order
 * @returns {React.FC}
 * @example <ServiceOrderModal />
 */
export const ServiceOrderModal: React.FC = () => {
  const [showDialog, setShowDialog] = useState(false)
  const [, setIsSubmitting] = useState(false)
  const [confirmClose, setConfirmClose] = useState(false)
  const [showAllSpecs, setShowAllSpecs] = useState(false)
  const notify = useNotify()
  const refresh = useRefresh()
  const [create] = useCreate()

  const handleClick = () => {
    setShowDialog(true)
  }

  const handleCloseClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    reason?: string
  ) => {
    // prevent backdrop click close
    if (reason && reason === 'backdropClick') {
      return false
    }
    setConfirmClose(true)
  }

  const handleClose = () => {
    setConfirmClose(false)
    setShowDialog(false)
  }

  const handleSubmit = async (values: FieldValues) => {
    setIsSubmitting(true)
    try {
      await create(`services/order`, { data: values }, { returnPromise: true })
      notify('Service Order has been created', { type: 'info' })
      setIsSubmitting(false)
      setShowDialog(false)
      refresh()
    } catch (error: any) {
      notify(error.message || error, { type: 'warning' })
    }
  }

  return (
    <>
      <Button color="primary" aria-label="complete" onClick={handleClick}>
        <AddShoppingCartIcon />
        &nbsp;{'Order'}
      </Button>
      <Confirm
        isOpen={confirmClose}
        title="New Service Order"
        content="Are you sure you want to cancel this Service Order?"
        onConfirm={handleClose}
        onClose={() => setConfirmClose(false)}
      />
      <Dialog
        fullWidth
        maxWidth="lg"
        open={showDialog}
        onClose={handleCloseClick}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          New Service Order
          <Button
            sx={{ margin: 0, float: 'right' }}
            size={'small'}
            onClick={handleCloseClick}
          >
            <CloseIcon />
            Close
          </Button>
        </DialogTitle>
        <DialogContent>
          <WizardForm onSubmit={handleSubmit}>
            <WizardFormStep label="Service">
              <ServiceInput />
            </WizardFormStep>
            <WizardFormStep label="Specifications">
              <ServiceSpecificationInput
                showAllSpecs={showAllSpecs}
                setShowAllSpecs={setShowAllSpecs}
              />
            </WizardFormStep>
            <WizardFormStep label="Client Contract">
              <FormDataConsumer>
                {() => (
                  <ClientContractForm
                    sourcePrefix="clientContract"
                    reference="service"
                  />
                )}
              </FormDataConsumer>
            </WizardFormStep>
            <WizardFormStep label="Sites">
              <SitesInput />
            </WizardFormStep>
          </WizardForm>
        </DialogContent>
      </Dialog>
    </>
  )
}
