import { Typography } from '@mui/material'
import React from 'react'
import { RaRecord, useRecordContext } from 'react-admin'

export const RequestFlowTitle: React.FC<{
  record?: RaRecord
  service?: RaRecord
}> = (props) => {
  const record = useRecordContext()
  const request = (props.record || record)?.requestNo ? record : record?.request

  return (
    <Typography>{`${request?.serviceCategory?.key ?? ''} Request ${
      request?.requestNo ?? ''
    }`}</Typography>
  )
}
