import React from 'react'
import { useValue } from './PageTitle.hooks'
import { IPageTitle } from './PageTitle.types'

/**
 * PageTitle component.
 *
 * A component that renders the title of a page.
 *
 * @param {IPageTitle} props - The props for the PageTitle component.
 * @returns {JSX.Element} The rendered PageTitle component.
 * @see IPageTitle
 * @see useValue
 */
export const PageTitle: React.FC<IPageTitle> = (props) => {
  return <span>{`${props.prefix} ${useValue(props.noRecordValue)}`}</span>
}
