import get from 'lodash/get'
import { useRecordContext } from 'react-admin'
import { ITextPreSuffixField } from './TextPreSuffixField.types'

export const useValue = (props: ITextPreSuffixField) => {
  const record = useRecordContext()
  let value = get(record, props.source)

  if (value === null || value === undefined || value === '') {
    return props.emptyText || 'N/A'
  }

  return (props.prefix || '') + value + (props.suffix || '')
}
