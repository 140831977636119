import React from 'react'
import {
  List,
  ListProps,
  TextField,
  NumberField,
  Datagrid,
  BooleanField,
} from 'react-admin'
import { NameFilter } from '../../atoms/NameFilter'

/**
 * Page to list user roles.
 * @property ListProps props
 * @returns React.FC<ListProps>
 * @example
 * import { UserRoleList } from './UserRoleList'
 * <UserRoleList {...props} />
 * @see https://marmelab.com/react-admin/List.html
 * @see https://marmelab.com/react-admin/Fields.html
 * @see https://marmelab.com/react-admin/Inputs.html
 */
export const UserRoleList: React.FC<ListProps> = (props) => {
  return (
    <List
      {...props}
      empty={false}
      perPage={25}
      filters={<NameFilter />}
      sort={{ field: 'level', order: 'DESC' }}
    >
      <Datagrid rowClick="show">
        <TextField source="id" />
        <NumberField source="level" />
        <TextField source="name" />
        <TextField source="description" />
        <BooleanField source="isSuperAdmin" />
        <BooleanField source="isEmployee" />
        <TextField source="dataView" emptyText="default (client)" />
        <TextField source="authFilter" emptyText="full access" />
      </Datagrid>
    </List>
  )
}
