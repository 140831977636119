import { Box, Typography, Chip, Paper, CircularProgress } from '@mui/material'
import { useState, useEffect } from 'react'
import { useRecordContext, useDataProvider, useNotify } from 'react-admin'

import { useWatch } from 'react-hook-form'

const UpdateEmailPreview: React.FC = () => {
  const record = useRecordContext()
  const dataProvider = useDataProvider()
  const [loading, setLoading] = useState(false)
  const [previewData, setPreviewData] = useState<{
    content: string
    contacts: [string]
  }>()

  const values = useWatch({
    name: [
      'sendImmediate',
      'estimatedDeliveryDate',
      'provisioningUpdateNotes',
      'provisioningStatus',
      'ragStatus',
      'isOnHold',
    ],
  })

  const notify = useNotify()

  useEffect(() => {
    if (!values) return
    const updatePreview = async () => {
      const parsedPreviewData = {
        sendImmediate: values[0],
        estimatedDeliveryDate: values[1],
        notes: values[2],
        provisioningStatus: values[3],
        ragStatus: values[4],
        isOnHold: values[5],
      }
      try {
        setLoading(true)
        const preview = await dataProvider.customRequest(
          `services`,
          `${record.id}/updates/preview`,
          { data: parsedPreviewData, method: 'POST' }
        )
        setPreviewData(preview.json)
        setLoading(false)
      } catch (error: any) {
        setLoading(false)
        notify(error.message || error, { type: 'warning' })
      }
    }
    updatePreview()
  }, [values, dataProvider, notify, record.id])

  if (loading) {
    return (
      <Box
        width={'100%'}
        display={'flex'}
        justifyContent={'center'}
        alignContent={'center'}
      >
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box sx={{ mb: 3 }}>
      <Typography p={1} variant="body2">
        The following is a preview of the update that will be send out to the
        contacts that should receive updates for this service.
      </Typography>
      <Box sx={{ pb: 2 }}>
        {!previewData?.contacts?.length ? (
          <Typography p={1} variant="body2" sx={{ fontWeight: 'bold' }}>
            This service does not appear to have any contacts that will receive
            immediate updates. Add them first under Service - Edit before saving
            this update.
          </Typography>
        ) : (
          <Typography p={1} variant="body2">
            To:{' '}
            {previewData?.contacts?.map((contact: any) => (
              <Chip
                sx={{ m: 0.25 }}
                key={contact.id}
                variant="outlined"
                size="small"
                label={`${contact.name} (${contact.role}) <${contact.emailAddress}>`}
              />
            ))}
          </Typography>
        )}
        <Typography p={1} variant="body2">
          Reply-To:{' '}
          <Chip
            sx={{ m: 0.25 }}
            variant="outlined"
            size="small"
            label={'GNX Provisioning'}
          />
        </Typography>
      </Box>
      <Paper sx={{ p: 2, mb: 2 }}>
        <div
          dangerouslySetInnerHTML={{
            __html: previewData?.content ?? '',
          }}
        />
      </Paper>
      <Typography variant="caption" sx={{ p: 1 }}>
        Note that opening and closing words will be added automatically and
        should not be included in the notes. The preview above is of just the
        part that describes the update.
      </Typography>
    </Box>
  )
}

export default UpdateEmailPreview
