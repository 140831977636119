import React, { useState } from 'react'
import { Box, Button, Grid } from '@mui/material'
import {
  FormDataConsumer,
  useRecordContext,
  useDataProvider,
} from 'react-admin'
import { IFrame } from '../../atoms/IFrame'
import { useWatch } from 'react-hook-form'
import { FieldValues } from 'react-hook-form/dist/types/fields'
import { Message } from '../../atoms/Message'
import { DisplayPDF } from '../../atoms/DisplayPDF'
import { useIFrameStyle } from './TemplatePreviewer.styles'
import {
  SelectClient,
  SelectEntry,
} from '../../molecules/TemplatePreviewSelect'

/**
 * Previewer for templates.
 * @example
 * return (
 * <TemplatePreviewer />
 * )
 * @see {@link https://mui.com/components/grid/|MUI Grid}
 * @see {@link https://mui.com/components/button/|MUI Button}
 * @see {@link https://mui.com/components/box/|MUI Box}
 */
export const TemplatePreviewer: React.FC = () => {
  const record = useRecordContext()
  const dataProvider = useDataProvider()
  const styles = useIFrameStyle()
  const [pdfData, setPdfData] = useState(null)
  const [htmlData, setHtmlData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const formData = useWatch({
    name: ['type', 'view', 'content', 'layout', 'context'],
  })

  const onSubmitPreview = async () => {
    setPdfData(null)
    setHtmlData(null)
    setError('')
    setLoading(true)

    const previewData = {
      type: formData[0],
      view: formData[1],
      content: formData[2],
      layout: formData[3],
      context: formData[4],
    }

    if (!previewData.content || !previewData.context?.model) {
      setLoading(false)
      setError('Please select an entity Model and Id')
    }

    try {
      const data = await dataProvider.customRequest(
        'templates',
        `${record.id}/preview`,
        {
          data: previewData,
          method: 'PUT',
        }
      )
      if (data.json && typeof data.json.data === 'object') {
        setPdfData(data.json.data.content)
      } else {
        setHtmlData(data.data || data.json)
      }
      setLoading(false)
    } catch (error: any) {
      setError(error.message)
      setLoading(false)
    }
  }

  return (
    <Box sx={{ height: '80vh', width: '100%' }}>
      <Grid container>
        <FormDataConsumer>
          {({ formData }: FieldValues) => <SelectClient {...formData} />}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData }: FieldValues) => {
            if (formData.clientId) {
              return <SelectEntry {...formData} />
            }
          }}
        </FormDataConsumer>
        <Grid item xs={1} p={1}>
          <Button onClick={onSubmitPreview} variant={'outlined'} sx={{ mt: 2 }}>
            Preview
          </Button>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} p={1} sx={{ width: '100%', height: '80vh' }}>
          {error !== '' && <Message message={`Error: ${error}`} />}
          {loading && <Message message="Loading preview.." />}
          {pdfData && <DisplayPDF pdfData={pdfData} />}
          {htmlData && (
            <Box sx={styles}>
              <IFrame html={htmlData} />
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}
