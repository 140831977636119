import React from 'react'
import {
  Create,
  CreateProps,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin'

/**
 * Page to create permissions.
 * @property {CreateProps} props
 * @returns {JSX.Element}
 * @example
 * <PermissionCreate {...props} />
 * @see {@link https://marmelab.com/react-admin/Create.html|Create}
 * @see {@link https://marmelab.com/react-admin/SimpleForm.html|SimpleForm}
 * @see {@link https://marmelab.com/react-admin/Fields.html|Fields}
 */
export const PermissionCreate: React.FC<CreateProps> = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" validate={required()} fullWidth />
        <TextInput source="description" fullWidth />
        <TextInput source="resource" validate={required()} fullWidth />
        <TextInput source="action" validate={required()} fullWidth />
      </SimpleForm>
    </Create>
  )
}
