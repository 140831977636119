import React from 'react'
import {
  Datagrid,
  TextField,
  ShowButton,
  EditButton,
  DeleteButton,
  DateField,
} from 'react-admin'
import { ReferenceText } from '../../molecules/ReferenceText'
import { useStyles } from './DocumentRow.styles'

/**
 * Row to display a document.
 * @returns {JSX.Element}
 * @example
 * import { DocumentRow } from './DocumentRow'
 * <DocumentRow />
 * @see https://marmelab.com/react-admin/List.html
 */
export const DocumentRow: React.FC = () => {
  const styles = useStyles()

  return (
    <Datagrid sx={styles} bulkActionButtons={false}>
      <TextField source="documentNo" />
      <ReferenceText
        label={'Category'}
        reference="documentCategories/list"
        source="categoryId"
        link={false}
        emptyText="N/A"
      />
      <TextField source="title" />
      <DateField source="createdAt" showTime />
      <ShowButton />
      <EditButton />
      <DeleteButton redirect={false} />
    </Datagrid>
  )
}
