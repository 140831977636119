import React from 'react'
import {
  Show,
  ShowProps,
  Datagrid,
  TabbedShowLayout,
  Tab,
  TextField,
  DateField,
  NumberField,
  ShowButton,
  BooleanField,
  SimpleShowLayout,
} from 'react-admin'
import { PageTitle } from '../../atoms/PageTitle'
import { TypeStateField } from '../../atoms/TypeStateField'
import { ReferenceChip } from '../../molecules/ReferenceChip'
import { ReferenceText } from '../../molecules/ReferenceText'
import { CurrencyField } from '../../atoms/CurrencyField'
import { TextPreSuffixField } from '../../atoms/TextPreSuffixField'
import { ReferenceManyTab } from '../../atoms/FormTab'
import { MetaListDisplay } from '../../organisms/MetaList'
import { MutationsList } from '../../organisms/MutationsList'

/**
 * Page that displays the details of a client contract.
 * @todo tabs are a repeat of the same component elsewhere, we
 * should refactor this.
 * @param {ShowProps} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 * @example
 * return (
 * <ClientContractShow {...props} />
 * )
 */
export const ClientContractShow = (props: ShowProps) => {
  return (
    <Show {...props} title={<PageTitle prefix="Client contract" />}>
      <TabbedShowLayout {...props}>
        <Tab label="general" key="general">
          <SimpleShowLayout
            sx={{
              '& .RaSimpleShowLayout-stack': {
                md: {
                  display: 'grid',
                  gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
                  gridGap: '4px',
                  gridAutoColumns: '1fr',
                  alignSelf: 'center',
                  '& .ra-field': {
                    marginTop: '8px',
                  },
                },
              },
              width: '100%',
              margin: 0,
              padding: 0,
            }}
          >
            <TypeStateField source="state" />
            <ReferenceChip
              referenceSource="clientId"
              tooltipSource="clientNo"
              source="name"
              chipSource="name"
              label={'Client'}
              reference="clients"
              emptyText={'N/A'}
              link="show"
              removeDoubleLabel
            />
            <ReferenceChip
              referenceSource="serviceId"
              tooltipSource="name"
              source="serviceNo"
              chipSource="name"
              label={'Service'}
              reference="services"
              emptyText={'N/A'}
              link="show"
              removeDoubleLabel
            />
            <ReferenceChip
              referenceSource="quoteId"
              tooltipSource="quoteNo"
              source="quoteNo"
              chipSource="quoteNo"
              label={'Related Quote'}
              reference="quotes"
              emptyText={'N/A'}
              link="show"
              removeDoubleLabel
            />
            <TextField source="contractNo" fullWidth />
            <TextField source="reference" emptyText="N/A" fullWidth />
            <TextField source="description" emptyText="N/A" fullWidth />
            <NumberField source="contractPeriod" emptyText="N/A" fullWidth />
            <NumberField source="renewalPeriod" emptyText="N/A" fullWidth />
            <NumberField
              source="cancellationPeriod"
              emptyText="N/A"
              fullWidth
            />
            <DateField source="periodStart" emptyText="N/A" fullWidth />
            <DateField source="periodEnd" emptyText="N/A" fullWidth />
            <TextField source="billingCycle" emptyText="N/A" fullWidth />
            <ReferenceText
              source={'salesCurrency'}
              label={'Sales Currency'}
              reference="currencies/list"
              emptyText={'N/A'}
              link={false}
            />
            <CurrencyField
              source="salesNrc"
              currency={'salesCurrency'}
              label="Sales NRC"
            />
            <CurrencyField
              source="salesMrc"
              currency={'salesCurrency'}
              label="Sales MRC"
            />
            <CurrencyField
              source="salesMarginNrc"
              currency={'salesCurrency'}
              label="Sales Margin NRC"
            />
            <CurrencyField
              source="salesMarginMrc"
              currency={'salesCurrency'}
              label="Sales Margin MRC"
            />
            <TextPreSuffixField
              source="salesVat"
              label="Sales VAT"
              emptyText="N/A"
              suffix={'%'}
            />
            <TextPreSuffixField
              source="serviceUptime"
              suffix={'%'}
              label="Uptime SLA"
            />
            <TextPreSuffixField
              source="leadTime"
              label="Lead time"
              suffix={' weeks'}
            />
            <TextPreSuffixField
              source="meanTimeToRepair"
              label="MTTR"
              suffix={' hours'}
            />
            <TextField source="supportLevel" emptyText="N/A" />
            <TextField source="serviceMonitoring" emptyText="N/A" />
            <DateField source="updatedAt" showTime />
            <DateField source="createdAt" showTime />
          </SimpleShowLayout>
        </Tab>
        <ReferenceManyTab
          reference="serviceOrders"
          target="contractIds"
          label="orders"
        >
          <Datagrid
            sx={{
              width: '100%',
              '& .column-title': {
                maxWidth: '20em',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              },
            }}
          >
            <TextField source="orderNo" />
            <TypeStateField source="state" />
            <ReferenceText
              source={'clientId'}
              label={'Client'}
              reference="clients"
              emptyText={'N/A'}
              link="show"
            />
            <TextField source="reference" emptyText="N/A" />
            <TextField source="description" emptyText="N/A" />
            <BooleanField source="isSigned" />
            <BooleanField source="isCustom" />
            <DateField source="createdAt" />
            <DateField source="updatedAt" />
            <ShowButton />
          </Datagrid>
        </ReferenceManyTab>
        <ReferenceManyTab
          reference="documents"
          target="relatedId"
          label="documents"
        >
          <Datagrid
            sx={{
              width: '100%',
              '& .column-title': {
                maxWidth: '20em',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              },
            }}
          >
            <TextField source="documentNo" fullWidth />
            <TextField source="title" fullWidth />
            <DateField source="createdAt" showTime />
            <ShowButton />
          </Datagrid>
        </ReferenceManyTab>
        <Tab label={'meta'} key="meta">
          <MetaListDisplay source="meta" />
        </Tab>
        <Tab label={'history'} key="history">
          <MutationsList showActor type="history" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
