import React from 'react'
import {
  List,
  ListProps,
  Datagrid,
  ReferenceField,
  TextField,
  NumberField,
} from 'react-admin'
import { ReferenceText } from '../../molecules/ReferenceText'
import { MarginRuleFilters } from '../../molecules/MarginRuleFilters/MarginRuleFilters.component'

/**
 * Page to list margin rules.
 * @property {ListProps} props
 * @returns {React.FC<ListProps>}
 * @example
 * <MarginRuleList {...props} />
 * @see https://marmelab.com/react-admin/List.html
 */
export const MarginRuleList: React.FC<ListProps> = (props) => {
  return (
    <List {...props} empty={false} perPage={25} filters={<MarginRuleFilters />}>
      <Datagrid rowClick="show">
        <ReferenceField
          source="marginBookId"
          reference="marginBooks"
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceText
          source="accessTechnologyId"
          reference="accessTechnologies"
          link={false}
          emptyText={'*'}
        />
        <ReferenceText
          source="serviceTypesId"
          reference="serviceTypes"
          link={false}
          emptyText={'*'}
        />
        <TextField source="priceCategory" />
        <NumberField source="priceFrom" />
        <NumberField source="priceUntil" emptyText={'infinity'} />
        <TextField source="marginType" />
        <NumberField source="marginValue" />
      </Datagrid>
    </List>
  )
}
