import React from 'react'
import {
  Create,
  CreateProps,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin'

/**
 * Form to create a monitor notification type.
 * @param props The Create component props
 * @see https://marmelab.com/react-admin/Create.html
 */
export const MonitorNotificationTypeCreate: React.FC<CreateProps> = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" validate={required()} fullWidth />
        <TextInput source="description" fullWidth />
      </SimpleForm>
    </Create>
  )
}
