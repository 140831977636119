import React from 'react'
import {
  ReferenceManyField,
  TextField,
  DateField,
  ShowButton,
  Pagination,
} from 'react-admin'
import { LabelledDatagrid } from '../../atoms/LabelledDatagrid'
import { ReferenceChip } from '../../molecules/ReferenceChip'
import { TypeStateField } from '../../atoms/TypeStateField'
import { EllipsedTextField } from '../../atoms/EllipsedField'

import { IServiceList } from './ServiceList.types'

/**
 * List of services
 * @returns {React.FC}
 * @example
 * import { ServiceList } from './ServiceList.component'
 * <ServiceList />
 * @see https://marmelab.com/react-admin/List.html
 */
export const ServiceList: React.FC<IServiceList> = (props) => {
  return (
    <ReferenceManyField
      reference="services"
      target={props.target}
      label="services"
      pagination={<Pagination />}
      sort={{ field: 'createdAt', order: 'DESC' }}
    >
      <LabelledDatagrid bulkActionButtons={false}>
        <TextField source="serviceNo" />
        <TypeStateField source="state" />
        <EllipsedTextField source="reference" emptyText="N/A" />
        <EllipsedTextField source="name" />
        <ReferenceChip
          referenceSource="projectId"
          tooltipSource="projectNo"
          source="Project"
          chipSource="title"
          reference="projects"
          emptyText={'N/A'}
          link="show"
        />
        <DateField source="updatedAt" showTime />
        <ShowButton />
      </LabelledDatagrid>
    </ReferenceManyField>
  )
}
