import React from 'react'
import { List, ListProps, TextField, DateField, NumberField } from 'react-admin'
import { ReferenceChip } from '../../molecules/ReferenceChip'
import { vendorExporter } from '../../particles/exporters/vendorExporter'
import { VendorContractFilters } from '../../molecules/VendorContractFilters'
import { LabelledDatagrid } from '../../atoms/LabelledDatagrid'
import { TypeStateField } from '../../atoms/TypeStateField'

/**
 * Page to list Vendor Contracts
 * @param props
 * @returns {JSX.Element}
 * @example
 * import { VendorContractList } from './VendorContractList'
 * <VendorContractList {...props} />
 */
export const VendorContractList: React.FC<ListProps> = (props) => {
  return (
    <List
      {...props}
      empty={false}
      perPage={props.perPage ?? 25}
      filters={props.filters ?? <VendorContractFilters />}
      sort={props.sort ?? { field: 'createdAt', order: 'DESC' }}
      exporter={vendorExporter}
    >
      <LabelledDatagrid rowClick="show">
        <TextField source="contractNo" />
        <TypeStateField source="state" />
        <ReferenceChip
          referenceSource="vendorId"
          tooltipSource="name"
          source="Vendor"
          reference="vendors"
          emptyText="N/A"
          link="show"
          chipSource="name"
        />
        <ReferenceChip
          referenceSource="serviceId"
          tooltipSource="name"
          source="serviceNo"
          chipSource="name"
          reference="services"
          emptyText={'N/A'}
          link="show"
        />
        <TextField source="reference" emptyText="N/A" />
        <TextField source="description" />
        <NumberField source="contractPeriod" />
        <DateField source="periodStart" />
        <DateField source="periodEnd" emptyText="N/A" />
        <DateField source="createdAt" />
      </LabelledDatagrid>
    </List>
  )
}
