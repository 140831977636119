import React from 'react'
import {
  Create,
  CreateProps,
  FormDataConsumer,
  required,
  SimpleForm,
  TextInput,
} from 'react-admin'
import { ReferenceAutocomplete } from '../../molecules/ReferenceAutocomplete'

/**
 * Page that displays a form to create a new ticket.
 * @param {CreateProps} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 * @example
 * return (
 * <TicketCreate />
 * )
 * @see {@link https://marmelab.com/react-admin/Create.html|Create}
 */
export const TicketCreate: React.FC<CreateProps> = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="summary" validate={required()} fullWidth />
        <TextInput
          source="description"
          multiline
          validate={required()}
          fullWidth
        />
        <FormDataConsumer>
          {({ formData, getSource, scopedFormData }) => (
            <>
              <ReferenceAutocomplete
                required
                label="Client"
                source="clientId"
                reference="clients/list"
              />
              <ReferenceAutocomplete
                required
                label="Service"
                source="serviceIds"
                reference="services/list"
                filter={{ clientId: formData?.clientId }}
              />
            </>
          )}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  )
}
