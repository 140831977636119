import React from 'react'
import {
  FormDataConsumer,
  SelectInput,
  TextInput,
  useGetOne,
} from 'react-admin'
import { ReferenceAutocomplete } from '../../molecules/ReferenceAutocomplete'
import { Grid } from '@mui/material'
import { useWatch } from 'react-hook-form'

/**
 * File generator context form.
 * @returns {React.FC}
 * @example
 * import { FileGeneratorContext } from './Context.component.tsx'
 * <FileGeneratorContext />
 * @see {@link https://mui.com/components/grid/|MUI Grid}
 */
export const FileGeneratorContext: React.FC = () => {
  const formData = useWatch({
    name: ['templateId'],
  })

  const { data } = useGetOne(`templates`, {
    id: formData[0],
  })

  if (!data) return null

  return (
    <Grid container>
      <FormDataConsumer>
        {({ formData, getSource, scopedFormData, ...rest }) => (
          <Grid item xs={6} p={1}>
            <SelectInput
              source="context.model"
              label="Model"
              defaultValue={data.context?.model || formData.relatedName}
              choices={[
                { id: 'ClientContract', name: 'Client Contract' },
                { id: 'ServiceOrder', name: 'Service Order' },
                { id: 'Client', name: 'Client' },
              ]}
              disabled={!!data.context?.model}
              fullWidth
            />
          </Grid>
        )}
      </FormDataConsumer>
      <Grid item xs={6} p={1}>
        <ReferenceAutocomplete
          required
          label="Client"
          source="clientId"
          reference="clients/list"
        />
      </Grid>
      <FormDataConsumer>
        {({ formData, getSource, scopedFormData, ...rest }) => (
          <>
            {formData.clientId && formData.context?.model !== 'Client' && (
              <Grid item xs={6} p={1}>
                <ReferenceAutocomplete
                  required
                  label="Service"
                  source="serviceId"
                  reference="services/list"
                  filter={{ clientId: formData?.clientId }}
                  disableSyncWithLocation
                />
              </Grid>
            )}
            {formData?.clientId && formData.serviceId ? (
              formData?.context?.model === 'ClientContract' ? (
                <Grid item xs={6} p={1}>
                  <ReferenceAutocomplete
                    required
                    label={'Client Contract'}
                    reference="clientContracts/list"
                    source="context.id"
                    filter={{
                      clientId: formData.clientId,
                      serviceId: formData.serviceId,
                    }}
                    defaultValue={formData.relatedId}
                  />
                </Grid>
              ) : formData?.context?.model === 'ServiceOrder' ? (
                <Grid item xs={6} p={1}>
                  <ReferenceAutocomplete
                    required
                    label={'Service Order'}
                    reference="serviceOrders/list"
                    source="context.id"
                    filter={{
                      clientId: formData.clientId,
                      serviceId: formData.serviceId,
                    }}
                    defaultValue={formData.relatedId}
                  />
                </Grid>
              ) : null
            ) : null}
            <Grid item xs={12} p={1}>
              {data.variables &&
                data.variables.length > 0 &&
                data.variables.map((variable: any) => (
                  <TextInput
                    source={`context.state.${variable.key}`}
                    label={`{{ ${variable.key} }}: ${variable.description}`}
                    fullWidth
                  />
                ))}
            </Grid>
          </>
        )}
      </FormDataConsumer>
    </Grid>
  )
}
