import React from 'react'
import { List, ListProps, TextField, DateField } from 'react-admin'
import { FilterExportToolbar } from '../../molecules/FilterExportToolbar'
import { useDocumentFilters } from '../../particles/filters'
import { TypeStateField } from '../../atoms/TypeStateField'
import { ReferenceChip } from '../../molecules/ReferenceChip'
import { LabelledDatagrid } from '../../atoms/LabelledDatagrid'

/**
 * Page that displays a list of documents.
 * @param {ListProps} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 * @example
 * return (
 * <DocumentList {...props} />
 * )
 */
export const DocumentList: React.FC<ListProps> = (props) => {
  return (
    <List
      empty={false}
      perPage={25}
      actions={<FilterExportToolbar showCreate />}
      filters={useDocumentFilters()}
      sort={{ field: 'createdAt', order: 'DESC' }}
      {...props}
    >
      <LabelledDatagrid rowClick="show">
        <TextField source="documentNo" />
        <TypeStateField source="state" fullWidth />
        <TextField source="title" />
        <ReferenceChip
          referenceSource="categoryId"
          tooltipSource="name"
          source="Category"
          chipSource="key"
          reference="documentCategories"
          emptyText="N/A"
          link="show"
        />
        <ReferenceChip
          referenceSource="clientId"
          tooltipSource="clientNo"
          source="Client"
          chipSource="name"
          reference="clients"
          emptyText="N/A"
          link="show"
        />
        <ReferenceChip
          referenceSource="vendorId"
          tooltipSource="name"
          source="Vendor"
          chipSource="name"
          reference="vendors"
          emptyText="N/A"
          link="show"
        />
        <DateField source="updatedAt" showTime />
      </LabelledDatagrid>
    </List>
  )
}
