import React from 'react'
import { ILogo } from './Logo.types'
import Tooltip from '@mui/material/Tooltip'
import packageJson from '../../../../package.json'

/**
 * GNX Logo wrapper
 * @prop {number} height - The height of the logo
 */
export const Logo: React.FC<ILogo> = (props) => {
  const logoImage = require('../../../logo.png')
  return (
    <Tooltip title={`${packageJson.name} version ${packageJson.version}`}>
      <img src={logoImage} alt="GNX Logo" {...props} />
    </Tooltip>
  )
}
