import React from 'react'
import {
  Create,
  CreateProps,
  BooleanInput,
  FormDataConsumer,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  regex,
} from 'react-admin'
import { ReferenceAutocomplete } from '../../molecules/ReferenceAutocomplete'

/**
 * Page to create a new template.
 * @param {CreateProps} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 * @example
 * return (
 * <TemplateCreate {...props} />
 * )
 */
export const TemplateCreate: React.FC<CreateProps> = (props) => {
  return (
    <Create {...props} redirect="edit">
      <SimpleForm>
        <TextInput source="name" validate={required()} fullWidth />
        <TextInput source="key" validate={[regex(/[a-z:]*/)]} fullWidth />
        <SelectInput
          source="type"
          validate={required()}
          choices={[
            { id: 'document', name: 'Document' },
            { id: 'email', name: 'Email' },
          ]}
          fullWidth
        />
        <FormDataConsumer>
          {({ formData, getSource, scopedFormData }) =>
            formData.type === 'document' ? (
              <ReferenceAutocomplete
                reference="documentCategories/list"
                source="categoryId"
                label="Document category"
                required
              />
            ) : null
          }
        </FormDataConsumer>
        <TextInput source="view" fullWidth />
        <BooleanInput source="isDefault" fullWidth />
      </SimpleForm>
    </Create>
  )
}
