import { IHandleOperationSubmit } from './ProcessOperationButton.types'

/**
 * Handles the click event of the process operation button.
 *
 * @param {IHandleOperationSubmit} options - The options for handling the operation submit.
 * @param {string} options.notify - The notification function.
 * @param {string} options.refresh - The refresh function.
 * @param {Record} options.record - The record.
 * @param {onComplete} Function - Callback function to fire once operation has been performed.
 * @param {DataProvider} options.dataProvider - The data provider.
 * @param {values} options.values - Values passed from the form that may accompany operation.
 * @returns {Promise<void>} - A promise that resolves when the download starts.
 * @throws {Error} - An error.
 */
export const handleOperationSubmit = async ({
  notify,
  refresh,
  record,
  resource,
  operation,
  onComplete,
  dataProvider,
  values,
}: IHandleOperationSubmit) => {
  try {
    const { status, json } = await dataProvider.customRequest(
      `${resource}/${record.id}`,
      operation,
      { method: 'PUT', data: values }
    )
    if (status < 300) {
      refresh()
      notify(json?.message ?? `Success`)
      if (typeof onComplete === 'function') onComplete()
    }
  } catch (error: any) {
    notify(error.message, { type: 'error' })
    if (typeof onComplete === 'function') onComplete()
  }
}
