import React from 'react'
import {
  TextField,
  DateField,
  Show,
  ShowProps,
  TabbedShowLayout,
  Tab,
} from 'react-admin'
import { PageTitle } from '../../atoms/PageTitle'
import { MetaListDisplay } from '../../organisms/MetaList'
import { HistoryDisplayTab } from '../../templates/HistoryDisplayTab'

/**
 * Component to show a monitor notification type.
 * @param props The Show component props
 * @see https://marmelab.com/react-admin/Show.html
 */
export const MonitorNotificationTypeShow: React.FC<ShowProps> = (props) => {
  return (
    <Show {...props} title={<PageTitle prefix="Monitor Notification Type" />}>
      <TabbedShowLayout {...props}>
        <Tab label="summary">
          <TextField source="name" />
          <TextField source="description" emptyText="N/A" />
          <DateField source="createdAt" showTime />
          <DateField source="updatedAt" showTime />
        </Tab>
        <Tab label="Config Schema" key="configSchema">
          <MetaListDisplay source="configSchema" />
        </Tab>
        <Tab label="history">
          <HistoryDisplayTab />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
