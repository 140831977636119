import { MonitorProbeCreate } from './Create.component'
import { MonitorProbeEdit } from './Edit.component'
import { MonitorProbeList } from './List.component'
import { MonitorProbeShow } from './Show.component'

export default {
  list: MonitorProbeList,
  show: MonitorProbeShow,
  create: MonitorProbeCreate,
  edit: MonitorProbeEdit,
}
