import React from 'react'
import { Filter, SelectInput, TextInput } from 'react-admin'
import { INameSelectFilters } from './NameSelectFilters.types'
/**
 * Filters for a list ny name and a select input of given options
 * @property {INameSelectFilters} props FilterProps with children omitted.
 * @returns {React.ReactElement} The react element.
 * @example
 * <NameSelectFilters />
 * @see https://marmelab.com/react-admin/List.html#the-filter-button
 * @see https://marmelab.com/react-admin/List.html#the-filterform-props
 */
export const NameSelectFilters: React.FC<INameSelectFilters> = (props) => {
  return (
    <Filter {...props}>
      <TextInput label="Name" source="name.like" alwaysOn />
      <SelectInput source="type" choices={props.choices} alwaysOn />
    </Filter>
  )
}
