import React from 'react'
import { useFormContext } from 'react-hook-form'
import {
  FormDataConsumer,
  NumberInput,
  SelectInput,
  TextInput,
  minValue,
  maxValue,
  BooleanInput,
  required,
  regex,
} from 'react-admin'
import get from 'lodash/get'
import { prefixSource } from '../../particles/utils'
import { Box } from '@mui/material'
import { ReferenceAutocomplete } from '../../molecules/ReferenceAutocomplete'
import { DateInput } from '../../atoms/DateInput'

export const VendorContractForm: React.FC<{
  reference?: string
  sourcePrefix?: string
}> = (props) => {
  const form = useFormContext()
  const { vendorId, clientContract, ...otherFormData } = form.getValues()

  return (
    <Box display={{ width: '100%' }}>
      <Box display={{ xs: 'block', sm: 'flex' }} sx={{ p: 0.5 }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <TextInput
            source={prefixSource('contractNo', props.sourcePrefix)}
            label="Contract no. (VSO)"
            validate={[regex(/^VSO[0-9]{9,12}$/)]}
            fullWidth
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <TextInput
            source={prefixSource('reference', props.sourcePrefix)}
            label="Reference"
            fullWidth
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <SelectInput
            source={prefixSource('state', props.sourcePrefix)}
            defaultValue="active"
            validate={required()}
            choices={[
              { id: 'draft', name: 'Draft' },
              { id: 'confirmed', name: 'Confirmed' },
              { id: 'active', name: 'Active' },
              { id: 'cancelled', name: 'Cancelled' },
              { id: 'ended', name: 'Ended' },
              { id: 'replaced', name: 'Replaced' },
            ]}
            fullWidth
          />
        </Box>
      </Box>
      <Box display={{ xs: 'block', sm: 'flex' }} sx={{ p: 0.5 }}>
        {!props.reference && (
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <FormDataConsumer>
              {({ formData }) => (
                <ReferenceAutocomplete
                  label="Service"
                  required
                  source={prefixSource('serviceId', props.sourcePrefix)}
                  reference="services/list"
                  filter={{
                    clientId: get(
                      formData,
                      prefixSource('clientId', props.sourcePrefix)
                    ),
                  }}
                  disableSyncWithLocation
                />
              )}
            </FormDataConsumer>
          </Box>
        )}
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <TextInput
            source={prefixSource('description', props.sourcePrefix)}
            label="Contract description"
            defaultValue={otherFormData?.name}
            validate={[required()]}
            fullWidth
          />
        </Box>
      </Box>
      <Box display={{ xs: 'block', sm: 'flex' }} sx={{ p: 0.5 }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <ReferenceAutocomplete
            label="Vendor"
            required
            defaultValue={vendorId}
            source={prefixSource('vendorId', props.sourcePrefix)}
            reference="vendors/list"
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <FormDataConsumer>
            {({ formData }) => (
              <ReferenceAutocomplete
                label="Related vendor contract"
                source={prefixSource('vendorOfferId', props.sourcePrefix)}
                reference="vendorOffers/list"
                filter={{
                  vendorId: get(
                    formData,
                    prefixSource('vendorId', props.sourcePrefix)
                  ),
                }}
                disableSyncWithLocation
              />
            )}
          </FormDataConsumer>
        </Box>
      </Box>
      <Box display={{ xs: 'block', sm: 'flex' }} sx={{ p: 0.5 }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <DateInput
            source={prefixSource('periodStart', props.sourcePrefix)}
            label="Contract start date"
            defaultValue={clientContract?.periodStart || null}
            fullWidth
            validate={[required()]}
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <DateInput
            source={prefixSource('periodEnd', props.sourcePrefix)}
            label="Contract end date"
            fullWidth
          />
        </Box>
      </Box>
      <Box display={{ xs: 'block', sm: 'flex' }} sx={{ p: 0.5 }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <NumberInput
            source={prefixSource('contractPeriod', props.sourcePrefix)}
            validate={[required(), minValue(1), maxValue(120)]}
            label="Contract term (months)"
            fullWidth
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <NumberInput
            source={prefixSource('cancellationPeriod', props.sourcePrefix)}
            validate={[minValue(1), maxValue(365)]}
            label="Cancellation term (days in advance)"
            fullWidth
          />
        </Box>
      </Box>
      <Box display={{ xs: 'block', sm: 'flex' }} sx={{ p: 0.5 }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <NumberInput
            source={prefixSource('billingCycle', props.sourcePrefix)}
            validate={[minValue(1), maxValue(365)]}
            defaultValue={1}
            label="Billing cycle (months)"
            fullWidth
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <NumberInput
            source={prefixSource('renewalPeriod', props.sourcePrefix)}
            validate={[minValue(1), maxValue(365)]}
            label="Renewal term (days in advance)"
            fullWidth
          />
        </Box>
      </Box>
      <Box display={{ xs: 'block', sm: 'flex' }} sx={{ p: 0.5 }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <ReferenceAutocomplete
            label="Purchase currency"
            required
            source={prefixSource('purchaseCurrency', props.sourcePrefix)}
            defaultValue={clientContract?.salesCurrency}
            reference="currencies/list"
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <NumberInput
            source={prefixSource('purchaseVat', props.sourcePrefix)}
            label="Purchase VAT (%, if applicable)"
            fullWidth
          />
        </Box>
      </Box>
      <Box display={{ xs: 'block', sm: 'flex' }} sx={{ p: 0.5 }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <NumberInput
            source={prefixSource('purchaseNrc', props.sourcePrefix)}
            label="Purchase NRC"
            fullWidth
            validate={[required()]}
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <NumberInput
            source={prefixSource('purchaseMrc', props.sourcePrefix)}
            label="Purchase MRC"
            fullWidth
            validate={[required()]}
          />
        </Box>
      </Box>
      <Box display={{ sm: 'block', md: 'flex' }} sx={{ p: 0.5 }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <NumberInput
            source={prefixSource('leadTime', props.sourcePrefix)}
            label="Lead time (weeks)"
            validate={[minValue(0), maxValue(128)]}
            fullWidth
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <SelectInput
            source={prefixSource('serviceUptime', props.sourcePrefix)}
            label="Uptime SLA (percent)"
            choices={[
              { id: 99, name: '99.0%' },
              { id: 99.5, name: '99.5%' },
              { id: 99.8, name: '99.8%' },
              { id: 99.9, name: '99.9%' },
              { id: 99.95, name: '99.95%' },
              { id: 99.99, name: '99.99%' },
              { id: null, name: 'Best-effort' },
            ]}
            fullWidth
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <SelectInput
            source={prefixSource('meanTimeToRepair', props.sourcePrefix)}
            label="MTTR"
            choices={[
              { id: 4, name: '4 hours' },
              { id: 8, name: '8 hours' },
              { id: 12, name: '12 hours' },
              { id: 24, name: '24 hours (NBD)' },
              { id: null, name: 'Best-effort' },
            ]}
            fullWidth
          />
        </Box>
      </Box>
      <Box display={{ sm: 'block', md: 'flex' }} sx={{ p: 0.5 }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <SelectInput
            source={prefixSource('supportLevel', props.sourcePrefix)}
            label="Support level"
            choices={[
              { id: '24x7x365', name: '24 x 7 x 365' },
              { id: '5x8', name: '5 x 8' },
              { id: 'other', name: 'Other' },
            ]}
            fullWidth
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <SelectInput
            source={prefixSource('serviceMonitoring', props.sourcePrefix)}
            label="Service monitoring"
            choices={[
              { id: 'pro-active', name: 'Pro-active 24 x 7 x 365' },
              { id: 'none', name: 'None' },
            ]}
            fullWidth
          />
        </Box>
      </Box>
      <Box display={{ sm: 'block', md: 'flex' }} sx={{ p: 0.5 }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <BooleanInput
            source={prefixSource('isSigned', props.sourcePrefix)}
            label="Contract signed"
            fullWidth
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <BooleanInput
            source={prefixSource('isCustom', props.sourcePrefix)}
            label="Custom offer"
            fullWidth
          />
        </Box>
      </Box>
      <Box display={{ sm: 'block', md: 'flex' }} sx={{ p: 0.5 }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <TextInput
            source={prefixSource('extraTerms', props.sourcePrefix)}
            label="Additional contract terms"
            multiline
            fullWidth
          />
        </Box>
      </Box>
    </Box>
  )
}
