import { ClientContactCreate } from './Create.component'
import { ClientContactEdit } from './Edit.component'
import { ClientContactList } from './List.component'
import { ClientContactShow } from './Show.component'

export default {
  list: ClientContactList,
  show: ClientContactShow,
  create: ClientContactCreate,
  edit: ClientContactEdit,
}
