import React from 'react'
import { NameFilter } from '../../atoms/NameFilter'
import {
  List,
  ListProps,
  Datagrid,
  DateField,
  TextField,
  ImageField,
  FunctionField,
} from 'react-admin'
import Chip from '@mui/material/Chip'

/**
 * Page to list all LastMileProviders.
 * @param props The page props.
 * @returns JSX.Element to render.
 */
export const LastMileProviderList: React.FC<ListProps> = (props) => {
  return (
    <List
      {...props}
      empty={false}
      perPage={25}
      filters={<NameFilter />}
      sort={{ field: 'name', order: 'ASC' }}
    >
      <Datagrid rowClick="show">
        <TextField source="name" fullWidth />
        <ImageField
          source="logo"
          fullWidth
          emptyText="N/A"
          sx={{
            '& img': { maxWidth: 150, maxHeight: 32, objectFit: 'contain' },
          }}
        />
        <FunctionField
          source="vendorIds"
          label="Vendors"
          render={(record: { vendorIds: string[] }) =>
            record?.vendorIds ? (
              <Chip label={record?.vendorIds?.length} />
            ) : (
              'N/A'
            )
          }
        />
        <DateField source="createdAt" showTime />
      </Datagrid>
    </List>
  )
}
