import React from 'react'
import { Edit, EditProps, SimpleForm, TextInput, required } from 'react-admin'
import { PageTitle } from '../../atoms/PageTitle'
import { ReferenceAutocomplete } from '../../molecules/ReferenceAutocomplete'

/**
 * Form to edit a monitor metric.
 * @param props The Edit component props
 * @see https://marmelab.com/react-admin/Edit.html
 */
export const MonitorMetricEdit: React.FC<EditProps> = (props) => {
  return (
    <Edit
      {...props}
      title={<PageTitle prefix="Monitor Metric" />}
      mutationMode="undoable"
    >
      <SimpleForm>
        <TextInput disabled label="Id" source="id" fullWidth />
        <ReferenceAutocomplete
          label="Probe Type"
          source="monitorProbeTypeId"
          reference="monitorProbeTypes/list"
          required
        />
        <TextInput source="name" validate={required()} fullWidth />
        <TextInput source="dataType" validate={required()} fullWidth />
        <TextInput source="units" validate={required()} fullWidth />
      </SimpleForm>
    </Edit>
  )
}
