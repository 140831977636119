import React, { useState, useEffect } from 'react'
import { Box } from '@mui/material'
import { IFrame } from '../../atoms/IFrame'
import { DisplayPDF } from '../../atoms/DisplayPDF'
import { Message } from '../../atoms/Message'
import { useWatch } from 'react-hook-form'
import { useDataProvider } from 'react-admin'

/**
 * Preview the generated file.
 * @example
 * return (
 * <FileGeneratorPreview />
 * )
 * @todo shares similar code to the template previewer.
 * Refactor to reduce wet code.
 */
export const FileGeneratorPreview: React.FC = () => {
  const dataProvider = useDataProvider()

  const [pdfData, setPdfData] = useState(null)
  const [htmlData, setHtmlData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const formData = useWatch({
    name: ['templateId', 'context', 'clientId'],
  })

  useEffect(() => {
    const fetchData = async () => {
      setPdfData(null)
      setHtmlData(null)
      setError('')

      const templateId = formData[0]
      const previewData = {
        context: formData[1],
      }

      if (previewData?.context?.model === 'Client') {
        previewData.context.id = formData[2]
      }

      if (previewData.context?.id && previewData.context?.model) {
        setLoading(true)
        try {
          const data = await dataProvider.customRequest(
            'templates',
            `${templateId}/preview`,
            {
              data: previewData,
              method: 'PUT',
            }
          )
          if (data.json && typeof data.json.data === 'object') {
            setPdfData(data.json.data.content)
          } else {
            setHtmlData(data.data || data.json)
          }
        } catch (e: any) {
          setError(e.message)
        }
        setLoading(false)
      }
    }

    fetchData()
  }, [dataProvider, formData])

  return (
    <Box style={{ minHeight: 800 }}>
      {error !== '' && <Message message={`Error: ${error}`} />}
      {loading && <Message message="Loading preview.." />}
      {pdfData && <DisplayPDF pdfData={pdfData} />}
      {htmlData && (
        <IFrame
          html={htmlData}
          style={{
            backgroundColor: 'white',
            padding: 16,
            width: '23cm',
            height: '750px',
            minHeight: 600,
            display: 'block',
            margin: '0 auto',
          }}
        />
      )}
    </Box>
  )
}
