import React from 'react'
import {
  Edit,
  EditProps,
  SimpleForm,
  TextInput,
  SelectInput,
  required,
  FileField,
  FileInput,
} from 'react-admin'
import { PageTitle } from '../../atoms/PageTitle'
import { useChoices } from './PriceBook.hooks'
import { ReferenceAutocomplete } from '../../molecules/ReferenceAutocomplete'

/**
 * Page to edit an existing price book.
 * @property {EditProps} props The properties passed to the component.
 * @returns {React.FC<EditProps>} A react functional component.
 * @see https://marmelab.com/react-admin/Edit.html
 * @see https://marmelab.com/react-admin/Inputs.html
 */
export const PriceBookEdit: React.FC<EditProps> = (props) => {
  const choices = useChoices()

  return (
    <Edit
      {...props}
      mutationMode="undoable"
      redirect="show"
      title={<PageTitle prefix="Price book" />}
    >
      <SimpleForm>
        <TextInput disabled label="Id" source="id" fullWidth />
        <TextInput source="name" validate={required()} fullWidth />
        <SelectInput
          source="state"
          validate={required()}
          choices={choices}
          fullWidth
        />
        <ReferenceAutocomplete
          required
          disabled
          label="Vendor"
          source="vendorId"
          reference="vendors/list"
          fullWidth
        />
        <ReferenceAutocomplete
          label="Country"
          source={'countryCode'}
          reference="countries/list"
          emptyText={'N/A'}
          fullWidth
          disabled
        />
        <ReferenceAutocomplete
          label="Currency"
          source="currencyCode"
          reference="currencies/list"
          fullWidth
          disabled
        />
        <TextInput source="notes" multiline fullWidth />
        <FileInput source="file" label="JSON file" multiple={false}>
          <FileField source="src" title="title" label="New JSON data" />
        </FileInput>
      </SimpleForm>
    </Edit>
  )
}
