import React from 'react'
import {
  List,
  ListProps,
  TextField,
  BooleanField,
  DateField,
} from 'react-admin'
import { LabelledDatagrid } from '../../atoms/LabelledDatagrid'
import { SearchFilter } from '../../atoms/SearchFilter'
import { TypeStateField } from '../../atoms/TypeStateField'
import { ReferenceChip } from '../../molecules/ReferenceChip'

/**
 * Page to list service orders.
 * @param {ListProps} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 * @example
 * return (
 * <ServiceOrderList {...props} />
 * )
 * @see {@link https://marmelab.com/react-admin/List.html|List}
 */
export const ServiceOrderList: React.FC<ListProps> = (props) => {
  return (
    <List
      empty={false}
      perPage={25}
      filters={<SearchFilter />}
      sort={{ field: 'createdAt', order: 'DESC' }}
      {...props}
    >
      <LabelledDatagrid rowClick="show">
        <TextField source="orderNo" />
        <TypeStateField source="state" fullWidth />
        <ReferenceChip
          referenceSource="clientId"
          tooltipSource="clientNo"
          source="Client"
          reference="clients"
          emptyText="N/A"
          link="show"
          chipSource="name"
        />
        <TextField source="reference" emptyText="N/A" />
        <TextField source="description" emptyText="N/A" />
        <BooleanField source="isSigned" />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
      </LabelledDatagrid>
    </List>
  )
}
