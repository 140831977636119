import React from 'react'
import {
  Edit,
  EditProps,
  FormTab,
  TabbedForm,
  TextInput,
  SelectInput,
  FormDataConsumer,
  required,
  regex,
  BooleanInput,
  ReferenceArrayInput,
} from 'react-admin'
import { ReferenceAutocomplete } from '../../molecules/ReferenceAutocomplete'
import { NotesInput } from '../../templates/NotesInput'
import { MetaListInput } from '../../organisms/MetaList'
import { DualListInput } from '@react-admin/ra-relationships'
import { DocumentRow } from '../../organisms/DocumentRow'
import { DocumentModalInput } from '../../organisms/DocumentModalInput'
import { PageTitle } from '../../atoms/PageTitle'
import { ReferenceManyFormTab, FormTabLabelBySource } from '../../atoms/FormTab'

/**
 * Page for editing a service order.
 * @param {EditProps} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 * @example
 * import { ServiceOrderEdit } from './ServiceOrderEdit.component.tsx'
 * <ServiceOrderEdit />
 * @see {@link https://marmelab.com/react-admin/Edit.html|Edit}
 */
export const ServiceOrderEdit: React.FC<EditProps> = (props) => {
  return (
    <Edit
      {...props}
      mutationMode="undoable"
      title={<PageTitle prefix="Service order" />}
    >
      <TabbedForm>
        <FormTab label="general" key="general">
          <TextInput
            source="orderNo"
            label="Order no. (CSO)"
            validate={[
              regex(
                /^CSO[0-9]{9,12}(|(\.[0-9]{1,3}|-[0-9]{1,3}))(|#[0-9]{1,3})$/
              ),
            ]}
            fullWidth
          />
          <TextInput source="reference" fullWidth />
          <SelectInput
            source="state"
            defaultValue="draft"
            validate={required()}
            choices={[
              { id: 'draft', name: 'Draft' },
              { id: 'confirmation', name: 'Confirmation' },
              { id: 'pending', name: 'Pending' },
              { id: 'provisioning', name: 'Provisioning' },
              { id: 'delivered', name: 'Delivered' },
              { id: 'cancelled', name: 'Cancelled' },
            ]}
            fullWidth
          />
          <FormDataConsumer>
            {({ formData, getSource, scopedFormData, ...rest }) => (
              <>
                <ReferenceAutocomplete
                  required
                  label={'Client'}
                  source="clientId"
                  reference="clients/list"
                  disableSyncWithLocation
                />
                <ReferenceAutocomplete
                  source="projectId"
                  reference="projects/list"
                  filter={{ clientId: formData?.clientId }}
                  disableSyncWithLocation
                  disabled={!formData?.clientId}
                  label="Project"
                />
              </>
            )}
          </FormDataConsumer>
          <BooleanInput source="isSigned" label="Contract signed" fullWidth />
        </FormTab>
        <FormTab
          label={
            <FormTabLabelBySource label="Contracts" source="contractIds" />
          }
        >
          <FormDataConsumer>
            {({ formData, getSource, scopedFormData, ...rest }) =>
              formData?.clientId ? (
                <>
                  <span>
                    Select one or more service contracts that will be part of
                    this service order.
                  </span>
                  <ReferenceArrayInput
                    label="Contracts"
                    source="contractIds"
                    reference="clientContracts/list"
                    filter={{ clientId: formData?.clientId }}
                  >
                    <DualListInput optionText="name" label={false} />
                  </ReferenceArrayInput>
                </>
              ) : (
                <span>Please select a client first.</span>
              )
            }
          </FormDataConsumer>
        </FormTab>
        <ReferenceManyFormTab
          reference="documents"
          target="relatedId"
          label="documents"
        >
          <DocumentRow />
          <DocumentModalInput reference="serviceOrders" />
        </ReferenceManyFormTab>
        <FormTab label={'notes'}>
          <NotesInput />
        </FormTab>
        <FormTab label={'meta'} key="meta">
          <MetaListInput source="meta" />
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}
