import React from 'react'
import { List, ListProps, Datagrid, TextField } from 'react-admin'
import { NameSelectFilters } from '../../molecules/NameSelectFilters'
import { useChoices } from './MarginBook.hooks'

/**
 * Page to list margin books
 * @param props
 * @prop {ListProps} props
 * @returns {JSX.Element}
 * @example
 * <MarginBookList {...props}/>
 * @see https://marmelab.com/react-admin/List.html
 * @see https://marmelab.com/react-admin/Fields.html
 */
export const MarginBookList: React.FC<ListProps> = (props) => {
  const choices = useChoices()

  return (
    <List
      {...props}
      empty={false}
      perPage={25}
      filters={<NameSelectFilters choices={choices} />}
    >
      <Datagrid rowClick="show">
        <TextField source="name" fullWidth />
        <TextField source="type" fullWidth />
        <TextField source="offsetNrc" fullWidth />
        <TextField source="offsetMrc" fullWidth />
      </Datagrid>
    </List>
  )
}
