import React from 'react'
import {
  List,
  ListProps,
  TextField,
  BooleanField,
  DateField,
} from 'react-admin'
import { SearchActiveFilters } from '../../molecules/SearchActiveFilters/SearchActiveFilters.component'
import { ReferenceText } from '../../molecules/ReferenceText'
import { LabelledDatagrid } from '../../atoms/LabelledDatagrid'

/**
 * Page to list clients
 * @property {ListProps} props
 * @example
 * <ClientList />
 * @returns {React.FC<ListProps>}
 * @see https://marmelab.com/react-admin/List.html
 */
export const ClientList: React.FC<ListProps> = (props) => {
  return (
    <List
      {...props}
      empty={false}
      perPage={25}
      filters={<SearchActiveFilters />}
      sort={{ field: 'createdAt', order: 'DESC' }}
    >
      <LabelledDatagrid rowClick="show">
        <TextField source="clientNo" fullWidth />
        <TextField source="name" fullWidth />
        <ReferenceText
          source="countryCode"
          reference="countries/list"
          emptyText={'N/A'}
          link={false}
        />
        <BooleanField source="isActive" fullWidth />
        <DateField source="updatedAt" showTime />
      </LabelledDatagrid>
    </List>
  )
}
