import * as Yup from 'yup'
import { ProvisioningStatus } from './ProvisioningUpdate.types'

export const validationSchema = Yup.object().shape({
  sendImmediate: Yup.boolean().required('Send Immediate is required'),
  estimatedDeliveryDate: Yup.date()
    .nullable()
    .optional()
    .typeError('Invalid date format'),
  provisioningUpdateNotes: Yup.string().nullable(),
  provisioningStatus: Yup.string()
    .oneOf(Object.values(ProvisioningStatus), 'Invalid provisioning status')
    .required('Provisioning status is required'),
  ragStatus: Yup.string()
    .oneOf(['red', 'amber', 'green'], 'Invalid RAG status')
    .required('RAG status is required'),
  isOnHold: Yup.boolean(),
})
