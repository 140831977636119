/**
 * CSS styles for MetaRow component
 * @returns {React.CSSProperties} The meta row styles.
 * @exports useMetaRowStyles
 * @see https://mui.com/styles/api/#css
 * @see https://mui.com/system/grid/#basic-grid
 * @see https://mui.com/system/grid/#grid-template-columns
 */
export const useMetaRowStyles = () => {
  return {
    display: 'grid',
    gap: 1,
    gridTemplateColumns: 'repeat(2, 1fr)',
    width: '100%',
  }
}
