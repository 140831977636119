/**
 * DocumentRow styles
 * @param {Theme} theme
 * @returns {Styles}
 */
export const useStyles = () => {
  return {
    width: '100%',
    '& .column-title': {
      maxWidth: '20em',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  }
}
