import React from 'react'
import { Edit, EditProps, SimpleForm, TextInput, required } from 'react-admin'

/**
 * Page to edit permissions.
 * @property {EditProps} props
 * @returns {JSX.Element}
 * @example
 * <PermissionEdit {...props} />
 * @see {@link https://marmelab.com/react-admin/Edit.html|Edit}
 * @see {@link https://marmelab.com/react-admin/Fields.html|Fields}
 * @see {@link https://marmelab.com/react-admin/SimpleForm.html|SimpleForm}
 */
export const PermissionEdit: React.FC<EditProps> = (props) => {
  return (
    <Edit {...props} mutationMode="undoable">
      <SimpleForm>
        <TextInput disabled label="Id" source="id" fullWidth />
        <TextInput source="name" validate={required()} fullWidth />
        <TextInput source="description" fullWidth />
        <TextInput disabled source="resource" validate={required()} fullWidth />
        <TextInput disabled source="action" validate={required()} fullWidth />
      </SimpleForm>
    </Edit>
  )
}
