import {
  ChangeEvent,
  Children,
  cloneElement,
  isValidElement,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from 'react'
import PropTypes from 'prop-types'
import { ResponsiveStyleValue, SxProps } from '@mui/system'
import { styled } from '@mui/material/styles'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Button,
} from '@mui/material'
import {
  RaRecord,
  useRecordContext,
  OptionalRecordContextProvider,
} from 'ra-core'

import { TabbedShowLayoutTabs } from 'react-admin'
import { Tab } from 'react-admin'

export const TabbedShowLayout = (props: TabbedShowLayoutProps) => {
  const {
    children,
    className,
    spacing,
    divider,
    syncWithLocation = true,
    tabs = DefaultTabs,
    isDirty,
    value,
    ...rest
  } = props
  const record = useRecordContext(props)
  const nonNullChildren = Children.toArray(children).filter(
    (child) => child !== null
  ) as ReactElement<{
    context?: string
    spacing?: ResponsiveStyleValue<number | string>
    divider?: ReactNode
  }>[]
  const [tabValue, setTabValue] = useState(value)
  const [selectedTab, setSelectedTab] = useState(0)
  const [open, setOpen] = useState(false)
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleTabChange = (event: ChangeEvent<{}>, value: any): void => {
    setSelectedTab(value)
    if (!syncWithLocation) {
      if (isDirty) {
        handleClickOpen()
        return
      }
      setTabValue(value)
    }
  }

  useEffect(() => {
    setTabValue(value)
  }, [value])

  if (!record) {
    return null
  }

  const handleCloseCancel = () => {
    setOpen(false)
  }
  const handleCloseContinue = () => {
    setOpen(false)
    setTabValue(selectedTab)
  }

  const renderTabHeaders = () =>
    cloneElement(
      tabs,
      {
        onChange: handleTabChange,
        syncWithLocation,
        value: tabValue,
      },
      nonNullChildren
    )
  return (
    <OptionalRecordContextProvider value={props.record}>
      <Root className={className} {...sanitizeRestProps(rest)}>
        <>
          {renderTabHeaders()}
          <Divider />
          <div className={TabbedShowLayoutClasses.content}>
            {Children.map(nonNullChildren, (tab, index) => {
              if (!isValidElement(tab) || tabValue !== index) {
                return null
              }
              return cloneElement(tab, {
                context: 'content',
                spacing,
                divider,
              })
            })}
          </div>
          {open && (
            <Dialog
              open={open}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {'Continue without saving?'}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  It seems you have unsaved changes in this tab. If you
                  continue, these changes will be lost. Are you sure you want to
                  leave this tab without saving?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseCancel}>Cancel</Button>
                <Button onClick={handleCloseContinue} autoFocus>
                  Continue
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </>
      </Root>
    </OptionalRecordContextProvider>
  )
}

TabbedShowLayout.Tab = Tab

export interface TabbedShowLayoutProps {
  children: ReactNode
  className?: string
  divider?: ReactNode
  record?: RaRecord
  rootPath?: string
  spacing?: ResponsiveStyleValue<number | string>
  sx?: SxProps
  syncWithLocation?: boolean
  tabs?: ReactElement
  value?: any
  isDirty?: boolean
}

TabbedShowLayout.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  record: PropTypes.object,
  spacing: PropTypes.any,
  sx: PropTypes.any,
  syncWithLocation: PropTypes.bool,
  tabs: PropTypes.element,
  value: PropTypes.number,
}

const DefaultTabs = <TabbedShowLayoutTabs />

const PREFIX = 'RaTabbedShowLayout'

export const TabbedShowLayoutClasses = {
  content: `${PREFIX}-content`,
}

const Root = styled('div', {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  flex: 1,
  [`& .${TabbedShowLayoutClasses.content}`]: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  },
}))

const sanitizeRestProps = ({
  record,
  resource,
  initialValues,
  staticContext,
  translate,
  tabs,
  ...rest
}: any) => rest
