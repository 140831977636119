import React from 'react'
import { IOnboardingFilters } from './OnboardingFilters.types'
import { Filter, TextInput, SelectArrayInput } from 'react-admin'

/**
 * Filters for the onboarding list.
 * @param props The properties
 * @returns The jsx component
 * @example <OnboardingFilters />
 */
export const OnboardingFilters: React.FC<IOnboardingFilters> = (props) => {
  return (
    <Filter {...props}>
      <TextInput label="Search" source="term" alwaysOn />
      <SelectArrayInput
        source="state"
        choices={[
          { id: 'pending', name: 'Pending' },
          { id: 'verified', name: 'Verified' },
          { id: 'completed', name: 'Completed' },
          { id: 'denied', name: 'Denied' },
          { id: 'archived', name: 'Archived' },
        ]}
        alwaysOn
      />
    </Filter>
  )
}
