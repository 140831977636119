import React from 'react'
import { Route } from 'react-router-dom'
import { Resource, Admin, localStorageStore, CustomRoutes } from 'react-admin'
import { QueryClient } from 'react-query'
import { i18nProvider } from './configuration/Language'
import { lightTheme, darkTheme } from './components/particles/themes'
import { Layout } from './configuration/Layout'
import { Login } from './components/pages/Login'
import { Dashboard } from './components/pages/Dashboard'

import dataProvider from './providers/dataProvider'
import authProvider from './providers/authProvider'

import application from './components/pages/Application'
import accesstechnology from './components/pages/AccessTechnology'
import product from './components/pages/Product'
import project from './components/pages/Project'
import vendor from './components/pages/Vendor'
import permission from './components/pages/Permission'
import documentcategory from './components/pages/DocumentCategory'
import usergroup from './components/pages/UserGroup'
import userrole from './components/pages/UserRole'
import user from './components/pages/User'
import clientcontact from './components/pages/ClientContact'
import vendorcontact from './components/pages/VendorContact'
import marginbook from './components/pages/MarginBook'
import monitorProbeType from './components/pages/MonitorProbeType'
import monitorAlertHistory from './components/pages/MonitorAlertHistory'
import monitorAlertRule from './components/pages/MonitorAlertRule'
import monitorMetric from './components/pages/MonitorMetric'
import monitorNotificationContact from './components/pages/MonitorNotificationContact'
import monitorNotificationType from './components/pages/MonitorNotificationType'
import monitorProbe from './components/pages/MonitorProbe'
import monitorTarget from './components/pages/MonitorTarget'
import servicetype from './components/pages/ServiceType'
import servicesite from './components/pages/ServiceSite'
import client from './components/pages/Client'
import marginrule from './components/pages/MarginRule'
import note from './components/pages/Note'
import ticket from './components/pages/Ticket'
import servicestatistic from './components/pages/ServiceStatistic'
import template from './components/pages/Template'
import quote from './components/pages/Quote'
import serviceorder from './components/pages/ServiceOrder'
import clientcontract from './components/pages/ClientContract'
import document from './components/pages/Document'
import vendorcontract from './components/pages/VendorContract'
import onboarding from './components/pages/Onboarding'
import serviceport from './components/pages/ServicePort'
import service from './components/pages/Service'
import request from './components/pages/Request'
import servicecategory from './components/pages/ServiceCategory'
import pricebook from './components/pages/PriceBook'
import datacenter from './components/pages/Datacenter'
import isps from './components/pages/InternetServiceProvider'
import lmps from './components/pages/LastMileProvider'
import coveragezone from './components/pages/CoverageZone'
import coveragearea from './components/pages/CoverageArea'
import coveragedatacenter from './components/pages/CoverageDatacenter'
import coverageaddress from './components/pages/CoverageAddress'
import endcustomer from './components/pages/EndCustomer'

import { RequestFlowServiceDetails } from './components/pages/RequestFlow'
import { RequestFlowOverview } from './components/pages/RequestFlow'
import { RequestFlowList } from './components/pages/RequestFlow'

// disable the auto-refersh-on-window-focus as it messes with forms sometimes
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

/**
 * Navigation routes for the application.
 * @returns {JSX.Element}
 */
export const Routes: React.FC = () => (
  <Admin
    queryClient={queryClient}
    layout={Layout}
    i18nProvider={i18nProvider}
    dataProvider={dataProvider(`${process.env.REACT_APP_API_URL}/api`)}
    lightTheme={lightTheme}
    darkTheme={darkTheme}
    loginPage={Login}
    dashboard={Dashboard}
    authProvider={authProvider}
    store={localStorageStore(process.env.REACT_STORE_VERSION || '6')}
  >
    <CustomRoutes>
      <Route path="quote-requests">
        <Route path="" element={<RequestFlowList />} />
        <Route path=":requestId/details" />
        <Route path=":requestId/*" element={<RequestFlowOverview />} />
        <Route
          path=":requestId/services/:serviceId/*"
          element={<RequestFlowServiceDetails />}
        />
      </Route>
    </CustomRoutes>
    <Resource name="notes" {...note} />
    <Resource name="requests" options={{ name: 'requests-old' }} {...request} />
    <Resource name="projects" {...project} />
    <Resource name="documents" {...document} />
    <Resource name="services" {...service} />
    <Resource
      name="serviceSites"
      options={{ label: 'Service sites' }}
      {...servicesite}
    />
    <Resource
      name="serviceStatistics"
      options={{ label: 'Service statistics' }}
      {...servicestatistic}
    />
    <Resource
      name="servicePorts"
      options={{ label: 'Service ports' }}
      {...serviceport}
    />
    <Resource name="quotes" {...quote} />
    <Resource name="clients" {...client} />
    <Resource
      name="clientContacts"
      options={{ label: 'Client contacts' }}
      {...clientcontact}
    />
    <Resource
      name="clientContracts"
      options={{ label: 'Client contracts' }}
      {...clientcontract}
    />
    <Resource name="products" {...product} />
    <Resource name="vendors" {...vendor} />
    <Resource
      name="vendorContacts"
      options={{ label: 'Vendor contacts' }}
      {...vendorcontact}
    />
    <Resource
      name="vendorContracts"
      options={{ label: 'Vendor contracts' }}
      {...vendorcontract}
    />
    <Resource
      name="marginBooks"
      options={{ label: 'Margin books' }}
      {...marginbook}
    />
    <Resource
      name="marginRules"
      options={{ label: 'Margin rules' }}
      {...marginrule}
    />
    <Resource name="users" {...user} />
    <Resource name="userRoles" {...userrole} />
    <Resource name="permissions" {...permission} />
    <Resource name="userGroups" {...usergroup} />
    <Resource name="applications" {...application} />
    <Resource
      name="accessTechnologies"
      options={{ label: 'Access technologies' }}
      {...accesstechnology}
    />
    <Resource
      name="serviceTypes"
      options={{ label: 'Service types' }}
      {...servicetype}
    />
    <Resource
      name="serviceCategories"
      options={{ label: 'Service categories' }}
      {...servicecategory}
    />
    <Resource
      name="monitorTargets"
      options={{ label: 'Monitor targets' }}
      {...monitorTarget}
    />
    <Resource
      name="monitorProbes"
      options={{ label: 'Monitor probes' }}
      {...monitorProbe}
    />
    <Resource
      name="monitorProbeTypes"
      options={{ label: 'Monitor probe types' }}
      {...monitorProbeType}
    />
    <Resource
      name="monitorNotificationTypes"
      options={{ label: 'Monitor notification types' }}
      {...monitorNotificationType}
    />
    <Resource
      name="monitorNotificationContacts"
      options={{ label: 'Monitor notification contacts' }}
      {...monitorNotificationContact}
    />
    <Resource
      name="monitorMetrics"
      options={{ label: 'Monitor metrics' }}
      {...monitorMetric}
    />
    <Resource
      name="monitorAlertRules"
      options={{ label: 'Monitor alert rules' }}
      {...monitorAlertRule}
    />
    <Resource
      name="monitorAlertHistories"
      options={{ label: 'Monitor alert history' }}
      {...monitorAlertHistory}
    />
    <Resource
      name="serviceOrders"
      options={{ label: 'Service orders' }}
      {...serviceorder}
    />
    <Resource
      name="documentCategories"
      options={{ label: 'Document categories' }}
      {...documentcategory}
    />
    <Resource name="templates" {...template} />
    <Resource name="onboardings" {...onboarding} />
    <Resource name="tickets" {...ticket} />
    <Resource name="files" />
    <Resource
      name="priceBooks"
      options={{ label: 'Price books' }}
      {...pricebook}
    />
    <Resource name="datacenters" {...datacenter} />
    <Resource
      name="lastMileProviders"
      options={{ label: 'Last mile providers' }}
      {...lmps}
    />
    <Resource
      name="internetServiceProviders"
      options={{ label: 'Internet service providers' }}
      {...isps}
    />
    <Resource
      name="coverageZones"
      {...coveragezone}
      options={{ label: 'Coverage zones' }}
    />
    <Resource
      name="coverageAreas"
      {...coveragearea}
      options={{ label: 'Coverage areas' }}
    />
    <Resource
      name="coverageDatacenters"
      {...coveragedatacenter}
      options={{ label: 'Coverage datacenters' }}
    />
    <Resource
      name="coverageAddresses"
      {...coverageaddress}
      options={{ label: 'Coverage addresses' }}
    />
    <Resource
      name="endCustomers"
      {...endcustomer}
      options={{ label: 'End customers' }}
    />
  </Admin>
)
