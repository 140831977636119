import React from 'react'
import { DateField, TextField } from 'react-admin'
import { LabelledDatagrid } from '../../atoms/LabelledDatagrid'
import { TypeStateField } from '../../atoms/TypeStateField'
import { EllipsedTextField } from '../../atoms/EllipsedField'

/**
 * This component is used in the Dashboard to display the newest tickets.
 * It is used in the "Slim" variant, which displays on small screens.
 * @example
 * return <NewestTicketsSlim />
 * @see {@link https://marmelab.com/react-admin/List.html#the-datagrid-component|Datagrid}
 * @see {@link https://marmelab.com/react-admin/Fields.html#referencefield|ReferenceField}
 * @see {@link https://marmelab.com/react-admin/Fields.html#singlefieldlist|SingleFieldList}
 */
export const NewestTicketsSlim: React.FC = () => {
  return (
    <LabelledDatagrid optimized rowClick="show" bulkActionButtons={false}>
      <TextField
        source="ticketNo"
        sortable={false}
        emptyText={'N/A'}
        sx={{ display: 'block', maxWidth: 100 }}
      />
      <TypeStateField source="state" fullWidth />
      <EllipsedTextField source="summary" maxWidth={500} />
      <DateField source="createdAt" showTime />
    </LabelledDatagrid>
  )
}
