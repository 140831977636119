import React from 'react'
import { useGetList } from 'react-admin'
import { IconCard } from '../../molecules/IconCard/IconCard.component'
import ActiveServicesIcon from '@mui/icons-material/WifiTetheringOutlined'

/**
 * A card with active services metrics.
 * @example <ActiveServicesCard />
 */
export const ActiveServicesCard: React.FC = () => {
  const activeServices = useGetList('services/list', {
    filter: { state: 'active' },
    pagination: { page: 1, perPage: 10 },
    sort: { field: 'createdAt', order: 'DESC' },
  })

  return (
    <IconCard
      icon={ActiveServicesIcon}
      to={'/services?filter=%7B"state"%3A%5B"active"%5D%7D'}
      subtitle={`${activeServices?.total || 'N/A'}`}
      title={'Active services'}
    />
  )
}
