import { MarginRuleCreate } from './Create.component'
import { MarginRuleEdit } from './Edit.component'
import { MarginRuleList } from './List.component'
import { MarginRuleShow } from './Show.component'

export default {
  list: MarginRuleList,
  show: MarginRuleShow,
  create: MarginRuleCreate,
  edit: MarginRuleEdit,
}
