import { ServiceOrderShow } from './Show.component'
import { ServiceOrderCreate } from './Create.component'
import { ServiceOrderEdit } from './Edit.component'
import { ServiceOrderList } from './List.component'

export default {
  list: ServiceOrderList,
  show: ServiceOrderShow,
  create: ServiceOrderCreate,
  edit: ServiceOrderEdit,
}
