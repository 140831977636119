import React, { useState } from 'react'
import {
  useCreate,
  useNotify,
  useRecordContext,
  useRefresh,
  Confirm,
} from 'react-admin'
import { FieldValues } from 'react-hook-form/dist/types/fields'
import { WizardForm, WizardFormStep } from '@react-admin/ra-form-layout'
import { ServiceSpecificationInput } from '../../templates/ServiceSpecificationInput'
import { Button, Dialog, DialogTitle, DialogContent } from '@mui/material'
import MutateIcon from '@mui/icons-material/EditRoadOutlined'
import CloseIcon from '@mui/icons-material/Close'
import { ServiceInput } from './ServiceInput.component'
import { VendorContractInput } from './VendorContractInput.component'
import { ClientContractInput } from './ClientContractInput.component'
import { SitesInput } from '../../molecules/SitesInput'
import { PortsInput } from './PortsInput.component'
import { NotesInput } from './NotesInput.component'

/**
 * Modal for mutating a service.
 * @returns {React.FC}
 * @example
 * import { MutateServiceModal } from './MutateServiceModal'
 * <MutateServiceModal />
 */
export const MutateServiceModal: React.FC = () => {
  const record = useRecordContext()
  const [showDialog, setShowDialog] = useState(false)
  const [, setIsSubmitting] = useState(false)
  const [confirmClose, setConfirmClose] = useState(false)
  const notify = useNotify()
  const refresh = useRefresh()
  const [create] = useCreate()

  const handleClick = () => {
    setShowDialog(true)
  }

  const handleCloseClick = (event?: any, reason?: string) => {
    if (reason && reason === 'backdropClick') {
      // prevent backdrop click close
      return false
    }
    setConfirmClose(true)
  }

  const handleClose = () => {
    setConfirmClose(false)
    setShowDialog(false)
  }

  const handleSubmit = async (values: FieldValues) => {
    setIsSubmitting(true)

    const { ports, sites, vendorContract, clientContract, ...formData } = values

    if (values.vendorContractAction !== 'none') {
      formData['vendorContract'] = vendorContract
    }

    if (values.clientContractAction !== 'none') {
      formData['clientContract'] = clientContract
    }

    if (!(record?.ports?.length > 0)) {
      formData['ports'] = ports
    }

    if (!(record?.sites?.length > 0)) {
      formData['sites'] = sites
    }

    try {
      await create(
        `services/${values.id}/mutate`,
        { data: formData },
        { returnPromise: true }
      )
      notify('Service data has been updated', { type: 'info' })
      setIsSubmitting(false)
      setShowDialog(false)
      setTimeout(refresh, 500)
    } catch (error: any) {
      notify(error.message || error, { type: 'warning' })
    }
  }

  return (
    <>
      <Button color="primary" aria-label="complete" onClick={handleClick}>
        <MutateIcon />
        &nbsp;{'Mutate'}
      </Button>
      <Confirm
        isOpen={confirmClose}
        title="Cancel Mutation"
        content="Are you sure you want to cancel mutating this service? Any changed data will be lost."
        onConfirm={handleClose}
        onClose={() => {
          setConfirmClose(false)
        }}
      />
      <Dialog
        fullWidth
        maxWidth="lg"
        open={showDialog}
        onClose={handleCloseClick}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Mutate {record?.serviceNo}
          <Button
            sx={{ margin: 0, float: 'right' }}
            size={'small'}
            onClick={handleCloseClick}
          >
            <CloseIcon />
            Close
          </Button>
        </DialogTitle>
        <DialogContent>
          <WizardForm onSubmit={handleSubmit}>
            <WizardFormStep label="Service">
              <ServiceInput />
            </WizardFormStep>
            <WizardFormStep label="Specifications">
              <ServiceSpecificationInput />
            </WizardFormStep>
            <WizardFormStep label="Vendor Contract">
              <VendorContractInput />
            </WizardFormStep>
            <WizardFormStep label="Client Contract">
              <ClientContractInput />
            </WizardFormStep>
            {record?.sites?.length === 0 && (
              <WizardFormStep label="Sites">
                <SitesInput />
              </WizardFormStep>
            )}
            {record?.ports?.length === 0 && (
              <WizardFormStep label="Ports">
                <PortsInput />
              </WizardFormStep>
            )}
            <WizardFormStep label="Notes">
              <NotesInput />
            </WizardFormStep>
          </WizardForm>
        </DialogContent>
      </Dialog>
    </>
  )
}
