import React from 'react'
import { IContactModalInput } from './ContactModalInput.types'
import { CreateInDialogButton } from '@react-admin/ra-form-layout'
import {
  SimpleForm,
  TextInput,
  SelectInput,
  required,
  useRefresh,
} from 'react-admin'
import { ReferenceAutocomplete } from '../../molecules/ReferenceAutocomplete'
import { useRecord, useRoleChoices } from './ContactModalInput.hooks'
import { ContactDetailsInput } from '../ContactDetailsInput'

/**
 * Form for creating a new contact.
 * @property {string} resource The resource name.
 * @property {string} reference The reference name
 * @example
 * <ContactModalInput resource="clientContacts" reference="clientContacts/list" />
 * @see https://marmelab.com/react-admin/CreateEdit.html
 * @see https://marmelab.com/react-admin/Inputs.html
 * @see https://marmelab.com/react-admin/Inputs.html#referenceinput
 */
export const ContactModalInput: React.FC<IContactModalInput> = (props) => {
  const record = useRecord(props.reference, props.resource)
  const roleChoices = useRoleChoices()
  const refresh = useRefresh()

  return (
    <CreateInDialogButton
      inline
      fullWidth
      record={record}
      resource={props.resource}
      mutationOptions={{ onSettled: refresh }}
    >
      <SimpleForm {...props}>
        {props.resource === 'clientContacts' ? (
          <ReferenceAutocomplete
            required
            label="Client"
            source="clientId"
            reference="clients/list"
          />
        ) : null}
        {props.resource === 'vendorContacts' ? (
          <ReferenceAutocomplete
            required
            label="Vendor"
            source="vendorId"
            reference="vendors/list"
          />
        ) : null}
        {props.reference === 'services' ? (
          <ReferenceAutocomplete
            label="Service"
            source="serviceIds"
            defaultValue={String(record.serviceIds?.[0])}
            reference="services/list"
          />
        ) : null}
        <TextInput source="name" validate={required()} fullWidth />
        <SelectInput
          source="role"
          validate={required()}
          choices={roleChoices}
          fullWidth
        />
        <ContactDetailsInput />
      </SimpleForm>
    </CreateInDialogButton>
  )
}
