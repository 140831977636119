import React from 'react'
import { useGetList } from 'react-admin'
import { IconCard } from '../../molecules/IconCard/IconCard.component'
import TicketsIcon from '@mui/icons-material/ConfirmationNumberOutlined'

/**
 * A card with open tickets metrics.
 * @example <OpenTicketsCard />
 */
export const OpenTicketsCard: React.FC = () => {
  const openTickets = useGetList('tickets/list', {
    pagination: { page: 1, perPage: 10 },
    sort: { field: 'createdAt', order: 'DESC' },
    filter: { state: { not: 'done' } },
  })

  return (
    <IconCard
      icon={TicketsIcon}
      to={'/tickets?filter=%7B"state"%3A%5B"in-progress"%2C"pending"%5D%7D'}
      subtitle={`${openTickets?.total || 'N/A'}`}
      title={'Open tickets'}
    />
  )
}
