import React from 'react'
import {
  Edit,
  EditProps,
  Datagrid,
  BooleanInput,
  TextInput,
  TextField,
  FormTab,
  SelectInput,
  FormDataConsumer,
  SimpleShowLayout,
  ArrayField,
  ArrayInput,
  SimpleFormIterator,
  TabbedForm,
  required,
} from 'react-admin'
import { Box, Button } from '@mui/material'
import AddIcon from '@mui/icons-material/AddOutlined'
import { PageTitle } from '../../atoms/PageTitle'
import { AddressDisplay } from '../../organisms/AddressDisplay'
import { ContactDetailsDisplay } from '../../organisms/ContactDetailsDisplay'

/**
 * Page to edit an existing onboarding.
 * @todo refactor forms to use a common components.
 * @param props The properties
 * @returns The jsx component
 * @example <OnboardingEdit />
 */
export const OnboardingEdit: React.FC<EditProps> = (props) => {
  return (
    <Edit
      {...props}
      mutationMode="undoable"
      title={<PageTitle prefix="Onboarding" />}
    >
      <TabbedForm>
        <FormTab label="general" key="general">
          <TextInput source="description" label="Description" fullWidth />
          <SelectInput
            source="state"
            defaultValue="pending"
            choices={[
              { id: 'pending', name: 'Pending' },
              { id: 'verified', name: 'Verified' },
              { id: 'denied', name: 'Denied' },
              { id: 'completed', name: 'Completed' },
              { id: 'archived', name: 'Archived' },
            ]}
            fullWidth
          />
          <FormDataConsumer>
            {({ formData }) => (
              <SelectInput
                source="type"
                defaultValue="full"
                disabled={
                  !(
                    ['user', 'full'].includes(formData?.type) &&
                    ['verified', 'pending'].includes(formData?.state)
                  )
                }
                validate={required()}
                choices={[
                  { id: 'full', name: 'Full (both client and user)' },
                  { id: 'user', name: 'User only' },
                ]}
                fullWidth
              />
            )}
          </FormDataConsumer>
          <BooleanInput
            source="requireVerification"
            label="Require e-mail verification"
            defaultValue={true}
            fullWidth
          />
          <BooleanInput
            source="requireApproval"
            label="Require on-boarding completion by staff"
            disabled
            defaultValue={true}
            fullWidth
          />
        </FormTab>
        <FormTab label={'user'}>
          <FormDataConsumer>
            {({ formData }) =>
              !(
                ['user', 'full'].includes(formData?.type) &&
                ['verified', 'pending'].includes(formData?.state)
              ) ? (
                <SimpleShowLayout sx={{ m: 0, p: 0 }} record={formData?.user}>
                  <TextField source="name" emptyText="N/A" />
                  <TextField source="email" emptyText="N/A" />
                </SimpleShowLayout>
              ) : (
                <>
                  <TextInput source="user.name" label="User name" fullWidth />
                  <TextInput
                    source="user.email"
                    label="User e-mail"
                    disabled
                    fullWidth
                  />
                </>
              )
            }
          </FormDataConsumer>
        </FormTab>
        <FormTab label={'client'}>
          <FormDataConsumer>
            {({ formData }) =>
              !(
                ['client', 'full'].includes(formData?.type) &&
                ['verified', 'pending'].includes(formData?.state)
              ) ? (
                <SimpleShowLayout sx={{ m: 0, p: 0 }} record={formData?.client}>
                  <TextField source="name" />
                  <AddressDisplay />
                  <TextField source="phoneNumber" emptyText={'N/A'} fullWidth />
                  <TextField source="email" fullWidth />
                  <TextField source="companyName" fullWidth />
                  <TextField
                    source="companyRegNo"
                    emptyText={'N/A'}
                    fullWidth
                  />
                  <TextField
                    source="companyVatNo"
                    emptyText={'N/A'}
                    fullWidth
                  />
                  <TextField source="companyIban" emptyText={'N/A'} fullWidth />
                </SimpleShowLayout>
              ) : (
                <>
                  <TextInput source="client.name" fullWidth />
                  <AddressDisplay
                    streetNameSource="client.streetName"
                    addressSource="client.address"
                    postalCodeSource="client.postalCode"
                    citySource="client.city"
                    countrySource="client.countryCode"
                    streetNumberRoomSource="client.streetNumberRoom"
                    streetNumberSource="client.streetNumber"
                    streetNumberSuffixSource="client.streetNumberSuffix"
                  />
                  <TextInput source="client.phoneNumber" fullWidth />
                  <TextInput source="client.email" fullWidth />
                  <TextInput source="client.companyName" fullWidth />
                  <TextInput source="client.companyRegNo" fullWidth />
                  <TextInput source="client.companyVatNo" fullWidth />
                  <TextInput source="client.companyIban" fullWidth />
                </>
              )
            }
          </FormDataConsumer>
        </FormTab>
        <FormTab label={'contacts'}>
          <FormDataConsumer>
            {({ formData, getSource, scopedFormData }) =>
              !(
                ['client', 'full'].includes(formData?.type) &&
                ['verified', 'pending'].includes(formData?.state)
              ) ? (
                <ArrayField source="contacts" fullWidth>
                  <Datagrid sx={{ width: '100%' }} bulkActionButtons={false}>
                    <TextField source="category" />
                    <TextField source="name" />
                    <ContactDetailsDisplay />
                  </Datagrid>
                </ArrayField>
              ) : (
                <ArrayInput
                  source="contacts"
                  label={false}
                  sx={{ mb: 3 }}
                  fullWidth
                >
                  <SimpleFormIterator
                    getItemLabel={(index) => `#${index + 1}`}
                    addButton={
                      <Button>
                        <AddIcon />
                        &nbsp;Add Contact
                      </Button>
                    }
                    fullWidth
                  >
                    <FormDataConsumer {...props}>
                      {({ formData, getSource, scopedFormData, ...rest }) => (
                        <Box sx={{ width: '100%' }}>
                          <Box
                            display={{ sm: 'block', md: 'flex' }}
                            sx={{ p: 0.5 }}
                          >
                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                              <SelectInput
                                source={getSource?.('category') || ''}
                                choices={[
                                  { id: 'general', name: 'General' },
                                  { id: 'billing', name: 'Billing' },
                                  { id: 'maintenance', name: 'Maintenance' },
                                  { id: 'delivery', name: 'Delivery' },
                                ]}
                                fullWidth
                              />
                            </Box>
                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                              <TextInput
                                source={getSource?.('name') || ''}
                                validate={required()}
                                fullWidth
                              />
                            </Box>
                          </Box>
                          <Box
                            display={{ sm: 'block', md: 'flex' }}
                            sx={{ p: 0.5 }}
                          >
                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                              <TextInput
                                source={getSource?.('emailAddress') || ''}
                                validate={required()}
                                fullWidth
                              />
                            </Box>
                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                              <TextInput
                                source={getSource?.('phoneNumber') || ''}
                                fullWidth
                              />
                            </Box>
                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                              <TextInput
                                source={getSource?.('mobileNumber') || ''}
                                fullWidth
                              />
                            </Box>
                          </Box>
                        </Box>
                      )}
                    </FormDataConsumer>
                  </SimpleFormIterator>
                </ArrayInput>
              )
            }
          </FormDataConsumer>
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}
