import React from 'react'
import { IProcessOperationButton } from './ProcessOperationButton.types'
import { handleOperationSubmit } from './ProcessOperationButton.funcs'
import {
  Button,
  useNotify,
  useRecordContext,
  useDataProvider,
  useRefresh,
  Form,
  SaveButton,
} from 'react-admin'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@mui/material'
import { FieldValues } from 'react-hook-form'

import IconApply from '@mui/icons-material/CheckCircleOutline'
import IconCancel from '@mui/icons-material/Cancel'

/**
 * Process operation button component.
 *
 * @description Supports multiple process operations in one component.
 * @param { IProcessOperationButton } props - The props for the ProcessOperationButton component.
 * @returns {React.ReactElement}
 * @see IProcessOperationButton
 * @see https://marmelab.com/react-admin/Actions.html
 * @see https://mui.com/components/buttons/#icon-buttons
 * @see https://mui.com/components/material-icons/
 *
 */
export const ProcessOperationButton: React.FC<IProcessOperationButton> = ({
  resource,
  hideIfDisabled,
  enabledInStates,
  disabledInStates,
  operation,
  children,
  label,
  title,
  icon,
  color,
  withConfirm,
  ...props
}) => {
  const notify = useNotify()
  const refresh = useRefresh()

  const record = useRecordContext()
  const dataProvider = useDataProvider()

  const [showDialog, setShowDialog] = React.useState(false)
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [disabled, setDisabled] = React.useState(false)

  if (!children && withConfirm) {
    children = <span>Are you sure?</span>
  }

  if (!icon) {
    icon = <IconApply />
  }

  React.useEffect(() => {
    if (enabledInStates) {
      setDisabled(!enabledInStates.includes(record?.state))
    } else if (disabledInStates) {
      setDisabled(!!disabledInStates.includes(record?.state))
    }
  }, [enabledInStates, disabledInStates, record?.state])

  const handleSubmit = (values: FieldValues) => {
    setIsSubmitting(true)
    try {
      handleOperationSubmit({
        notify,
        refresh,
        record,
        operation,
        values,
        dataProvider,
        resource,
        onComplete: () => {
          setIsSubmitting(false)
        },
      })
    } catch (e) {
      setIsSubmitting(false)
      return false
    }
  }

  const handleClick = () => {
    if (children) {
      setShowDialog(true)
    } else {
      setIsSubmitting(true)
      handleOperationSubmit({
        notify,
        refresh,
        record,
        operation,
        dataProvider,
        resource,
        onComplete: () => {
          setIsSubmitting(false)
        },
      })
    }
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }

  return !(hideIfDisabled && disabled) ? (
    <>
      <Button
        onClick={handleClick}
        disabled={disabled || isSubmitting}
        label={label}
        color={color}
        {...props}
      >
        {icon}
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        maxWidth="sm"
        onClose={handleCloseClick}
        aria-label={label}
      >
        <Form onSubmit={handleSubmit}>
          <DialogTitle>{title ?? label}</DialogTitle>
          <DialogContent>{children}</DialogContent>
          <DialogActions>
            <Button label="ra.action.cancel" onClick={handleCloseClick}>
              <IconCancel />
            </Button>
            <SaveButton
              disabled={isSubmitting}
              alwaysEnable={!isSubmitting}
              label={operation ?? 'Save'}
              icon={icon}
            />
          </DialogActions>
        </Form>
      </Dialog>
    </>
  ) : null
}
