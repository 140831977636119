import { PermissionCreate } from './Create.component'
import { PermissionEdit } from './Edit.component'
import { PermissionList } from './List.component'
import { PermissionShow } from './Show.component'

export default {
  list: PermissionList,
  show: PermissionShow,
  edit: PermissionEdit,
  create: PermissionCreate,
}
