import React from 'react'
import { MarkdownField } from '@react-admin/ra-markdown'

/**
 * Primary text on a note in a list.
 * @returns {React.FC}
 * @example
 * import { PrimaryText } from './PrimaryText.component.tsx'
 * <PrimaryText />
 * @see https://marmelab.com/react-admin/Fields.html#markdownfield
 */
export const PrimaryText: React.FC = () => {
  return <MarkdownField source={'content'} label={false} />
}
