import { FC } from 'react'
import { Box, Card, CardContent, Grid, Stack, Typography } from '@mui/material'
import {
  ReferenceManyField,
  Show,
  SingleFieldList,
  useGetOne,
  useRedirect,
  Button,
  useDataProvider,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  useNotify,
  SimpleForm,
  FunctionField,
  RaRecord,
  useRefresh,
} from 'react-admin'
import { useParams } from 'react-router-dom'
import { Breadcrumbs } from '../../atoms/Breadcrumbs'
import { SiteDetailsAccordion } from '../../molecules/SiteDetailsAccordion'
import { ProductSalesReviewAccordion } from '../../atoms/ProductSalesReviewAccordion'
import { RequestDetailsAccordion } from '../../atoms/RequestDetailsAccordion'
import { ProductsTabbedShowLayout } from '../../organisms/ProductsTabbedShowLayout'
import { RequestServiceTabs } from '../../atoms/RequestServiceTabs'
import DownloadIcon from '@mui/icons-material/GetApp'
import { useExportRequestToExcelFunction } from './RequestFlow.hooks'
import { RequestFlowTitle } from './RequestFlowTitle.component'
import DoneIcon from '@mui/icons-material/CheckBoxOutlined'
import ToDoIcon from '@mui/icons-material/CheckBoxOutlineBlank'

export const RequestFlowServiceDetails: FC = () => {
  const { requestId, serviceId } = useParams()
  const redirect = useRedirect()
  const exportRequestToExcel = useExportRequestToExcelFunction()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const refresh = useRefresh()

  const { data: request } = useGetOne(
    'requests',
    { id: requestId },
    { enabled: requestId !== undefined }
  )

  const handleExportButtonClick = async () => {
    if (requestId) await exportRequestToExcel(requestId, { serviceId })
  }

  async function handleAssigneesChange(assigneeIds: string[]) {
    const data = await dataProvider.customRequest(
      'requestServices',
      serviceId,
      {
        data: { assigneeIds },
        method: 'PUT',
      }
    )

    if (data?.json?.data?.id) {
      notify('Assignees updated')
      refresh()
    }
  }

  if (!requestId || !serviceId) {
    redirect('/requests')
    return null
  }

  if (!request) return null

  return (
    <Show
      resource="requestServices"
      id={serviceId}
      component={Box}
      title={<RequestFlowTitle />}
    >
      <Stack gap={1} paddingX={1} paddingY={0}>
        <Stack direction="row" justifyContent="space-between" paddingY={1}>
          <Breadcrumbs />
          <Button
            label="ra.action.export"
            type="button"
            onClick={handleExportButtonClick}
            startIcon={<DownloadIcon />}
          />
        </Stack>
        <Stack gap={1}>
          <RequestServiceTabs
            requestId={requestId}
            serviceId={serviceId}
            requestServices={request?.services}
          />
          <ReferenceManyField
            reference="requestSites"
            source="siteIds"
            target="id"
          >
            <SingleFieldList linkType={false}>
              <SiteDetailsAccordion />
            </SingleFieldList>
          </ReferenceManyField>
          <RequestDetailsAccordion />
          <FunctionField
            render={(record: RaRecord) =>
              record.isRequested ? (
                <>
                  <ProductSalesReviewAccordion />
                  <Card sx={{ mt: 1, mb: 1 }}>
                    <CardContent>
                      <Grid
                        container
                        alignItems="center"
                        gap={0.5}
                        justifyContent="space-between"
                      >
                        <Grid item>
                          <FunctionField
                            render={(record: RaRecord) => (
                              <Typography
                                variant="h6"
                                alignItems="center"
                                display="flex"
                                gap={1}
                              >
                                {record?.assigneeIds?.length > 0 ? (
                                  <DoneIcon sx={{ mr: 1 }} />
                                ) : (
                                  <ToDoIcon sx={{ mr: 1 }} />
                                )}
                                Request: Assignment
                              </Typography>
                            )}
                          />
                        </Grid>
                      </Grid>
                      <SimpleForm
                        toolbar={false}
                        sx={{
                          '& legend': { display: 'none' },
                          ':last-child': { padding: 0 },
                        }}
                      >
                        <ReferenceArrayInput
                          source="assigneeIds"
                          reference="users/list"
                          sx={{ margin: 0 }}
                          filter={{
                            role: {
                              in: [
                                'gnx-procurement',
                                'gnx-sales',
                                'gnx-admin',
                                'admin',
                                'sales',
                                'procurement',
                              ],
                            },
                          }}
                        >
                          <AutocompleteArrayInput
                            sx={{ margin: 0 }}
                            fullWidth
                            label="Assignee(s)"
                            optionText="name"
                            optionValue="id"
                            onChange={handleAssigneesChange}
                          />
                        </ReferenceArrayInput>
                      </SimpleForm>
                    </CardContent>
                  </Card>
                  <ProductsTabbedShowLayout
                    serviceId={serviceId}
                    requestId={requestId}
                    clientId={request?.clientId}
                    endCustomerId={request?.endCustomerId}
                  />
                </>
              ) : (
                <Typography variant="h6" p={2}>
                  ℹ️ Customer has not made a firm offer request for this service
                  (yet)
                </Typography>
              )
            }
          />
        </Stack>
      </Stack>
    </Show>
  )
}
