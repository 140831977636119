import React from 'react'
import { TextField, DateField, Labeled } from 'react-admin'
import { ReferenceChip } from '../../molecules/ReferenceChip'
import { TypeStateField } from '../../atoms/TypeStateField'
import { AddressDisplay } from '../AddressDisplay'

/**
 * Displays a site's information.
 * @returns {ReactElement}
 * @example
 * <SiteDisplay />
 */
export const SiteDisplay: React.FC = () => {
  return (
    <>
      <Labeled label="Site No.">
        <TextField source="siteNo" />
      </Labeled>
      <ReferenceChip
        referenceSource="clientId"
        tooltipSource="clientNo"
        source="name"
        chipSource="name"
        label={'Client'}
        reference="clients"
        emptyText="N/A"
        link="show"
      />
      <TypeStateField label="Type" source="type" />
      <AddressDisplay />
      <Labeled label="Updated At">
        <DateField source="updatedAt" showTime />
      </Labeled>
      <Labeled label="Created At">
        <DateField source="createdAt" showTime />
      </Labeled>
    </>
  )
}
