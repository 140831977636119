import React from 'react'
import {
  Show,
  ShowProps,
  TabbedShowLayout,
  Tab,
  TextField,
  DateField,
} from 'react-admin'
import { PageTitle } from '../../atoms/PageTitle'
import { MutationsList } from '../../organisms/MutationsList'
import { ReferenceChip } from '../../molecules/ReferenceChip'

/**
 * Page to show a single Service Type.
 * @property { ShowProps } props The properties of the Show component.
 * @returns { React.ReactNode } The Service Type Show page.
 * @example
 * import { ServiceTypeShow } from './ServiceTypeShow';
 * <ServiceTypeShow {...props} />
 * @see https://marmelab.com/react-admin/Show.html
 * @see https://marmelab.com/react-admin/Fields.html
 */
export const ServiceTypeShow: React.FC<ShowProps> = (props) => {
  return (
    <Show {...props} title={<PageTitle prefix="Service Type" />}>
      <TabbedShowLayout {...props}>
        <Tab label="summary">
          <TextField source="key" />
          <TextField source="name" />
          <TextField source="description" emptyText={'N/A'} />
          <ReferenceChip
            referenceSource="serviceCategoryId"
            tooltipSource="key"
            source="name"
            label={'Service category'}
            reference="serviceCategories"
            emptyText="N/A"
            link="show"
            chipSource="name"
          />
          <DateField source="createdAt" showTime />
          <DateField source="updatedAt" showTime />
        </Tab>
        <Tab label={'history'}>
          <MutationsList showActor type="history" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
