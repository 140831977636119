export const useStyles = () => {
  return {
    '& .RaBreadcrumb-list .MuiTypography-root': {
      fontSize: '1em',
    },
    '& .RaLabeled-label.MuiTypography-root': {
      fontSize: '0.9em',
    },
  }
}
