import React from 'react'
import { TextInput } from 'react-admin'

/**
 * Notes input for the ticket show page.
 * @returns {React.FC}
 * @example
 * import { NotesInput } from './NotesInput.component.tsx'
 * <NotesInput />
 * @see {@link https://marmelab.com/react-admin/Inputs.html|React Admin Inputs}
 */
export const NotesInput: React.FC = () => {
  return (
    <>
      <TextInput multiline source="notes" label="Internal notes" fullWidth />
      <TextInput multiline source="notesForClient" fullWidth />
    </>
  )
}
