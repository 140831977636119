import React from 'react'
import { RaRecord, Identifier } from 'react-admin'

/**
 * Tertiary text on a note in a list.
 * @returns {React.FC}
 * @example
 * import { TertiaryText } from './TertiaryText.component.tsx'
 * <TertiaryText />
 */
export const TertiaryText: React.FC<RaRecord<Identifier>> = (props) => {
  const date = new Date(props.createdAt)
  return (
    <>
      {`Added ${date.toLocaleDateString()} ${date.toLocaleTimeString()} - ${
        props.isInternal ? 'internal' : 'public'
      } `}
    </>
  )
}
