/**
 * Grid styles for Onboarding component
 * @todo all display components use this same grid.
 * Refactor into a shared component.
 * @returns {Object} styles
 */
export const useStyles = () => {
  return {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
    gridGap: '4px',
    gridAutoColumns: '1fr',
    alignSelf: 'center',
    '& .ra-field': {
      marginTop: '8px',
    },
  }
}
