import { DatacenterShow } from './Show.component'
import { DatacenterList } from './List.component'
import { DatacenterCreate } from './Create.component'
import { DatacenterEdit } from './Edit.component'

export default {
  list: DatacenterList,
  show: DatacenterShow,
  create: DatacenterCreate,
  edit: DatacenterEdit,
}
