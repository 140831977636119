import { stringify } from 'query-string'
import axios from 'axios'
import { DataProvider } from 'ra-core'
import {
  GetListParams,
  CreateParams,
  DeleteManyParams,
  DeleteParams,
  GetManyParams,
  GetManyReferenceParams,
  GetOneParams,
  UpdateManyParams,
  UpdateParams,
} from 'react-admin'
import { SearchResult } from '@react-admin/ra-search'
import { set } from 'lodash'

let isRefreshing: boolean = false
let subscribers: any = []

function onRefreshed(token: string | null) {
  subscribers.map((cb: any) => cb(token))
}

function subscribeTokenRefresh(cb: any) {
  subscribers.push(cb)
}

function updateRequestConfig(config: any, accessToken: string) {
  config.headers.Authorization = `Bearer ${accessToken}`
  config.__isRetry = true
  return config
}

export const apiHttpClient = (url: string, options?: any) => {
  const token = localStorage.getItem('token')
  if (!options) {
    options = {}
  }

  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
  }

  axios.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (!error || !error.response) {
        return Promise.reject('Invalid response from server')
      }

      let { status, data, statusText, config, message } = error.response

      const refreshToken = window.localStorage.getItem('refreshToken')

      if (
        config.url.indexOf('/auth/user/token') === -1 &&
        status === 401 &&
        refreshToken
      ) {
        if (!isRefreshing) {
          isRefreshing = true

          axios
            .post(`${process.env.REACT_APP_API_URL}/auth/user/token`, {
              refreshToken,
            })
            .then(({ data }: any) => {
              isRefreshing = false
              if (data) {
                if (
                  data.accessToken &&
                  data.refreshToken &&
                  !data.isLoggedOut
                ) {
                  localStorage.setItem('token', data.accessToken)
                  localStorage.setItem('accessToken', data.accessToken)
                  localStorage.setItem('refreshToken', data.refreshToken)

                  onRefreshed(data.accessToken)
                } else {
                  onRefreshed(null)
                }
              } else {
                return Promise.reject('Invalid response from server')
              }
            })
            .catch((error: any) => {
              localStorage.removeItem('token')
              localStorage.removeItem('refreshToken')
              localStorage.removeItem('user')

              setTimeout(() => {
                window.location.reload()
              }, 1000)
            })
        }

        return new Promise((resolve) => {
          subscribeTokenRefresh((token: string) => {
            resolve(axios(updateRequestConfig(error.config, token)))
          })
        })
      }

      if (data && data.errors) {
        message = data.errors
          .map((e: any) => {
            let err = e.message
            if (e.field) {
              err = e.field + ': ' + e.message
            }
            return err
          })
          .join('; ')
        if (!data.message) data.message = message
      }

      return Promise.reject({
        ...error,
        message: message || (data && data.message) || statusText,
      })
    }
  )

  return axios({
    url,
    ...options,
  }).then((response) =>
    Promise.resolve({ status: response.status, json: response.data })
  )
}

export default (apiUrl: string, httpClient = apiHttpClient): DataProvider => ({
  getList: (resource: string, params: GetListParams) => {
    const { page, perPage } = params.pagination
    const { field, order } = params.sort
    const query = {
      sort: order,
      orderBy: field,
      page: page,
      perPage: perPage,
      filter: params.filter ? JSON.stringify(params.filter || {}) : undefined,
      meta: params.meta ? JSON.stringify(params.meta || {}) : undefined,
    }
    const url = `${apiUrl}/${resource}?${stringify(query)}`
    return httpClient(url).then(({ json }) => ({
      data: json.data ?? [],
      meta: json.meta ?? {},
      total: json.meta && json.meta.total,
    }))
  },

  getOne: (resource: string, params: GetOneParams) => {
    return httpClient(`${apiUrl}/${resource}/${params.id}`).then(
      ({ json }) => ({
        data: json.data,
      })
    )
  },

  getMany: (resource: string, params: GetManyParams) => {
    const query = {
      filter: params.ids
        ? JSON.stringify({ id: { in: params.ids || [] } })
        : undefined,
      meta: params.meta ? JSON.stringify(params.meta || {}) : undefined,
    }
    const url = `${apiUrl}/${resource}?${stringify(query)}`
    return httpClient(url).then(({ json }) => ({
      data: json.data || [],
    }))
  },

  getManyReference: (resource: string, params: GetManyReferenceParams) => {
    const { page, perPage } = params.pagination
    const { field, order } = params.sort
    const query = {
      sort: order,
      orderBy: field,
      page: page,
      perPage: perPage,
      filter: JSON.stringify({
        ...params.filter,
        [params.target || 'id']: params.id,
      }),
      meta: params.meta ? JSON.stringify(params.meta || {}) : undefined,
    }
    const url = `${apiUrl}/${resource}?${stringify(query)}`
    return httpClient(url).then(({ json }) => ({
      data: json.data ?? [],
      meta: json.meta ?? {},
      total: json.meta && json.meta.total,
    }))
  },

  update: (resource: string, params: UpdateParams) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'PUT',
      data: encodeRequestBody(params.data),
    }).then(({ json }) => ({ data: json.data })),

  create: (resource: string, params: CreateParams) =>
    httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      data: encodeRequestBody(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, ...json.data },
    })),

  delete: (resource: string, params: DeleteParams) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: json.data })),

  download: (resource: string, params: any) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'GET',
      responseType: 'blob',
    }),

  customRequest: (resource: string, uri: any, options: any) =>
    httpClient(`${apiUrl}/${resource}/${uri}`, options),

  updateMany: (resource: string, params: UpdateManyParams) =>
    Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: 'PUT',
          data: encodeRequestBody(params.data),
        })
      )
    ).then((responses) => ({
      data: responses.map(({ json }) => json.data.id),
    })),

  deleteMany: (resource: string, params: DeleteManyParams) =>
    Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: 'DELETE',
        })
      )
    ).then((responses) => ({
      data: responses.map(({ json }) => json.data.id),
    })),

  search: async (term: string) => {
    const { json } = await httpClient(`${apiUrl}/search/term`, {
      method: 'GET',
      params: { q: term },
    })
    return json as SearchResult
  },
})

const encodeRequestBody = (data: any) => {
  let bodyData

  // Convert from property->value object back to normal object
  if (data && data._jsonObjectInput) {
    const objectKeys = Object.keys(data._jsonObjectInput)
    if (objectKeys && Array.isArray(objectKeys)) {
      const extraRecord = {}
      for (let source of objectKeys) {
        if (data._jsonObjectInput[source]) {
          for (let item of data._jsonObjectInput[source]) {
            set(extraRecord, `${source}.${item.property}`, item.value)
          }
        }
      }
      data = { ...data, ...extraRecord }
    }
  }

  if (data.file || data.files) {
    const formData = new FormData()
    buildFormData(formData, data)
    bodyData = formData
  } else {
    bodyData = data
  }

  return bodyData
}

const buildFormData = (formData: FormData, data: any, parentKey?: string) => {
  if (
    data &&
    typeof data === 'object' &&
    !(data instanceof Date) &&
    !(data instanceof File) &&
    !(data instanceof Blob) &&
    !(Array.isArray(data) && !data.length)
  ) {
    const dataKeys = Object.keys(data)
    if (dataKeys && dataKeys.length > 0) {
      dataKeys.forEach((key) => {
        if (data[key]) {
          buildFormData(
            formData,
            data[key],
            parentKey ? `${parentKey}[${key}]` : key
          )
        }
      })
    }
  } else if (parentKey) {
    const value = data == null ? '' : data
    formData.append(parentKey, value)
  }
}
