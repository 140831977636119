import React from 'react'
import {
  IconMenu,
  MenuItemList,
  MenuItemNode,
} from '@react-admin/ra-navigation'
import { CardContent } from '@mui/material'
import VendorsIcon from '@mui/icons-material/BusinessOutlined'

/**
 * This is a custom menu item that will be added to the main menu.
 * It will display a list of menu items when clicked.
 * @returns {JSX.Element}
 */
export const VendorsMenuItem: React.FC = () => {
  return (
    <IconMenu.Item name="vendors" icon={<VendorsIcon />} label="Vendors">
      <CardContent>
        <MenuItemList>
          <MenuItemNode name="vendors" to="/vendors" label="Vendors" />
          <MenuItemNode
            name="lastMileProviders"
            to="/lastMileProviders"
            label="LMPs"
          />
          <MenuItemNode
            name="internetServiceProviders"
            to="/internetServiceProviders"
            label="ISPs"
          />
          <MenuItemNode
            name="vendorOffers"
            to={'/vendorOffers'}
            label="Offers"
          />
          <MenuItemNode
            name="vendorContracts"
            to={'/vendorContracts'}
            label="Contracts"
          />
          <MenuItemNode
            name="vendorContacts"
            to={'/vendorContacts'}
            label="Contacts"
          />
          <MenuItemNode
            name="datacenters"
            to="/datacenters"
            label="Datacenters"
          />
        </MenuItemList>
      </CardContent>
    </IconMenu.Item>
  )
}
