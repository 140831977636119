import React from 'react'
import { useGetList } from 'react-admin'
import { IconCard } from '../../molecules/IconCard/IconCard.component'
import RequestsIcon from '@mui/icons-material/AllInboxOutlined'

/**
 * A card with pending requests metrics.
 * @example <PendingRequestsCard />
 */
export const PendingRequestsCard: React.FC = () => {
  const pendingRequests = useGetList('requests/list', {
    filter: { state: 'in-progress' },
    pagination: { page: 1, perPage: 50 },
    sort: { field: 'createdAt', order: 'DESC' },
  })

  return (
    <IconCard
      icon={RequestsIcon}
      to={'/quote-requests?filter=%7B"state"%3A%5B"in-progress"%5D%7D'}
      subtitle={`${pendingRequests?.total || 'N/A'}`}
      title={'In-progress requests'}
    />
  )
}
