import React from 'react'
import {
  Create,
  CreateProps,
  required,
  TextInput,
  SelectInput,
  BooleanInput,
  SimpleForm,
} from 'react-admin'
import { ReferenceAutocomplete } from '../../molecules/ReferenceAutocomplete/ReferenceAutocomplete.component'
import { ContactDetailsInput } from '../../organisms/ContactDetailsInput'
import { useRoleChoices } from './ClientContact.hooks'

/**
 * Page to create a client contact.
 * @property {CreateProps} props
 * @returns {React.FC<CreateProps>}
 * @example
 * import { ClientContactCreate } from './ClientContactCreate'
 * <Create {...props}>
 * @see https://marmelab.com/react-admin/CreateEdit.html
 * @see https://marmelab.com/react-admin/Inputs.html
 * @see https://marmelab.com/react-admin/TabbedForm.html
 * @see https://marmelab.com/react-admin/BooleanInput.html
 */
export const ClientContactCreate: React.FC<CreateProps> = (props) => {
  const roleChoices = useRoleChoices()

  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" validate={required()} fullWidth />
        <ReferenceAutocomplete
          source={'clientId'}
          label={'Client'}
          reference="clients/list"
          emptyText={'N/A'}
          link={false}
          required
        />
        <SelectInput
          validate={required()}
          source="role"
          choices={roleChoices}
          fullWidth
        />
        <ContactDetailsInput />
        <BooleanInput source="isActive" defaultValue={true} fullWidth />
        <BooleanInput source="isPrimary" defaultValue={false} fullWidth />
      </SimpleForm>
    </Create>
  )
}
