import React from 'react'
import {
  Create,
  CreateProps,
  TextInput,
  BooleanInput,
  required,
  SimpleForm,
} from 'react-admin'
import { ReferenceAutocomplete } from '../../molecules/ReferenceAutocomplete'

/**
 * Page to create coverage of a datacenter.
 * @property {CreateProps} props
 * @example
 * <CoverageDatacenterCreate />
 * @returns {React.FC<CreateProps>}
 * @see https://marmelab.com/react-admin/Create.html
 */
export const CoverageDatacenterCreate: React.FC<CreateProps> = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" validate={required()} fullWidth />
        <ReferenceAutocomplete
          required
          source={'coverageZoneId'}
          reference="coverageZones/list"
        />
        <ReferenceAutocomplete
          required
          source={'datacenterId'}
          reference="datacenters/list"
        />
        <BooleanInput source="isActive" defaultValue={true} fullWidth />
      </SimpleForm>
    </Create>
  )
}
