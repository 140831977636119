import React from 'react'
import { List, ListProps, TextField, DateField, Datagrid } from 'react-admin'
import { SearchServiceFilter } from '../../molecules/SearchServiceFilter'
import { ReferenceChip } from '../../molecules/ReferenceChip'

/**
 * Page to list all service statistics.
 * @param {ListProps} props
 * @returns {React.FC}
 * @example
 * import { ServiceStatisticList } from './ServiceStatisticList.component.tsx'
 * <ServiceStatisticList />
 * @see {@link https://marmelab.com/react-admin/List.html|RA List}
 */
export const ServiceStatisticList: React.FC<ListProps> = (props) => {
  return (
    <List
      empty={false}
      perPage={25}
      filters={<SearchServiceFilter />}
      sort={{ field: 'createdAt', order: 'DESC' }}
      {...props}
    >
      <Datagrid rowClick="show">
        <TextField source="title" />
        <TextField source="type" />
        <ReferenceChip
          referenceSource="serviceId"
          tooltipSource="serviceNo"
          source="Service"
          reference="services"
          emptyText="N/A"
          link="show"
          chipSource="name"
        />
        <TextField source="module" />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
      </Datagrid>
    </List>
  )
}
