import React from 'react'
import { TextInput } from 'react-admin'

/**
 * Input fields for the contact details:
 * - Email Address
 * - Phone Number
 * - Mobile Number
 * @returns React.FC
 * @see https://marmelab.com/react-admin/Fields.html
 * @see https://marmelab.com/react-admin/Inputs.html
 */
export const ContactDetailsInput: React.FC = () => {
  return (
    <>
      <TextInput source="emailAddress" fullWidth />
      <TextInput source="phoneNumber" fullWidth />
      <TextInput source="mobileNumber" fullWidth />
    </>
  )
}
