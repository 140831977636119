import {
  AutocompleteArrayInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  TextInput,
} from 'react-admin'
import { ReferenceAutocomplete } from '../../molecules/ReferenceAutocomplete'

/**
 * Filters for the service list page.
 * @returns {Array} An array of react-admin filters.
 */
export const useServiceFilters = () => {
  return [
    <TextInput label="Search" source="term" alwaysOn />,
    <SelectArrayInput
      source="state"
      label={'State'}
      choices={[
        { id: 'pending', name: 'Pending' },
        { id: 'provisioning', name: 'Provisioning' },
        { id: 'cancelled', name: 'Cancelled' },
        { id: 'active', name: 'Active' },
        { id: 'terminated', name: 'Terminated' },
      ]}
      fullWidth
    />,
    <SelectInput
      source="health"
      label={'Health'}
      choices={[
        { id: 'ok', name: 'OK' },
        { id: 'nok', name: 'Not OK' },
        { id: 'degraded', name: 'Degraded' },
        { id: 'error', name: 'Error' },
        { id: 'unmonitored', name: 'Unmonitored' },
      ]}
      fullWidth
    />,
    <ReferenceInput source="clientId" reference="clients/list" alwaysOn>
      <AutocompleteArrayInput
        label={'Client'}
        optionText="name"
        sx={{ width: 250 }}
      />
    </ReferenceInput>,
    <ReferenceInput source="vendorId" reference="vendors/list" alwaysOn>
      <AutocompleteArrayInput
        label={'Vendor'}
        optionText="name"
        sx={{ width: 250 }}
      />
    </ReferenceInput>,
    <ReferenceInput source="projectId" reference="projects/list">
      <AutocompleteArrayInput label={'Project'} optionText="name" />
    </ReferenceInput>,
    <ReferenceInput
      source={'serviceTypeId'}
      label={'Service Type'}
      reference="serviceTypes/list"
    >
      <AutocompleteArrayInput optionText="name" />
    </ReferenceInput>,
    <ReferenceInput
      source={'accessTechnologyId'}
      label={'Technology'}
      reference="accessTechnologies/list"
    >
      <AutocompleteArrayInput optionText="name" />
    </ReferenceInput>,
    <ReferenceInput
      source={'monitorTargetIds'}
      label={'Monitor Targets'}
      reference="monitorTargets/list"
    >
      <AutocompleteArrayInput optionText="name" />
    </ReferenceInput>,
    <ReferenceAutocomplete
      source="countryCode"
      reference="countries/list"
      sx={{ width: 200 }}
    />,
  ]
}
