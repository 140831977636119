import React from 'react'
import {
  List,
  ListProps,
  Datagrid,
  TextField,
  DateField,
  BooleanField,
} from 'react-admin'
import { NameFilter } from '../../atoms/NameFilter'
import { ReferenceChip } from '../../molecules/ReferenceChip'

/**
 * List of monitor alert rules.
 * @param props The List component props
 * @see https://marmelab.com/react-admin/List.html
 */
export const MonitorAlertRuleList: React.FC<ListProps> = (props) => {
  return (
    <List
      {...props}
      empty={false}
      filters={<NameFilter />}
      sort={{ field: 'name', order: 'ASC' }}
      perPage={25}
    >
      <Datagrid rowClick="show">
        <TextField source="name" fullWidth />
        <ReferenceChip
          referenceSource="monitorProbeTypeId"
          tooltipSource="name"
          source="name"
          chipSource="name"
          reference="monitorProbeTypes"
          emptyText="N/A"
          link="show"
        />
        <TextField source="state" />
        <TextField source="summary" />
        <TextField source="dataSource" />
        <BooleanField source="isMuted" />
        <BooleanField source="isCustom" />
        <DateField source="updatedAt" showTime />
      </Datagrid>
    </List>
  )
}
