import React from 'react'
import { AddressDisplay } from '../../organisms/AddressDisplay'
import { ContactDetailsDisplay } from '../../organisms/ContactDetailsDisplay'

/**
 * Display the contact details and address of a client contact.
 * @returns React.FC
 * @see https://marmelab.com/react-admin/Show.html
 * @see https://marmelab.com/react-admin/Fields.html
 * @see https://marmelab.com/react-admin/Inputs.html
 */
export const ContactDisplayTab: React.FC = () => {
  return (
    <>
      <ContactDetailsDisplay />
      <AddressDisplay />
    </>
  )
}
