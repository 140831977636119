import React from 'react'
import {
  AutocompleteArrayInput,
  BooleanInput,
  Create,
  CreateProps,
  ReferenceArrayInput,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin'
import { DualSelect } from '../../atoms/DualSelect'

/**
 * Form to create a monitor target.
 * @param props The Create component props
 * @see https://marmelab.com/react-admin/Create.html
 */
export const MonitorTargetCreate: React.FC<CreateProps> = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="hostname" validate={required()} fullWidth />
        <ReferenceArrayInput source="serviceIds" reference="services/list">
          <AutocompleteArrayInput
            optionText="name"
            optionValue="id"
            fullWidth
            label="Related services"
          />
        </ReferenceArrayInput>
        <DualSelect
          label="Probes"
          source="monitorProbeTypeIds"
          reference="monitorProbeTypes/list"
        />
        <BooleanInput source="isActive" defaultValue={true} />
        <BooleanInput source="isMuted" defaultValue={false} />
      </SimpleForm>
    </Create>
  )
}
