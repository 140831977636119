import React from 'react'
import { Segment } from '../../atoms/Segment'
import {
  useMediaQuery,
  Theme,
  Card,
  CardHeader,
  CardContent,
} from '@mui/material'
import { PendingRequestsFull } from './PendingRequestsFull.component'
import { PendingRequestsSlim } from './PendingRequestsSlim.component'
import { ListBase, ListButton } from 'react-admin'

/**
 * Pending Requests Segment.
 * On small screens, displays a slim version of the list.
 * On large screens, displays a full version of the list.
 * @example
 * return (
 * <PendingRequestsSegment />
 * )
 * @see {@link https://marmelab.com/react-admin/List.html#the-datagrid-component|Datagrid}
 * @see {@link https://marmelab.com/react-admin/Theming.html|Theming}
 * @see {@link https://marmelab.com/react-admin/Theming.html#using-a-custom-theme|Using a Custom Theme}
 * @see {@link https://marmelab.com/react-admin/List.html#the-listbase-component|ListBase}
 */
export const PendingRequestsSegment: React.FC = () => {
  const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))

  return (
    <Segment>
      <Card sx={{ flex: 1 }}>
        <CardHeader
          subheader={'Newest quote requests'}
          action={<ListButton resource="quote-requests" label={'Requests'} />}
        />
        <CardContent>
          <ListBase
            resource="requests"
            perPage={5}
            filter={{ state: 'in-progress' }}
            sort={{ field: 'createdAt', order: 'desc' }}
            disableSyncWithLocation
          >
            {isXSmall || isSmall ? (
              <PendingRequestsSlim />
            ) : (
              <PendingRequestsFull />
            )}
          </ListBase>
        </CardContent>
      </Card>
    </Segment>
  )
}
