import React from 'react'
import { Edit, EditProps, SimpleForm, TextInput, required } from 'react-admin'
import { PageTitle } from '../../atoms/PageTitle'

/**
 * Edit the details of a single service category row.
 * @property {EditProps} props
 * @returns {React.FC<EditProps>}
 * @example
 * import {ServiceCategoryEdit} from './ServiceCategory'
 * return <ServiceCategoryEdit {...props} />
 * @see https://marmelab.com/react-admin/Edit.html
 * @see https://marmelab.com/react-admin/Fields.html
 */
export const ServiceCategoryEdit: React.FC<EditProps> = (props) => {
  return (
    <Edit {...props} title={<PageTitle prefix="Service category" />}>
      <SimpleForm>
        <TextInput disabled label="Id" source="id" fullWidth />
        <TextInput source="key" validate={required()} fullWidth />
        <TextInput source="name" validate={required()} fullWidth />
        <TextInput source="description" fullWidth />
      </SimpleForm>
    </Edit>
  )
}
