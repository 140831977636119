import { TextFieldProps } from 'react-admin'

/**
 * Interface for ProductSearchQueryField component props
 * @prop {string} source - source of the field
 * @prop {string} label - label of the field
 */
export interface IProductSearchQueryField extends TextFieldProps {
  source: 'preferences' | 'requirements' | 'addons' | string
  label?: string | boolean
}

// maps query comparators to display labels
export enum QueryComparator {
  in = 'including one of',
  notIn = 'excluding all of',
  eq = 'matching exactly',
  gte = 'greater or equal to',
  lte = 'less or equal to',
  lt = 'less than',
  gt = 'greater than',
}

export enum QueryComparatorSymbol {
  in = 'including',
  notIn = 'excluding',
  eq = '=',
  gte = '>=',
  lte = '<=',
  lt = '<',
  gt = '>',
}

// maps query resources to reference
export enum ResourcesToReference {
  serviceType = 'serviceTypes/list',
  accessTechnology = 'accessTechnologies/list',
  serviceCategory = 'serviceCategories/list',
  vendor = 'vendors/list',
}
