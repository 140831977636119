import React from 'react'
import { ReferenceArrayField, ChipField, SingleFieldList } from 'react-admin'
import { IReferenceChipList } from './ReferenceChipList.types'

/**
 * List of Chips that references another resource.
 * @property {string} referenceSource - The source of the reference.
 * @returns The rendered component.
 * @see IReferenceChipList
 * @see https://marmelab.com/react-admin/Fields.html#referencearrayfield
 * @see https://marmelab.com/react-admin/Fields.html#singlefieldlist
 */
export const ReferenceChipList: React.FC<IReferenceChipList> = (props) => {
  return (
    <ReferenceArrayField {...props}>
      <SingleFieldList linkType="show">
        <ChipField source="name" />
      </SingleFieldList>
    </ReferenceArrayField>
  )
}
