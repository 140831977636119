import React from 'react'
import { List, ListProps, Datagrid, TextField, DateField } from 'react-admin'
import { NameFilter } from '../../../components/atoms/NameFilter'

/**
 * List all access technology rows.
 * @property {ListProps} props
 * @returns {React.FC<ListProps>}
 * @example
 * import {AccessTechnologyList} from './AccessTechnology'
 * return <AccessTechnologyList {...props} />
 * @see https://marmelab.com/react-admin/List.html
 * @see https://marmelab.com/react-admin/Fields.html
 */
export const AccessTechnologyList: React.FC<ListProps> = (props) => {
  return (
    <List
      {...props}
      empty={false}
      filters={<NameFilter />}
      sort={{ field: 'name', order: 'ASC' }}
      perPage={25}
    >
      <Datagrid rowClick="show">
        <TextField source="name" />
        <TextField source="key" emptyText="N/A" />
        <TextField source="description" emptyText={'N/A'} />
        <DateField source="createdAt" showTime />
        <DateField source="updatedAt" showTime />
      </Datagrid>
    </List>
  )
}
