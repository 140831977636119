import polyglotI18nProvider from 'ra-i18n-polyglot'
import englishMessages from 'ra-language-english'
import { raFormLayoutLanguageEnglish } from '@react-admin/ra-form-layout'

/**
 * This is the language configuration for the ReactAdmin framework.
 * It is a polyglot configuration, which means that it will use the language
 * specified by the user in their browser settings.
 * @see https://marmelab.com/react-admin/Translation.html
 */
const messages = {
  en: { ...englishMessages, ...raFormLayoutLanguageEnglish },
}

/**
 * This is the i18nProvider that will be used by the ReactAdmin framework.
 * It is a polyglot provider, which means that it will use the language
 * specified by the user in their browser settings.
 * @see https://marmelab.com/react-admin/Translation.html
 */
export const i18nProvider = polyglotI18nProvider(() => messages['en'], 'en')
