import React from 'react'
import { List, ListProps, Datagrid, TextField, DateField } from 'react-admin'
import { NameFilter } from '../../atoms/NameFilter'

/**
 * List of monitor notification types.
 * @param props The List component props
 * @see https://marmelab.com/react-admin/List.html
 */
export const MonitorNotificationTypeList: React.FC<ListProps> = (props) => {
  return (
    <List
      {...props}
      empty={false}
      filters={<NameFilter />}
      sort={{ field: 'name', order: 'ASC' }}
      perPage={25}
    >
      <Datagrid rowClick="show">
        <TextField source="name" fullWidth />
        <TextField source="description" emptyText="N/A" />
        <DateField source="updatedAt" showTime />
      </Datagrid>
    </List>
  )
}
