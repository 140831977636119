import { ServiceTypeCreate } from './Create.component'
import { ServiceTypeEdit } from './Edit.component'
import { ServiceTypeList } from './List.component'
import { ServiceTypeShow } from './Show.component'

export default {
  list: ServiceTypeList,
  show: ServiceTypeShow,
  edit: ServiceTypeEdit,
  create: ServiceTypeCreate,
}
