import React from 'react'
import {
  Show,
  ShowProps,
  TabbedShowLayout,
  Tab,
  TextField,
  NumberField,
} from 'react-admin'
import { PageTitle } from '../../atoms/PageTitle'
import { ReferenceChipList } from '../../molecules/ReferenceChipList'
import { HistoryDisplayTab } from '../../templates/HistoryDisplayTab'

/**
 * Page to show a user role.
 * @property ShowProps props
 * @returns React.FC<ShowProps>
 * @example
 * import { UserRoleShow } from './UserRoleShow'
 * <UserRoleShow {...props} />
 * @see https://marmelab.com/react-admin/Show.html
 * @see https://marmelab.com/react-admin/Fields.html
 * @see https://marmelab.com/react-admin/Inputs.html
 * @see https://marmelab.com/react-admin/TabbedShowLayout.html
 */
export const UserRoleShow: React.FC<ShowProps> = (props) => {
  return (
    <Show {...props} title={<PageTitle prefix="User role" />}>
      <TabbedShowLayout {...props}>
        <Tab label="summary">
          <TextField source="id" />
          <NumberField source="level" />
          <TextField source="name" />
          <TextField source="description" />
          <TextField source="dataView" emptyText="default (client)" />
          <TextField source="authFilter" emptyText="full access" />
          <ReferenceChipList
            label="Permissions"
            source="permissionIds"
            reference="permissions"
          />
        </Tab>
        <Tab label={'history'}>
          <HistoryDisplayTab />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
