import { Grid } from '@mui/material'
import React from 'react'
import { useRecordContext, Button } from 'react-admin'
import { useFormState } from 'react-hook-form'
import SaveIcon from '@mui/icons-material/SaveOutlined'
import CheckCircleIcon from '@mui/icons-material/CheckCircleOutlined'
import { SalesNotesFormToolbarProps } from './SalesNotesFormToolbarProps.types'

export const SalesNotesFormToolbar: React.FC<SalesNotesFormToolbarProps> = (
  props
) => {
  const { isDirty } = useFormState()
  const record = useRecordContext()

  const confirmDisabled =
    props.isLoading ||
    !record.reviewNotes ||
    record.requestService?.isReviewConfirmed ||
    isDirty
  const saveDisabled =
    props.isLoading || record.requestService?.isReviewConfirmed || !isDirty

  return !record.requestService?.isReviewConfirmed ? (
    <Grid container spacing={1} justifyContent="flex-end">
      <Grid item>
        <Button
          size="small"
          label="Save Notes"
          type="submit"
          variant="outlined"
          color="info"
          disabled={saveDisabled}
          startIcon={<SaveIcon />}
        />
      </Grid>
      <Grid item>
        <Button
          size="small"
          label="Confirm"
          type="button"
          onClick={props.onConfirm}
          disabled={confirmDisabled}
          variant="contained"
          color="info"
          startIcon={<CheckCircleIcon />}
        />
      </Grid>
    </Grid>
  ) : null
}
