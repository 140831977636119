import React from 'react'
import { IMonitorProbeFilters } from './MonitorProbeFilters.types'
import {
  Filter,
  TextInput,
  ReferenceInput,
  SelectInput,
  BooleanInput,
} from 'react-admin'

/**
 * Filters for a list of monitor probes.
 * @property {IMonitorProbeFilters} props The properties of the component.
 * @returns {JSX.Element} The JSX to render.
 * @example
 * <MonitorProbeFilters />
 * @see https://marmelab.com/react-admin/List.html#the-filter-button
 * @see https://marmelab.com/react-admin/List.html#the-filter-form
 * @see https://marmelab.com/react-admin/List.html#the-filter-button
 */
export const MonitorProbeFilters: React.FC<IMonitorProbeFilters> = (props) => {
  return (
    <Filter {...props}>
      <TextInput label="Search" source="term" alwaysOn />
      <ReferenceInput
        source="monitorTargetId"
        reference="monitorTargets/list"
        alwaysOn
      >
        <SelectInput label={'Target'} optionText="name" alwaysOn />
      </ReferenceInput>
      <ReferenceInput
        source="monitorProbeTypeId"
        reference="monitorProbeTypes/list"
        alwaysOn
      >
        <SelectInput label={'Type'} optionText="name" alwaysOn />
      </ReferenceInput>
      <BooleanInput source="isActive" />
      <BooleanInput source="isMuted" />
    </Filter>
  )
}
