import React from 'react'
import {
  Show,
  ShowProps,
  TabbedShowLayout,
  Tab,
  TextField,
  DateField,
  BooleanField,
} from 'react-admin'
import { PageTitle } from '../../atoms/PageTitle'
import { ReferenceText } from '../../molecules/ReferenceText'
import { TypeStateField } from '../../atoms/TypeStateField'
import { ContactDisplayTab } from '../../templates/ContactDisplayTab'
import { MetaListDisplay } from '../../organisms/MetaList'
import { MutationsList } from '../../organisms/MutationsList'

/**
 * Page to show a single Vendor Contact
 * @property {ShowProps} props
 * @returns {JSX.Element}
 * @example
 * return <VendorContactShow {...props} />
 * @see https://marmelab.com/react-admin/Show.html
 * @see https://marmelab.com/react-admin/Fields.html
 * @see https://marmelab.com/react-admin/Inputs.html
 */
export const VendorContactShow: React.FC<ShowProps> = (props) => {
  return (
    <Show {...props} title={<PageTitle prefix="Vendor Contact" />}>
      <TabbedShowLayout {...props}>
        <Tab label="general">
          <ReferenceText
            source={'vendorId'}
            label={'Vendor'}
            reference="vendors"
            emptyText={'N/A'}
            link="show"
          />
          <TypeStateField source="role" />
          <TextField source="name" fullWidth />
          <BooleanField source="isActive" />
          <DateField source="updatedAt" showTime />
          <DateField source="createdAt" showTime />
        </Tab>
        <Tab label="contact">
          <ContactDisplayTab />
        </Tab>
        <Tab label={'meta'}>
          <MetaListDisplay source="meta" />
        </Tab>
        <Tab label={'history'}>
          <MutationsList showActor type="history" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
