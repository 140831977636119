import React from 'react'
import {
  Edit,
  EditProps,
  SimpleForm,
  TextInput,
  SelectInput,
  ReferenceInput,
  NumberInput,
  FormDataConsumer,
  required,
} from 'react-admin'
import { useChoices } from './MarginBook.hooks'
import { PageTitle } from '../../atoms/PageTitle'

/**
 * Page to edit an existing margin book.
 * @property {EditProps} props The properties passed to the component.
 * @returns {React.FC<EditProps>} A react functional component.
 * @see https://marmelab.com/react-admin/Edit.html
 * @see https://marmelab.com/react-admin/Inputs.html
 */
export const MarginBookEdit: React.FC<EditProps> = (props) => {
  const choices = useChoices()

  return (
    <Edit
      {...props}
      mutationMode="undoable"
      title={<PageTitle prefix="Margin Book" />}
    >
      <SimpleForm>
        <TextInput disabled label="Id" source="id" fullWidth />
        <TextInput source="name" validate={required()} fullWidth />
        <SelectInput
          source="type"
          validate={required()}
          choices={choices}
          fullWidth
        />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.type === 'offset' && (
              <>
                <ReferenceInput
                  source="offsetMarginBookId"
                  reference="marginBooks/list"
                >
                  <SelectInput
                    label="Margin book to offset"
                    validate={required()}
                    fullWidth
                  />
                </ReferenceInput>
                <NumberInput
                  source="offsetNrc"
                  defaultValue={0}
                  options={{ minimumFractionDigits: 2 }}
                  validate={required()}
                />
                <NumberInput
                  source="offsetMrc"
                  defaultValue={0}
                  options={{ minimumFractionDigits: 2 }}
                  validate={required()}
                />
              </>
            )
          }
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  )
}
