import React from 'react'
import {
  BooleanInput,
  Create,
  CreateProps,
  FormTab,
  TabbedForm,
  required,
} from 'react-admin'
import { ReferenceAutocomplete } from '../../molecules/ReferenceAutocomplete'
import { MetaListInput } from '../../organisms/MetaList'

/**
 * Form to create a monitor probe.
 * @param props The Create component props
 * @see https://marmelab.com/react-admin/Create.html
 */
export const MonitorProbeCreate: React.FC<CreateProps> = (props) => {
  return (
    <Create {...props}>
      <TabbedForm>
        <FormTab label="general">
          <ReferenceAutocomplete
            label="Target"
            source="monitorTargetId"
            reference="monitorTargets/list"
            required
          />
          <ReferenceAutocomplete
            label="Probe Type"
            source="monitorProbeTypeId"
            reference="monitorProbeTypes/list"
            required
          />
          <BooleanInput
            source="isActive"
            defaultValue={true}
            fullWidth
            validate={required()}
          />
          <BooleanInput source="isMuted" defaultValue={false} fullWidth />
        </FormTab>
        <FormTab label="config">
          <MetaListInput source="config" />
          <small>
            ℹ️ This is the custom config for this probe (overrides default
            config-schema of probe type)
          </small>
        </FormTab>
      </TabbedForm>
    </Create>
  )
}
