import React from 'react'
import {
  List,
  ListProps,
  Datagrid,
  TextField,
  DateField,
  BooleanField,
} from 'react-admin'
import { ReferenceChip } from '../../molecules/ReferenceChip'
import { MonitorAlertHistoryFilters } from '../../molecules/MonitorAlertHistoryFilters'

/**
 * List of monitor alert histories.
 * @param props The List component props
 * @see https://marmelab.com/react-admin/List.html
 */
export const MonitorAlertHistoryList: React.FC<ListProps> = (props) => {
  return (
    <List
      {...props}
      empty={false}
      filters={<MonitorAlertHistoryFilters />}
      sort={{ field: 'name', order: 'ASC' }}
      perPage={25}
    >
      <Datagrid rowClick="show">
        <TextField source="description" emptyText="N/A" />
        <ReferenceChip
          referenceSource="monitorAlertRuleId"
          tooltipSource="name"
          chipSource="name"
          source="monitorAlertRule"
          reference="monitorAlertRules"
          emptyText="N/A"
          link="show"
        />
        <ReferenceChip
          referenceSource="monitorProbeId"
          tooltipSource="name"
          chipSource="name"
          source="monitorProbe"
          reference="monitorProbes"
          emptyText="N/A"
          link="show"
        />
        <BooleanField source="isFiring" emptyText="N/A" fullWidth />
        <DateField source="startedAt" emptyText="N/A" showTime />
        <DateField source="endedAt" emptyText="ongoin" showTime />
      </Datagrid>
    </List>
  )
}
