import React from 'react'
import { IMonitorTargetFilters } from './MonitorTargetFilters.types'
import {
  Filter,
  TextInput,
  BooleanInput,
  ReferenceInput,
  AutocompleteArrayInput,
} from 'react-admin'

/**
 * Filters for a list of monitor targets.
 * @property {IMonitorProbeFilters} props The properties of the component.
 * @returns {JSX.Element} The JSX to render.
 * @example
 * <MonitorTargetFilters />
 * @see https://marmelab.com/react-admin/List.html#the-filter-button
 * @see https://marmelab.com/react-admin/List.html#the-filter-form
 */
export const MonitorTargetFilters: React.FC<IMonitorTargetFilters> = (
  props
) => {
  return (
    <Filter {...props}>
      <TextInput label="Hostname" source="term" alwaysOn />
      <ReferenceInput source="serviceIds" reference="services/list" alwaysOn>
        <AutocompleteArrayInput label="Service" optionText="name" />
      </ReferenceInput>
      <BooleanInput source="isActive" />
      <BooleanInput source="isMuted" />
    </Filter>
  )
}
