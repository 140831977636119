import React from 'react'
import {
  AutocompleteArrayInput,
  BooleanInput,
  Edit,
  EditProps,
  ReferenceArrayInput,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin'
import { PageTitle } from '../../atoms/PageTitle'

/**
 * Form to edit a monitor target.
 * @param props The Edit component props
 * @see https://marmelab.com/react-admin/Edit.html
 */
export const MonitorTargetEdit: React.FC<EditProps> = (props) => {
  return (
    <Edit
      {...props}
      title={<PageTitle prefix="Monitor Target" />}
      mutationMode="undoable"
    >
      <SimpleForm>
        <TextInput disabled label="Id" source="id" fullWidth />
        <TextInput source="hostname" validate={required()} fullWidth />
        <ReferenceArrayInput source="serviceIds" reference="services/list">
          <AutocompleteArrayInput
            optionText="name"
            optionValue="id"
            fullWidth
            label="Related services"
          />
        </ReferenceArrayInput>
        <BooleanInput source="isActive" defaultValue={true} />
        <BooleanInput source="isMuted" defaultValue={false} />
      </SimpleForm>
    </Edit>
  )
}
