import React from 'react'
import { Message } from '../Message'

/**
 * Displays a PDF document.
 * @param {string} props.pdfData - The base64 encoded PDF data.
 * @returns {JSX.Element} The rendered component.
 * @example
 * return (
 * <DisplayPDF pdfData={pdfData} />
 * )
 */
export const DisplayPDF: React.FC<{ pdfData: string }> = (props) => {
  return (
    <object
      data={`data:application/pdf;base64,${props.pdfData}`}
      type={'application/pdf'}
      width={'100%'}
      height={'100%'}
    >
      <Message message="Could not render PDF document. Do you have PDF support enabled?" />
    </object>
  )
}
