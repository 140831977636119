import React from 'react'
import { DualSelect } from '../../atoms/DualSelect'
import {
  Edit,
  EditProps,
  SimpleForm,
  BooleanInput,
  TextInput,
  required,
  SelectInput,
} from 'react-admin'

/**
 * Edit Application page.
 * @property {EditProps} props
 * @returns {JSX.Element} ApplicationEdit component
 * @see {@link https://marmelab.com/react-admin/CreateEdit.html|CreateEdit}
 */
export const ApplicationEdit: React.FC<EditProps> = (props) => {
  return (
    <Edit {...props} mutationMode="undoable">
      <SimpleForm>
        <TextInput disabled label="Id" source="id" fullWidth />
        <TextInput source="name" validate={required()} fullWidth />
        <BooleanInput source="isActive" defaultValue={true} fullWidth />
        <SelectInput
          source="dataView"
          defaultValue={'client'}
          choices={[
            { id: 'client', name: 'Client (default)' },
            { id: 'clientPlusVendor', name: 'Client+Vendor (reveals Vendor)' },
            { id: 'technical', name: 'Client Technical (hides financials)' },
          ]}
          fullWidth
        />
        <DualSelect
          label="Clients"
          source="clientIds"
          reference="clients/list"
        />
        <DualSelect
          label="Permissions"
          source="permissionIds"
          reference="permissions/list"
        />
      </SimpleForm>
    </Edit>
  )
}
