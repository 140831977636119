import React from 'react'
import {
  TabbedShowLayout,
  Tab,
  TextField,
  DateField,
  ArrayField,
  Datagrid,
  SimpleShowLayout,
} from 'react-admin'
import { ReferenceChip } from '../../molecules/ReferenceChip'
import { TypeStateField } from '../../atoms/TypeStateField'
import { MutationsList } from '../../organisms/MutationsList'
import { IServicePortTabs } from './ServicePortTabs.types'

/**
 * Tabs display for ServicePort
 * @property {TabbedShowLayoutProps} props
 * @returns {React.FC<TabbedShowLayoutProps>}
 * @example <ServicePortTabsDisplay />
 */
export const ServicePortTabsDisplay: React.FC<IServicePortTabs> = (props) => {
  return (
    <TabbedShowLayout {...props} syncWithLocation={false}>
      <Tab label="general">
        <ReferenceChip
          referenceSource="serviceId"
          tooltipSource="serviceNo"
          source="name"
          label="Service"
          reference="services"
          emptyText="N/A"
          link="show"
          chipSource="name"
          removeDoubleLabel
        />

        <ReferenceChip
          referenceSource="serviceSiteId"
          tooltipSource="siteNo"
          source="name"
          label="Site"
          reference="serviceSites"
          emptyText="N/A"
          link="show"
          chipSource="name"
          removeDoubleLabel
        />

        <TextField source="name" />
        <TypeStateField source="state" />
        <DateField source="updatedAt" showTime />
        <DateField source="createdAt" showTime />
      </Tab>
      <Tab label={'port'}>
        <TextField source="type" label="Type" />
        <TextField source="connector" label="Connector" />
        <TextField source="mode" label="Mode" />
        <TextField source="speed" label="Speed" />
      </Tab>
      <Tab label={'patch'}>
        <TextField source="room" label="Room" emptyText="N/A" />
        <TextField source="rack" label="Rack" emptyText="N/A" />
        <TextField source="panel" label="Panel" emptyText="N/A" />
        <TextField source="device" label="Device" emptyText="N/A" />
        <TextField source="port" label="Port" emptyText="N/A" />
        <TextField source="label" label="Label" emptyText="N/A" />
      </Tab>
      <Tab label={'equipment'}>
        <ArrayField source="equipment" label={false}>
          <Datagrid
            bulkActionButtons={false}
            expand={
              <SimpleShowLayout
                sx={{
                  '& .RaSimpleShowLayout-stack': {
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
                    gridGap: '4px',
                    gridAutoColumns: '1fr',
                    alignSelf: 'center',
                    '& .ra-field': {
                      marginTop: '8px',
                    },
                  },
                  margin: 0,
                  padding: 0,
                }}
              >
                <TextField source={'notes'} emptyText={'No notes'} fullWidth />
              </SimpleShowLayout>
            }
            rowClick="expand"
          >
            <TextField source="type" />
            <TextField source="brand" />
            <TextField source="model" />
            <TextField source="serial" />
            <TextField source="hostname" />
            <TextField source="address" />
          </Datagrid>
        </ArrayField>
      </Tab>
      <Tab label={'networks'}>
        <ArrayField source="networks" label={false}>
          <Datagrid
            bulkActionButtons={false}
            expand={
              <>
                <SimpleShowLayout
                  sx={{
                    '& .RaSimpleShowLayout-stack': {
                      display: 'grid',
                      gridTemplateColumns:
                        'repeat(auto-fit, minmax(300px, 1fr))',
                      gridGap: '4px',
                      gridAutoColumns: '1fr',
                      alignSelf: 'center',
                      '& .ra-field': {
                        marginTop: '8px',
                      },
                    },
                    margin: 0,
                    padding: 0,
                  }}
                >
                  <TextField
                    source={'localPeer'}
                    label="Local BGP peer"
                    emptyText={'N/A'}
                    fullWidth
                  />
                  <TextField
                    source={'remotePeer'}
                    label="Remote BGP peer"
                    emptyText={'N/A'}
                    fullWidth
                  />
                  <TextField
                    source={'localAS'}
                    label="Local AS number"
                    emptyText={'N/A'}
                    fullWidth
                  />
                  <TextField
                    source={'remoteAS'}
                    label="Remote AS number"
                    emptyText={'N/A'}
                    fullWidth
                  />
                </SimpleShowLayout>
                <SimpleShowLayout sx={{ margin: 0, padding: 0 }} {...props}>
                  <TextField
                    source={'notes'}
                    label="Notes"
                    emptyText={'N/A'}
                    fullWidth
                  />
                </SimpleShowLayout>
              </>
            }
            rowClick="expand"
          >
            <TextField source="scope" />
            <TextField source="version" />
            <TextField source="allocation" />
            <TextField source="network" />
            <TextField
              source={'address'}
              label="Address"
              emptyText={'N/A'}
              fullWidth
            />
            <TextField
              source={'dns1'}
              label="DNS server 1"
              emptyText={'N/A'}
              fullWidth
            />
            <TextField
              source={'dns2'}
              label="DNS server 2"
              emptyText={'N/A'}
              fullWidth
            />
          </Datagrid>
        </ArrayField>
      </Tab>
      <Tab label={'notes'}>
        <TextField source="notes" />
      </Tab>
      <Tab label={'history'}>
        <MutationsList showActor type="history" />
      </Tab>
    </TabbedShowLayout>
  )
}
