/**
 * Styles for QuoteItemDisplay
 * @returns {React.CSSProperties} The style of the component.
 */
export const useStyles = () => {
  return {
    '& .RaSimpleShowLayout-stack': {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
      gridGap: '2px',
      gridAutoColumns: '1fr',
      alignSelf: 'center',
      '& .ra-field': {
        marginTop: '8px',
      },
    },
    width: '100%',
  }
}
