import React from 'react'
import { TextField, DateField, Labeled } from 'react-admin'
import { TypeStateField } from '../../atoms/TypeStateField'

/**
 * Component that displays a summary of a quote.
 * @returns {JSX.Element} The rendered component.
 * @example
 * import { QuoteSummary } from './QuoteSummary.component.tsx'
 * <QuoteSummary />
 */
export const QuoteSummary: React.FC = () => {
  return (
    <>
      <Labeled label="Quote no">
        <TextField source="quoteNo" />
      </Labeled>
      <Labeled label="State">
        <TypeStateField source="state" />
      </Labeled>
      <Labeled label="Type">
        <TypeStateField source="type" />
      </Labeled>
      <Labeled label="Client name">
        <TextField source="client.name" />
      </Labeled>
      <Labeled label="Project no">
        <TextField source="project.projectNo" label={'Project no.'} />
      </Labeled>
      <Labeled label="Project title">
        <TextField source="project.title" />
      </Labeled>
      <Labeled label="Offered at">
        <DateField source="offeredAt" showTime />
      </Labeled>
      <Labeled label="Updated at">
        <DateField source="updatedAt" showTime />
      </Labeled>
      <Labeled label="Created at">
        <DateField source="createdAt" showTime />
      </Labeled>
    </>
  )
}
