import { DateInputProps, DateInput as ReactAdminDateInput } from 'react-admin'

/**
 * React-Admin DateInput component with a fix for sending "yyyy-MM-ddT00:00:00.000Z" to the backend.
 * Without this, the date is sent as "yyyy-MM-ddTHH:mm:ss.SSSZ" which causes issue GNX-1662.
 *
 * Note: It's possible that this issue has been fixed in a later version of react-admin.
 * When we upgrade react-admin, we should consider removing this component.
 */
export function DateInput(props: DateInputProps) {
  return (
    <ReactAdminDateInput
      {...props}
      format={(
        /** The value has the format "yyyy-MM-ddT00:00:00.000Z" when it's a string. But it can also be `null` in case of invalid date. */
        value: string | null
      ) => value?.substring(0, 10)}
      parse={(
        /**
         * The value has the format "yyyy-MM-dd". But it can also come as an empty string in case of the user deleting the date,
         * in this case we need to let it throw an error (invalid date error), which prevents the date field from being updated to an empty date.
         */
        value: string
      ) => (value ? new Date(`${value}T00:00:00.000Z`).toISOString() : null)}
    />
  )
}
