import { useWizardFormContext } from '@react-admin/ra-form-layout'
import { Toolbar, Button, Grid } from '@mui/material'
import { useTranslate } from 'react-admin'
import { ExtendedWizardToolbarProps } from './ExtendedWizardToolbar.types'
import { extractPropsForToolbar } from './ExtendedWizardToolbar.functions'

/** A WizardToolbar which allows you to pass a custom Save Button as prop. */
export const ExtendedWizardToolbar: React.FC<ExtendedWizardToolbarProps> = (
  props
) => {
  const { hasNextStep, hasPreviousStep, goToNextStep, goToPreviousStep } =
    useWizardFormContext()
  const translate = useTranslate()

  return (
    <Toolbar {...extractPropsForToolbar(props)}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          {hasPreviousStep && (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => goToPreviousStep()}
            >
              {translate('ra-form-layout.action.previous')}
            </Button>
          )}
        </Grid>
        <Grid item>
          {hasNextStep ? (
            <Button
              variant="contained"
              color="primary"
              onClick={() => goToNextStep()}
            >
              {translate('ra-form-layout.action.next')}
            </Button>
          ) : (
            props.saveButton
          )}
        </Grid>
      </Grid>
    </Toolbar>
  )
}
