import React from 'react'
import { TextField, useRecordContext, List } from 'react-admin'
import { LabelledDatagrid } from '../../atoms/LabelledDatagrid'
import { TypeStateField } from '../../atoms/TypeStateField'
import { IRequestSitesList } from './RequestSitesList.types'
import { EllipsedTextField } from '../../atoms/EllipsedField'
import { TermFilter } from '../../atoms/TermFilter'
import { RequestSiteDetails } from '../../organisms/RequestSiteDetails'

/**
 * List of sites for a request
 * @param {IRequestSitesList} props
 * @returns {React.FC<IRequestSitesList>}
 * @example
 * <RequestSitesList />
 */
export const RequestSitesList: React.FC<IRequestSitesList> = (props) => {
  const record = useRecordContext(props)

  return (
    <List
      title={' '}
      {...props}
      sort={props.sort ?? { field: 'createdAt', order: 'DESC' }}
      empty={false}
      resource={'requestSites'}
      perPage={props.perPage ?? 1000}
      filters={props.filters ?? <TermFilter />}
      filter={props.filter ?? { requestId: record.id }}
      sx={
        props.sx ?? {
          '& .MuiToolbar-gutters': { display: 'none' },
          '& .MuiTablePagination-toolbar': { display: 'flex' },
        }
      }
      storeKey={`requestSites.${record.id}`}
      disableSyncWithLocation
    >
      <LabelledDatagrid
        rowClick="expand"
        optimized
        bulkActionButtons={false}
        expand={
          <RequestSiteDetails
            showServices={props.context !== 'requestServices'}
          />
        }
      >
        <TypeStateField source="state" fullWidth />
        <TextField source="name" emptyText="N/A" />
        <TextField source="reference" emptyText="N/A" />
        <EllipsedTextField source="address" />
      </LabelledDatagrid>
    </List>
  )
}
