import React from 'react'
import {
  Show,
  ShowProps,
  TabbedShowLayout,
  Tab,
  TextField,
  DateField,
  TabbedShowLayoutTabs,
  BooleanField,
} from 'react-admin'

import { MutationsList } from '../../organisms/MutationsList'
import { CoverageAreasList } from '../../organisms/CoverageAreasList'
import { CoverageDatacentersList } from '../../organisms/CoverageDatacentersList'
import { CoverageAddressList } from '../../organisms/CoverageAddressList'
import { ProductsList } from '../../organisms/ProductsList'

import { PageTitle } from '../../atoms/PageTitle'
import { RelatedResourcesPage, IPageMap } from '../../molecules/RelatedResource'
import { ReferenceChip } from '../../molecules/ReferenceChip'
import { ReferenceText } from '../../molecules/ReferenceText'

/**
 * List of related pages to show in the "Related" tab
 */
const relatedPages: IPageMap = {
  Areas: <CoverageAreasList target="coverageZoneId" />,
  Datacenters: <CoverageDatacentersList target="coverageZoneId" />,
  Addresses: <CoverageAddressList target="coverageZoneId" />,
  Products: (
    <ProductsList target="coverageZoneId" omit={['vendorId', 'name']} />
  ),
}

/**
 * Page to show a single coverage zone
 * @property {ShowProps} props
 * @returns {React.FC<ShowProps>}
 * @example
 * <CoverageZoneShow {...props} />
 * @see https://marmelab.com/react-admin/Show.html
 */
export const CoverageZoneShow: React.FC<ShowProps> = (props) => {
  return (
    <Show {...props} title={<PageTitle prefix="Coverage zone" />}>
      <TabbedShowLayout
        tabs={
          <TabbedShowLayoutTabs variant="scrollable" scrollButtons="auto" />
        }
      >
        <Tab label="general">
          <TextField source="name" fullWidth />
          <ReferenceText
            source="countryCode"
            reference="countries/list"
            emptyText={'N/A'}
            link={false}
          />
          <ReferenceChip
            referenceSource="vendorId"
            tooltipSource="name"
            source="Vendor"
            chipSource="name"
            reference="vendors"
            emptyText={'N/A'}
          />
          <BooleanField source="isActive" fullWidth />
          <DateField source="updatedAt" showTime />
          <DateField source="createdAt" showTime />
        </Tab>
        <Tab label="history">
          <MutationsList showActor type="history" />
        </Tab>
        <Tab label="related">
          <RelatedResourcesPage resource="coverageZone" pages={relatedPages} />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
