import { useRecordContext } from 'react-admin'
import { IRecord } from './StatsModalInput.types'

/**
 * Hook to get the record from the context
 * @param reference - The reference of the record
 * @returns {IRecord} The record
 * @example
 * import { useRecord } from './StatsModalInput.hooks'
 * useRecord('services')
 * useRecord('clients')
 * useRecord('stats')
 */
export const useRecord = (reference: string) => {
  const record = useRecordContext()

  let defaultRecord: IRecord = {}

  if (record?.clientId) {
    defaultRecord.clientId = record.clientId
  }
  if (reference === 'services') {
    defaultRecord.serviceId = record.id
  }
  if (reference === 'clients') {
    defaultRecord.clientId = record.id
  }

  return defaultRecord
}
