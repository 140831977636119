import React from 'react'
import {
  TextField,
  DateField,
  Show,
  ShowProps,
  TabbedShowLayout,
  Tab,
  BooleanField,
  ReferenceManyField,
  Datagrid,
  Pagination,
  ShowButton,
} from 'react-admin'
import { PageTitle } from '../../atoms/PageTitle'
import { HistoryDisplayTab } from '../../templates/HistoryDisplayTab'
import { EllipsedTextField } from '../../atoms/EllipsedField'
import { TypeStateField } from '../../atoms/TypeStateField'
import { ReferenceChip } from '../../molecules/ReferenceChip'
import { ServiceHealthIndicatorField } from '../../atoms/ServiceHealthIndicatorField'
import { ServiceDetails } from '../../organisms/ServiceDetails'

/**
 * Component to show a monitor target.
 * @param props The Show component props
 * @see https://marmelab.com/react-admin/Show.html
 */
export const MonitorTargetShow: React.FC<ShowProps> = (props) => {
  return (
    <Show {...props} title={<PageTitle prefix="Monitor Target" />}>
      <TabbedShowLayout {...props}>
        <Tab label="summary">
          <TextField source="hostname" />
          <ServiceHealthIndicatorField source="health" />
          <BooleanField source="isActive" />
          <BooleanField source="isMuted" />
          <DateField source="createdAt" showTime />
          <DateField source="updatedAt" showTime />
        </Tab>
        <Tab label="services">
          <ReferenceManyField
            reference="services"
            target="monitorTargetIds"
            label="Related services"
            pagination={<Pagination />}
            sort={{ field: 'createdAt', order: 'DESC' }}
          >
            <Datagrid bulkActionButtons={false} expand={<ServiceDetails />}>
              <TextField source="serviceNo" />
              <TypeStateField source="state" fullWidth sx={{ maxWidth: 150 }} />
              <EllipsedTextField source="name" />
              <ReferenceChip
                referenceSource="clientId"
                tooltipSource="clientNo"
                source="Client"
                reference="clients"
                emptyText="N/A"
                link="show"
                chipSource="name"
              />
              <ShowButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="probes">
          <ReferenceManyField
            reference="monitorProbes"
            target="monitorTargetId"
            label={false}
            pagination={<Pagination />}
            sort={{ field: 'createdAt', order: 'DESC' }}
          >
            <Datagrid
              sx={{
                width: '100%',
                '& .column-title': {
                  maxWidth: '20em',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                },
              }}
            >
              <TextField source="name" emptyText="N/A" />
              <ReferenceChip
                referenceSource="monitorTargetId"
                tooltipSource="hostname"
                source="Target"
                chipSource="hostname"
                reference="monitorTargets"
                emptyText="N/A"
                link="show"
              />
              <ReferenceChip
                referenceSource="monitorProbeTypeId"
                tooltipSource="name"
                source="Type"
                chipSource="name"
                reference="monitorProbeTypes"
                emptyText="N/A"
                link="show"
              />
              <ServiceHealthIndicatorField label="Health" source="health" />
              <BooleanField source="isActive" fullWidth />
              <BooleanField source="isMuted" defaultChecked={false} fullWidth />
              <DateField source="updatedAt" showTime />
              <ShowButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="history">
          <HistoryDisplayTab />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
