import React from 'react'
import { RaRecord, Identifier } from 'react-admin'

/**
 * Secondary text on a comment in a list.
 * @returns {React.FC}
 * @example
 * import { SecondaryText } from './SecondaryText.component.tsx'
 * <SecondaryText />
 */
export const SecondaryText: React.FC<RaRecord<Identifier>> = (props) => {
  return <>{`Written by ${props.author?.name}`}</>
}
