/**
 * CoverImage styles
 */
export const useStyles = (image?: string) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    height: '1px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundImage:
      image ??
      'radial-gradient(circle at 0% 0em, #25376e 10%, #000 75%, #25376e 100%)',
  }
}
