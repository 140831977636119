import green from '@mui/material/colors/green'
import orange from '@mui/material/colors/orange'
import red from '@mui/material/colors/red'
import blue from '@mui/material/colors/blue'
import grey from '@mui/material/colors/grey'
import { IAllStates } from './states.types'
import {
  successStates,
  errorStates,
  warningStates,
  defaultStates,
} from './states.funcs'

/**
 * Returns the color string corresponding to the given state.
 * @param {IAllStates['state']} state - The state to determine the color for.
 * @returns {string} The color string ('success', 'error', 'warning', 'default', or 'info').
 */
export const useStateToColorString = (state: IAllStates['state']) => {
  if (state && successStates.includes(state)) return 'success'
  if (state && errorStates.includes(state)) return 'error'
  if (state && warningStates.includes(state)) return 'warning'
  if (state && defaultStates.includes(state)) return 'default'
  return 'info'
}

/**
 * Returns the color hex corresponding to the given state.
 * @param {IAllStates['state']} state - The state to determine the color for.
 * @returns {string} The color string ('success', 'error', 'warning', 'default', or 'info').
 */
export const useStateToColorHex = (state: IAllStates['state']) => {
  if (state && successStates.includes(state)) return green[300]
  if (state && errorStates.includes(state)) return red[300]
  if (state && warningStates.includes(state)) return orange[300]
  if (state && defaultStates.includes(state)) return grey[300]
  return blue[300]
}
