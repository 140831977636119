import { Exporter, downloadCSV } from 'react-admin'
import { IQuote, IQuoteItem } from './quoteExporter.types'
// @ts-ignore
import jsonExport from 'jsonexport/dist'

/**
 * Returns a CSV file with the quotes data.
 * @param {IQuote[]} quotes - The quotes data.
 * @returns {IQuote[]} The quotes data.
 * @exports quotesList
 */
const quotesList = (quotes: IQuote[]) => {
  return quotes.map((quote: IQuote) => {
    return {
      quoteNo: quote.quoteNo,
      reference: quote.reference,
      clientName: quote.client.name,
      clientNo: quote.client.clientNo,
      project: quote.project.title,
      projectNo: quote.project.projectNo,
      diversification: quote.diversification,
      state: quote.state,
      createdAt: quote.createdAt,
      expiresAt: quote.expiresAt,
      items: quote.items.map((item: IQuoteItem) => {
        return {
          title: item.title,
          description: item.description,
          salesNrc: item.salesNrc,
          salesMrc: item.salesMrc,
          salesCurrency: item.salesCurrency,
          salesVat: item.salesVat,
          contractPeriod: item.contractPeriod,
          siteNo: item.site.siteNo,
          siteName: item.site.name,
          siteAddress: item.site.address,
          siteCity: item.site.city,
          siteCountry: item.site.countryCode,
          vendorName: item.offer.vendor.name,
          specification: item.offer.specification,
        }
      }),
    }
  })
}

/**
 * Returns a CSV file with the quotes data.
 * @param {IQuote[]} quotes - The quotes data.
 * @returns {void}
 */
export const quoteExporter: Exporter = (quotes: IQuote[]) => {
  jsonExport(
    quotesList,
    {
      headers: [
        'quoteNo',
        'reference',
        'clientName',
        'clientNo',
        'project',
        'projectNo',
        'diversification',
        'state',
        'createdAt',
        'expiresAt',
        'items.title',
        'items.description',
        'items.salesNrc',
        'items.salesMrc',
        'items.salesCurrency',
        'items.salesVat',
        'items.contractPeriod',
        'items.salesNo',
        'items.siteName',
        'items.siteAddress',
        'items.siteCity',
        'items.siteCountry',
        'items.vendorName',
        'items.specifications.ipPrefixV4',
        'items.specifications.ipPrefixV6',
        'items.specifications.bandwidthUp',
        'items.specifications.bandwidthDown',
        'items.specifications.serviceUptime',
        'items.specifications.contentionRatio',
        'items.specifications.meanTimeToRepair',
      ],
      rowDelimiter: ';',
    },
    (err: any, csv: any) => {
      downloadCSV(csv, 'quotes')
    }
  )
}
