import React from 'react'
import {
  Edit,
  EditProps,
  SimpleForm,
  TextInput,
  NumberInput,
  BooleanInput,
  required,
  SelectInput,
} from 'react-admin'
import { DualSelect } from '../../atoms/DualSelect'

/**
 * Page to edit user roles.
 * @property EditProps props
 * @returns React.FC<EditProps>
 * @example
 * import { UserRoleEdit } from './UserRoleEdit'
 * <UserRoleEdit {...props} />
 * @see https://marmelab.com/react-admin/Edit.html
 * @see https://marmelab.com/react-admin/Fields.html
 * @see https://marmelab.com/react-admin/Inputs.html
 */
export const UserRoleEdit: React.FC<EditProps> = (props) => {
  return (
    <Edit {...props} mutationMode="undoable">
      <SimpleForm>
        <TextInput disabled label="Id" source="id" fullWidth />
        <TextInput source="name" validate={required()} fullWidth />
        <TextInput source="description" fullWidth />
        <NumberInput source="level" fullWidth />
        <BooleanInput source="isEmployee" fullWidth />
        <BooleanInput source="isSuperAdmin" fullWidth />
        <SelectInput
          source="authFilter"
          choices={[{ id: 'client', name: 'Only associated clients' }]}
          fullWidth
        />
        <SelectInput
          source="dataView"
          choices={[
            { id: 'client', name: 'Client (default)' },
            { id: 'clientPlusVendor', name: 'Client+Vendor (reveals Vendor)' },
            { id: 'technical', name: 'Client Technical (hides financials)' },
            { id: 'full', name: 'Full (admin access)' },
          ]}
          fullWidth
        />
        <DualSelect
          label="Permissions"
          source="permissionIds"
          reference="permissions/list"
        />
      </SimpleForm>
    </Edit>
  )
}
