import React from 'react'
import { Edit, EditProps } from 'react-admin'
import { CreateEditStatsForm } from '../../molecules/CreateEditStatsForm'

/**
 * Page to edit a service statistic.
 * @param {EditProps} props
 * @returns {React.FC}
 * @example
 * import { ServiceStatisticEdit } from './ServiceStatisticEdit.component.tsx'
 * <ServiceStatisticEdit />
 * @see {@link https://marmelab.com/react-admin/Edit.html|RA Edit}
 */
export const ServiceStatisticEdit: React.FC<EditProps> = (props) => {
  return (
    <Edit {...props} mutationMode="undoable">
      <CreateEditStatsForm />
    </Edit>
  )
}
