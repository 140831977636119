import { MonitorAlertRuleCreate } from './Create.component'
import { MonitorAlertRuleEdit } from './Edit.component'
import { MonitorAlertRuleList } from './List.component'
import { MonitorAlertRuleShow } from './Show.component'

export default {
  list: MonitorAlertRuleList,
  show: MonitorAlertRuleShow,
  create: MonitorAlertRuleCreate,
  edit: MonitorAlertRuleEdit,
}
