import React from 'react'
import {
  List,
  ListProps,
  Datagrid,
  TextField,
  DateField,
  BooleanField,
  FunctionField,
  ReferenceManyField,
  SingleFieldList,
  ChipField,
  RaRecord,
} from 'react-admin'
import { MonitorTargetFilters } from '../../molecules/MonitorTargetFilters'
import { ServiceHealthIndicatorField } from '../../atoms/ServiceHealthIndicatorField'

/**
 * List of monitor targets.
 * @param props The List component props
 * @see https://marmelab.com/react-admin/List.html
 */
export const MonitorTargetList: React.FC<ListProps> = (props) => {
  return (
    <List
      empty={false}
      filters={<MonitorTargetFilters />}
      sort={{ field: 'hostname', order: 'ASC' }}
      perPage={25}
      {...props}
    >
      <Datagrid rowClick="show">
        <TextField source="hostname" />
        <FunctionField
          render={(record: RaRecord) => record.monitorProbes?.length}
          label="Probes"
          sortable={false}
        />
        <ReferenceManyField
          label="Services"
          reference="services"
          target="monitorTargetIds"
          sortable={false}
        >
          <SingleFieldList linkType="show">
            <FunctionField
              render={(record: RaRecord) => (
                <ChipField
                  size="small"
                  record={{ label: `${record.serviceNo}: ${record.name}` }}
                  source="label"
                />
              )}
            />
          </SingleFieldList>
        </ReferenceManyField>
        <ServiceHealthIndicatorField
          label="Health"
          source="health"
          sortable={false}
        />
        <BooleanField source="isActive" />
        <BooleanField source="isMuted" defaultChecked={false} fullWidth />
        <DateField source="updatedAt" showTime />
      </Datagrid>
    </List>
  )
}
