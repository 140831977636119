import React from 'react'
import {
  SortButton,
  ExportButton,
  ToolbarProps,
  useListContext,
} from 'react-admin'
import { quoteExporter } from '../../particles/exporters/quoteExporter/quoteExporter.funcs'
import { Toolbar } from '../Toolbar'

/**
 * Toolbar for the quote list page.
 * @param {ToolbarProps} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 * @example
 * import { QuoteToolbar } from './QuoteToolbar.component.tsx'
 * <QuoteToolbar />
 */
export const QuoteToolbar: React.FC<ToolbarProps> = (props) => {
  const { total } = useListContext()

  return (
    <Toolbar {...props} showSelectColumns showFilter>
      <SortButton
        fields={[
          'createdAt',
          'updatedAt',
          'quoteNo',
          'type',
          'clientId',
          'projectId',
          'state',
          'offeredAt',
        ]}
      />
      <ExportButton disabled={total === 0} exporter={quoteExporter} />
    </Toolbar>
  )
}
