import React from 'react'
import {
  Create,
  CreateProps,
  SimpleForm,
  TextInput,
  NumberInput,
  BooleanInput,
  required,
  regex,
  SelectInput,
} from 'react-admin'
import { DualSelect } from '../../atoms/DualSelect'

/**
 * Page to create a user role.
 * @property CreateProps props
 * @returns React.FC<CreateProps>
 * @example
 * import { UserRoleCreate } from './UserRoleCreate'
 * <UserRoleCreate {...props} />
 * @see https://marmelab.com/react-admin/CreateEdit.html
 * @see https://marmelab.com/react-admin/Fields.html
 * @see https://marmelab.com/react-admin/Inputs.html
 */
export const UserRoleCreate: React.FC<CreateProps> = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput
          source="id"
          validate={[required(), regex(/[a-z-]*/)]}
          fullWidth
        />
        <TextInput source="name" validate={required()} fullWidth />
        <TextInput source="description" fullWidth />
        <NumberInput source="level" fullWidth />
        <BooleanInput source="isEmployee" fullWidth />
        <BooleanInput source="isSuperAdmin" fullWidth />
        <SelectInput
          source="authFilter"
          choices={[{ id: 'client', name: 'Only associated clients' }]}
          fullWidth
        />
        <SelectInput
          source="dataView"
          choices={[
            { id: 'client', name: 'Client (default)' },
            { id: 'clientPlusVendor', name: 'Client+Vendor (reveals Vendor)' },
            { id: 'technical', name: 'Client Technical (hides financials)' },
            { id: 'full', name: 'Full (admin access)' },
          ]}
          fullWidth
        />
        <DualSelect
          label="Permissions"
          source="permissionIds"
          reference="permissions/list"
        />
      </SimpleForm>
    </Create>
  )
}
