import React from 'react'
import {
  Create,
  CreateProps,
  TextInput,
  required,
  FormTab,
  TabbedForm,
  FileInput,
  FileField,
  ReferenceArrayInput,
} from 'react-admin'
import { DualListInput } from '@react-admin/ra-relationships'

/**
 * Page to create a new InternetServiceProvider.
 */
export const InternetServiceProviderCreate: React.FC<CreateProps> = (props) => {
  return (
    <Create {...props}>
      <TabbedForm>
        <FormTab label={'general'}>
          <TextInput source="name" validate={required()} fullWidth />
          <FileInput
            source="file"
            label="Logo in webp format"
            accept="image/webp"
            multiple={false}
          >
            <FileField source="src" title="title" />
          </FileInput>
        </FormTab>
        <FormTab label={'vendors'}>
          <ReferenceArrayInput
            label="Vendors"
            source="vendorIds"
            reference="vendors/list"
          >
            <DualListInput optionText="name" label={false} />
          </ReferenceArrayInput>
        </FormTab>
      </TabbedForm>
    </Create>
  )
}
