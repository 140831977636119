import React from 'react'

/**
 * Adds spacing between elements horizontally.
 * @returns {React.FC} - React component.
 * @example
 * return (
 *  <Box style={styles.gridAutoCol}>
 *      <PendingRequestsCard />
 *      <Spacer />
 *      <OfferedQuotesCard />
 *      <Spacer />
 *  </Box>
 * )
 */
export const HorizontalSpacer: React.FC = () => {
  return <span style={{ width: '1em' }} />
}
