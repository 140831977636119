import React from 'react'
import { IActivityDisplayTab } from './ActivityDisplayTab.types'
import { MutationsList } from '../../organisms/MutationsList'

/**
 * Tab for displaying activity
 * @param props
 * @example
 * <ActivityDisplayTab />
 * @returns {FunctionComponent<IActivityDisplayTab>}
 * @see {@link https://marmelab.com/react-admin/List.html|ActivityDisplayTab}
 * @see {@link https://marmelab.com/react-admin/TabbedShowLayout.html|ActivityDisplayTab}
 * @see {@link https://marmelab.com/react-admin/Tab.html|ActivityDisplayTab}
 */
export const ActivityDisplayTab: React.FC<IActivityDisplayTab> = () => {
  return <MutationsList showActor type="activity" />
}
