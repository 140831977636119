import React from 'react'
import { IDownloadFileButton } from './DownloadFileButton.types'
import { handleDownloadFileButtonClick } from './DownloadFileButton.funcs'
import IconDownload from '@mui/icons-material/Download'
import {
  Button,
  useNotify,
  useRecordContext,
  useDataProvider,
} from 'react-admin'

/**
 * Download file button component.
 *
 * @param {IDownloadFileButton} props - The props for the DownloadFileButton component.
 * @returns {React.ReactElement}
 * @see IDownloadFileButton
 * @see https://marmelab.com/react-admin/Actions.html
 * @see https://mui.com/components/buttons/#icon-buttons
 * @see https://mui.com/components/material-icons/
 *
 */
export const DownloadFileButton: React.FC<IDownloadFileButton> = ({
  resource,
}) => {
  const notify = useNotify()
  const record = useRecordContext()
  const dataProvider = useDataProvider()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    handleDownloadFileButtonClick({ notify, record, dataProvider, resource })
  }

  return (
    <Button onClick={handleClick} label="Download">
      <IconDownload />
    </Button>
  )
}
