import React from 'react'
import {
  Create,
  CreateProps,
  TabbedForm,
  FormTab,
  TextInput,
  BooleanInput,
  required,
} from 'react-admin'
import { AddressInput } from '../../organisms/AddressInput'

/**
 * Page to create a datacenter.
 * @property {CreateProps} props
 * @example
 * <DatacenterCreate />
 * @returns {React.FC<CreateProps>}
 * @see https://marmelab.com/react-admin/Create.html
 */
export const DatacenterCreate: React.FC<CreateProps> = (props) => {
  return (
    <Create {...props} redirect="show">
      <TabbedForm>
        <FormTab label={'general'}>
          <TextInput source="key" validate={required()} fullWidth />
          <TextInput source="name" validate={required()} fullWidth />
          <BooleanInput source="isActive" defaultValue={true} fullWidth />
        </FormTab>
        <FormTab label={'address'}>
          <AddressInput />
        </FormTab>
      </TabbedForm>
    </Create>
  )
}
