import React from 'react'
import {
  List,
  RaRecord,
  DateField,
  FunctionField,
  ChipField,
  ReferenceField,
  WrapperField,
  TextField,
} from 'react-admin'
import { EllipsedTextField } from '../../atoms/EllipsedField'
import { RequestFilters } from '../../molecules/RequestFilters'
import { TypeStateField } from '../../atoms/TypeStateField'
import { ConfigurableLabelledDatagrid } from '../../atoms/LabelledDatagrid'
import { Toolbar } from '../../molecules/Toolbar'
import { ReferenceChip } from '../../molecules/ReferenceChip'
import { Box, Stack } from '@mui/material'

export const RequestFlowList: React.FC = () => {
  return (
    <Stack gap={1} paddingX={2} paddingY={0}>
      <List
        resource="requests"
        sort={{ field: 'submittedAt', order: 'DESC' }}
        title={'Requests'}
        perPage={25}
        actions={<Toolbar showSelectColumns />}
        filters={<RequestFilters />}
        filterDefaultValues={{
          channel: ['portal'],
          state: ['in-progress', 'done'],
        }}
        component={Box}
      >
        <ConfigurableLabelledDatagrid
          rowClick={(id) => `/quote-requests/${id}`}
          bulkActionButtons={false}
          omit={[
            'updatedAt',
            'reference',
            'serviceCategoryId',
            'endCustomerId',
          ]}
        >
          <TextField source="requestNo" emptyText="N/A" />
          <EllipsedTextField source="reference" emptyText="N/A" />
          <TypeStateField source="state" emptyText="N/A" fullWidth />
          <ReferenceField
            label="Category"
            source="serviceCategoryId"
            reference="serviceCategories"
            link={false}
            emptyText="N/A"
          >
            <WrapperField sortBy="name">
              <TextField source="key" />
            </WrapperField>
          </ReferenceField>
          <EllipsedTextField source="reference" emptyText={'N/A'} />
          <ReferenceChip
            referenceSource="clientId"
            tooltipSource="clientNo"
            source="Customer"
            reference="clients"
            emptyText={'N/A'}
            link="show"
            chipSource="name"
          />
          <ReferenceChip
            referenceSource="endCustomerId"
            tooltipSource="name"
            source="End Customer"
            reference="endCustomers"
            emptyText={'N/A'}
            link="show"
            chipSource="name"
          />
          <ReferenceField source="projectId" reference="projects" link={false}>
            <TextField emptyText="N/A" source="title" />
          </ReferenceField>
          <FunctionField
            label="Services"
            render={(record: RaRecord) => (
              <ChipField
                size="small"
                record={{ value: record.services ? record.services.length : 0 }}
                source="value"
              />
            )}
          />
          <DateField source="submittedAt" emptyText="N/A" />
          <DateField source="createdAt" showTime />
          <DateField source="updatedAt" showTime />
        </ConfigurableLabelledDatagrid>
      </List>
    </Stack>
  )
}
