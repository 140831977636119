import React from 'react'
import {
  Create,
  CreateProps,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin'
import { ReferenceAutocomplete } from '../../molecules/ReferenceAutocomplete'
/**
 * Page to create a new project.
 * @property {CreateProps} props
 * @returns {React.FC<CreateProps>}
 */
export const ProjectCreate: React.FC<CreateProps> = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="title" validate={required()} fullWidth />
        <ReferenceAutocomplete
          required
          label="Client"
          source="clientId"
          reference="clients/list"
        />
      </SimpleForm>
    </Create>
  )
}
