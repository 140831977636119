import * as L from 'leaflet'
const mapBoxKey = process.env.REACT_APP_MAPBOX_KEY

export const useTileLayers = () => {
  const layers: Record<string, L.TileLayer> = {
    'Google Maps Streets': L.tileLayer(
      'http://{s}.google.com/vt?lyrs=m&x={x}&y={y}&z={z}',
      {
        attribution: '&copy; Google Maps',
        maxZoom: 20,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      }
    ),
    'Google Maps Hybrid': L.tileLayer(
      'http://{s}.google.com/vt?lyrs=s,h&x={x}&y={y}&z={z}',
      {
        maxZoom: 20,
        attribution: '&copy; Google Maps',
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      }
    ),
    'MapBox Streets': L.tileLayer(
      'https://api.mapbox.com/styles/v1/mapbox/streets-v12/tiles/{z}/{x}/{y}?access_token={accessToken}',
      {
        tileSize: 512,
        zoomOffset: -1,
        attribution: '&copy; Mapbox, OpenStreetMap',
        accessToken: mapBoxKey || '',
      }
    ),
    'MapBox Hybrid': L.tileLayer(
      'https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v12/tiles/{z}/{x}/{y}?access_token={accessToken}',
      {
        tileSize: 512,
        zoomOffset: -1,
        attribution: '&copy; Mapbox, OpenStreetMap',
        accessToken: mapBoxKey || '',
      }
    ),
    'OSM Streets': L.tileLayer(
      'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      {
        attribution: '&copy; OpenStreetMap',
        maxNativeZoom: 19,
        maxZoom: 20,
        subdomains: ['a', 'b', 'c'],
      }
    ),
  }

  if (!mapBoxKey) {
    delete layers['MapBox Streets']
    delete layers['MapBox Hybrid']
  }

  return layers
}
