import { ICurrencyField } from './CurrencyField.types'
import React from 'react'
import { TextField } from 'react-admin'
import { useValue } from './CurrencyField.hooks'

/**
 * CurrencyField component to display formatted currency values
 *
 * @param props - The component's props
 * @returns The CurrencyField component
 * @see https://marmelab.com/react-admin/Inputs.html#using-a-custom-input-component
 * @see https://mui.com/components/text-fields/#api
 * @see https://mui.com/components/text-fields/#alignment
 * @see https://mui.com/components/text-fields/#label
 * @see https://mui.com/components/text-fields/#record
 *
 */
export const CurrencyField: React.FC<ICurrencyField> = ({
  textAlign,
  label,
  ...props
}) => {
  const value = useValue(
    props.source,
    props.currency,
    props.divide,
    props.suffix
  )
  return (
    <TextField
      {...props}
      textAlign={textAlign || 'right'}
      label={label || 'Number'}
      source="value"
      record={{ value }}
    />
  )
}
