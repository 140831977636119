import { SaveButton, Toolbar, useFormGroup, useTranslate } from 'react-admin'
import { useWizardFormContext } from '@react-admin/ra-form-layout'
import { Grid } from '@mui/material'
import { useFormState, useFormContext } from 'react-hook-form'
import { Button } from '../../atoms/Button'
import { Button as MaterialButton, ButtonProps } from '@mui/material'

import { useEffect } from 'react'

const NextButton = ({ disabled: disabledProp, ...rest }: ButtonProps) => {
  const translate = useTranslate()
  const { isValidating } = useFormState()
  const wizardFormContext = useWizardFormContext()
  const { isValid } = useFormGroup(`step-${wizardFormContext.currentStep}`)

  const disabled = disabledProp || isValidating || !isValid

  const label = translate('ra-form-layout.action.next')

  return (
    <MaterialButton
      variant="contained"
      color="primary"
      disabled={disabled}
      type="submit"
      aria-label={label}
      {...rest}
    >
      {label}
    </MaterialButton>
  )
}

export const CustomToolbar = ({
  isSentImmediate,
  setIsOnPreviewStep,
}: {
  isSentImmediate: boolean
  setIsOnPreviewStep: (value: boolean) => void
}) => {
  const {
    hasNextStep,
    hasPreviousStep,
    goToNextStep,
    goToPreviousStep,
    currentStep,
  } = useWizardFormContext()
  const methods = useFormContext()

  useEffect(() => {
    methods.trigger()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep])

  const handleNextClick = async (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    setIsOnPreviewStep(true)
    goToNextStep()
  }

  const handlePreviousClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault()
    setIsOnPreviewStep(false)
    goToPreviousStep()
  }

  return (
    <Toolbar sx={{ backgroundColor: 'inherit' }}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          {hasPreviousStep ? (
            <Button
              onClick={handlePreviousClick}
              variant="contained"
              color="primary"
            >
              PREVIOUS
            </Button>
          ) : null}
        </Grid>
        <Grid item>
          {hasNextStep ? (
            <NextButton onClick={handleNextClick}>NEXT</NextButton>
          ) : isSentImmediate ? (
            <SaveButton label="Save & Send" alwaysEnable={true} />
          ) : (
            <SaveButton />
          )}
        </Grid>
      </Grid>
    </Toolbar>
  )
}
