import React from 'react'
import { Box } from '@mui/material'
import { OverviewMetricsSegment } from '../../templates/OverviewMetricsSegment'
import { PendingRequestsSegment } from '../../templates/PendingRequestsSegment'
import { NewestTicketsSegment } from '../../templates/NewestTicketsSegment'

/**
 * Dashboard template.
 * @returns {React.FC} Dashboard template.
 */
export const Dashboard: React.FC = () => {
  return (
    <Box>
      <OverviewMetricsSegment />
      <PendingRequestsSegment />
      <NewestTicketsSegment />
    </Box>
  )
}
