import React from 'react'
import {
  Create,
  CreateProps,
  SimpleForm,
  TextInput,
  SelectInput,
  ReferenceInput,
  NumberInput,
  FormDataConsumer,
  required,
} from 'react-admin'
import { useChoices } from './MarginBook.hooks'

/**
 * Page to create a new margin book.
 * @property {CreateProps} props The properties passed to the component.
 * @returns {React.FC<CreateProps>} A react functional component.
 * @see https://marmelab.com/react-admin/Create.html
 * @see https://marmelab.com/react-admin/Inputs.html
 */
export const MarginBookCreate: React.FC<CreateProps> = (props) => {
  const choices = useChoices()

  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" validate={required()} fullWidth />
        <SelectInput
          source="type"
          validate={required()}
          choices={choices}
          fullWidth
        />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.type === 'offset' && (
              <>
                <ReferenceInput
                  source="offsetMarginBookId"
                  reference="marginBooks/list"
                >
                  <SelectInput
                    label="Margin book to offset"
                    validate={required()}
                    fullWidth
                  />
                </ReferenceInput>
                <NumberInput
                  source="offsetNrc"
                  defaultValue={0}
                  options={{ minimumFractionDigits: 2 }}
                  validate={required()}
                />
                <NumberInput
                  source="offsetMrc"
                  defaultValue={0}
                  options={{ minimumFractionDigits: 2 }}
                  validate={required()}
                />
              </>
            )
          }
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  )
}
