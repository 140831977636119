import React from 'react'
import { Datagrid, TextField, EditButton } from 'react-admin'
import { ContactDetails } from './QuoteOrderDetailsAccordion.component'

export const ContactDetail: React.FC<{
  contact?: ContactDetails
}> = ({ contact }) => {
  return (
    <Datagrid bulkActionButtons={false}>
      <TextField source="name" record={contact} />
      <TextField source="emailAddress" record={contact} />
      <TextField source="phoneNumber" record={contact} />
      <EditButton resource={`clientContacts`} record={contact} />
    </Datagrid>
  )
}
