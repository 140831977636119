import React from 'react'
import {
  Edit,
  EditProps,
  TabbedForm,
  FormTab,
  TextInput,
  NumberInput,
  SelectInput,
  FormDataConsumer,
  useGetRecordId,
} from 'react-admin'
import { PageTitle } from '../../atoms/PageTitle'
import { ReferenceAutocomplete } from '../../molecules/ReferenceAutocomplete'
import { MarkdownInput } from '@react-admin/ra-markdown'
import Typography from '@mui/material/Typography'
import { MetaListInput } from '../../organisms/MetaList'

/**
 * Page to edit a single request.
 * @property {EditProps} props
 * @returns {JSX.Element}
 * @example <RequestEdit />
 */
export const RequestEdit: React.FC<EditProps> = (props) => {
  const recordId = useGetRecordId()

  return (
    <Edit
      {...props}
      title={<PageTitle prefix="Request" />}
      redirect={`/quote-requests/${recordId}`}
    >
      <TabbedForm>
        <FormTab label="general">
          <TextInput source="id" disabled fullWidth />
          <TextInput source="reference" fullWidth />
          <TextInput source="projectId" label={'Project'} disabled fullWidth />
          <ReferenceAutocomplete
            label="Client"
            emptyText="No"
            source="clientId"
            reference="clients/list"
          />
          <ReferenceAutocomplete
            label="End Customer"
            emptyText="No"
            source="endCustomerId"
            reference="endCustomers/list"
          />
          <FormDataConsumer>
            {({ formData }) =>
              formData.clientId && (
                <ReferenceAutocomplete
                  emptyText="No"
                  label="Existing Project"
                  source="existingProjectId"
                  reference="projects/list"
                  filter={{ clientId: formData.clientId }}
                  disableSyncWithLocation
                />
              )
            }
          </FormDataConsumer>
          <NumberInput source="priority" fullWidth />
          <SelectInput
            label="State"
            source="state"
            fullWidth
            choices={[
              { id: 'draft', name: 'Draft' },
              { id: 'in-progress', name: 'In Progress' },
              { id: 'done', name: 'Done' },
              { id: 'archived', name: 'Archived' },
            ]}
          />
        </FormTab>
        <FormTab label="notes">
          <MarkdownInput source="notes" fullWidth />
          <Typography variant={'subtitle1'}>
            Warning: below notes will be visible to the client.
          </Typography>
          <MarkdownInput source="notesForClient" fullWidth />
        </FormTab>
        <FormTab label="meta">
          <MetaListInput source="meta" />
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}
