import { AuthProvider } from 'ra-core'
import { UserIdentity } from 'react-admin'

import { apiHttpClient } from './dataProvider'

const authProvider: AuthProvider = {
  login: ({ username, password, code }) => {
    const refreshToken = localStorage.getItem('refreshToken')

    if (refreshToken && code) {
      const options = {
        method: 'POST',
        data: { code, refreshToken },
        headers: new Headers({ 'Content-Type': 'application/json' }),
        noRejection: true,
      }
      return apiHttpClient(
        `${process.env.REACT_APP_API_URL}/auth/user/token`,
        options
      )
        .then(({ json, status }: any) => {
          if (json.accessToken && json.refreshToken) {
            localStorage.setItem('token', json.accessToken)
            localStorage.setItem('refreshToken', json.refreshToken)

            if (json.user) {
              localStorage.setItem('user', JSON.stringify(json.user))
            }

            return Promise.resolve()
          }
        })
        .catch((error: any) => {
          if (error.response) {
            const { data } = error.response

            if (data.isLoggedOut) {
              localStorage.removeItem('token')
              localStorage.removeItem('refreshToken')
              localStorage.removeItem('user')

              setTimeout(() => {
                window.location.reload()
              }, 1000)
            }

            if (data.message) {
              return Promise.reject(
                data.message || 'Login failed. Please try again.'
              )
            }
          }
        })
    } else {
      const options = {
        method: 'POST',
        data: { email: username, password },
        headers: new Headers({ 'Content-Type': 'application/json' }),
      }
      return apiHttpClient(
        `${process.env.REACT_APP_API_URL}/auth/user/login`,
        options
      ).then(({ json }) => {
        if (json) {
          if (json.accessToken) {
            localStorage.setItem('token', json.accessToken)
            localStorage.setItem('refreshToken', json.refreshToken)
            localStorage.removeItem('twoFactorEnabled')

            if (json.user) {
              localStorage.setItem('user', JSON.stringify(json.user))
            }

            setTimeout(Promise.resolve, 500)
          } else if (json.refreshToken && json.twoFactorEnabled) {
            localStorage.setItem('twoFactorEnabled', 'true')
            localStorage.setItem('refreshToken', json.refreshToken)

            return Promise.reject('Two factor authentication required')
          }
        }
      })
    }
  },
  logout: () => {
    const refreshToken = localStorage.getItem('refreshToken')

    localStorage.removeItem('token')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('user')
    localStorage.removeItem('twoFactorEnabled')

    if (refreshToken) {
      const options = {
        method: 'POST',
        data: { refreshToken },
      }

      apiHttpClient(
        `${process.env.REACT_APP_API_URL}/auth/user/logout`,
        options
      )
    }

    return Promise.resolve()
  },
  checkAuth: () =>
    localStorage.getItem('token') ? Promise.resolve() : Promise.reject(),
  checkError: (error) => {
    const status = error.status
    if (status === 401) {
      const refreshToken = localStorage.getItem('refreshToken')

      if (refreshToken && localStorage.getItem('token')) {
        const options = {
          method: 'POST',
          data: { refreshToken },
          headers: new Headers({ 'Content-Type': 'application/json' }),
        }

        return apiHttpClient(
          `${process.env.REACT_APP_API_URL}/auth/user/token`,
          options
        ).then(({ json }) => {
          if (json) {
            if (json.accessToken) {
              localStorage.setItem('token', json.accessToken)
              localStorage.setItem('refreshToken', json.refreshToken)
              return Promise.resolve()
            } else if (json.isLoggedOut) {
              return Promise.reject('You have been logged out')
            }
          } else {
            return Promise.reject('Invalid response from server')
          }
        })
      } else {
        return Promise.reject('Your session has expired')
      }
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve()
  },
  getIdentity: () => {
    return apiHttpClient(
      `${process.env.REACT_APP_API_URL}/auth/user/profile`
    ).then(({ json }) => {
      if (json) {
        localStorage.setItem('user', JSON.stringify(json))
        return Promise.resolve<UserIdentity>({
          id: json.id,
          fullName: json.name || json.email,
          role: json.role,
          jiraId: json.jiraId,
        })
      } else {
        return Promise.reject('Could not retrieve user profile')
      }
    })
  },
  getPermissions: () => Promise.resolve(''),
  getRoles: () => Promise.reject('Not implemented yet'),
}

export default authProvider
