import { FC } from 'react'
import { useRecordContext, useRedirect } from 'react-admin'
import { RequestServiceTabsProps } from './RequestServiceTabs.types'
import { startCase } from 'lodash'
import { TabbedShowLayout } from '../../molecules/CustomTabbedShowLayout/CustomTabbedShowLayout.component'

/** Expects a RequestService as the record in context. */
export const RequestServiceTabs: FC<RequestServiceTabsProps> = (props) => {
  const record = useRecordContext(props)
  const redirect = useRedirect()

  const requestServicesFromCurrentSite = props.requestServices
    ?.filter(
      (serviceFromCurrentRequest) =>
        serviceFromCurrentRequest.diversityRole !== 'none' &&
        serviceFromCurrentRequest.sites?.some((site) =>
          record?.sites?.some(
            (siteFromCurrentRecord) => site.id === siteFromCurrentRecord.id
          )
        )
    )
    .sort((a, b) => {
      const order = ['primary', 'secondary', 'tertiary']
      return order.indexOf(a.diversityRole) - order.indexOf(b.diversityRole)
    })

  const handleTabClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    const serviceId = (event as unknown as { target: { id: string } }).target
      ?.id
    redirect(`/quote-requests/${props.requestId}/services/${serviceId}`)
    return null
  }

  return requestServicesFromCurrentSite.length > 0 ? (
    <TabbedShowLayout
      syncWithLocation={false}
      value={requestServicesFromCurrentSite.findIndex(
        (service: { id: string }) => service.id === props.serviceId
      )}
    >
      {requestServicesFromCurrentSite.map(
        (service: { id: string; diversityRole: string }, index) => (
          <TabbedShowLayout.Tab
            value={index}
            id={service.id}
            label={startCase(service.diversityRole)}
            onClick={handleTabClick}
          >
            &nbsp;
          </TabbedShowLayout.Tab>
        )
      )}
    </TabbedShowLayout>
  ) : null
}
