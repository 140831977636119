import { MonitorMetricCreate } from './Create.component'
import { MonitorMetricEdit } from './Edit.component'
import { MonitorMetricList } from './List.component'
import { MonitorMetricShow } from './Show.component'

export default {
  list: MonitorMetricList,
  show: MonitorMetricShow,
  create: MonitorMetricCreate,
  edit: MonitorMetricEdit,
}
