import React from 'react'
import {
  SimpleShowLayout,
  TextField,
  BooleanField,
  DateField,
} from 'react-admin'
import { ReferenceChip } from '../../molecules/ReferenceChip'
import { TypeStateField } from '../../atoms/TypeStateField'
import { useStyles } from './Onboarding.styles'

/**
 * Displays the details of a single onboarding request.
 * @returns {React.ReactElement}
 * @example <OnboardingRequest />
 * @see https://marmelab.com/react-admin/Fields.html
 */
export const OnboardingRequest: React.FC = () => {
  const styles = useStyles()

  return (
    <SimpleShowLayout sx={{ '& .RaSimpleShowLayout-stack': styles }}>
      <TextField source="description" emptyText={'N/A'} />
      <TypeStateField source="state" />
      <ReferenceChip
        referenceSource="clientId"
        tooltipSource="clientNo"
        source="name"
        chipSource="name"
        reference="clients"
        label="Related Client"
        emptyText={'N/A'}
        link="show"
      />
      <ReferenceChip
        referenceSource="userId"
        tooltipSource="email"
        source="name"
        chipSource="name"
        reference="users"
        label="Related User"
        emptyText={'N/A'}
        link="show"
      />
      <TypeStateField source="type" />
      <BooleanField source="requireApproval" />
      <BooleanField source="requireVerification" />
      <TextField source="environment.ip" label="IP address" emptyText={'N/A'} />
      <TextField
        source="environment.user-agent"
        label="User agent"
        emptyText={'N/A'}
      />
      <DateField source="createdAt" showTime />
      <DateField source="verifiedAt" emptyText={'N/A'} showTime />
      <DateField source="updatedAt" showTime />
    </SimpleShowLayout>
  )
}
