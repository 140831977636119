import { CoverageZoneShow } from './Show.component'
import { CoverageZoneList } from './List.component'
import { CoverageZoneCreate } from './Create.component'
import { CoverageZoneEdit } from './Edit.component'

export default {
  list: CoverageZoneList,
  show: CoverageZoneShow,
  create: CoverageZoneCreate,
  edit: CoverageZoneEdit,
}
