import React from 'react'
import {
  Show,
  ShowProps,
  TabbedShowLayout,
  Tab,
  TextField,
  DateField,
  BooleanField,
} from 'react-admin'
import { ReferenceChip } from '../../molecules/ReferenceChip'
import { MetaListDisplay } from '../../organisms/MetaList'
import { PageTitle } from '../../atoms/PageTitle'
import { HistoryDisplayTab } from '../../templates/HistoryDisplayTab'
import { ContactDisplayTab } from '../../templates/ContactDisplayTab'
import { TypeStateField } from '../../atoms/TypeStateField'

/**
 * Page to show a single Client Contact.
 * @property ShowProps props
 * @returns React.FC<ShowProps>
 * @see https://marmelab.com/react-admin/Show.html
 * @see https://marmelab.com/react-admin/Fields.html
 * @see https://marmelab.com/react-admin/Inputs.html
 */
export const ClientContactShow: React.FC<ShowProps> = (props) => {
  return (
    <Show {...props} title={<PageTitle prefix="Client Contact" />}>
      <TabbedShowLayout {...props}>
        <Tab label="general">
          <ReferenceChip
            referenceSource="clientId"
            tooltipSource="clientNo"
            source="name"
            chipSource="name"
            label={'Client'}
            reference="clients"
            emptyText={'N/A'}
            link="show"
            removeDoubleLabel
          />
          <TypeStateField source="role" />
          <TextField source="name" fullWidth />
          <BooleanField source="isActive" />
          <BooleanField source="isPrimary" />
          <DateField source="updatedAt" showTime />
          <DateField source="createdAt" showTime />
        </Tab>
        <Tab label="contact">
          <ContactDisplayTab />
        </Tab>
        <Tab label={'meta'}>
          <MetaListDisplay source="meta" />
        </Tab>
        <Tab label={'history'}>
          <HistoryDisplayTab />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
