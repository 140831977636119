import React from 'react'
import {
  Create,
  CreateProps,
  BooleanInput,
  TextInput,
  required,
  TabbedForm,
  FormTab,
  SelectInput,
  FormDataConsumer,
} from 'react-admin'
import { ReferenceAutocomplete } from '../../molecules/ReferenceAutocomplete'
import { Message } from '../../atoms/Message'

/**
 * Page to create a new onboarding.
 * @todo general tab shares common design with others components.
 * It should be extracted to a common component.
 * @param props The properties
 * @returns The jsx component
 * @example <OnboardingCreate />
 */
export const OnboardingCreate: React.FC<CreateProps> = (props) => {
  return (
    <Create {...props}>
      <TabbedForm>
        <FormTab label="general" key="general">
          <SelectInput
            source="type"
            defaultValue="full"
            validate={required()}
            choices={[
              { id: 'full', name: 'Full (both client and user)' },
              { id: 'user', name: 'User only' },
            ]}
            fullWidth
          />
          <TextInput source="description" label="Description" fullWidth />
          <FormDataConsumer>
            {({ formData }) =>
              formData?.type !== 'full' &&
              (formData?.type === 'user' ? (
                <ReferenceAutocomplete
                  source="clientId"
                  reference="clients/list"
                  disableSyncWithLocation
                  label="Related to Client"
                  required
                />
              ) : (
                <ReferenceAutocomplete
                  source="userId"
                  reference="users/list"
                  disableSyncWithLocation
                  label="Related to User"
                  required
                />
              ))
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData }) =>
              (formData?.type === 'full' || formData?.type === 'user') && (
                <BooleanInput
                  source="requireVerification"
                  label="Require e-mail verification"
                  defaultValue={true}
                  fullWidth
                />
              )
            }
          </FormDataConsumer>
          <BooleanInput
            source="requireApproval"
            label="Require on-boarding completion by staff"
            disabled
            defaultValue={true}
            fullWidth
          />
        </FormTab>
        <FormTab label={'user'}>
          <FormDataConsumer>
            {({ formData }) =>
              !['user', 'full'].includes(formData?.type) ? (
                <Message message="Not possible to pre-fill for this type of on-boarding" />
              ) : (
                <>
                  <TextInput
                    source="user.name"
                    label="User name"
                    validate={[required()]}
                    fullWidth
                  />
                  <TextInput
                    source="user.email"
                    label="User e-mail"
                    validate={[required()]}
                    fullWidth
                  />
                </>
              )
            }
          </FormDataConsumer>
        </FormTab>
        <FormTab label={'client'}>
          <FormDataConsumer>
            {({ formData }) =>
              !['client', 'full'].includes(formData?.type) ? (
                <Message message="Not possible to pre-fill for this type of on-boarding" />
              ) : (
                <>
                  <TextInput
                    source="client.name"
                    validate={[required()]}
                    fullWidth
                  />
                  <TextInput
                    source="client.companyName"
                    validate={[required()]}
                    fullWidth
                  />
                </>
              )
            }
          </FormDataConsumer>
        </FormTab>
      </TabbedForm>
    </Create>
  )
}
