import React from 'react'
import { RaRecord, Identifier } from 'react-admin'

/**
 * Secondary text on a note in a list.
 * @returns {React.FC}
 * @example
 * import { SecondaryText } from './SecondaryText.component.tsx'
 * <SecondaryText />
 */
export const SecondaryText: React.FC<RaRecord<Identifier>> = (props) => {
  return (
    <>
      {`Written by ${props.user?.name} regarding 
        ${props.relatedName ? `a ${props.relatedName} of` : ''} ${
        props.client?.name
      }`}
    </>
  )
}
