import React from 'react'
import { Edit, EditProps, SimpleForm, TextInput, required } from 'react-admin'
import { PageTitle } from '../../atoms/PageTitle'
import { ReferenceAutocomplete } from '../../molecules/ReferenceAutocomplete'

/**
 * Page to edit a Service Type.
 * @property { EditProps } props The properties of the Edit component.
 * @returns { React.ReactNode } The Service Type Edit page.
 * @example
 * import { ServiceTypeEdit } from './ServiceTypeEdit';
 * <ServiceTypeEdit {...props} />
 * @see https://marmelab.com/react-admin/Edit.html
 * @see https://marmelab.com/react-admin/Fields.html
 */
export const ServiceTypeEdit: React.FC<EditProps> = (props) => (
  <Edit
    {...props}
    title={<PageTitle prefix="Service Type" />}
    mutationMode="undoable"
  >
    <SimpleForm>
      <TextInput disabled label="Id" source="id" fullWidth />
      <TextInput source="key" validate={required()} fullWidth />
      <TextInput source="name" validate={required()} fullWidth />
      <TextInput source="description" fullWidth />
      <ReferenceAutocomplete
        required
        fullWidth
        label="Service category"
        source="serviceCategoryId"
        reference="serviceCategories"
      />
    </SimpleForm>
  </Edit>
)
