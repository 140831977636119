import React from 'react'
import Typography from '@mui/material/Typography'
import Box, { BoxProps } from '@mui/material/Box'

/**
 * VersionLabel component - contains the current version of the application.
 * @example
 * <VersionLabel />
 * @returns JSX.Element
 */
export const VersionLabel: React.FC<BoxProps> = (props) => {
  const version = process.env.REACT_APP_VERSION || 'development'

  return (
    <Box {...props}>
      <Typography variant="subtitle1">GNX+ Manager</Typography>
      <Typography variant="subtitle2">Version {version}</Typography>
    </Box>
  )
}
