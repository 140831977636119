import React from 'react'
import { Typography } from '@mui/material'
import { IMessage } from './Message.types'
import { useStyle } from './Message.styles'

/**
 * Component that displays a message -- adds styling to plain text.
 * @component
 * @param {IMessage} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 * @example
 * return (
 * <Message message="No results found" />
 * )
 * @see {@link https://mui.com/api/typography/|Typography}
 * @see {@link https://mui.com/styles/api/#makestyles-styles-options-hook|makeStyles()}
 * @see {@link https://mui.com/styles/basics/|Material UI Styles Basics}
 * @see {@link https://mui.com/styles/advanced/|Material UI Styles Advanced}
 */
export const Message: React.FC<IMessage> = (props) => {
  const styles = useStyle()
  return (
    <Typography sx={styles} variant="body2">
      {props.message}
    </Typography>
  )
}
