import { RaThemeOptions } from 'react-admin'

/**
 * The common theme styles.
 * @exports baseTheme
 * @see https://mui.com/customization/default-theme/
 */
export const baseTheme: RaThemeOptions = {
  palette: {
    contrastThreshold: 3,
    tonalOffset: 0.3,
  },
  sidebar: {
    width: 84,
    closedWidth: 34,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          whiteSpace: 'nowrap',
          fontWeight: 'bold',
        },
      },
      defaultProps: {
        variant: 'filled',
        margin: 'dense',
        size: 'small',
      },
    },
    MuiToolbar: {
      styleOverrides: {
        regular: {
          minHeight: '24px',
          '@media (min-width: 600px)': {
            minHeight: '32px',
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
          '&$disabled': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'filled',
        margin: 'dense',
        size: 'small',
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'filled',
        margin: 'dense',
        size: 'small',
      },
    },
  },
}
