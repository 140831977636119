export interface ProvisioningUpdate {
  sendImmediate: boolean
  estimatedDeliveryDate: Date
  notes: string
  provisioningStatus: ProvisioningStatus
  ragStatus: string
  isOnHold: boolean
  serviceId: string
  userId: string
}

export enum ProvisioningStatus {
  SiteSurvey = 'site-survey',
  Installation = 'installation',
  Configuration = 'configuration',
  Testing = 'testing',
  HandedOver = 'handed-over',
  OnHold = 'onHold',
}
