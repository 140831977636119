import React from 'react'
import {
  Edit,
  EditProps,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  NumberInput,
  required,
  TextInput,
} from 'react-admin'
import { PageTitle } from '../../atoms/PageTitle'

/**
 * Page to edit a margin rule.
 * @property {EditProps} props
 * @returns {React.FC<EditProps>}
 * @example
 * <MarginRuleEdit {...props} />
 * @see https://marmelab.com/react-admin/Edit.html
 */
export const MarginRuleEdit: React.FC<EditProps> = (props) => {
  return (
    <Edit
      {...props}
      mutationMode="undoable"
      title={<PageTitle prefix="Margin Rule" />}
    >
      <SimpleForm>
        <TextInput disabled label="Id" source="id" fullWidth />
        <ReferenceInput source="marginBookId" reference="marginBooks/list">
          <SelectInput
            label="Margin book"
            optionText="name"
            validate={required()}
            fullWidth
          />
        </ReferenceInput>
        <ReferenceInput
          source="accessTechnologyId"
          reference="accessTechnologies/list"
        >
          <SelectInput label="Access technology" optionText="name" fullWidth />
        </ReferenceInput>
        <ReferenceInput source="serviceTypeId" reference="serviceTypes/list">
          <SelectInput label="Service type" optionText="name" fullWidth />
        </ReferenceInput>
        <SelectInput
          source="priceCategory"
          validate={required()}
          choices={[
            { id: 'nrc', name: 'Non-recurring' },
            { id: 'mrc', name: 'Monthly recurring' },
          ]}
          fullWidth
        />
        <NumberInput source="priceFrom" validate={required()} fullWidth />
        <NumberInput source="priceUntil" fullWidth />
        <SelectInput
          source="marginType"
          validate={required()}
          choices={[
            { id: 'absolute', name: 'Absolute' },
            { id: 'percentage', name: 'Percentage' },
          ]}
          fullWidth
        />
        <NumberInput source="marginValue" validate={required()} fullWidth />
      </SimpleForm>
    </Edit>
  )
}
