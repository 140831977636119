import React from 'react'
import {
  Filter,
  ReferenceInput,
  AutocompleteArrayInput,
  SelectInput,
} from 'react-admin'
import { IClientVisibilityFilters } from './ClientVisibilityFilters.types'

/**
 * Filter by client and visibility.
 * @property {IClientVisibilityFilters} props The filter properties.
 * @returns {JSX.Element} The element to render.
 * @example
 * <ClientVisibilityFilters />
 * @see https://marmelab.com/react-admin/List.html#the-filter-button
 */
export const ClientVisibilityFilters: React.FC<IClientVisibilityFilters> = (
  props
) => {
  return (
    <Filter {...props}>
      <ReferenceInput source="clientId" reference="clients/list" alwaysOn>
        <AutocompleteArrayInput label={'Client'} optionText="name" />
      </ReferenceInput>
      <SelectInput
        source="isInternal"
        label={'Visibility'}
        choices={[
          { id: false, name: 'Public' },
          { id: true, name: 'Internal' },
        ]}
        alwaysOn
      />
    </Filter>
  )
}
