import React from 'react'
import { Toolbar } from '../../molecules/Toolbar'
import { FileGenerator } from '../FileGenerator'
import { UploadFileModal } from '../../molecules/UploadFileModal'

/**
 * Toolbar for the document file list page.
 * @returns {React.FC}
 * @example
 * import { DocumentFileToolbar } from './DocumentFileToolbar.component.tsx'
 * <DocumentFileToolbar />
 * @see {@link https://mui.com/components/toolbar/|MUI Toolbar}
 */
export const DocumentFileToolbar: React.FC = () => {
  return (
    <Toolbar>
      <UploadFileModal resource="documents" />
      <FileGenerator />
    </Toolbar>
  )
}
