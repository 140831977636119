import React from 'react'
import { Create, CreateProps, SimpleForm } from 'react-admin'
import { ClientContractForm } from '../../organisms/ClientContractForm'
import { PageTitle } from '../../atoms/PageTitle'

/**
 * Page for creating a new client contract.
 * @param {CreateProps} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 * @example
 * return (
 * <ClientContractCreate />
 * )
 * @see {@link https://marmelab.com/react-admin/Create.html|Create}
 */
export const ClientContractCreate: React.FC<CreateProps> = (props) => {
  return (
    <Create {...props} title={<PageTitle prefix="Client contract" />}>
      <SimpleForm>
        <ClientContractForm />
      </SimpleForm>
    </Create>
  )
}
