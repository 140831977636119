import React from 'react'
import { Box, ToggleButtonGroup, ToggleButton } from '@mui/material'
import { useStore } from 'react-admin'
import { ISideBar } from './RelatedResource.types'

/**
 * Related resource page side bar navigation.
 * @property {ISideBar} props
 * @returns {JSX.Element}
 * @example
 * <RelatedResourcesSideBar pages={pages} />
 */
export const RelatedResourcesSideBar: React.FC<ISideBar> = (props) => {
  const defaultPage = Object.keys(props.pages)[0]
  const [selectedRelatedResource, setSelectedRelatedResource] = useStore(
    `selected.${props.resource}.related.resource`,
    defaultPage
  )

  const handleChange = (_: React.MouseEvent<HTMLElement>, newPage: string) => {
    setSelectedRelatedResource(newPage ?? defaultPage)
  }

  return (
    <Box mr={'1em'} sx={{ order: -1 }}>
      <ToggleButtonGroup
        color="primary"
        value={selectedRelatedResource}
        exclusive
        onChange={handleChange}
        aria-label="Platform"
        orientation="vertical"
      >
        {Object.keys(props.pages).map((resource) => (
          <ToggleButton key={resource} value={resource}>
            {resource}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Box>
  )
}
