import React from 'react'
import {
  List,
  ListProps,
  Datagrid,
  TextField,
  DateField,
  BooleanField,
} from 'react-admin'
import { ReferenceChip } from '../../molecules/ReferenceChip'
import { ServiceHealthIndicatorField } from '../../atoms/ServiceHealthIndicatorField'
import { MonitorProbeFilters } from '../../molecules/MonitorProbeFilters'

/**
 * List of monitor probes.
 * @param props The List component props
 * @see https://marmelab.com/react-admin/List.html
 */
export const MonitorProbeList: React.FC<ListProps> = (props) => {
  return (
    <List
      {...props}
      empty={false}
      filters={<MonitorProbeFilters />}
      sort={{ field: 'name', order: 'ASC' }}
      perPage={25}
    >
      <Datagrid rowClick="show">
        <TextField source="name" emptyText="N/A" />
        <ReferenceChip
          referenceSource="monitorTargetId"
          tooltipSource="hostname"
          source="Target"
          chipSource="hostname"
          reference="monitorTargets"
          emptyText="N/A"
          link="show"
        />
        <ReferenceChip
          referenceSource="monitorProbeTypeId"
          tooltipSource="name"
          source="Type"
          chipSource="name"
          reference="monitorProbeTypes"
          emptyText="N/A"
          link="show"
        />
        <ServiceHealthIndicatorField
          label="Health"
          source="health"
          sortable={false}
        />
        <BooleanField source="isActive" fullWidth />
        <BooleanField source="isMuted" defaultChecked={false} fullWidth />
        <DateField source="updatedAt" showTime />
      </Datagrid>
    </List>
  )
}
