import React from 'react'
import {
  Create,
  CreateProps,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  NumberInput,
  required,
} from 'react-admin'

/**
 * Page to create a margin rule.
 * @property {CreateProps} props
 * @returns {React.FC<CreateProps>}
 * @example
 * <MarginRuleCreate {...props} />
 * @see https://marmelab.com/react-admin/Create.html
 */
export const MarginRuleCreate: React.FC<CreateProps> = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <ReferenceInput source="marginBookId" reference="marginBooks/list">
          <SelectInput
            label="Margin book"
            optionText="name"
            validate={required()}
            fullWidth
          />
        </ReferenceInput>
        <ReferenceInput
          source="accessTechnologyId"
          reference="accessTechnologies/list"
        >
          <SelectInput label="Access technology" optionText="name" fullWidth />
        </ReferenceInput>
        <ReferenceInput source="serviceTypeId" reference="serviceTypes/list">
          <SelectInput label="Service type" optionText="name" fullWidth />
        </ReferenceInput>
        <SelectInput
          source="priceCategory"
          validate={required()}
          choices={[
            { id: 'nrc', name: 'Non-recurring' },
            { id: 'mrc', name: 'Monthly recurring' },
          ]}
          fullWidth
        />
        <NumberInput source="priceFrom" validate={required()} fullWidth />
        <NumberInput source="priceUntil" fullWidth />
        <SelectInput
          source="marginType"
          validate={required()}
          choices={[
            { id: 'absolute', name: 'Absolute' },
            { id: 'percentage', name: 'Percentage' },
          ]}
          fullWidth
        />
        <NumberInput source="marginValue" validate={required()} fullWidth />
      </SimpleForm>
    </Create>
  )
}
