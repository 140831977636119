import React from 'react'
import { PageTitle } from '../../atoms/PageTitle'
import { ReferenceChipList } from '../../molecules/ReferenceChipList'
import {
  Show,
  ShowProps,
  TabbedShowLayout,
  Tab,
  TextField,
  DateField,
} from 'react-admin'
import { HistoryDisplayTab } from '../../templates/HistoryDisplayTab'

/**
 * Page to show the details of a user group.
 * @property {ShowProps} props
 * @returns {React.FC<ShowProps>}
 * @example
 * import { UserGroupShow } from './UserGroupShow.component'
 * <UserGroupShow {...props} />
 * @see https://marmelab.com/react-admin/Show.html
 * @see https://marmelab.com/react-admin/Fields.html
 * @see https://marmelab.com/react-admin/TabbedShowLayout.html
 */
export const UserGroupShow: React.FC<ShowProps> = (props) => {
  return (
    <Show {...props} title={<PageTitle prefix="User group" />}>
      <TabbedShowLayout {...props}>
        <Tab label="summary">
          <TextField source="name" />
          <DateField source="updatedAt" showTime />
          <DateField source="createdAt" showTime />
          <ReferenceChipList
            label={'Clients'}
            reference="clients"
            source="clientIds"
          />
          <ReferenceChipList
            label={'Permissions'}
            reference="permissions"
            source="permissionIds"
          />
        </Tab>
        <Tab label={'history'}>
          <HistoryDisplayTab />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
