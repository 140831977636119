/**
 * EllipsedField styles.
 * @returns {React.CSSProperties} The ellipsed field styles.
 */
export const useEllipsedFieldStyles = (props: { maxWidth?: number }) => {
  return {
    display: 'block',
    maxWidth: props?.maxWidth ?? 200,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  }
}
