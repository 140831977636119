import React from 'react'
import { TextField, Labeled } from 'react-admin'

/**
 * Tab for displaying notes.
 * @returns {JSX.Element} The rendered component.
 * @example
 * return (
 * <NotesDisplay />
 * )
 */
export const NotesDisplay: React.FC = () => {
  return (
    <>
      <Labeled label="Internal notes">
        <TextField source="notes" emptyText={'N/A'} fullWidth />
      </Labeled>
      <Labeled label="Notes for client">
        <TextField source="notesForClient" emptyText={'N/A'} fullWidth />
      </Labeled>
    </>
  )
}
