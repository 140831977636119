/**
 * Select options for the price book
 * @returns {Array} Array of options
 * @example
 * const options = useChoices()
 */
export const useChoices = () => {
  return [
    { id: 'draft', name: 'Draft' },
    { id: 'pending', name: 'Pending' },
    { id: 'approved', name: 'Approved' },
    { id: 'applied', name: 'Applied' },
    { id: 'archived', name: 'Archived' },
    { id: 'denied', name: 'Denied' },
  ]
}
