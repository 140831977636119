/**
 * Styles for the IFrame component.
 * @returns {React.CSSProperties} The style of the component.
 */
export const useIFrameStyle = () => {
  return {
    height: '90%',
    minHeight: 600,
    maxWidth: 1280,
    padding: '1cm',
    width: '23cm',
    margin: '0 auto',
  }
}
