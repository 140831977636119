import React from 'react'
import {
  FormDataConsumer,
  TextInput,
  BooleanInput,
  required,
} from 'react-admin'
import { ReferenceAutocomplete } from '../../molecules/ReferenceAutocomplete'
import { AddressInput } from '../../organisms/AddressInput'

/**
 * Form for creating a new client, or selecting an existing one.
 * @returns {React.ReactElement}
 * @example <OnboardingClientForm />
 * @see https://marmelab.com/react-admin/Inputs.html
 */
export const OnboardingClientForm: React.FC = () => {
  return (
    <FormDataConsumer>
      {({ formData }) =>
        ['client', 'full'].includes(formData?.type) ? (
          <>
            <TextInput
              source="client.name"
              validate={required()}
              defaultValue={formData.client?.companyName}
              fullWidth
            />
            <AddressInput
              addressSource="client.address"
              countrySource="client.countryCode"
              citySource="client.city"
              postalCodeSource="client.postalCode"
              streetNameSource="client.streetName"
              streetNumberSource="client.streetNumber"
              streetNumberSuffixSource="client.streetNumberSuffix"
              streetNumberRoomSource="client.streetNumberRoom"
            />
            <TextInput source="client.email" validate={required()} fullWidth />
            <TextInput
              source="client.companyName"
              validate={required()}
              fullWidth
            />
            <TextInput source="client.companyRegNo" fullWidth />
            <TextInput source="client.companyVatNo" fullWidth />
            <TextInput source="client.companyIban" fullWidth />
            <BooleanInput
              source="client.isActive"
              defaultValue={true}
              fullWidth
            />
          </>
        ) : (
          <ReferenceAutocomplete
            source={'clientId'}
            label={'Existing Client'}
            required
            reference="clients/list"
            emptyText={'N/A'}
            link={false}
          />
        )
      }
    </FormDataConsumer>
  )
}
