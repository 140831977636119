import React from 'react'
import { PageTitle } from '../../atoms/PageTitle'
import { Show, ShowProps, TabbedShowLayout, Tab, TextField } from 'react-admin'
import { HistoryDisplayTab } from '../../templates/HistoryDisplayTab'

/**
 * Page to show permissions.
 * @property {ShowProps} props
 * @returns {JSX.Element}
 * @example
 * <PermissionShow {...props} />
 * @see {@link https://marmelab.com/react-admin/Show.html|Show}
 * @see {@link https://marmelab.com/react-admin/Fields.html|Fields}
 * @see {@link https://marmelab.com/react-admin/TabbedShowLayout.html|TabbedShowLayout}
 */
export const PermissionShow: React.FC<ShowProps> = (props) => {
  return (
    <Show {...props} title={<PageTitle prefix="Permission" />}>
      <TabbedShowLayout {...props}>
        <Tab label="summary">
          <TextField source="id" />
          <TextField source="name" />
          <TextField source="description" emptyText={'N/A'} />
          <TextField source="resource" />
          <TextField source="action" />
        </Tab>
        <Tab label={'history'}>
          <HistoryDisplayTab />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
