import { UserCreate } from './Create.component'
import { UserEdit } from './Edit.component'
import { UserList } from './List.component'
import { UserShow } from './Show.component'

export default {
  list: UserList,
  show: UserShow,
  edit: UserEdit,
  create: UserCreate,
}
