import React from 'react'
import {
  List,
  ListProps,
  Datagrid,
  TextField,
  DateField,
  BooleanField,
} from 'react-admin'
import { SearchFilter } from '../../atoms/SearchFilter'
import { ReferenceChip } from '../../molecules/ReferenceChip'
import { TypeStateField } from '../../atoms/TypeStateField'

/**
 * Page to list client contacts.
 * @property {ListProps} props
 * @returns {React.FC<ListProps>}
 * @example
 * import { ClientContactList } from './ClientContactList'
 * <List {...props}>
 * @see https://marmelab.com/react-admin/List.html
 * @see https://marmelab.com/react-admin/Filter.html
 * @see https://marmelab.com/react-admin/Sort.html
 */
export const ClientContactList: React.FC<ListProps> = (props) => {
  return (
    <List
      empty={false}
      perPage={25}
      filters={<SearchFilter />}
      sort={{ field: 'createdAt', order: 'DESC' }}
      {...props}
    >
      <Datagrid rowClick="show">
        <ReferenceChip
          referenceSource="clientId"
          source="Client"
          chipSource="name"
          tooltipSource="clientNo"
          reference="clients"
          emptyText={'N/A'}
          link="show"
        />
        <TextField source="name" />
        <TypeStateField source="role" />
        <BooleanField source="isActive" />
        <BooleanField source="isPrimary" />
        <DateField source="updatedAt" showTime />
        <DateField source="createdAt" showTime />
      </Datagrid>
    </List>
  )
}
