import { useRecordContext } from 'react-admin'
import get from 'lodash/get'
import { IMetaListSources } from './MetaList.types'

/**
 * Hook that returns the record with the meta list.
 * @param {IMetaListSources['source']} source The source of the meta list.
 * @returns {RaRecord} The record with the meta list.
 * @exports useGenerateMetaListRecord
 * @see https://marmelab.com/react-admin/Fields.html#useRecordContext
 * @see https://lodash.com/docs/4.17.15#get
 * @see https://marmelab.com/react-admin/Fields.html#record
 */
export const useGenerateMetaListRecord = (
  source: IMetaListSources['source']
) => {
  const record = useRecordContext()
  const metaListObject = get(record, source)

  if (record) {
    if (!record._jsonObjectInput) {
      record._jsonObjectInput = {}
    }

    record._jsonObjectInput[source] = metaListObject
      ? Object.keys(metaListObject).map((property) => ({
          property,
          value:
            typeof metaListObject[property] === 'object'
              ? JSON.stringify(metaListObject[property])
              : metaListObject[property],
        }))
      : []
  }

  return { record, source: `_jsonObjectInput[${source}]` }
}
