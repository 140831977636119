import { FieldValues } from 'react-hook-form'
import { get } from 'lodash'

/**
 * Returns the choices for the mode field.
 * @param {FieldValues} formData - The form data.
 * @param {string} typeField - The type field.
 * @returns {Array} The mode choices.
 */
export const modeChoices = (formData: FieldValues, typeField: string) => {
  const typeValue = get(formData, typeField)
  if (typeValue === 'copper') {
    return [
      { id: 'auto-negotiation', name: 'Auto-negotiation' },
      { id: 'full-duplex', name: 'Full-duplex' },
      { id: 'half-duplex', name: 'Half-duplex' },
    ]
  }
  return [
    { id: 'sm-1310nm', name: 'Single-mode 1310nm' },
    { id: 'sm-1550nm', name: 'Single-mode 1550nm' },
    { id: 'mm-850nm', name: 'Multi-mode 850nm' },
    { id: 'mm-1310nm', name: 'Multi-mode 1310nm' },
  ]
}
