import React from 'react'
import { SearchFilter } from '../../atoms/SearchFilter'
import { List, ListProps, Datagrid, TextField } from 'react-admin'

/**
 * Page to list permissions.
 * @property {ListProps} props
 * @returns {JSX.Element}
 * @example
 * <PermissionList {...props} />
 * @see {@link https://marmelab.com/react-admin/List.html|List}
 * @see {@link https://marmelab.com/react-admin/Fields.html|Fields}
 * @see {@link https://marmelab.com/react-admin/Datagrid.html|Datagrid}
 */
export const PermissionList: React.FC<ListProps> = (props) => {
  return (
    <List
      {...props}
      empty={false}
      perPage={25}
      filters={<SearchFilter />}
      sort={{ field: 'id', order: 'ASC' }}
    >
      <Datagrid rowClick="show">
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="resource" />
        <TextField source="action" />
      </Datagrid>
    </List>
  )
}
