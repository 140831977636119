import React from 'react'
import {
  useRecordContext,
  useDataProvider,
  SimpleList,
  RaRecord,
  Identifier,
} from 'react-admin'
import { Message } from '../../atoms/Message'
import { styles } from './TicketCommentList.styles'
import { PrimaryText, SecondaryText, TertiaryText } from '../../atoms/Comment'

/**
 * Component that displays a list of comments on a ticket.
 * @returns {React.FC}
 * @example
 * import { TicketCommentList } from './TicketCommentList.component.tsx'
 * <TicketCommentList />
 * @see {@link https://marmelab.com/react-admin/List.html|List}
 */
export const TicketCommentList: React.FC = () => {
  const record = useRecordContext()
  const dataProvider = useDataProvider()
  const [comments, setComments] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(true)
  const [error, setError] = React.useState(false)

  React.useEffect(() => {
    const loadComments = async () => {
      try {
        const data = await dataProvider.customRequest(
          'tickets',
          `${record.id}/comments`,
          { method: 'GET' }
        )
        setComments(data.json?.data)
        setIsLoading(false)
      } catch (error: any) {
        setComments([])
        setError(error.message)
        setIsLoading(false)
      }
    }
    loadComments()
  }, [setIsLoading, setError, setComments, record.id, dataProvider])

  if (isLoading) {
    return <Message message="Loading comments" />
  }
  if (error) {
    return <Message message="Something went wrong" />
  }
  if (!comments) {
    return <Message message="No comments to display" />
  }
  if (comments.length === 0) {
    return <Message message="No comments to display" />
  }

  return (
    <SimpleList
      linkType={false}
      data={comments}
      primaryText={<PrimaryText />}
      secondaryText={(record: RaRecord<Identifier>) => (
        <SecondaryText {...record} />
      )}
      tertiaryText={(record: RaRecord<Identifier>) => (
        <TertiaryText {...record} />
      )}
      rowStyle={(record: RaRecord<Identifier>) => styles(record.isInternal)}
    />
  )
}
