import { ServicePortCreate } from './Create.component'
import { ServicePortEdit } from './Edit.component'
import { ServicePortList } from './List.component'
import { ServicePortShow } from './Show.component'

export default {
  list: ServicePortList,
  show: ServicePortShow,
  create: ServicePortCreate,
  edit: ServicePortEdit,
}
