import React from 'react'
import { Grid } from '@mui/material'
import { ReferenceAutocomplete } from '../../molecules/ReferenceAutocomplete'
import { FieldValues } from 'react-hook-form/dist/types/fields'

/**
 * Selects the entry for the template previewer.
 * @param {FieldValues} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 * @example
 * return (
 * <SelectEntry />
 * )
 * @see {@link https://mui.com/components/grid/|MUI Grid}
 * @see {@link https://mui.com/components/autocomplete/|MUI Autocomplete}
 */
export const SelectEntry: React.FC<FieldValues> = (props) => {
  const reference =
    props.context?.model === 'ClientContract'
      ? 'clientContracts/list'
      : 'serviceOrders/list'

  if (props.context?.model === 'Client') {
    return (
      <Grid item xs={4} p={1}>
        <ReferenceAutocomplete
          label="Entry ID"
          reference="clients/list"
          source="context.id"
        />
      </Grid>
    )
  }

  return (
    <Grid item xs={4} p={1}>
      <ReferenceAutocomplete
        label="Entry Id"
        reference={reference}
        source="context.id"
        filter={{ clientId: props.clientId }}
      />
    </Grid>
  )
}
