import React from 'react'
import { PageTitle } from '../../atoms/PageTitle'
import {
  Show,
  ShowProps,
  DateField,
  TextField,
  ImageField,
  TabbedShowLayout,
  Tab,
  TabbedShowLayoutTabs,
  ShowButton,
  ReferenceArrayField,
} from 'react-admin'
import { LabelledDatagrid } from '../../atoms/LabelledDatagrid'

/**
 * Page to show an existing LastMileProvider.
 * @param props The page props.
 * @returns JSX.Element to render.
 */
export const LastMileProviderShow: React.FC<ShowProps> = (props) => {
  return (
    <Show {...props} title={<PageTitle prefix="Last mile provider" />}>
      <TabbedShowLayout
        tabs={
          <TabbedShowLayoutTabs variant="scrollable" scrollButtons="auto" />
        }
      >
        <Tab label="general">
          <TextField source="name" fullWidth />
          <ImageField source="logo" fullWidth />
          <DateField source="createdAt" showTime />
        </Tab>
        <Tab label="vendors">
          <ReferenceArrayField
            reference="vendors"
            source="vendorIds"
            label={'Vendors'}
            sort={{ field: 'name', order: 'ASC' }}
          >
            <LabelledDatagrid bulkActionButtons={false} rowClick={'show'}>
              <TextField source="name" />
              <ShowButton resource={'vendors'} />
            </LabelledDatagrid>
          </ReferenceArrayField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
