import React from 'react'
import {
  FormDataConsumer,
  TextInput,
  BooleanInput,
  required,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
} from 'react-admin'
import AddIcon from '@mui/icons-material/AddOutlined'
import { Box, Typography, Button } from '@mui/material'

/**
 * Form for creating a new contact.
 * @returns {React.ReactElement}
 * @example <OnboardingContactsForm />
 * @see https://marmelab.com/react-admin/Inputs.html
 */
export const OnboardingContactsForm: React.FC = () => {
  return (
    <FormDataConsumer>
      {({ formData }) =>
        ['client', 'full'].includes(formData?.type) ? (
          <>
            <ArrayInput
              source="contacts"
              label={false}
              sx={{ mb: 3 }}
              fullWidth
            >
              <SimpleFormIterator
                getItemLabel={(index) => `#${index + 1}`}
                addButton={
                  <Button>
                    <AddIcon />
                    &nbsp;Add Contact
                  </Button>
                }
                fullWidth
              >
                <FormDataConsumer>
                  {({ getSource }) => (
                    <Box sx={{ width: '100%' }}>
                      <Box
                        display={{ sm: 'block', md: 'flex' }}
                        sx={{ p: 0.5 }}
                      >
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                          <SelectInput
                            source={getSource?.('category') || ''}
                            choices={[
                              { id: 'general', name: 'General' },
                              { id: 'billing', name: 'Billing' },
                              { id: 'maintenance', name: 'Maintenance' },
                              { id: 'delivery', name: 'Delivery' },
                            ]}
                            fullWidth
                          />
                        </Box>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                          <TextInput
                            source={getSource?.('name') || ''}
                            validate={required()}
                            fullWidth
                          />
                        </Box>
                      </Box>
                      <Box
                        display={{ sm: 'block', md: 'flex' }}
                        sx={{ p: 0.5 }}
                      >
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                          <TextInput
                            source={getSource?.('emailAddress') || ''}
                            validate={required()}
                            fullWidth
                          />
                        </Box>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                          <TextInput
                            source={getSource?.('phoneNumber') || ''}
                            fullWidth
                          />
                        </Box>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                          <TextInput
                            source={getSource?.('mobileNumber') || ''}
                            fullWidth
                          />
                        </Box>
                      </Box>
                      <Box
                        display={{ sm: 'block', md: 'flex' }}
                        sx={{ p: 0.5 }}
                      >
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                          <BooleanInput
                            source={getSource?.('isPrimary') || ''}
                            fullWidth
                          />
                        </Box>
                      </Box>
                    </Box>
                  )}
                </FormDataConsumer>
              </SimpleFormIterator>
            </ArrayInput>
          </>
        ) : (
          <Typography variant="body2" p={2}>
            This type of on-boarding flow does not involve creating new
            contacts.
          </Typography>
        )
      }
    </FormDataConsumer>
  )
}
