import React from 'react'
import {
  Edit,
  EditProps,
  SimpleForm,
  BooleanInput,
  required,
} from 'react-admin'
import { ReferenceAutocomplete } from '../../molecules/ReferenceAutocomplete'
import { RelatedInputs } from '../../molecules/RelatedInputs'
import { MarkdownInput } from '@react-admin/ra-markdown'

/**
 * Page for editing an existing note.
 * @property {EditProps} props
 * @returns {React.FC<EditProps>}
 * @example
 * return <NoteEdit {...props} />
 * @see https://marmelab.com/react-admin/Edit.html
 */
export const NoteEdit: React.FC<EditProps> = (props) => {
  return (
    <Edit {...props} redirect={'list'}>
      <SimpleForm>
        <ReferenceAutocomplete
          required
          label="Client"
          source="clientId"
          reference="clients/list"
        />
        <RelatedInputs />
        <BooleanInput source="isInternal" />
        <MarkdownInput source="content" validate={required()} />
      </SimpleForm>
    </Edit>
  )
}
