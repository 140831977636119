import React from 'react'
import { Box, BoxProps } from '@mui/material'

/**
 * Consistent vertical spacing between components.
 * @example <Segment />
 * @see {@link https://mui.com/components/box/#main-content|Box}
 * @see {@link https://mui.com/system/spacing/#main-content|Spacing}
 */
export const Segment: React.FC<BoxProps> = (props) => {
  return (
    <Box my={2} {...props}>
      {props.children}
    </Box>
  )
}
