import React from 'react'
import {
  Show,
  ShowProps,
  TabbedShowLayout,
  Tab,
  TextField,
  DateField,
  ShowButton,
} from 'react-admin'
import { PageTitle } from '../../atoms/PageTitle'
import { SiteDisplay } from '../../organisms/SiteDisplay'
import { TypeStateField } from '../../atoms/TypeStateField'
import { EllipsedTextField } from '../../atoms/EllipsedField'
import { ReferenceChip } from '../../molecules/ReferenceChip'
import { MutationsList } from '../../organisms/MutationsList'
import { ReferenceManyTab } from '../../atoms/FormTab'
import { LabelledDatagrid } from '../../atoms/LabelledDatagrid'
import { Map } from '../../atoms/Map'

/**
 * Page to show a service site.
 * @property {ShowProps} props
 * @example
 * <ServiceSiteShow />
 * @returns {React.FC<ShowProps>}
 * @see https://marmelab.com/react-admin/Show.html
 */
export const ServiceSiteShow: React.FC<ShowProps> = (props) => {
  return (
    <Show {...props} title={<PageTitle prefix="Service site" />}>
      <TabbedShowLayout {...props}>
        <Tab label="general">
          <SiteDisplay />
        </Tab>
        <ReferenceManyTab
          reference="services"
          target="siteIds"
          label="services"
        >
          <LabelledDatagrid bulkActionButtons={false}>
            <TextField source="serviceNo" />
            <TypeStateField source="state" />
            <EllipsedTextField source="reference" emptyText="N/A" />
            <EllipsedTextField source="name" />
            <ReferenceChip
              referenceSource="clientId"
              tooltipSource="clientNo"
              source="Client"
              reference="clients"
              emptyText="N/A"
              link="show"
              chipSource="name"
            />
            <ReferenceChip
              referenceSource="projectId"
              tooltipSource="projectNo"
              source="Project"
              reference="projects"
              emptyText="N/A"
              link="show"
              chipSource="title"
            />
            <DateField source="updatedAt" showTime />
            <ShowButton />
          </LabelledDatagrid>
        </ReferenceManyTab>
        <Tab label="geometry">
          <Map style={{ height: '70vh' }} />
        </Tab>
        <Tab label={'history'}>
          <MutationsList showActor type="history" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
