import React from 'react'
import { useGetList } from 'react-admin'
import { IconCard } from '../../molecules/IconCard/IconCard.component'
import QuotesIcon from '@mui/icons-material/RequestQuoteOutlined'

/**
 * A card with offered quotes metrics.
 * @example <OfferedQuotesCard />
 */
export const OfferedQuotesCard: React.FC = () => {
  const offeredQuotes = useGetList('quotes/list', {
    filter: { state: 'sent' },
    pagination: { page: 1, perPage: 10 },
    sort: { field: 'createdAt', order: 'DESC' },
  })

  return (
    <IconCard
      icon={QuotesIcon}
      to={'/quotes?filter=%7B"state"%3A%5B"sent"%5D%7D'}
      subtitle={`${offeredQuotes?.total || 'N/A'}`}
      title={'Open (sent) quotes'}
    />
  )
}
