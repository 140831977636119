import React from 'react'
import {
  Edit,
  EditProps,
  required,
  TextInput,
  SelectInput,
  BooleanInput,
  SimpleForm,
} from 'react-admin'
import { ReferenceAutocomplete } from '../../molecules/ReferenceAutocomplete/ReferenceAutocomplete.component'
import { ContactDetailsInput } from '../../organisms/ContactDetailsInput'
import { useRoleChoices } from './VendorContact.hooks'

/**
 * Page to edit a vendor contact.
 * @property {EditProps} props
 * @returns {React.FC<EditProps>}
 * @example
 * import { VendorContactEdit } from './VendorContactEdit'
 * <Edit {...props}>
 * @see https://marmelab.com/react-admin/CreateEdit.html
 * @see https://marmelab.com/react-admin/Inputs.html
 * @see https://marmelab.com/react-admin/TabbedForm.html
 * @see https://marmelab.com/react-admin/BooleanInput.html
 */
export const VendorContactEdit: React.FC<EditProps> = (props) => {
  const roleChoices = useRoleChoices()

  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" validate={required()} fullWidth />
        <ReferenceAutocomplete
          source={'vendorId'}
          label={'Vendor'}
          reference="vendors/list"
          emptyText={'N/A'}
          link={false}
          required
        />
        <SelectInput
          validate={required()}
          source="role"
          choices={roleChoices}
          fullWidth
        />
        <ContactDetailsInput />
        <BooleanInput source="isActive" defaultValue={true} fullWidth />
      </SimpleForm>
    </Edit>
  )
}
